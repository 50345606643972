import { Button, Select } from 'antd';
import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';

const SelectWithCreate = ({
  placeholder,
  options,
  handleCreate,
  loadingCreate,
  value,
  onChange,
  disabled,
  loadingSelect,
  className,
}) => {
  const [newOption, setNewOption] = useState('');

  return (
    <Select
      placeholder={placeholder}
      filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
      filterSort={(optionA, optionB) =>
        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
      }
      disabled={disabled}
      loading={loadingSelect}
      showSearch
      value={value}
      onChange={onChange}
      optionFilterProp="children"
      onSearch={e => setNewOption(e)}
      notFoundContent={
        newOption ? (
          <Button block loading={loadingCreate} icon={<PlusOutlined />} onClick={() => handleCreate(newOption)}>
            Tambah Baru
          </Button>
        ) : (
          'Belum ada data, silahkan ketik untuk menambahkan'
        )
      }
      options={options || []}
      className={className}
    />
  );
};

export default SelectWithCreate;
