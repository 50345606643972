import { Avatar, Button, Form, Modal, Space, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowLeftOutlined, EditOutlined } from '@ant-design/icons';
import ScheduleForm from './ScheduleForm';
import MemberForm from './MemberForm';
import { createSchedule, getSchedules, updateSchedule } from '../../services/action';
import { alertSuccess } from '../../../../helpers/alert';
import moment from 'moment';
import { getFirstChar } from '../../../../helpers/string';

const ModalCreate = ({ isOpen, onClose, selected, setSelected }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [step, setStep] = useState(1);
  const loading = useSelector(state => state.schedule.loadingSubmit);
  const params = useSelector(state => state.schedule.params);

  useEffect(() => {
    if (isOpen) {
      setStep(1);
      if (selected) {
        form.setFieldsValue({
          ...selected,
          start_date: moment(selected.start_date),
          end_date: moment(selected.end_date),
        });
      }
    }
  }, [isOpen]);

  const handleOk = () => {
    form
      .validateFields()
      .then(values => {
        if (selected) {
          dispatch(updateSchedule(selected.id, values)).then(() => {
            alertSuccess('Jadwal kegiatan berhasil diubah');
            setSelected();
            handleClose();
            dispatch(getSchedules(params));
          });
        } else {
          dispatch(createSchedule(values)).then(() => {
            alertSuccess('Jadwal kegiatan berhasil dibuat');
            handleClose();
            dispatch(getSchedules(params));
          });
        }
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });
  };

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  const handlePrev = () => {
    setStep(1);
  };

  const handleNext = () => {
    form
      .validateFields()
      .then(values => {
        console.log(values);
        setStep(2);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });
  };

  return (
    <Modal
      width={400}
      title={
        step === 1 ? (
          `${selected ? 'Ubah' : 'Buat'} Jadwal Kegiatan`
        ) : (
          <Space>
            <ArrowLeftOutlined onClick={handlePrev} />
            <div>Anggota yang Terlibat</div>
          </Space>
        )
      }
      maskClosable={false}
      centered
      open={isOpen}
      onOk={step === 1 && !selected ? handleNext : handleOk}
      onCancel={step === 1 ? handleClose : handlePrev}
      cancelText={step === 1 ? 'Batal' : 'Kembali'}
      okText={step === 1 && !selected ? 'Lanjutkan' : 'Simpan'}
      confirmLoading={loading}
      wrapClassName="py-3"
      bodyStyle={{ padding: 0 }}
    >
      <Form name="basic" layout="vertical" form={form}>
        {selected && step === 1 && (
          <div className="p-6 py-3 border-b-2">
            <div className="mb-2">Anggota yang terlibat</div>
            <div className="center-y justify-between">
              <Avatar.Group
                maxCount={4}
                maxPopoverPlacement="bottom"
                maxStyle={{
                  color: '#f56a00',
                  backgroundColor: '#fde3cf',
                  cursor: 'pointer',
                }}
              >
                {selected?.participants?.map((item, idx) => (
                  <Tooltip key={idx} title={`${item?.first_name} ${item?.last_name}`}>
                    <Avatar style={{ backgroundColor: item.bg_color, cursor: 'default' }}>
                      {getFirstChar(item?.first_name) + getFirstChar(item?.last_name)}
                    </Avatar>
                  </Tooltip>
                ))}
                {selected?.participants?.length === 0 && '-'}
              </Avatar.Group>
              <Button size="small" type="link" onClick={handleNext} icon={<EditOutlined />}>
                Ubah
              </Button>
            </div>
          </div>
        )}
        <ScheduleForm visible={step === 1} />
        <Form.Item name="participants" noStyle hidden={step !== 2}>
          <MemberForm visible={true} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalCreate;
