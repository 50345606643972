import React, { useState } from 'react';
import { Col, List, Pagination, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { BUCKET_URL } from '../../../../configs/config';
import { getDocuments } from '../../services/action';
import DocumentViewer from '../../../../components/DocumentViewer';
import DocumentItem from './DocumentItem';
import ModalAccess from '../access/ModalAccess';
import ModalEdit from '../edit/ModalEdit';

const DocumentList = () => {
  const dispatch = useDispatch();
  const documents = useSelector(state => state.document.documents);
  const documentParams = useSelector(state => state.document.documentParams);
  const loadingDocuments = useSelector(state => state.document.loadingDocuments);
  const documentPagination = useSelector(state => state.document.documentPagination);
  const [docSelected, setDocSelected] = useState(null);
  const [openViewer, setOpenViewer] = useState(false);
  const [modalAccess, setModalAccess] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);

  const onPaginationChange = (page, limit) => {
    dispatch(getDocuments({ ...documentParams, page, limit }));
  };

  const handlePreview = doc => {
    setDocSelected(doc);
    setOpenViewer(true);
  };

  const handleAccess = doc => {
    setDocSelected(doc);
    setModalAccess(true);
  };

  const handleEdit = doc => {
    setDocSelected(doc);
    setModalEdit(true);
  };

  const handleCloseViewer = () => {
    setOpenViewer(false);
    setDocSelected(null);
  };

  return (
    <div className="mt-2">
      <List
        className="bg-white rounded-md border"
        loading={loadingDocuments}
        footer={
          <div className="px-5">
            <Pagination
              current={documentPagination.current}
              onChange={onPaginationChange}
              total={documentPagination.total}
              showSizeChanger
              pageSize={documentPagination.limit}
              pageSizeOptions={[5, 10, 25, 50]}
            />
          </div>
        }
        header={
          <Row className="px-5 font-semibold">
            <Col flex={1}>Nama Dokumen</Col>
            <Col style={{ width: 160 }}>Document Owner</Col>
            <Col style={{ width: 150 }}>Tanggal Upload</Col>
            <Col style={{ width: 130 }}>Last Modified</Col>
            <Col style={{ width: 80, textAlign: 'center' }}>Action</Col>
          </Row>
        }
        dataSource={documents}
        renderItem={item => <DocumentItem {...{ item, handlePreview, handleAccess, handleEdit }} />}
      />
      <DocumentViewer
        open={openViewer}
        onClose={handleCloseViewer}
        fileName={docSelected?.document_name}
        subFileName={docSelected?.category + (docSelected?.sub_category ? ` - ${docSelected?.sub_category}` : '')}
        fileUrl={BUCKET_URL + docSelected?.file_path}
      />
      <ModalAccess isOpen={modalAccess} onClose={() => setModalAccess(false)} data={docSelected} />
      <ModalEdit isOpen={modalEdit} onClose={() => setModalEdit(false)} data={docSelected} />
    </div>
  );
};

export default DocumentList;
