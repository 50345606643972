import { notification } from 'antd';
import { DOC_API_URL } from '../../../configs/config';
import { httpAuth } from '../../../helpers/http';

export const getCategories =
  (params, useRedux = true) =>
  async dispatch => {
    try {
      useRedux && dispatch({ type: 'setLoadingCategories', payload: true });
      const res = await httpAuth().get(`${DOC_API_URL}/doc_categories`, { params });
      useRedux && dispatch({ type: 'setCategories', payload: res.data, params });
      useRedux && dispatch({ type: 'setLoadingCategories', payload: false });
      return res.data;
    } catch (err) {
      useRedux && dispatch({ type: 'setLoadingCategories', payload: false });
      throw err?.response;
    }
  };

export const getCategoriById = async id => {
  try {
    const res = await httpAuth().get(`${DOC_API_URL}/doc_categories/${id}`);
    return res.data;
  } catch (err) {
    throw err?.response;
  }
};

export const createCategory = data => async dispatch => {
  try {
    const res = await httpAuth().post(`${DOC_API_URL}/doc_categories`, data);
    return res.data;
  } catch (err) {
    notification.error({
      message: 'Terjadi kesalahan',
      description: err?.response?.data?.message || 'Mohon coba beberapa saat lagi',
    });
    throw err?.response;
  }
};

export const getSubCategories = params => async dispatch => {
  try {
    dispatch({ type: 'setLoadingSubCategories', payload: true });
    const res = await httpAuth().get(`${DOC_API_URL}/doc_sub_categories`, { params });
    dispatch({ type: 'setSubCategories', payload: res.data });
    dispatch({ type: 'setLoadingSubCategories', payload: false });
    return res.data;
  } catch (err) {
    dispatch({ type: 'setLoadingSubCategories', payload: false });
    throw err?.response;
  }
};

export const createSubCategory = data => async dispatch => {
  try {
    const res = await httpAuth().post(`${DOC_API_URL}/doc_sub_categories`, data);
    dispatch({ type: 'pushSubCategory', payload: res.data });
    return res.data;
  } catch (err) {
    notification.error({
      message: 'Terjadi kesalahan',
      description: err?.response?.data?.message || 'Mohon coba beberapa saat lagi',
    });
    throw err?.response;
  }
};

export const getDocuments =
  (params, useRedux = true) =>
  async dispatch => {
    try {
      useRedux && dispatch({ type: 'setLoadingDocuments', payload: true });
      const res = await httpAuth().get(`${DOC_API_URL}/documents`, { params });
      useRedux && dispatch({ type: 'setDocuments', payload: res.data, params });
      useRedux && dispatch({ type: 'setLoadingDocuments', payload: false });
      return res.data;
    } catch (err) {
      useRedux && dispatch({ type: 'setLoadingDocuments', payload: false });
      throw err?.response;
    }
  };

export const createDocument = async (data, disableNotif) => {
  try {
    const res = await httpAuth().post(`${DOC_API_URL}/documents`, data);
    !disableNotif &&
      notification.success({
        message: 'Sukses',
        description: 'Dokumen berhasil ditambahkan',
      });
    return res.data;
  } catch (err) {
    !disableNotif &&
      notification.error({
        message: 'Terjadi kesalahan',
        description: err?.response?.data?.message || 'Mohon coba beberapa saat lagi',
      });
    throw err?.response;
  }
};

export const updateDocument = async (id, data) => {
  try {
    const res = await httpAuth().put(`${DOC_API_URL}/documents/${id}`, data);
    notification.success({
      message: 'Sukses',
      description: 'Dokumen berhasil diupdate',
    });
    return res.data;
  } catch (err) {
    notification.error({
      message: 'Terjadi kesalahan',
      description: err?.response?.data?.message || 'Mohon coba beberapa saat lagi',
    });
    throw err?.response;
  }
};

export const deleteDocument = async id => {
  try {
    const res = await httpAuth().delete(`${DOC_API_URL}/documents/${id}`);
    notification.success({
      message: 'Sukses',
      description: 'Dokumen berhasil dihapus',
    });
    return res.data;
  } catch (err) {
    notification.error({
      message: 'Terjadi kesalahan',
      description: err?.response?.data?.message || 'Mohon coba beberapa saat lagi',
    });
    throw err?.response;
  }
};

export const getDocumentPermissions = async (documentId, params) => {
  try {
    const res = await httpAuth().get(`${DOC_API_URL}/document_permissions/${documentId}`, { params });
    return res.data;
  } catch (err) {
    throw err?.response;
  }
};

export const addDocumentPermission = async data => {
  try {
    const res = await httpAuth().post(`${DOC_API_URL}/document_permissions`, data);
    return res.data;
  } catch (err) {
    throw err?.response;
  }
};

export const deleteDocumentPermission = async id => {
  try {
    const res = await httpAuth().delete(`${DOC_API_URL}/document_permissions/${id}`);
    return res.data;
  } catch (err) {
    throw err?.response;
  }
};
