import { Button, Col, Dropdown, List, Modal, Row } from 'antd';
import docIcon from '../../../../assets/icons/doc.svg';
import {
  EyeOutlined,
  MoreOutlined,
  DownloadOutlined,
  UserAddOutlined,
  EditOutlined,
  DeleteOutlined,
  LoadingOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { downloadFile } from '../../../../helpers/file';
import { formatDateTime } from '../../../../helpers/string';
import { BUCKET_URL } from '../../../../configs/config';
import { useState } from 'react';
import { deleteDocument, getDocuments } from '../../services/action';
import { useDispatch, useSelector } from 'react-redux';

const DocumentItem = ({ item, handlePreview, handleAccess, handleEdit }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const documentParams = useSelector(state => state.document.documentParams);

  const handleDownload = item => {
    setLoading(true);
    downloadFile(BUCKET_URL + item?.file_path, item.document_name).finally(() => setLoading(false));
  };

  const handleDelete = item => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined className="mt-1" />,
      title: 'Apakah anda yakin ingin menghapus dokumen ini?',
      onOk: () => deleteDocument(item?.id).then(() => dispatch(getDocuments(documentParams))),
      okText: 'Iya',
      cancelText: 'Batal',
    });
  };

  return (
    <List.Item style={{ display: 'inherit' }}>
      <Row className="px-5">
        <Col flex={1}>
          <div className="flex items-center">
            <div>
              <img src={docIcon} width={40} />
            </div>
            <div className="ml-3 w-full">
              <div className="pointer hover:text-primary" onClick={() => handlePreview(item)}>
                {item.document_name}
              </div>
              {item.sub_category && <div className="text-gray-400">{item.sub_category}</div>}
            </div>
          </div>
        </Col>
        <Col style={{ width: 160 }} className="flex items-center">
          {item.doc_owner?.first_name ? `${item.doc_owner?.first_name} ${item.doc_owner?.last_name}` : '-'}
        </Col>
        <Col style={{ width: 150 }} className="flex items-center">
          {formatDateTime(item.created_at)}
        </Col>
        <Col style={{ width: 130 }} className="flex items-center">
          {formatDateTime(item.modified_at)}
        </Col>
        <Col style={{ width: 80, textAlign: 'center' }} className="flex items-center justify-center">
          <Dropdown
            disabled={false}
            menu={{
              items: [
                { key: '1', icon: <EyeOutlined />, label: 'Preview', onClick: () => handlePreview(item) },
                { key: '2', icon: <DownloadOutlined />, label: 'Download', onClick: () => handleDownload(item) },
                { key: '3', icon: <UserAddOutlined />, label: 'Access', onClick: () => handleAccess(item) },
                { key: '4', icon: <EditOutlined />, label: 'Edit', onClick: () => handleEdit(item) },
                { key: '5', type: 'divider' },
                { key: '6', icon: <DeleteOutlined />, label: 'Hapus', danger: true, onClick: () => handleDelete(item) },
              ],
            }}
            placement="bottomRight"
          >
            <Button size="small" icon={loading ? <LoadingOutlined /> : <MoreOutlined />} />
          </Dropdown>
        </Col>
      </Row>
    </List.Item>
  );
};

export default DocumentItem;
