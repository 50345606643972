import { Calendar } from 'antd';
import moment from 'moment';
import 'moment/locale/id';
import locale from 'antd/es/date-picker/locale/id_ID';
import HeaderCalendar from './HeaderCalendar';

const CalendarDetail = ({ data }) => {
  const mapDates = (dateValue, startDate, endDate) => {
    const start = moment(startDate);
    const end = moment(endDate);
    if (
      dateValue.format('YYYY-MM-DD') === start.format('YYYY-MM-DD') ||
      dateValue.format('YYYY-MM-DD') === end.format('YYYY-MM-DD')
    ) {
      return (
        <div className="centered h-full">
          <div className="border rounded text-white centered h-full w-full" style={{ background: '#5396C7' }}>
            {dateValue.date()}
          </div>
        </div>
      );
    }
    if (dateValue.isBetween(start, end)) {
      return <div className="bg-blue-50 centered h-full">{dateValue.date()}</div>;
    }
    return <div className="">{dateValue.date()}</div>;
  };

  return (
    <Calendar
      fullscreen={false}
      locale={locale}
      defaultValue={moment(data?.start_date)}
      dateFullCellRender={value => mapDates(value, data?.start_date, data?.end_date)}
      headerRender={({ value, onChange }) => <HeaderCalendar {...{ value, onChange, data }} />}
    />
  );
};

export default CalendarDetail;
