import { Col, Form, Input, Modal, notification, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { assignStructure, editStructure, getStructures } from '../services/action';
import UserAssign from './UserAssign';
import { TeamOutlined, SearchOutlined, LoadingOutlined } from '@ant-design/icons';
import EditForm from './EditForm';
import { debounce } from 'lodash';
import { getUsers } from '../../../stores/user/action';

const ModalAssign = ({ isOpen, onClose, data }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTap] = useState(1);
  const [userId, setUserId] = useState();
  const dispatch = useDispatch();
  const params = useSelector(state => state.user.params);
  const [loadingGetUsers, setLoadingGetUsers] = useState(false);
  const positionsId = Form.useWatch('positions_id', form);
  const divisionsId = Form.useWatch('divisions_id', form);
  const parent = Form.useWatch('parent', form);

  const isDefault = () => {
    return positionsId === data?.positions_id && divisionsId === data?.divisions_id && parent === data?.parent;
  };

  useEffect(() => {
    if (isOpen) {
      setActiveTap(1);
      setUserId(data?.users_id);
      form.setFieldsValue({ positions_id: data?.positions_id, divisions_id: data?.divisions_id, parent: data?.parent });
    }
  }, [isOpen]);

  const handleOk = () => {
    form
      .validateFields()
      .then(async values => {
        try {
          setLoading(true);
          if (!isDefault()) {
            await editStructure(data?.id, { ...values, is_active: true });
          }
          if (data?.users_id !== userId) {
            await assignStructure(data?.id, { users_id: userId });
          }
          notification.success({
            message: 'Sukses',
            description: 'Struktur berhasil diubah',
          });
          dispatch(getStructures());
          setLoading(false);
          handleClose();
        } catch (error) {
          setLoading(false);
        }
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });
  };

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  const handleSearch = debounce(async e => {
    setLoadingGetUsers(true);
    await dispatch(getUsers({ ...params, offset: 0, search: e.target.value }));
    setLoadingGetUsers(false);
  }, 500);

  return (
    <Modal
      width={350}
      title={
        <div>
          <TeamOutlined className="mr-2" /> Pengaturan
        </div>
      }
      centered
      open={isOpen}
      onOk={handleOk}
      okButtonProps={{ disabled: data?.users_id === userId && isDefault() }}
      onCancel={handleClose}
      cancelText="Batal"
      okText="Simpan"
      confirmLoading={loading}
      bodyStyle={{ padding: 0 }}
    >
      <Row className="border-b">
        <Col span={12} className={`tab-item ${activeTab === 1 && 'active'}`} onClick={() => setActiveTap(1)}>
          Assign
        </Col>
        <Col span={12} className={`tab-item ${activeTab === 2 && 'active'}`} onClick={() => setActiveTap(2)}>
          Posisi dan Divisi
        </Col>
      </Row>

      {activeTab === 1 && (
        <div>
          <div className="p-5 py-4 border-b">
            <Input
              placeholder="Cari Anggota"
              onChange={handleSearch}
              prefix={<SearchOutlined />}
              suffix={loadingGetUsers && <LoadingOutlined />}
            />
          </div>
          <UserAssign name="test" data={data} {...{ userId, setUserId }} />
        </div>
      )}
      <Form name="basic" layout="vertical" form={form} style={{ margin: '1.25rem' }}>
        <EditForm data={data} hidden={activeTab !== 2} />
      </Form>
    </Modal>
  );
};

export default ModalAssign;
