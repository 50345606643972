import { Avatar, Button, Calendar, Col, DatePicker, Drawer, Image, Input, Row, Select, Space } from 'antd';
import React, { useState } from 'react';
import { UpOutlined, DownOutlined, CloseOutlined, UserOutlined } from '@ant-design/icons';
import moment from 'moment';
import CheckboxUserGroup from '../pages/schedule/components/create/CheckboxUserGroup';

const data = null;
const DebugTest = props => {
  const [open, setOpen] = useState(false);
  const onPanelChange = (value, mode) => {
    console.log(value.format('YYYY-MM-DD'), mode);
  };
  const onChange = value => {
    console.log(value.format('YYYY-MM-DD'));
  };
  const [dateRange, setDateRange] = useState([]);
  console.log(dateRange);
  // console.log(data?.id?.toString());
  return (
    <div className="p-5">
      <CheckboxUserGroup name="test" items={[{ id: 1 }, { id: 2 }]} />
    </div>
  );
};

export default DebugTest;
