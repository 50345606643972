import React from 'react';
import { Button, Image, Typography } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import empty from '../../../../assets/images/empty-doc.svg';

const EmptyCategory = ({ onCreate }) => {
  return (
    <div className="p-5 h-full flex flex-col">
      <Typography.Title level={4}>Document Management</Typography.Title>
      <div className="centered h-full">
        <div className="text-center border-2 border-gray-300 border-dashed p-5 rounded-xl">
          <Image src={empty} preview={false} width={180} />
          <Typography.Title level={5}>Belum ada dokumen yang bisa ditampilkan</Typography.Title>
          <Typography.Text>
            Silahkan upload dokumen sesuai dengan kategori atau sub kategori yang diinginkan
          </Typography.Text>
          <div className="mt-5">
            <Button type="primary" icon={<UploadOutlined />} onClick={onCreate}>
              Upload Dokumen
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmptyCategory;
