const initialState = {
  structures: {},
  divisions: [],
  positions: [],
  loadingStuctures: false,
};

export default function organization(state = initialState, action) {
  switch (action.type) {
    case 'setStructures':
      return { ...state, structures: action.payload };
    case 'setLoadingStructures':
      return { ...state, loadingStructures: action.payload };
    case 'setDivisions':
      return { ...state, divisions: action.payload?.items || [] };
    case 'setPositions':
      return { ...state, positions: action.payload || [] };

    default:
      return state;
  }
}
