import { Select, Space, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { RightOutlined } from '@ant-design/icons';
import React, { useEffect } from 'react';
import { getDocuments, getSubCategories } from '../../services/action';

const optionAll = { value: 0, label: 'Semua' };

const DocumentHeader = ({ selectedCategory, subCategoryId, setSubCategoryId }) => {
  const dispatch = useDispatch();
  const subCategories = useSelector(state => state.document.subCategories);

  useEffect(() => {
    if (selectedCategory) {
      setSubCategoryId(0);
      dispatch(getSubCategories({ category_id: selectedCategory.id }));
    }
  }, [selectedCategory]);

  const handleFilterSubCategory = value => {
    setSubCategoryId(value);
    dispatch(getDocuments({ category_id: selectedCategory?.id, sub_category_id: value, page: 1 }));
  };

  return (
    <div className="center-y justify-between">
      <Typography.Title level={5} className="center-y">
        <span>Document Management</span>
        {selectedCategory && (
          <>
            <RightOutlined style={{ fontSize: 12, padding: '0 .5rem' }} />
            {selectedCategory?.category}
          </>
        )}
      </Typography.Title>
      {selectedCategory && (
        <Space className="mb-2">
          <Typography.Text>Sub kategori dokumen</Typography.Text>
          <Select
            style={{ width: 200 }}
            options={[optionAll, ...subCategories.map(item => ({ value: item.id, label: item.sub_category }))]}
            value={subCategoryId}
            onChange={handleFilterSubCategory}
          />
        </Space>
      )}
    </div>
  );
};

export default DocumentHeader;
