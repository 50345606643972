import { Space } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import moment from 'moment';

const HeaderCalendar = ({ value, onChange, data }) => {
  useEffect(() => {
    if (data) {
      onChange(value.clone().month(moment(data?.start_date).month()));
    }
  }, [data]);

  return (
    <div className="py-3 center-y justify-between">
      <div className="font-semibold">{value.format('MMMM YYYY')}</div>
      <Space>
        <LeftOutlined onClick={() => onChange(value.clone().month(value.month() - 1))} />
        <RightOutlined onClick={() => onChange(value.clone().month(value.month() + 1))} />
      </Space>
    </div>
  );
};

export default HeaderCalendar;
