import { SSO_URL } from '../../configs/config';
import { httpAuth } from '../../helpers/http';

export const getProfile = () => async dispatch => {
  try {
    const res = await httpAuth().get(`${SSO_URL}/users/me`);
    dispatch({ type: 'set', profile: res.data?.data?.user });
    return res.data;
  } catch (err) {
    throw err?.response;
  }
};

export const updateProfile = async data => {
  try {
    const res = await httpAuth().put(`${SSO_URL}/users/me`, data);
    return res.data;
  } catch (err) {
    throw err?.response;
  }
};

export const updatePassword = async data => {
  try {
    const res = await httpAuth().post(`${SSO_URL}/users/change-password`, data);
    return res.data;
  } catch (err) {
    throw err?.response;
  }
};
