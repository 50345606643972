import React, { useState } from 'react';
import { SpecialZoomLevel, Viewer } from '@react-pdf-viewer/core';
import { Button, Input, Modal, Progress, Space } from 'antd';
import { ArrowLeftOutlined, UpOutlined, DownOutlined, CloseOutlined } from '@ant-design/icons';
import docIcon from '../assets/icons/doc.svg';
import searchIcon from '../assets/icons/find_in_page.svg';
import printIcon from '../assets/icons/print.svg';
import { printPlugin } from '@react-pdf-viewer/print';
import { searchPlugin } from '@react-pdf-viewer/search';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/print/lib/styles/index.css';
import '@react-pdf-viewer/search/lib/styles/index.css';

const renderError = error => {
  let message = '';
  switch (error.name) {
    case 'InvalidPDFException':
      message = 'The document is invalid or corrupted';
      break;
    case 'MissingPDFException':
      message = 'The document is missing';
      break;
    case 'UnexpectedResponseException':
      message = 'Unexpected server response';
      break;
    default:
      console.log(error);
      message = 'Cannot load the document';
      break;
  }

  return (
    <div className="centered" style={{ height: 'calc(100vh - 5rem)' }}>
      <div
        style={{
          backgroundColor: '#e53e3e',
          borderRadius: '0.25rem',
          color: '#fff',
          padding: '0.5rem',
        }}
      >
        {message}
      </div>
    </div>
  );
};

const renderLoader = percent => {
  return (
    <div className="centered text-white" style={{ height: 'calc(100vh - 5rem)' }}>
      <div className="w-80 text-center">
        <Progress percent={Math.round(percent)} showInfo={false} />
        Loading... {Math.round(percent)}%
      </div>
    </div>
  );
};

const SearchElement = ({ renderSearchProps, openSearch, setOpenSearch }) => {
  const handleCloseSearch = () => {
    renderSearchProps.clearKeyword();
    setOpenSearch(false);
  };
  return (
    <div className="relative">
      <img src={searchIcon} className="pointer" onClick={() => setOpenSearch(!openSearch)} />
      {openSearch && (
        <div
          className="p-2 mt-3 bg-white rounded shadow-lg"
          style={{ position: 'absolute', right: 0, width: 350, zIndex: 10 }}
        >
          <Space>
            <Input
              value={renderSearchProps.keyword}
              autoFocus
              onChange={e => renderSearchProps.setKeyword(e.target.value)}
              onKeyDown={e => {
                if (e.key === 'Enter' && renderSearchProps.keyword) {
                  renderSearchProps.search();
                }
              }}
              placeholder="Search"
              suffix={`${renderSearchProps.currentMatch} of ${renderSearchProps.numberOfMatches}`}
            />
            <Button
              disabled={!renderSearchProps.keyword}
              icon={<UpOutlined />}
              onClick={renderSearchProps.jumpToPreviousMatch}
            />
            <Button
              disabled={!renderSearchProps.keyword}
              icon={<DownOutlined />}
              onClick={renderSearchProps.jumpToNextMatch}
            />
            <Button icon={<CloseOutlined />} onClick={handleCloseSearch} />
          </Space>
        </div>
      )}
    </div>
  );
};

const PdfViewer = ({ open, onClose, fileName, subFileName, fileUrl }) => {
  const printPluginInstance = printPlugin();
  const searchPluginInstance = searchPlugin();
  const { Print } = printPluginInstance;
  const { Search } = searchPluginInstance;
  const [openSearch, setOpenSearch] = useState(false);

  const handleBack = () => {
    setOpenSearch(false);
    onClose();
  };

  return (
    <Modal
      open={open}
      width="100vw"
      footer={null}
      centered
      closable={false}
      destroyOnClose
      wrapClassName="modal-pdf-viewer"
      bodyStyle={{ padding: 0, minHeight: '100vh' }}
    >
      <div className="bg-black h-16 center-y justify-between px-5 w-full text-white">
        <div className="flex items-center">
          <ArrowLeftOutlined style={{ color: '#fff', fontSize: 18 }} onClick={handleBack} />
          <div className="ml-5">
            <img src={docIcon} width={35} />
          </div>
          <div className="ml-3">
            <div>{fileName}</div>
            {subFileName && <div style={{ fontSize: 12 }}>{subFileName}</div>}
          </div>
        </div>
        <Space size={16}>
          <Search>
            {renderSearchProps => <SearchElement {...{ renderSearchProps, openSearch, setOpenSearch }} />}
          </Search>
          <Print>{({ onClick }) => <img src={printIcon} className="pointer" onClick={onClick} />}</Print>
        </Space>
      </div>
      <div style={{ height: 'calc(100vh - 4rem)' }}>
        <Viewer
          fileUrl={fileUrl}
          theme="dark"
          renderError={renderError}
          defaultScale={SpecialZoomLevel.ActualSize}
          renderLoader={renderLoader}
          pageLayout={{
            tranformSize: ({ size }) => ({ height: size.height + 18, width: size.height + 18 }),
            buildPageStyles: () => ({ backgroundColor: 'transparent' }),
          }}
          plugins={[printPluginInstance, searchPluginInstance]}
        />
      </div>
    </Modal>
  );
};

export default PdfViewer;
