import { Card, Col, Row, Skeleton, Space } from 'antd';
import React from 'react';
import { Tree, TreeNode } from 'react-organizational-chart';

const TreeContent = () => (
  <div className="tree-container">
    <Card bordered bodyStyle={{ padding: '1rem' }}>
      <Row>
        <Col md={5}>
          <Skeleton.Avatar active size="large" />
        </Col>
        <Col md={19}>
          <Space direction="vertical" className="w-full">
            <Skeleton.Input style={{ height: 18 }} active block />
            <Skeleton.Input style={{ height: 18 }} active block />
          </Space>
        </Col>
      </Row>
    </Card>
  </div>
);
const TreeLoading = () => {
  return (
    <Tree
      lineWidth={'2px'}
      lineHeight={'45px'}
      nodePadding={'1rem'}
      lineColor={'#5396C7'}
      lineBorderRadius={'10px'}
      label={<TreeContent />}
    >
      <TreeNode label={<TreeContent />} />
      <TreeNode label={<TreeContent />} />
      <TreeNode label={<TreeContent />} />
    </Tree>
  );
};

export default TreeLoading;
