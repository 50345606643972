import docIcon from '../../../assets/icons/doc.svg';
import ListTable from '../../../components/ListTable';
import { useDispatch, useSelector } from 'react-redux';
import DropdownAction from './DropdownAction';
import { deleteCertification, getCertifications } from '../services/action';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { alertSuccess } from '../../../helpers/alert';
import { formatDateTime } from '../../../helpers/string';

const CertificationList = ({ handleEdit, handleView, handlePreview }) => {
  const dispatch = useDispatch();
  const certifications = useSelector(state => state.certification.certifications);
  const loadingList = useSelector(state => state.certification.loadingList);
  const pagination = useSelector(state => state.certification.pagination);
  const params = useSelector(state => state.certification.params);

  const onPaginationChange = (page, limit) => {
    dispatch(getCertifications({ ...params, page, limit }));
  };

  const handleDelete = item => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined className="mt-1" />,
      title: 'Apakah anda yakin ingin menghapus skema sertifikasi ini?',
      onOk: () =>
        deleteCertification(item?.id).then(() => {
          dispatch(getCertifications(params));
          alertSuccess('Data skema berhasil dihapus');
        }),
      okText: 'Iya',
      cancelText: 'Batal',
    });
  };

  return (
    <>
      <ListTable
        loading={loadingList}
        pagination={pagination}
        onPaginationChange={onPaginationChange}
        columns={[
          {
            header: 'Nama Skema Sertifikasi',
            key: 'schema_name',
            style: { flex: 1 },
            render: (item, row) => (
              <div className="flex items-center">
                <img src={docIcon} width={30} />
                <div className="ml-3 pointer hover:text-primary" onClick={() => handlePreview(row)}>
                  {item}
                </div>
              </div>
            ),
          },
          { header: 'Tanggal Dibuat', key: 'created_at', style: { width: 150 }, render: cell => formatDateTime(cell) },
          { header: 'Last Modified', key: 'modified_at', style: { width: 130 }, render: cell => formatDateTime(cell) },
          {
            header: 'Action',
            key: 'action',
            style: { width: 80, textAlign: 'center' },
            render: (_, row) => <DropdownAction item={row} {...{ handleDelete, handleEdit, handleView }} />,
          },
        ]}
        data={certifications}
      />
    </>
  );
};

export default CertificationList;
