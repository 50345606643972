import { notification } from 'antd';
import { MASTER_URL } from '../../../configs/config';
import { httpAuth } from '../../../helpers/http';

export const getSchedules = params => async dispatch => {
  try {
    dispatch({ type: 'setLoadingListSchedule', payload: true });
    const res = await httpAuth().get(`${MASTER_URL}/schedules`, { params });
    dispatch({ type: 'setSchedules', payload: res.data, params });
    dispatch({ type: 'setLoadingListSchedule', payload: false });
    return res.data;
  } catch (err) {
    dispatch({ type: 'setLoadingListSchedule', payload: false });
    throw err?.response;
  }
};

export const getTuk = params => async dispatch => {
  try {
    const res = await httpAuth().get(`${MASTER_URL}/tuk`, { params });
    dispatch({ type: 'setTuk', payload: res.data });
    return res.data;
  } catch (err) {
    throw err?.response;
  }
};

export const createSchedule = data => async dispatch => {
  try {
    dispatch({ type: 'setLoadingSubmitSchedule', payload: true });
    const res = await httpAuth().post(`${MASTER_URL}/schedules`, data);
    dispatch({ type: 'setLoadingSubmitSchedule', payload: false });
    return res.data;
  } catch (err) {
    dispatch({ type: 'setLoadingSubmitSchedule', payload: false });
    notification.error({
      message: 'Terjadi kesalahan',
      description: err?.response?.data?.message || 'Mohon coba beberapa saat lagi',
    });
    throw err?.response;
  }
};

export const updateSchedule = (id, data) => async dispatch => {
  try {
    dispatch({ type: 'setLoadingSubmitSchedule', payload: true });
    const res = await httpAuth().put(`${MASTER_URL}/schedules/${id}`, data);
    dispatch({ type: 'setLoadingSubmitSchedule', payload: false });
    return res.data;
  } catch (err) {
    dispatch({ type: 'setLoadingSubmitSchedule', payload: false });
    notification.error({
      message: 'Terjadi kesalahan',
      description: err?.response?.data?.message || 'Mohon coba beberapa saat lagi',
    });
    throw err?.response;
  }
};

export const deleteSchedule = async id => {
  try {
    const res = await httpAuth().delete(`${MASTER_URL}/schedules/${id}`);
    return res.data;
  } catch (err) {
    notification.error({
      message: 'Terjadi kesalahan',
      description: err?.response?.data?.message || 'Mohon coba beberapa saat lagi',
    });
    throw err?.response;
  }
};
