import { message } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

export const alertSuccess = text => {
  const collapsed = localStorage.getItem('collapsed') === 'true';
  message.config({ rtl: true });
  message.info({
    key: 'success',
    content: text || 'Berhasil',
    className: `custom-alert success ${collapsed ? 'collapsed' : ''}`,
    style: {
      marginTop: '89vh',
    },
    duration: 3,
    icon: <CloseOutlined onClick={() => message.destroy('success')} style={{ color: '#fff', fontSize: 14 }} />,
  });
};

export const alertError = text => {
  const collapsed = localStorage.getItem('collapsed') === 'true';
  message.config({ rtl: true });
  message.info({
    key: 'error',
    content: text || 'Error',
    className: `custom-alert error ${collapsed ? 'collapsed' : ''}`,
    style: {
      marginTop: '89vh',
    },
    duration: 3,
    icon: <CloseOutlined onClick={() => message.destroy('error')} style={{ color: '#fff', fontSize: 14 }} />,
  });
};

export const alertWarning = text => {
  const collapsed = localStorage.getItem('collapsed') === 'true';
  message.config({ rtl: true });
  message.info({
    key: 'warning',
    content: text || 'Warning',
    className: `custom-alert warning ${collapsed ? 'collapsed' : ''}`,
    style: {
      marginTop: '89vh',
    },
    duration: 3,
    icon: <CloseOutlined onClick={() => message.destroy('warning')} style={{ color: '#fff', fontSize: 14 }} />,
  });
};
