import { Form, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDocuments, updateDocument } from '../../services/action';
import DocumentForm from './DocumentForm';

const ModalEdit = ({ isOpen, onClose, data }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const documentParams = useSelector(state => state.document.documentParams);

  useEffect(() => {
    if (isOpen) {
      form.setFieldsValue(data);
    }
  }, [isOpen]);

  const handleOk = () => {
    form
      .validateFields()
      .then(values => {
        setLoading(true);
        updateDocument(data?.id, values)
          .then(() => {
            handleClose();
            dispatch(getDocuments(documentParams));
          })
          .finally(() => setLoading(false));
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });
  };

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      width={400}
      title="Edit Dokumen"
      maskClosable={false}
      centered
      open={isOpen}
      onOk={handleOk}
      onCancel={handleClose}
      cancelText="Batal"
      okText="Simpan"
      confirmLoading={loading}
      bodyStyle={{ padding: 0 }}
    >
      <Form name="basic" layout="vertical" form={form}>
        <DocumentForm data={data} />
      </Form>
    </Modal>
  );
};

export default ModalEdit;
