import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import DebugTest from './DebugTest';
import SsoCallback from './SsoCallback';
import DashboardLayout from './DashboardLayout';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/debug" name="debug" element={<DebugTest />} />
        <Route exact path="/oauth/callback" name="OAuth" element={<SsoCallback />} />
        <Route path="*" name="Home" element={<DashboardLayout />} />
      </Routes>
    </BrowserRouter>
  );
};
export default App;
