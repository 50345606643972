import { MapContainer, GeoJSON } from 'react-leaflet';
import sendIcon from '../../../assets/icons/send.svg';
import { indonesiaGeojson } from '../../../configs/indonesia-geojson';
import 'leaflet/dist/leaflet.css';

const Map = ({ province, setProvince }) => {
  return (
    <div className="bg-gray-100 rounded-lg w-full h-full relative">
      <MapContainer
        key={Math.random()}
        center={[-1.781, 117.708]}
        zoom={province ? 4.6 : 5}
        scrollWheelZoom={false}
        zoomSnap={0.1}
        maxZoom={6}
        zoomDelta={0.2}
        minZoom={4.6}
        doubleClickZoom={false}
        style={{ width: '100%', height: '100%', background: '#F5F5F5' }}
      >
        <GeoJSON
          data={indonesiaGeojson}
          pathOptions={{ weight: 1, color: '#F5F5F5' }}
          style={{ fillColor: '#C2C2C2', fillOpacity: 0.8 }}
          onEachFeature={(_feature, layer) => {
            layer.on({
              click: e => {
                setProvince(e.target.feature.properties.state);
              },
              mouseover: e => {
                e.target.setStyle({ fillColor: '#5396C7' });
                layer
                  .bindPopup(e.target.feature.properties.state, {
                    className: 'custom-popup-map',
                    closeButton: false,
                  })
                  .openPopup();
              },
              mouseout: e => {
                e.target.setStyle({ fillColor: '#C2C2C2' });
                layer.closePopup();
              },
            });
          }}
        />
      </MapContainer>
      {!province && (
        <div className="absolute flex justify-center left-0 right-0" style={{ bottom: 12, zIndex: 1000 }}>
          <div className="rounded-lg center-y text-white py-2 px-3 text-xs" style={{ background: '#0a0a0a66' }}>
            <img src={sendIcon} className="mr-2" width={10} />
            Silahkan klik salah satu provinsi pada peta untuk menampilkan data summary provinsi tersebut
          </div>
        </div>
      )}
    </div>
  );
};

export default Map;
