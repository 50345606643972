import { Avatar, Button, Collapse, Divider, Empty, Modal, Space } from 'antd';
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import CalendarDetail from './CalendarDetail';
import { formatDateTime, getFirstChar } from '../../../../helpers/string';
import { Fragment } from 'react';
import { deleteSchedule } from '../../services/action';

const ScheduleDetail = ({ selected, onEdit, onDeleted }) => {
  const handleDelete = () => {
    Modal.confirm({
      icon: <ExclamationCircleOutlined className="mt-1" />,
      title: 'Apakah anda yakin ingin menghapus kegiatan ini?',
      onOk: () => deleteSchedule(selected?.id).then(() => onDeleted()),
      okText: 'Iya',
      cancelText: 'Batal',
    });
  };

  return (
    <div className={`schedule-detail ${selected ? 'active' : ''}`}>
      <div className="center-y justify-between p-3 px-4 border-b-4 border-gray-100">
        <div className="text-lg">Detail Kegiatan</div>
        <Space>
          <Button size="small" onClick={onEdit} icon={<EditOutlined style={{ color: '#5396C7' }} />} />
          <Button size="small" onClick={handleDelete} icon={<DeleteOutlined style={{ color: '#FF3131' }} />} />
        </Space>
      </div>
      <div className="border-b-4 border-gray-100 px-4">
        <CalendarDetail data={selected} />
      </div>
      <Collapse ghost expandIconPosition="end" defaultActiveKey={['1', '2']}>
        <Collapse.Panel className="schedule-collapse-panel" header="Detail Kegiatan" key="1">
          <div className="mb-2">
            <div>Nama Kegiatan</div>
            <div className="text-gray-400">{selected?.title}</div>
          </div>
          <div className="mb-2">
            <div>Jenis Kegiatan</div>
            <div className="text-gray-400 capitalize">{selected?.type}</div>
          </div>
          {selected?.schema_name && (
            <div className="mb-2">
              <div>Skema Sertifikasi</div>
              <div className="text-gray-400 capitalize">{selected?.schema_name}</div>
            </div>
          )}
          {selected?.tuk_name && (
            <div className="mb-2">
              <div>TUK Pelaksana</div>
              <div className="text-gray-400 capitalize">{selected?.tuk_name}</div>
            </div>
          )}
          <div>
            <div>Tanggal Mulai - Selesai</div>
            <div className="text-gray-400">
              {formatDateTime(selected?.start_date)} - {formatDateTime(selected?.end_date)}
            </div>
          </div>
        </Collapse.Panel>
        <Collapse.Panel className="schedule-collapse-panel" header="Anggota yang terlibat" key="2">
          {selected?.participants?.length === 0 && <Empty description="Belum ada anggota" />}
          {selected?.participants?.map((item, idx) => (
            <Fragment key={idx}>
              <div className="center-y">
                <div className="mr-3">
                  <Avatar size={40} style={{ backgroundColor: item.bg_color, cursor: 'default' }}>
                    {getFirstChar(item.first_name) + getFirstChar(item.last_name)}
                  </Avatar>
                </div>
                <div>
                  <div>
                    {item.first_name || '-'} {item.last_name}
                  </div>
                  <div className="text-gray-400">
                    {item?.position_name} {item?.division_name}
                  </div>
                </div>
              </div>
              <Divider style={{ margin: '0.75rem 0' }} />
            </Fragment>
          ))}
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default ScheduleDetail;
