import React, { useEffect, useState } from 'react';
import EmbedViewer from './EmbedViewer';
import ImageViewer from './ImageViewer';
import PdfViewer from './PdfViewer';

const DocumentViewer = ({ open, onClose, fileName, subFileName, fileUrl }) => {
  const [openEmbedViewer, setOpenEmbedViewer] = useState(false);
  const [openPdfViewer, setOpenPdfViewer] = useState(false);
  const [openImageViewer, setOpenImageViewer] = useState(false);

  const handleCloseViewer = () => {
    setOpenEmbedViewer(false);
    setOpenPdfViewer(false);
    setOpenImageViewer(false);
    onClose();
  };

  useEffect(() => {
    if (open) {
      let ext = fileUrl?.split('.').pop();
      if (ext === 'pdf') {
        setOpenPdfViewer(true);
      } else if (['png', 'jpg', 'jpeg', 'svg'].includes(ext)) {
        setOpenImageViewer(true);
      } else if (['doc', 'docx', 'msword', 'ppt', 'pptx', 'xlsx', 'xls'].includes(ext)) {
        setOpenEmbedViewer(true);
      } else {
        setOpenEmbedViewer(true);
        /* for open new tab */
        // onClose();
        // window.open(fileUrl, '_blank');
      }
    }
  }, [open]);

  return (
    <>
      <EmbedViewer
        open={openEmbedViewer}
        onClose={handleCloseViewer}
        fileName={fileName}
        subFileName={subFileName}
        fileUrl={fileUrl}
      />
      <PdfViewer
        open={openPdfViewer}
        onClose={handleCloseViewer}
        fileName={fileName}
        subFileName={subFileName}
        fileUrl={fileUrl}
      />
      <ImageViewer open={openImageViewer} onClose={handleCloseViewer} fileUrl={fileUrl} />
    </>
  );
};

export default DocumentViewer;
