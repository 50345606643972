import { Badge, Layout, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import { BellFilled } from '@ant-design/icons';
import InputSearch from './InputSearch';
import AvatarMenu from './AvatarMenu';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getProfile } from '../../stores/app/action';

const { Header } = Layout;

const AppHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getProfile());
  }, []);

  return (
    <Header
      className="app-header"
      style={{
        padding: 0,
        position: 'sticky',
        top: 0,
        zIndex: 2,
        width: '100%',
      }}
    >
      <div className="flex justify-between items-center">
        <Space align="center">
          <div className="logo">
            <img src="/logos/nas-text-logo.svg" alt="" className="pointer" onClick={() => navigate('/')} />
          </div>
          <div className="logo-title">Manajemen Mutu</div>
        </Space>
        <div className="w-2/5">
          <InputSearch />
        </div>
        <div className="flex items-center mr-4">
          <div className="mr-5">
            <Badge dot offset={[-3, 3]}>
              <BellFilled style={{ fontSize: 18, color: '#5396C7' }} onClick={() => console.log('notif')} />
            </Badge>
          </div>
          <AvatarMenu />
        </div>
      </div>
    </Header>
  );
};

export default AppHeader;
