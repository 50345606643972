import { Image } from 'antd';

const ImageViewer = ({ open, onClose, fileUrl }) => {
  return (
    <Image
      width={200}
      style={{ display: 'none' }}
      preview={{
        visible: open,
        scaleStep: 0.1,
        src: fileUrl,
        onVisibleChange: onClose,
      }}
    />
  );
};

export default ImageViewer;
