import { Col, List, Pagination, Row } from 'antd';

const ListTable = ({
  columns = [],
  data = [],
  loading,
  pagination,
  onPaginationChange,
  hovered,
  onClick,
  activeRow,
}) => {
  return (
    <List
      className="bg-white rounded-md border"
      loading={loading}
      footer={
        <div className="px-5">
          <Pagination
            current={pagination?.current || 1}
            onChange={onPaginationChange}
            total={pagination?.total || 0}
            showSizeChanger
            pageSize={pagination?.limit || 10}
            pageSizeOptions={[5, 10, 25, 50]}
          />
        </div>
      }
      header={
        <Row className="px-5 font-semibold">
          {columns.map((item, idx) => (
            <Col key={idx} style={item.style}>
              {item.header}
            </Col>
          ))}
        </Row>
      }
      dataSource={data}
      renderItem={item => (
        <List.Item style={{ display: 'inherit', padding: 0 }}>
          <Row
            className={`px-5 py-3 ${activeRow === item ? 'bg-blue-50' : ''} ${
              hovered ? 'pointer hover:bg-gray-50' : ''
            }`}
            align="middle"
            onClick={() => onClick && onClick(item)}
          >
            {columns.map((col, idx) => (
              <Col key={idx} style={col.style}>
                {col.render ? col.render(item[col.key], item) : item[col.key] || '-'}
              </Col>
            ))}
          </Row>
        </List.Item>
      )}
    />
  );
};

export default ListTable;
