import { Col, Row } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';

const AllSummary = ({ setShowDetail }) => {
  return (
    <Row wrap gutter={[12, 12]}>
      <Col sm={12}>
        <div className="rounded-lg center-y justify-between border p-2 px-3 bg-white">
          <div>
            Jumlah Asesmen
            <div className="text-lg font-semibold">999</div>
          </div>
          <ArrowRightOutlined
            className="bg-blue-50 rounded p-2 border-blue-100 hover:bg-blue-100"
            style={{ color: '#5396C7' }}
            onClick={() => setShowDetail('Asesmen')}
          />
        </div>
      </Col>
      <Col sm={12}>
        <div className="rounded-lg center-y justify-between border p-2 px-3 bg-white">
          <div>
            Jumlah Asesi
            <div className="text-lg font-semibold">999</div>
          </div>
          <ArrowRightOutlined
            className="bg-blue-50 rounded p-2 border-blue-100 hover:bg-blue-100"
            style={{ color: '#5396C7' }}
            onClick={() => setShowDetail('Asesi')}
          />
        </div>
      </Col>
      <Col sm={12}>
        <div className="rounded-lg center-y justify-between border p-2 px-3 bg-white">
          <div>
            Jumlah Skema Sertifikasi
            <div className="text-lg font-semibold">999</div>
          </div>
          <ArrowRightOutlined
            className="bg-blue-50 rounded p-2 border-blue-100 hover:bg-blue-100"
            style={{ color: '#5396C7' }}
            onClick={() => setShowDetail('Skema Sertifikasi')}
          />
        </div>
      </Col>
      <Col sm={12}>
        <div className="rounded-lg center-y justify-between border p-2 px-3 bg-white">
          <div>
            Jumlah TUK
            <div className="text-lg font-semibold">999</div>
          </div>
          <ArrowRightOutlined
            className="bg-blue-50 rounded p-2 border-blue-100 hover:bg-blue-100"
            style={{ color: '#5396C7' }}
            onClick={() => setShowDetail('TUK')}
          />
        </div>
      </Col>
    </Row>
  );
};

export default AllSummary;
