import React from 'react';
import { Modal } from 'antd';
import { ArrowLeftOutlined, LoadingOutlined } from '@ant-design/icons';
import docIcon from '../assets/icons/doc.svg';

const EmbedViewer = ({ open, onClose, fileName, subFileName, fileUrl }) => {
  return (
    <Modal
      open={open}
      width="100vw"
      footer={null}
      centered
      closable={false}
      destroyOnClose
      wrapClassName="modal-pdf-viewer"
      bodyStyle={{ padding: 0, minHeight: '100vh' }}
    >
      <div className="bg-black h-16 fixed z-10 center-y justify-between px-5 w-full text-white">
        <div className="flex items-center">
          <ArrowLeftOutlined style={{ color: '#fff', fontSize: 18 }} onClick={onClose} />
          <div className="ml-5">
            <img src={docIcon} width={35} />
          </div>
          <div className="ml-3">
            <div>{fileName}</div>
            {subFileName && <div style={{ fontSize: 12 }}>{subFileName}</div>}
          </div>
        </div>
      </div>
      <div className="pt-16 relative">
        <iframe
          className="w-full absolute z-10"
          style={{ minHeight: 'calc(100vh - 4rem)' }}
          src={`https://docs.google.com/viewer?url=${fileUrl}&embedded=true`}
        ></iframe>
        <div className="centered absolute inset-0 text-white" style={{ height: 'calc(100vh - 4rem)', zIndex: 0 }}>
          <div className="text-center">
            <LoadingOutlined style={{ color: '#fff', fontSize: 40 }} />
            <div className="mt-2">Please wait...</div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EmbedViewer;
