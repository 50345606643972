import { Button, Spin, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import ModalForm from './components/ModalForm';
import CertificationList from './components/CertificationList';
import { useDispatch, useSelector } from 'react-redux';
import { getCertifications, getCompetences } from './services/action';
import DocumentViewer from '../../components/DocumentViewer';
import { BUCKET_URL } from '../../configs/config';

const Certification = () => {
  const dispatch = useDispatch();
  const [modalForm, setModalForm] = useState(false);
  const [openViewer, setOpenViewer] = useState(false);
  const [formType, setFormType] = useState('Buat');
  const [selected, setSelected] = useState(null);
  const certifications = useSelector(state => state.certification.certifications);
  const loadingList = useSelector(state => state.certification.loadingList);

  useEffect(() => {
    dispatch(getCertifications({ page: 1, limit: 10 }));
    dispatch(getCompetences());
  }, []);

  const handleAdd = () => {
    setSelected(null);
    setFormType('Buat');
    setModalForm(true);
  };

  const handleView = item => {
    setSelected(item);
    setFormType('Detail');
    setModalForm(true);
  };

  const handleEdit = item => {
    setSelected(item);
    setFormType('Edit');
    setModalForm(true);
  };

  const handlePreview = item => {
    if (item?.file_path) {
      setSelected(item);
      setOpenViewer(true);
    }
  };

  const handleCloseViewer = () => {
    setOpenViewer(false);
  };

  return (
    <div className="p-5 h-full flex flex-col">
      <div className="center-y justify-between">
        <Typography.Title level={4}>Skema Sertifikasi</Typography.Title>
        {certifications.length > 0 && (
          <Button type="primary" className="mb-2" icon={<PlusOutlined />} onClick={handleAdd}>
            Buat Skema Sertifikasi
          </Button>
        )}
      </div>
      {certifications.length > 0 && <CertificationList {...{ handleEdit, handleView, handlePreview }} />}
      {certifications.length === 0 &&
        (loadingList ? (
          <div className="centered h-full">
            <Spin size="large" tip="Memuat..." />
          </div>
        ) : (
          <div className="centered h-full">
            <div className="text-center border-2 border-gray-300 border-dashed p-5 rounded-xl">
              <Typography.Text>Belum ada skema sertifikasi yang bisa ditampilkan</Typography.Text>
              <div className="mt-5">
                <Button type="primary" icon={<PlusOutlined />} onClick={handleAdd}>
                  Buat Skema Sertifikasi
                </Button>
              </div>
            </div>
          </div>
        ))}
      <ModalForm
        isOpen={modalForm}
        onClose={() => setModalForm(false)}
        formType={formType}
        data={selected}
        handlePreview={handlePreview}
      />
      <DocumentViewer
        open={openViewer}
        onClose={handleCloseViewer}
        fileName={selected?.schema_name}
        fileUrl={BUCKET_URL + selected?.file_path}
      />
    </div>
  );
};

export default Certification;
