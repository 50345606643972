import { Avatar, Button, Space } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UserOutlined } from '@ant-design/icons';
import { getUsers } from '../../../stores/user/action';

const UserAssign = ({ name, userId, setUserId }) => {
  const dispatch = useDispatch();
  const users = useSelector(state => state.user.list);
  const params = useSelector(state => state.user.params);
  const count = useSelector(state => state.user.count);
  const [loading, setLoading] = useState(false);

  const handleMore = async () => {
    setLoading(true);
    await dispatch(getUsers({ ...params, offset: params.offset + params.limit }, true));
    setLoading(false);
  };

  return (
    <Space direction="vertical" className="w-full p-5" style={{ maxHeight: '50vh', overflow: 'auto' }}>
      {users.map((item, idx) => (
        <label
          key={idx}
          className={`ant-radio-wrapper border-b ${userId === item.user_id && 'ant-radio-wrapper-checked'}`}
          style={{ display: 'flex', marginRight: 0, paddingBottom: '8px' }}
        >
          <div className="center-y" style={{ flex: 1 }}>
            <div className="mr-3">
              <Avatar src="" icon={<UserOutlined />} size={42} className="border" />
            </div>
            <div>{`${item.first_name} ${item.last_name}`}</div>
          </div>
          <div className={`ant-radio ${userId === item.user_id && 'ant-radio-checked'}`}>
            <input
              type="radio"
              className="ant-radio-input"
              onClick={e => setUserId(parseInt(e.target.value))}
              value={item.user_id}
              name={name}
            />
            <div className="ant-radio-inner"></div>
          </div>
        </label>
      ))}
      {users?.length < count && (
        <Button block type="primary" className="semi-outlined mt-3" onClick={handleMore} loading={loading}>
          Load More
        </Button>
      )}
    </Space>
  );
};

export default UserAssign;
