import { Modal, notification, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { generateLink, refreshToken } from '../../helpers/sso';
import { getRefreshToken, isAuth, isValidRole, removeToken, setToken } from '../../helpers/token';

const WithAuth = ({ children }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const getLink = async () => {
    setLoading(true);
    removeToken();
    await generateLink()
      .then(res => window.location.assign(res.data.url))
      .catch(() =>
        notification.error({
          message: 'Mohon Maaf',
          description: 'Server sedang bermasalah, mohon tunggu beberapa saat lagi!',
        })
      )
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!isAuth()) {
      if (getRefreshToken()) {
        refreshToken(getRefreshToken())
          .then(res => {
            setToken(res?.data?.access_token, res?.data?.refresh_token);
            navigate(0);
          })
          .catch(() => getLink());
      } else {
        getLink();
      }
    }
  }, [navigate]);

  if (!isAuth()) {
    return (
      <div className="flex justify-center items-center" style={{ height: 'calc(100vh - 64px)' }}>
        <Spin size="large" tip="Please wait..." />
      </div>
    );
  }

  if (!isValidRole()) {
    return (
      <Modal open closable={false} cancelText onOk={getLink} confirmLoading={loading}>
        Maaf, hanya Admin, Super Admin, dan Asesor yang memiliki akses ke Manajemen Mutu
      </Modal>
    );
  }

  return children;
};

export default WithAuth;
