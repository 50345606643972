import { SSO_URL } from '../../configs/config';
import { httpAuth } from '../../helpers/http';

export const getUsers =
  (params = { limit: 20, offset: 0 }, isConcat) =>
  async dispatch => {
    try {
      dispatch({ type: 'setLoadingGetUsers', payload: true });
      const res = await httpAuth().get(`${SSO_URL}/users`, {
        params: {
          sort: 'first_name',
          search_key: ['first_name', 'last_name'],
          ...params,
        },
      });
      dispatch({ type: 'setLoadingGetUsers', payload: false });
      isConcat
        ? dispatch({ type: 'setMoreUsers', payload: res.data, params })
        : dispatch({ type: 'setUsers', payload: res.data, params });
      return res.data;
    } catch (err) {
      dispatch({ type: 'setLoadingGetUsers', payload: false });
      throw err?.response;
    }
  };
