import { Avatar, Button, Card, Space } from 'antd';
import Meta from 'antd/lib/card/Meta';
import React from 'react';
import { PlusOutlined, EditOutlined, DeleteOutlined, EyeFilled, UserOutlined } from '@ant-design/icons';

const TreeContent = ({ data, onCreate, onDelete, onView, onAssign }) => {
  return (
    <div className="tree-container">
      <Card bordered bodyStyle={{ padding: '1rem' }}>
        <Meta
          avatar={<Avatar src="" icon={<UserOutlined />} size={42} className="centered border" />}
          title={
            <div className="text-left">
              {data.position_name} {data.division_name}
            </div>
          }
          description={
            <div className="text-left">
              {data.users_id ? `${data.first_name || '-'} ${data.last_name || ''}` : 'Posisi dan Divisi masih kosong'}
            </div>
          }
        />
      </Card>
      <div className="tree-action">
        <Space size={6}>
          <Button size="small" type="primary" icon={<PlusOutlined />} onClick={() => onCreate(data)} />
          <Button
            size="small"
            type="primary"
            className="outlined"
            icon={<EditOutlined />}
            onClick={() => onAssign(data)}
          />
          {!!data.users_id && (
            <Button
              size="small"
              type="primary"
              className="outlined"
              icon={<EyeFilled />}
              onClick={() => onView(data)}
            />
          )}
          <Button size="small" danger icon={<DeleteOutlined />} onClick={() => onDelete(data)} />
        </Space>
      </div>
    </div>
  );
};

export default TreeContent;
