import { Form, Input } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SelectWithCreate from '../../../../components/SelectWithCreate';
import { createCategory, createSubCategory, getCategories, getSubCategories } from '../../services/action';

const DocumentForm = ({ data }) => {
  const form = Form.useFormInstance();
  const dispatch = useDispatch();
  const [loadingCategory, setLoadingCategory] = useState(false);
  const [loadingSubCategory, setLoadingSubCategory] = useState(false);
  const [categories, setCategories] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const loadingSubCategories = useSelector(state => state.document.loadingSubCategories);
  const subCategories = useSelector(state => state.document.subCategories);
  const categoryId = Form.useWatch('doc_categories_id', form);

  useEffect(() => {
    setLoadingCategories(true);
    dispatch(getCategories({}, false))
      .then(res => setCategories(res))
      .finally(() => setLoadingCategories(false));
  }, []);

  useEffect(() => {
    if (data) {
      dispatch(getSubCategories({ category_id: categoryId }));
    }
  }, [data]);

  const handleChangeCategory = () => {
    form.resetFields(['doc_sub_categories_id']);
  };

  const handleCreateCategory = value => {
    if (!value) {
      return;
    }
    const body = {
      category: value,
    };
    setLoadingCategory(true);
    dispatch(createCategory(body)).finally(() => setLoadingCategory(false));
  };

  const handleCreateSubCategory = value => {
    if (!value) {
      return;
    }
    const body = {
      doc_categories_id: categoryId,
      sub_category: value,
    };
    setLoadingSubCategory(true);
    dispatch(createSubCategory(body)).finally(() => setLoadingSubCategory(false));
  };

  return (
    <div className="p-6 pt-4">
      <Form.Item
        label="Kategori Dokumen"
        name="doc_categories_id"
        rules={[{ required: true, message: 'Silahkan pilih kategori!' }]}
      >
        <SelectWithCreate
          placeholder="Pilih/Tambahkan Kategori Dokumen"
          handleCreate={handleCreateCategory}
          loadingCreate={loadingCategory}
          loadingSelect={loadingCategories}
          disabled={loadingCategories}
          onChange={handleChangeCategory}
          options={categories.map(item => ({ value: item.id, label: item.category }))}
        />
      </Form.Item>

      <Form.Item label="Sub Kategori Dokumen" name="doc_sub_categories_id">
        <SelectWithCreate
          placeholder="Pilih/Tambahkan Sub Kategori Dokumen"
          handleCreate={handleCreateSubCategory}
          loadingCreate={loadingSubCategory}
          loadingSelect={loadingSubCategories}
          disabled={!categoryId || loadingSubCategories}
          options={subCategories.map(item => ({ value: item.id, label: item.sub_category }))}
        />
      </Form.Item>
      <Form.Item
        label="Nama Dokumen"
        name="document_name"
        rules={[{ required: true, message: 'Silahkan input nama dokumen!' }]}
      >
        <Input placeholder="Input Nama Dokumen" />
      </Form.Item>
      <Form.Item name="file_path" hidden>
        <Input />
      </Form.Item>
    </div>
  );
};

export default DocumentForm;
