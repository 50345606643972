import moment from 'moment/moment';

export const formatSize = (size = 0) => {
  let result = 0;
  if (size > 1000000) {
    result = size / 1000000;
    return result.toFixed(2) + ' MB';
  } else if (size > 1000) {
    result = size / 1000;
    return result.toFixed(2) + ' KB';
  } else {
    return size + ' bytes';
  }
};

export const formatDate = (value, format = 'DD/MM/YYYY') => {
  if (!value) {
    return '-';
  }
  return moment(value).format(format);
};

export const formatDateTime = (value, format = 'DD/MM/YYYY HH:mm') => {
  if (!value) {
    return '-';
  }
  return moment(value).format(format);
};

export const getFirstChar = value => {
  if (!value) {
    return '';
  }
  return Array.from(value)[0];
};

export const randomColor = () => {
  let color = '#';
  let hexaDecimal = '0123456789ABCDEF';

  for (let i = 0; i < 6; i++) {
    color = color + hexaDecimal[Math.floor(Math.random() * 12)];
  }
  return color;
};
