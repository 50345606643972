import { Button, Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import ModalCompetenceUnit from './ModalCompetenceUnit';
import { useState } from 'react';
import CompetenceUnitItem from './CompetenceUnitItem';

const CompetenceUnit = ({ formType, data }) => {
  const form = Form.useFormInstance();
  const [modalCompetenceUnit, setModalCompetenceUnit] = useState(false);
  const [index, setIndex] = useState(null);
  const [unitSelected, setUnitSelected] = useState(null);

  const handleEditUnit = index => {
    setIndex(index);
    setUnitSelected(form.getFieldValue('unit_competences')[index]);
    setModalCompetenceUnit(true);
  };

  const handleAddUnit = () => {
    setIndex(null);
    setUnitSelected(null);
    setModalCompetenceUnit(true);
  };

  const handleEditted = value => {
    const existing = form.getFieldValue('unit_competences');
    existing[index] = value;
    form.setFieldValue('unit_competences', existing);
  };

  const handleAdd = (value, add) => {
    const existing = form.getFieldValue('unit_competences');
    const isDuplicate = existing?.some(item => item.unit_competences_id === value.unit_competences_id);
    if (isDuplicate) {
      return;
    }
    add(value);
  };

  return (
    <Form.List name="unit_competences">
      {(fields, { add, remove }) => (
        <>
          {fields.length === 0 && <div className="text-center text-gray-400 pb-5">Belum ada unit kompetensi</div>}
          {fields.map((field, idx) => (
            <CompetenceUnitItem key={idx} field={field} {...{ remove, handleEditUnit, formType, data }} />
          ))}
          {formType !== 'Detail' && (
            <Form.Item>
              <Button type="primary" className="semi-outlined" onClick={handleAddUnit} block icon={<PlusOutlined />}>
                Tambah Unit Kompetensi
              </Button>
            </Form.Item>
          )}
          <ModalCompetenceUnit
            isOpen={modalCompetenceUnit}
            onClose={() => setModalCompetenceUnit(false)}
            data={unitSelected}
            onCreated={value => handleAdd(value, add)}
            onEditted={handleEditted}
            formType={formType}
            listSelected={form.getFieldValue('unit_competences')}
          />
        </>
      )}
    </Form.List>
  );
};

export default CompetenceUnit;
