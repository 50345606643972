import { Button, Spin, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSchedules } from './services/action';
import ScheduleList from './components/list/ScheduleList';
import ModalCreate from './components/create/ModalCreate';
import ScheduleDetail from './components/detail/ScheduleDetail';
import { getCertifications } from '../certification/services/action';
import { getStructures } from '../organization/services/action';

const Schedule = () => {
  const dispatch = useDispatch();
  const [modalCreate, setModalCreate] = useState(false);
  const [selected, setSelected] = useState(null);
  const schedules = useSelector(state => state.schedule.schedules);
  const loadingList = useSelector(state => state.schedule.loadingList);

  useEffect(() => {
    dispatch(getCertifications());
    dispatch(getStructures());
    dispatch(getSchedules({ page: 1, limit: 10 }));
  }, []);

  const handleAdd = () => {
    setSelected(null);
    setModalCreate(true);
  };

  const handleView = item => {
    if (item === selected) {
      setSelected(null);
    } else {
      setSelected(item);
    }
  };

  const handleOnDeleted = () => {
    dispatch(getSchedules({ page: 1, limit: 10 }));
    setSelected(null);
  };

  return (
    <div className="flex h-full">
      <div className="p-5 h-full w-full flex flex-col">
        <div className="center-y justify-between">
          <Typography.Title level={4}>Jadwal Kegiatan</Typography.Title>
          {schedules.length > 0 && (
            <Button type="primary" className="mb-2" icon={<PlusOutlined />} onClick={handleAdd}>
              Buat Kegiatan
            </Button>
          )}
        </div>
        {schedules.length > 0 && <ScheduleList {...{ handleView, activeRow: selected }} />}
        {schedules.length === 0 &&
          (loadingList ? (
            <div className="centered h-full">
              <Spin size="large" tip="Memuat..." />
            </div>
          ) : (
            <div className="centered h-full">
              <div className="text-center border-2 border-gray-300 border-dashed p-5 rounded-xl">
                <Typography.Text>Belum ada jadwal kegiatan yang bisa ditampilkan</Typography.Text>
                <div className="mt-5">
                  <Button type="primary" icon={<PlusOutlined />} onClick={handleAdd}>
                    Buat Jadwal Kegiatan
                  </Button>
                </div>
              </div>
            </div>
          ))}
        <ModalCreate
          isOpen={modalCreate}
          onClose={() => setModalCreate(false)}
          selected={selected}
          setSelected={setSelected}
        />
      </div>
      <ScheduleDetail selected={selected} onEdit={() => setModalCreate(true)} onDeleted={handleOnDeleted} />
    </div>
  );
};

export default Schedule;
