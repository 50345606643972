import { CalendarIcon, FolderIcon, HomeIcon, MedaliIcon, PeopleIcon, SettingIcon } from '../components/Icons';

const menus = [
  {
    key: '/',
    icon: <HomeIcon />,
    label: 'Home',
  },
  {
    key: '/organization',
    icon: <PeopleIcon />,
    label: 'Organization Management',
  },
  {
    key: '/document',
    icon: <FolderIcon />,
    label: 'Document Management',
  },
  {
    key: '/certification',
    icon: <MedaliIcon />,
    label: 'Skema Sertifikasi',
  },
  {
    key: '/schedule',
    icon: <CalendarIcon />,
    label: 'Jadwal Kegiatan',
  },
];

export default menus;
