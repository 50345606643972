import { Form, Input, Modal, Progress, notification } from 'antd';
import { useState } from 'react';
import { updatePassword } from '../../stores/app/action';
import { yellow, green, blue, orange } from '@ant-design/colors';

const ModalPassword = ({ open, onClose }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [strength, setStrength] = useState(0);

  const onSubmit = () => {
    form
      .validateFields()
      .then(values => {
        let formBody = values;
        if (values.new_password !== values.confirm_password) {
          form.setFields([{ name: 'confirm_password', errors: ['Konfirmasi password salah'] }]);
          return;
        }
        setLoading(true);
        updatePassword(formBody)
          .then(() => {
            notification.success({
              message: 'Sukses',
              description: 'Password lama berhasil diubah',
            });
            handleClose();
          })
          .catch(err =>
            notification.error({
              message: 'Terjadi kesalahan',
              description: err?.data?.error || 'Mohon coba beberapa saat lagi',
            })
          )
          .finally(() => setLoading(false));
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });
  };

  const handleClose = () => {
    onClose();
    form.resetFields();
  };

  const strengthChecker = value => {
    if (value.length > 5) {
      let lower = new RegExp(/^(?=.*[a-z])/);
      let upper = new RegExp(/^(?=.*[A-Z])/);
      let number = new RegExp(/^(?=.*\d)/);
      let char = new RegExp(/^(?=.*[^A-Za-z0-9])/);
      let lowerValue = lower.test(value) ? 25 : 0;
      let upperValue = upper.test(value) ? 25 : 0;
      let numberValue = number.test(value) ? 25 : 0;
      let charValue = char.test(value) ? 25 : 0;
      setStrength(lowerValue + upperValue + numberValue + charValue);
    } else {
      setStrength(25);
    }
  };

  const strengthColor = () => {
    switch (strength) {
      case 100:
        return green[6];
      case 75:
        return blue[6];
      case 50:
        return yellow[5];
      default:
        return orange[6];
    }
  };

  const strengthLabel = percent => {
    switch (percent) {
      case 100:
        return 'Strong';
      case 75:
        return 'Good';
      case 50:
        return 'Fair';
      default:
        return 'Weak';
    }
  };

  return (
    <Modal
      open={open}
      onCancel={handleClose}
      onOk={onSubmit}
      title="Ubah Password"
      cancelText="Batal"
      okText="Simpan"
      centered
      wrapClassName="p-3"
      width={400}
      destroyOnClose
      confirmLoading={loading}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="Password Lama"
          name="old_password"
          rules={[{ required: true, message: 'Silahkan input password lama!' }]}
        >
          <Input.Password placeholder="Password Lama" />
        </Form.Item>
        <Form.Item
          label="Password Baru"
          name="new_password"
          rules={[
            { required: true, message: 'Silahkan input password baru!' },
            { min: 6, message: 'Minimal 6 karakter', validateTrigger: [''] },
          ]}
          style={{ marginBottom: 0 }}
        >
          <Input.Password placeholder="Password Baru" onChange={e => strengthChecker(e.target.value)} />
        </Form.Item>
        {strength > 0 && <Progress percent={strength} steps={4} strokeColor={strengthColor()} format={strengthLabel} />}
        <Form.Item
          style={{ marginTop: '0.75rem' }}
          label="Konfirmasi Password Baru"
          name="confirm_password"
          rules={[{ required: true, message: 'Silahkan konfirmasi password baru!' }]}
        >
          <Input.Password placeholder="Konfirmasi Password Baru" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalPassword;
