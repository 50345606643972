import { DatePicker, Form, Input, Modal, notification, Select } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { numberOnly } from '../../helpers/normalize';
import { updateProfile } from '../../stores/app/action';

const ModalProfile = ({ open, onClose }) => {
  const [form] = Form.useForm();
  const profile = useSelector(state => state.app.profile);
  const [loading, setLoading] = useState(false);

  const onSubmit = () => {
    form
      .validateFields()
      .then(values => {
        let formBody = { ...values, birth_date: values?.birth_date.format('YYYY-MM-DD') };
        setLoading(true);
        updateProfile(formBody)
          .then(() => {
            notification.success({
              message: 'Sukses',
              description: 'Profile berhasil diperbarui',
            });
            handleClose();
          })
          .catch(err =>
            notification.error({
              message: 'Terjadi kesalahan',
              description: err?.data?.message || 'Mohon coba beberapa saat lagi',
            })
          )
          .finally(() => setLoading(false));
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });
  };

  const handleClose = () => {
    onClose();
    form.resetFields();
  };

  return (
    <Modal
      open={open}
      onCancel={handleClose}
      onOk={onSubmit}
      title="Edit Profile"
      cancelText="Batal"
      okText="Simpan"
      centered
      wrapClassName="p-3"
      width={400}
      destroyOnClose
      confirmLoading={loading}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{ ...profile, birth_date: moment(profile?.birth_date, 'DD MMM YYYY') }}
      >
        <Form.Item label="Username" name="username">
          <Input
            placeholder="-"
            className="input-view"
            disabled
            // suffix={<div className="text-primary cursor-pointer">Ubah</div>}
          />
        </Form.Item>
        <Form.Item label="Email" name="email">
          <Input
            placeholder="-"
            className="input-view"
            disabled
            // suffix={<div className="text-primary cursor-pointer">Ubah</div>}
          />
        </Form.Item>
        <Form.Item label="NIK" name="nik">
          <Input placeholder="-" className="input-view" disabled />
        </Form.Item>
        <Form.Item label="Nama Depan" name="first_name">
          <Input placeholder="Nama Depan" />
        </Form.Item>
        <Form.Item label="Nama Belakang" name="last_name">
          <Input placeholder="Nama Belakang" />
        </Form.Item>
        <Form.Item label="No. Telepon" name="contact" normalize={numberOnly}>
          <Input placeholder="No. Telepon" maxLength={15} />
        </Form.Item>
        <Form.Item label="Tanggal Lahir" name="birth_date">
          <DatePicker placeholder="Tanggal Lahir" format="DD/MM/YYYY" className="w-full" />
        </Form.Item>
        <Form.Item label="Alamat" name="address">
          <Input placeholder="Alamat" />
        </Form.Item>
        <Form.Item label="Jenis Kelamin" name="gender">
          <Select
            placeholder="Pilih Jenis Kelamin"
            options={[
              { value: 'M', label: 'Laki-laki' },
              { value: 'F', label: 'Perempuan' },
            ]}
          />
        </Form.Item>
        <Form.Item label="Agama" name="religion">
          <Input placeholder="Agama" />
        </Form.Item>
        <Form.Item label="Pedidikan" name="education">
          <Input placeholder="Pedidikan" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalProfile;
