import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import app from './app/reducer';
import user from './user/reducer';
import organization from '../pages/organization/services/reducer';
import document from '../pages/document/services/reducer';
import certification from '../pages/certification/services/reducer';
import schedule from '../pages/schedule/services/reducer';

const reducer = combineReducers({
  app,
  user,
  organization,
  document,
  certification,
  schedule,
});

const store = configureStore({
  reducer,
});

export default store;
