import { Layout } from 'antd';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import routes from '../../routes';
import WithAuth from './WithAuth';

const { Content } = Layout;

const AppContent = props => {
  const collapsed = useSelector(state => state.app.collapsed);

  return (
    <Content className="app-content" style={{ marginLeft: collapsed ? '55px' : '240px' }}>
      <Routes>
        {routes.map((route, idx) => {
          return (
            route.element && (
              <Route
                key={idx}
                path={route.path}
                exact={route.exact}
                name={route.name}
                element={
                  <WithAuth>
                    <route.element title={route.name} />
                  </WithAuth>
                }
              />
            )
          );
        })}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Content>
  );
};

export default AppContent;
