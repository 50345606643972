import { Button, Col, Image, Modal, Row } from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteStructure, getStructures } from '../services/action';

const ModalDelete = ({ isOpen, onClose, data }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleDelete = () => {
    setLoading(true);
    deleteStructure(data?.id)
      .then(() => {
        onClose();
        dispatch(getStructures());
      })
      .finally(() => setLoading(false));
  };
  return (
    <Modal width={350} open={isOpen} onCancel={onClose} closable={false} footer={null} className="text-center">
      <Image src="/icons/warning.svg" width={80} />
      <div>Anda yakin akan menghapus posisi dan divisi {data?.position_name}?</div>
      <Row gutter={8} className="mt-5">
        <Col flex={1}>
          <Button onClick={onClose} block>
            Batal
          </Button>
        </Col>
        <Col flex={1}>
          <Button type="primary" onClick={handleDelete} loading={loading} danger block>
            Hapus
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default ModalDelete;
