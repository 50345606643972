import S3 from 'react-aws-s3';
import { ACCESS_KEY_ID, BUCKET_NAME, REGION, SECRET_ACCESS_KEY } from '../configs/config';

window.Buffer = window.Buffer || require('buffer').Buffer;

export const uploadToS3 = async (file, dirName, fileName) => {
  if (!file) {
    return;
  }

  const config = {
    bucketName: BUCKET_NAME,
    region: REGION,
    accessKeyId: ACCESS_KEY_ID,
    secretAccessKey: SECRET_ACCESS_KEY,
    dirName,
  };

  const s3Client = new S3(config);

  return s3Client
    .uploadFile(file, `${fileName}.${file.name?.split('.').pop()}`)
    .then(data => {
      return data;
    })
    .catch(err => {
      throw err;
    });
};
