const initialState = {
  documents: [],
  documentPagination: {
    current: 1,
    total: 0,
    limit: 10,
  },
  documentParams: {},
  categories: [],
  categoryParams: {},
  subCategories: [],
  loadingDocuments: false,
  loadingSubCategories: false,
  loadingCategories: false,
};

export default function document(state = initialState, action) {
  switch (action.type) {
    case 'setDocuments':
      return {
        ...state,
        documents: action.payload?.items || [],
        documentPagination: {
          current: action.payload?.curPage || 1,
          total: action.payload?.itemsTotal || 0,
          limit: action.params?.limit || state.documentParams?.limit || 10,
        },
        documentParams: action.params,
      };
    case 'setLoadingDocuments':
      return { ...state, loadingDocuments: action.payload };
    case 'setCategories':
      return { ...state, categories: action.payload || [], categoryParams: action.params };
    case 'setLoadingCategories':
      return { ...state, loadingCategories: action.payload };
    case 'setSubCategories':
      return { ...state, subCategories: action.payload || [] };
    case 'setLoadingSubCategories':
      return { ...state, loadingSubCategories: action.payload };
    case 'pushCategory':
      return { ...state, categories: [...state.categories, action.payload] };
    case 'pushSubCategory':
      return { ...state, subCategories: [...state.subCategories, action.payload] };

    default:
      return state;
  }
}
