import React, { useState } from 'react';
import { Avatar, Checkbox, Typography } from 'antd';
import { UserOutlined } from '@ant-design/icons';

const CheckboxGroup = Checkbox.Group;

const CheckboxUserGroup = ({ name, onChangeSelect, items = [], initialValue = [] }) => {
  const [checkedList, setCheckedList] = useState(initialValue);
  const [indeterminate, setIndeterminate] = useState(!!initialValue.length && initialValue.length < items.length);
  const [checkAll, setCheckAll] = useState(!!items.length && initialValue.length === items.length);

  const onChange = list => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < items.length);
    setCheckAll(list.length === items.length);
    if (onChangeSelect) {
      onChangeSelect(list);
    }
  };

  const onCheckAllChange = e => {
    setCheckedList(e.target.checked ? items.map(item => item.users_id) : []);
    if (onChangeSelect) {
      onChangeSelect(e.target.checked ? items.map(item => item.users_id) : []);
    }
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  return (
    <div className="mb-3 border-b-8 border-gray-100">
      <div className="center-y justify-between border-b py-3">
        <Typography.Title level={5} style={{ marginBottom: 0 }}>
          {name}
        </Typography.Title>
        <Checkbox
          className="custom-checkbox"
          indeterminate={indeterminate}
          onChange={onCheckAllChange}
          checked={checkAll}
          disabled={items.length === 0}
        />
      </div>
      {items.length === 0 && <div className="pt-3 text-center">Belum ada Anggota dengan role ini</div>}
      <CheckboxGroup value={checkedList} onChange={onChange} className="w-full">
        {items.map((item, idx) => (
          <div key={idx} className="center-y justify-between py-3 border-b">
            <div className="center-y flex-1">
              <div className="mr-3">
                <Avatar src="" icon={<UserOutlined />} size={42} className="border" />
              </div>
              <div>
                <div>
                  {item.first_name || '-'} {item.last_name}
                </div>
                <div className="text-gray-400">{`${item.position_name} ${item.division_name}`}</div>
              </div>
            </div>
            <div>
              <Checkbox value={item.users_id} className="custom-checkbox" />
            </div>
          </div>
        ))}
      </CheckboxGroup>
    </div>
  );
};

export default CheckboxUserGroup;
