import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { setToken } from '../helpers/token';

const SSOCallback = () => {
  const [params] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    setToken(params.get('access_token'), params.get('refresh_token'));
    navigate('/');
  }, []);

  return null;
};

export default SSOCallback;
