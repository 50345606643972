export const downloadFile = async (fileUrl, fileName) => {
  const response = await fetch(fileUrl, { method: 'GET' });
  const blob = await response.blob();
  const url = URL.createObjectURL(new Blob([blob]));
  const ext = fileUrl?.split('.').pop();
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${fileName}.${ext}`);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
  return response;
};
