import { Button, Collapse, DatePicker, Form, Input, Modal, notification, Select } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import UploadS3 from '../../../components/UploadS3';
import { SCHEMA_CATEGORY_ID } from '../../../configs/config';
import { alertSuccess } from '../../../helpers/alert';
import { getTokenData } from '../../../helpers/token';
import { createDocument } from '../../document/services/action';
import { createCertification, getCertifications, getCompetences, updateCertification } from '../services/action';
import CompetenceUnit from './CompetenceUnit';

const ModalForm = ({ isOpen, onClose, data, formType, handlePreview }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const dirName = getTokenData().lsp_name + '/Skema Sertifikasi';
  const docName = Form.useWatch('schema_code', form);

  useEffect(() => {
    if (isOpen) {
      const intialValue = data
        ? { ...data, assignment_no: data.assignment_no ? moment(data.assignment_no) : null }
        : null;
      form.setFieldsValue(intialValue);
    }
  }, [isOpen]);

  const handleOk = () => {
    form
      .validateFields()
      .then(values => {
        const mapValue = {
          ...values,
          unit_competences: values.unit_competences?.map(item => {
            if (typeof item.unit_competences_id === 'string') {
              item.unit_competences_id = undefined;
            }
            return item;
          }),
        };
        setLoadingSubmit(true);
        if (mapValue.file_path) {
          const docFormBody = {
            file_path: (mapValue.file_path?.key || mapValue.file_path || '').replace(/ /g, '+'),
            doc_categories_id: SCHEMA_CATEGORY_ID,
            document_name: mapValue.schema_code,
          };
          if (docFormBody.file_path === data?.file_path) {
            const body = {
              ...mapValue,
              assignment_no: mapValue.assignment_no.format('YYYY'),
              document_id: data?.document_id,
            };
            editCertification(body);
            return;
          }
          createDocument(docFormBody, true)
            .then(res => {
              const body = { ...mapValue, assignment_no: mapValue.assignment_no.format('YYYY'), document_id: res.id };
              formType === 'Buat' ? addCertification(body) : editCertification(body);
            })
            .catch(err => {
              notification.error({
                message: 'Terjadi kesalahan',
                description: err?.data?.message || 'Mohon coba beberapa saat lagi',
              });
              setLoadingSubmit(false);
            });
        } else {
          const body = { ...mapValue, assignment_no: mapValue.assignment_no.format('YYYY') };
          formType === 'Buat' ? addCertification(body) : editCertification(body);
        }
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });
  };

  const addCertification = body => {
    dispatch(createCertification(body))
      .then(() => {
        dispatch(getCertifications());
        dispatch(getCompetences());
        handleClose();
        alertSuccess('Data skema sertifikasi berhasil dibuat');
      })
      .finally(() => setLoadingSubmit(false));
  };

  const editCertification = body => {
    dispatch(updateCertification(data.id, body))
      .then(() => {
        dispatch(getCertifications());
        dispatch(getCompetences());
        handleClose();
        alertSuccess('Data skema sertifikasi berhasil diupdate');
      })
      .finally(() => setLoadingSubmit(false));
  };

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      width={400}
      title={`${formType} Data Skema Sertifikasi`}
      maskClosable={false}
      centered
      open={isOpen}
      onCancel={handleClose}
      footer={[
        <Button key="back" onClick={handleClose}>
          {formType !== 'Detail' ? 'Batal' : 'Tutup'}
        </Button>,
        formType !== 'Detail' && (
          <Button type="primary" key="submit" onClick={handleOk} loading={loadingSubmit}>
            Simpan
          </Button>
        ),
      ]}
      bodyStyle={{ padding: 0 }}
      wrapClassName="py-5"
      destroyOnClose
    >
      <Form name="basic" layout="vertical" form={form}>
        <div className="p-5">
          <Form.Item
            name="schema_code"
            label="Kode Skema"
            rules={[{ required: true, message: 'Silahkan input kode skema!' }]}
          >
            <Input placeholder="Kode Skema" disabled={formType !== 'Buat'} className="input-view" />
          </Form.Item>
          <Form.Item
            name="schema_name"
            label="Nama Skema"
            rules={[{ required: true, message: 'Silahkan input nama skema!' }]}
          >
            <Input placeholder="Nama Skema" disabled={formType === 'Detail'} className="input-view" />
          </Form.Item>
          <Form.Item
            name="schema_type"
            label="Jenis Standar Kompetensi"
            rules={[{ required: true, message: 'Silahkan pilih jenis standar kompetensi!' }]}
          >
            <Select
              placeholder="Pilih jenis standar kompetensi"
              options={[
                { value: 'SKKNI', label: 'SKKNI' },
                { value: 'SKKK', label: 'SKKK' },
                { value: 'SKKI', label: 'SKKI' },
              ]}
              disabled={formType === 'Detail'}
              className="input-view"
            />
          </Form.Item>
          <Form.Item name="assignment_no" label="Tahun" rules={[{ required: true, message: 'Silahkan pilih tahun!' }]}>
            <DatePicker
              className="w-full input-view"
              picker="year"
              placeholder="Pilih Tahun"
              disabled={formType === 'Detail'}
            />
          </Form.Item>
        </div>
        <Collapse ghost expandIconPosition="end" defaultActiveKey={formType !== 'Buat' ? ['1', '2'] : ''}>
          <Collapse.Panel className="certificate-collapse-panel" header="Unggah Dokumen" key="1">
            {!data?.document_id && formType === 'Detail' ? (
              <div className="text-center text-gray-400 pb-5">Belum ada dokumen</div>
            ) : (
              <Form.Item name="file_path">
                <UploadS3
                  label="Upload Dokumen"
                  disabled={!docName}
                  dirName={dirName}
                  fileName={docName}
                  onPreview={formType !== 'Buat' ? () => handlePreview(data) : undefined}
                  disableClose={formType !== 'Buat'}
                  initValue={data?.document_id ? { name: data?.document_name, file_path: data?.file_path } : null}
                  accept="application/pdf, 
                    application/msword, 
                    application/vnd.ms-powerpoint, 
                    application/vnd.openxmlformats-officedocument.presentationml.slideshow,
                    application/vnd.openxmlformats-officedocument.presentationml.presentation,
                    application/vnd.ms-excel, 
                    application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  onError={err => form.setFields([{ name: 'file_path', errors: [err] }])}
                />
              </Form.Item>
            )}
          </Collapse.Panel>
          <Collapse.Panel className="certificate-collapse-panel" header="Input Data Unit Kompetensi" key="2">
            <CompetenceUnit formType={formType} data={data} />
          </Collapse.Panel>
        </Collapse>
      </Form>
    </Modal>
  );
};

export default ModalForm;
