import { DatePicker, Input, Space, Typography } from 'antd';
import { ArrowLeftOutlined, RightOutlined, SearchOutlined } from '@ant-design/icons';
import ListTable from '../../../components/ListTable';

const data = [
  { city: 'Lamongan', tuk: 999, asesmen: 999, asesi: 999, asesor: 999, name: 'Lorem ipsum', code: 'A00123' },
  { city: 'Lamongan', tuk: 999, asesmen: 999, asesi: 999, asesor: 999, name: 'Lorem ipsum', code: 'A00123' },
  { city: 'Lamongan', tuk: 999, asesmen: 999, asesi: 999, asesor: 999, name: 'Lorem ipsum', code: 'A00123' },
  { city: 'Lamongan', tuk: 999, asesmen: 999, asesi: 999, asesor: 999, name: 'Lorem ipsum', code: 'A00123' },
  { city: 'Lamongan', tuk: 999, asesmen: 999, asesi: 999, asesor: 999, name: 'Lorem ipsum', code: 'A00123' },
  { city: 'Lamongan', tuk: 999, asesmen: 999, asesi: 999, asesor: 999, name: 'Lorem ipsum', code: 'A00123' },
  { city: 'Lamongan', tuk: 999, asesmen: 999, asesi: 999, asesor: 999, name: 'Lorem ipsum', code: 'A00123' },
  { city: 'Lamongan', tuk: 999, asesmen: 999, asesi: 999, asesor: 999, name: 'Lorem ipsum', code: 'A00123' },
];

const SummaryDetail = ({ showDetail, setShowDetail, province }) => {
  return (
    <div>
      <Typography.Title level={4} className="center-y">
        <ArrowLeftOutlined
          className="mr-3"
          style={{ fontSize: 16, color: '#757575' }}
          onClick={() => setShowDetail('')}
        />
        <span className="text-gray-500">Beranda</span>
        <RightOutlined style={{ fontSize: 14, padding: '0 .5rem' }} />
        {showDetail === 'Province' ? province : 'Summary ' + showDetail}
      </Typography.Title>
      {showDetail !== 'Province' && (
        <div className="flex items-end justify-between mb-3">
          <div className="font-semibold">Jumlah {showDetail} : 10</div>
          <Space>
            <Input prefix={<SearchOutlined style={{ color: '#5396C7' }} />} placeholder="Cari" className="w-48" />
            <DatePicker className="w-48" />
          </Space>
        </div>
      )}
      {showDetail === 'Province' && (
        <ListTable
          columns={[
            { header: 'Kabupaten/Kota', key: 'city', style: { flex: 1 } },
            { header: 'Jumlah TUK', key: 'tuk', style: { width: 130 } },
            { header: 'Jumlah Asesmen', key: 'asesmen', style: { width: 130 } },
            { header: 'Jumlah Asesi', key: 'asesi', style: { width: 130 } },
            { header: 'Jumlah Asesor', key: 'asesor', style: { width: 130 } },
          ]}
          data={data}
        />
      )}
      {showDetail === 'Asesmen' && (
        <ListTable
          columns={[
            { header: 'Nama Skema', key: 'name', style: { flex: 1 } },
            { header: 'Nama TUK', key: 'name', style: { width: 130 } },
            { header: 'Jumlah Asesi', key: 'asesi', style: { width: 130 } },
            { header: 'Jumlah Asesor', key: 'asesor', style: { width: 130 } },
          ]}
          data={data}
        />
      )}
      {showDetail === 'Asesi' && (
        <ListTable
          columns={[
            { header: 'Nama Asesi', key: 'name', style: { flex: 1 } },
            { header: 'Skema Sertifikasi', key: 'name', style: { width: 160 } },
            { header: 'No. Sertifikat', key: 'code', style: { width: 130 } },
            { header: 'No. Blanko', key: 'code', style: { width: 120 } },
            { header: 'Masa Berlaku Sertifikat', key: 'asesor', style: { width: 160 } },
          ]}
          data={data}
        />
      )}
      {showDetail === 'Skema Sertifikasi' && (
        <ListTable
          columns={[
            { header: 'Nama Skema Sertifikasi', key: 'name', style: { flex: 1 } },
            { header: 'Kode Skema Sertifikasi', key: 'code', style: { width: 180 } },
            { header: 'Jumlah Asesi yang mengikuti uji sertifikasi', key: 'asesmen', style: { width: 290 } },
          ]}
          data={data}
        />
      )}
      {showDetail === 'TUK' && (
        <ListTable
          columns={[
            { header: 'Nama TUK', key: 'city', style: { flex: 1 } },
            { header: 'Jumlah Asesmen', key: 'asesmen', style: { width: 140 } },
            { header: 'Jumlah Skema', key: 'tuk', style: { width: 130 } },
            { header: 'Jumlah Asesi', key: 'asesi', style: { width: 130 } },
            { header: 'Jumlah Asesor', key: 'asesor', style: { width: 130 } },
          ]}
          data={data}
        />
      )}
    </div>
  );
};

export default SummaryDetail;
