import { notification } from 'antd';
import { API_URL } from '../../../configs/config';
import { httpAuth } from '../../../helpers/http';

export const getStructures = params => async dispatch => {
  try {
    dispatch({ type: 'setLoadingStructures', payload: true });
    const res = await httpAuth().get(`${API_URL}/organization_structures`, { params });
    dispatch({ type: 'setStructures', payload: res.data });
    dispatch({ type: 'setLoadingStructures', payload: false });
    return res.data;
  } catch (err) {
    dispatch({ type: 'setLoadingStructures', payload: false });
    throw err?.response;
  }
};

export const createStructure = async data => {
  try {
    const res = await httpAuth().post(`${API_URL}/organization_structures`, data);
    notification.success({
      message: 'Sukses',
      description: 'Struktur baru berhasil ditambahkan',
    });
    return res.data;
  } catch (err) {
    notification.error({
      message: 'Terjadi kesalahan',
      description: err?.response?.data?.message || 'Mohon coba beberapa saat lagi',
    });
    throw err?.response;
  }
};

export const editStructure = async (id, data) => {
  try {
    const res = await httpAuth().put(`${API_URL}/organization_structures/${id}`, data);
    return res.data;
  } catch (err) {
    notification.error({
      message: 'Terjadi kesalahan',
      description: err?.response?.data?.message || 'Mohon coba beberapa saat lagi',
    });
    throw err?.response;
  }
};

export const assignStructure = async (id, data) => {
  try {
    const res = await httpAuth().put(`${API_URL}/organization_structures/${id}/assign`, data);
    return res.data;
  } catch (err) {
    notification.error({
      message: 'Terjadi kesalahan',
      description: err?.response?.data?.message || 'Mohon coba beberapa saat lagi',
    });
    throw err?.response;
  }
};

export const deleteStructure = async id => {
  try {
    const res = await httpAuth().delete(`${API_URL}/organization_structures/${id}`);
    return res.data;
  } catch (err) {
    notification.error({
      message: 'Terjadi kesalahan',
      description: err?.response?.data?.message || 'Mohon coba beberapa saat lagi',
    });
    throw err?.response;
  }
};

export const getDivisions = () => async dispatch => {
  try {
    const res = await httpAuth().get(`${API_URL}/divisions`);
    dispatch({ type: 'setDivisions', payload: res.data });
    return res.data;
  } catch (err) {
    throw err?.response;
  }
};

export const createDivision = async data => {
  try {
    const res = await httpAuth().post(`${API_URL}/divisions`, data);
    return res.data;
  } catch (err) {
    notification.error({
      message: 'Terjadi kesalahan',
      description: err?.response?.data?.message || 'Mohon coba beberapa saat lagi',
    });
    throw err?.response;
  }
};

export const getPositions = () => async dispatch => {
  try {
    const res = await httpAuth().get(`${API_URL}/positions`);
    dispatch({ type: 'setPositions', payload: res.data });
    return res.data;
  } catch (err) {
    throw err?.response;
  }
};

export const createPosition = async data => {
  try {
    const res = await httpAuth().post(`${API_URL}/positions`, data);
    return res.data;
  } catch (err) {
    notification.error({
      message: 'Terjadi kesalahan',
      description: err?.response?.data?.message || 'Mohon coba beberapa saat lagi',
    });
    throw err?.response;
  }
};
