import { Avatar, Button, Form, Input, Modal } from 'antd';
import React, { useEffect } from 'react';
import { UserOutlined } from '@ant-design/icons';

const ModalProfile = ({ isOpen, onClose, data }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (isOpen) {
      form.setFieldsValue({
        name: `${data?.first_name} ${data?.last_name}`,
        email: data?.email,
        phone: data?.phone_number,
      });
    }
  }, [isOpen]);

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      width={350}
      title="Profile User"
      open={isOpen}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          Tutup
        </Button>,
      ]}
    >
      <Form
        name="basic"
        layout="vertical"
        form={form}
        initialValues={{
          name: `${data?.first_name} ${data?.last_name}`,
          email: data?.email,
          phone: data?.phone_number,
        }}
      >
        <div className="text-center mb-4">
          <Avatar size={100} className="border" src="" icon={<UserOutlined />} />
        </div>
        <Form.Item label="Nama Lengkap" name="name">
          <Input disabled className="input-view" />
        </Form.Item>

        <Form.Item label="Email" name="email">
          <Input disabled className="input-view" />
        </Form.Item>
        <Form.Item label="No. Telepon" name="phone">
          <Input disabled className="input-view" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalProfile;
