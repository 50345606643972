import { Form, Modal, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowLeftOutlined } from '@ant-design/icons';
import DocumentForm from './DocumentForm';
import AccessForm from './AccessForm';
import { createDocument, getCategories, getDocuments } from '../../services/action';

const ModalCreate = ({ isOpen, onClose, selectedCategory }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const documentParams = useSelector(state => state.document.documentParams);

  useEffect(() => {
    if (isOpen) {
      setStep(1);
      if (selectedCategory) {
        form.setFieldValue('doc_categories_id', selectedCategory.id);
      }
    }
  }, [isOpen]);

  const handleOk = () => {
    form
      .validateFields()
      .then(values => {
        setLoading(true);
        createDocument({ ...values, file_path: (values.file_path?.key || '').replace(/ /g, '+') })
          .then(() => {
            handleClose();
            dispatch(getDocuments(documentParams));
            dispatch(getCategories({ has_documents: true }));
          })
          .finally(() => setLoading(false));
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });
  };

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  const handlePrev = () => {
    setStep(1);
  };

  const handleNext = () => {
    form
      .validateFields()
      .then(values => {
        console.log(values);
        setStep(2);
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });
  };

  return (
    <Modal
      width={400}
      title={
        step === 1 ? (
          'Upload Dokumen'
        ) : (
          <Space>
            <ArrowLeftOutlined onClick={handlePrev} />
            <div>Atur Akses Dokumen</div>
          </Space>
        )
      }
      maskClosable={false}
      centered
      open={isOpen}
      onOk={step === 1 ? handleNext : handleOk}
      onCancel={step === 1 ? handleClose : handlePrev}
      cancelText={step === 1 ? 'Batal' : 'Kembali'}
      okText={step === 1 ? 'Lanjutkan' : 'Simpan'}
      confirmLoading={loading}
      bodyStyle={{ padding: 0 }}
    >
      <Form name="basic" layout="vertical" form={form}>
        <DocumentForm visible={step === 1} />
        <Form.Item name="permission_users" noStyle>
          <AccessForm visible={step === 2} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalCreate;
