import { notification } from 'antd';
import axios from 'axios';
import { getToken } from './token';

export const http = () => {
  const service = axios.create();
  service.defaults.timeout = 20000;
  service.defaults.timeoutErrorMessage = 'Request timeout exceeded';

  service.interceptors.request.use(
    function (config) {
      if (!navigator.onLine) {
        notification.warning({ message: 'Connection lost', description: 'Please check your network!' });
        return Promise.reject();
      }
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  return service;
};

export const httpAuth = token => {
  const service = axios.create();
  service.defaults.timeout = 20000;
  service.defaults.timeoutErrorMessage = 'Request timeout exceeded';

  service.interceptors.request.use(
    function (config) {
      if (!navigator.onLine) {
        notification.warning({ message: 'Connection lost', description: 'Please check your network!' });
        return Promise.reject();
      }
      const currentToken = getToken() ? 'Bearer ' + getToken() : null;
      config.headers.Authorization = token || currentToken;
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  service.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (error.response && error.response.status === 401) {
        console.log(error?.message);
      } else if (error?.message === 'Network Error') {
        console.log(error?.message);
      } else {
        console.log(error?.message);
      }
      return Promise.reject(error);
    }
  );
  return service;
};
