import { notification } from 'antd';
import { MASTER_URL } from '../../../configs/config';
import { httpAuth } from '../../../helpers/http';

export const getCertifications = params => async dispatch => {
  try {
    dispatch({ type: 'setLoadingList', payload: true });
    const res = await httpAuth().get(`${MASTER_URL}/certification_schemes`, { params });
    dispatch({ type: 'setCertifications', payload: res.data, params });
    dispatch({ type: 'setLoadingList', payload: false });
    return res.data;
  } catch (err) {
    dispatch({ type: 'setLoadingList', payload: false });
    throw err?.response;
  }
};

export const getCompetences = params => async dispatch => {
  try {
    const res = await httpAuth().get(`${MASTER_URL}/unit_competences`, { params });
    dispatch({ type: 'setCompetences', payload: res.data, params });
    return res.data;
  } catch (err) {
    throw err?.response;
  }
};

export const createCertification = data => async dispatch => {
  try {
    dispatch({ type: 'setLoadingSubmit', payload: true });
    const res = await httpAuth().post(`${MASTER_URL}/certification_schemes`, data);
    dispatch({ type: 'setLoadingSubmit', payload: false });
    return res.data;
  } catch (err) {
    dispatch({ type: 'setLoadingSubmit', payload: false });
    notification.error({
      message: 'Terjadi kesalahan',
      description: err?.response?.data?.message || 'Mohon coba beberapa saat lagi',
    });
    throw err?.response;
  }
};

export const updateCertification = (id, data) => async dispatch => {
  try {
    dispatch({ type: 'setLoadingSubmit', payload: true });
    const res = await httpAuth().put(`${MASTER_URL}/certification_schemes/${id}`, data);
    dispatch({ type: 'setLoadingSubmit', payload: false });
    return res.data;
  } catch (err) {
    dispatch({ type: 'setLoadingSubmit', payload: false });
    notification.error({
      message: 'Terjadi kesalahan',
      description: err?.response?.data?.message || 'Mohon coba beberapa saat lagi',
    });
    throw err?.response;
  }
};

export const deleteCertification = async id => {
  try {
    const res = await httpAuth().delete(`${MASTER_URL}/certification_schemes/${id}`);
    return res.data;
  } catch (err) {
    notification.error({
      message: 'Terjadi kesalahan',
      description: err?.response?.data?.message || 'Mohon coba beberapa saat lagi',
    });
    throw err?.response;
  }
};

export const deleteCertificationUnit = async (certificationId, unitId) => {
  try {
    const res = await httpAuth().delete(`${MASTER_URL}/certification_schemes/${certificationId}/unit_competence`, {
      params: {
        unit_competences_id: unitId,
      },
    });
    return res.data;
  } catch (err) {
    notification.error({
      message: 'Terjadi kesalahan',
      description: err?.response?.data?.message || 'Mohon coba beberapa saat lagi',
    });
    throw err?.response;
  }
};
