export const API_URL = process.env.REACT_APP_API_URL;
export const AUTH_API_URL = process.env.REACT_APP_AUTH_API_URL;
export const DOC_API_URL = process.env.REACT_APP_DOC_API_URL;
export const MASTER_URL = process.env.REACT_APP_MASTER_URL;
export const BUCKET_URL = process.env.REACT_APP_BUCKET_URL;
export const BUCKET_NAME = process.env.REACT_APP_BUCKET_NAME;
export const REGION = process.env.REACT_APP_REGION;
export const ACCESS_KEY_ID = process.env.REACT_APP_ACCESS_KEY_ID;
export const SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY;
export const SSO_URL = process.env.REACT_APP_SSO_URL;
export const SSO_CLIENT_ID = process.env.REACT_APP_SSO_CLIENT_ID;
export const SSO_CLIENT_SECRET = process.env.REACT_APP_SSO_CLIENT_SECRET;
export const SCHEMA_CATEGORY_ID = process.env.REACT_APP_SCHEMA_CATEGORY_ID;
