import { http } from './http';
import { SSO_CLIENT_ID, SSO_CLIENT_SECRET, SSO_URL } from '../configs/config';

export const generateLink = async () => {
  try {
    const res = await http().post(`${SSO_URL}/oauth/generate-link`, {
      redirect_uri: window.location.origin + '/oauth/callback',
      client_secret: SSO_CLIENT_SECRET,
      remote_origin: window.location.origin,
      state: 'home',
      client_id: SSO_CLIENT_ID,
    });
    return res.data;
  } catch (err) {
    throw err?.response;
  }
};

export const refreshToken = async refreshToken => {
  try {
    const res = await http().post(`${SSO_URL}/auth/token/refresh`, { refresh_token: refreshToken });
    return res.data;
  } catch (err) {
    throw err?.response;
  }
};
