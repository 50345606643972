import { Form, Select } from 'antd';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SelectWithCreate from '../../../components/SelectWithCreate';
import { createDivision, createPosition, getDivisions, getPositions } from '../services/action';

const { Option } = Select;

const EditForm = ({ data, hidden }) => {
  const dispatch = useDispatch();
  const divisions = useSelector(state => state.organization.divisions);
  const positions = useSelector(state => state.organization.positions);
  const structures = useSelector(state => state.organization.structures?.items || []);
  const [loadingPosition, setLoadingPosition] = useState(false);
  const [loadingDivision, setLoadingDivision] = useState(false);

  const handleCreatePosition = value => {
    if (!value) {
      return;
    }
    const body = {
      position_name: value,
      position_code: value.replace(/ /g, '_'),
    };
    setLoadingPosition(true);
    createPosition(body)
      .then(() => {
        dispatch(getPositions());
      })
      .finally(() => setLoadingPosition(false));
  };

  const handleCreateDivision = value => {
    if (!value) {
      return;
    }
    const body = {
      division_name: value,
      division_code: value.replace(/ /g, '_'),
    };
    setLoadingDivision(true);
    createDivision(body)
      .then(() => {
        dispatch(getDivisions());
      })
      .finally(() => setLoadingDivision(false));
  };

  return (
    <div className={hidden ? 'hidden' : ''}>
      <Form.Item
        label="Posisi"
        requiredMark="optional"
        name="positions_id"
        rules={[
          {
            required: true,
            message: 'Silahkan pilih posisi!',
          },
        ]}
      >
        <SelectWithCreate
          placeholder="Pilih Posisi"
          handleCreate={handleCreatePosition}
          loading={loadingPosition}
          options={positions.map(item => ({ value: item.id, label: item.position_name }))}
        />
      </Form.Item>
      <Form.Item
        label="Divisi"
        requiredMark="optional"
        name="divisions_id"
        rules={[
          {
            required: true,
            message: 'Silahkan pilih divisi!',
          },
        ]}
      >
        <SelectWithCreate
          placeholder="Pilih Divisi"
          handleCreate={handleCreateDivision}
          loading={loadingDivision}
          options={divisions.map(item => ({ value: item.id, label: item.division_name }))}
        />
      </Form.Item>
      {data.parent > 0 && (
        <Form.Item
          label="Parent"
          requiredMark="optional"
          name="parent"
          rules={[
            {
              required: true,
              message: 'Silahkan pilih parent!',
            },
          ]}
        >
          <Select placeholder="Pilih parent">
            {structures.map((item, idx) => (
              <Option key={idx} value={item.id}>
                {item.position_name} {item.division_name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}
    </div>
  );
};

export default EditForm;
