import { Typography } from 'antd';
import React, { useState } from 'react';
import Map from './components/Map';
import CountySummary from './components/CountySummary';
import AllSummary from './components/AllSummary';
import SummaryDetail from './components/SummaryDetail';

const Home = () => {
  const [province, setProvince] = useState('');
  const [showDetail, setShowDetail] = useState('');

  return (
    <div className="p-5">
      {!showDetail && (
        <>
          <Typography.Title level={4}>Beranda</Typography.Title>
          <div className="rounded-lg border p-3 mb-4 flex" style={{ height: 375, background: '#fff' }}>
            <Map {...{ province, setProvince }} />
            {province && <CountySummary {...{ province, setProvince, setShowDetail }} />}
          </div>
          <AllSummary setShowDetail={setShowDetail} />
        </>
      )}
      {showDetail && <SummaryDetail {...{ province, showDetail, setShowDetail }} />}
    </div>
  );
};

export default Home;
