import React, { useEffect, useState } from 'react';
import { getCategoriById, getCategories, getDocuments } from './services/action';
import { useDispatch, useSelector } from 'react-redux';
import DocumentList from './components/list/DocumentList';
import EmptyDocument from './components/list/EmptyDocument';
import DocumentHeader from './components/list/DocumentHeader';
import CategoryList from './components/list/CategoryList';
import EmptyCategory from './components/list/EmptyCategory';
import { Spin } from 'antd';
import { useSearchParams } from 'react-router-dom';
import DocumentSearch from './components/list/DocumentSearch';
import ModalCreate from './components/create/ModalCreate';

const Document = props => {
  const dispatch = useDispatch();
  const [modalCreate, setModalCreate] = useState(false);
  const categories = useSelector(state => state.document.categories);
  const documents = useSelector(state => state.document.documents);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [subCategoryId, setSubCategoryId] = useState(0);
  const loadingCategories = useSelector(state => state.document.loadingCategories);
  const loadingDocuments = useSelector(state => state.document.loadingDocuments);
  const categorySearchId = useSelector(state => state.app.categorySearchId);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    dispatch(getCategories({ has_documents: true }));
  }, []);

  useEffect(() => {
    if (selectedCategory && searchParams.get('search')) {
      setSelectedCategory(null);
    } else if (searchParams.get('search')) {
      dispatch(getDocuments({ page: 1, limit: 10, search: searchParams.get('search') }));
    } else if (!categorySearchId && !searchParams.get('search')) {
      dispatch(getDocuments({ page: 1, limit: 10 }));
    }
  }, [searchParams.get('search')]);

  useEffect(() => {
    if (categorySearchId) {
      dispatch({ type: 'setLoadingDocuments', payload: true });
      getCategoriById(categorySearchId).then(res => {
        setSelectedCategory(res);
        dispatch(getDocuments({ page: 1, limit: 10, category_id: categorySearchId })).then(() =>
          dispatch({ type: 'set', categorySearchId: 0 })
        );
      });
    }
  }, [categorySearchId]);

  return (
    <div className="h-full">
      {categories.length === 0 ? (
        loadingCategories ? (
          <div className="centered h-full">
            <Spin size="large" tip="Memuat..." />
          </div>
        ) : (
          <EmptyCategory onCreate={() => setModalCreate(true)} />
        )
      ) : (
        <div className="flex h-full">
          <CategoryList onCreate={() => setModalCreate(true)} {...{ selectedCategory, setSelectedCategory }} />
          <div className="sub-content">
            {!selectedCategory && searchParams.get('search') && <DocumentSearch />}
            <DocumentHeader selectedCategory={selectedCategory} {...{ subCategoryId, setSubCategoryId }} />
            {documents.length === 0 && !subCategoryId && !searchParams.get('search') ? (
              loadingDocuments ? (
                <div className="centered h-full">
                  <Spin size="large" tip="Memuat..." />
                </div>
              ) : (
                <EmptyDocument onCreate={() => setModalCreate(true)} />
              )
            ) : (
              <DocumentList />
            )}
          </div>
        </div>
      )}
      <ModalCreate isOpen={modalCreate} onClose={() => setModalCreate(false)} selectedCategory={selectedCategory} />
    </div>
  );
};

export default Document;
