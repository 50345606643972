import { Form, Modal } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createDivision,
  createPosition,
  createStructure,
  getDivisions,
  getPositions,
  getStructures,
} from '../services/action';
import SelectWithCreate from '../../../components/SelectWithCreate';

const ModalCreate = ({ isOpen, onClose, data }) => {
  const [form] = Form.useForm();
  const divisions = useSelector(state => state.organization.divisions);
  const positions = useSelector(state => state.organization.positions);
  const [loading, setLoading] = useState(false);
  const [loadingPosition, setLoadingPosition] = useState(false);
  const [loadingDivision, setLoadingDivision] = useState(false);
  const dispatch = useDispatch();

  const handleOk = () => {
    form
      .validateFields()
      .then(values => {
        console.log(values);
        setLoading(true);
        let parent_path = data?.parent_path ? data?.parent_path + ',' + data?.id : `${data?.id || ''}`;
        createStructure({ ...values, parent: data?.id || 0, parent_path })
          .then(() => {
            handleClose();
            dispatch(getStructures());
          })
          .finally(() => setLoading(false));
      })
      .catch(info => {
        console.log('Validate Failed:', info);
      });
  };

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  const handleCreatePosition = value => {
    if (!value) {
      return;
    }
    const body = {
      position_name: value,
      position_code: value.replace(/ /g, '_'),
    };
    setLoadingPosition(true);
    createPosition(body)
      .then(() => {
        dispatch(getPositions());
      })
      .finally(() => setLoadingPosition(false));
  };

  const handleCreateDivision = value => {
    if (!value) {
      return;
    }
    const body = {
      division_name: value,
      division_code: value.replace(/ /g, '_'),
    };
    setLoadingDivision(true);
    createDivision(body)
      .then(() => {
        dispatch(getDivisions());
      })
      .finally(() => setLoadingDivision(false));
  };

  return (
    <Modal
      width={350}
      title="Buat Posisi dan Divisi"
      open={isOpen}
      onOk={handleOk}
      onCancel={handleClose}
      cancelText="Batal"
      okText="Simpan"
      confirmLoading={loading}
    >
      <Form name="basic" layout="vertical" form={form}>
        <Form.Item
          label="Posisi"
          requiredMark="optional"
          name="positions_id"
          rules={[
            {
              required: true,
              message: 'Silahkan pilih posisi!',
            },
          ]}
        >
          <SelectWithCreate
            placeholder="Pilih Posisi"
            handleCreate={handleCreatePosition}
            loading={loadingPosition}
            options={positions.map(item => ({ value: item.id, label: item.position_name }))}
          />
        </Form.Item>

        <Form.Item
          label="Divisi"
          requiredMark="optional"
          name="divisions_id"
          rules={[
            {
              required: true,
              message: 'Silahkan pilih divisi!',
            },
          ]}
        >
          <SelectWithCreate
            placeholder="Pilih Divisi"
            handleCreate={handleCreateDivision}
            loading={loadingDivision}
            options={divisions.map(item => ({ value: item.id, label: item.division_name }))}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalCreate;
