export const numberOnly = (value) => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, '');
  return onlyNums;
};

export const validNumber = (value) => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, '');
  if (!onlyNums) {
    return onlyNums;
  }
  return parseInt(onlyNums);
};

export const percentage = (value) => {
  if (!value) {
    return value;
  }
  if (value === '.') {
    return '0.';
  }
  if (value.split('.').length - 1 === 2 || value === '00') {
    return value.slice(0, -1);
  }
  let onlyNums = value.replace(/[^\d|\\.]/g, '');
  if (parseFloat(onlyNums) > 100) {
    onlyNums = 100;
  }

  return onlyNums;
};

export const moneyFormatter = (value) => {
  if (!value) return '';
  if (value.length < 4) {
    value = parseInt(value);
  }
  const onlyNums = value.toString().replace(/[^\d]/g, '');
  return onlyNums.replace(/(\d)(?=(\d{3})+\b)/g, '$1.');
};

export const moneyParser = (value) => (value ? parseInt(value.replace(/\./g, '')) : '');
