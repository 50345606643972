import { CloseOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';

const CountySummary = ({ province, setProvince, setShowDetail }) => {
  return (
    <div className="bg-white ml-3" style={{ width: 370 }}>
      <div className="font-semibold text-lg mb-2 center-y justify-between">
        {province} <CloseOutlined onClick={() => setProvince('')} />
      </div>
      <Space direction="vertical" className="mb-2 w-full">
        <div className="rounded-lg border p-2 px-3">
          Total TUK
          <div className="font-semibold">999</div>
        </div>
        <div className="rounded-lg border p-2 px-3">
          Total Asesmen
          <div className="font-semibold">999</div>
        </div>
        <div className="rounded-lg border p-2 px-3">
          Total Asesi
          <div className="font-semibold">999</div>
        </div>
        <div className="rounded-lg border p-2 px-3">
          Total Asesor
          <div className="font-semibold">999</div>
        </div>
      </Space>
      <Button type="primary" block onClick={() => setShowDetail('Province')}>
        Detail Selengkapnya
      </Button>
    </div>
  );
};

export default CountySummary;
