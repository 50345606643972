import jwtDecode from 'jwt-decode';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

export const isAuth = () => {
  let token = cookies.get('sso-access-token');

  return !!token;
};

export const isValidRole = () => {
  let token = cookies.get('sso-access-token');

  if (token) {
    let data = jwtDecode(token);
    return data?.role_code === 'SUP' || data?.role_code === 'ADM' || data?.role_code === 'ACS';
  }

  return false;
};

export const getToken = () => {
  let token = cookies.get('sso-access-token');

  return token;
};

export const getTokenData = () => {
  let token = cookies.get('sso-access-token');
  if (token) {
    return jwtDecode(token);
  }
  return {};
};

export const getRefreshToken = () => {
  let token = cookies.get('sso-refresh-token');

  return token;
};

export const setToken = (token, refreshToken) => {
  let { exp } = jwtDecode(token);
  let { exp: expRefresh } = jwtDecode(refreshToken);
  let host = window.location.host;

  if (host.split('.').length === 1) {
    cookies.set('sso-access-token', token, { path: '/', expires: new Date(exp * 1000) });
    cookies.set('sso-refresh-token', refreshToken, { path: '/', expires: new Date(expRefresh * 1000) });
  } else {
    let domainParts = host.split('.');
    domainParts.shift();
    let domain = '.' + domainParts.join('.');
    cookies.set('sso-access-token', token, { path: '/', expires: new Date(exp * 1000), domain });
    cookies.set('sso-refresh-token', refreshToken, { path: '/', expires: new Date(expRefresh * 1000), domain });
  }
};

export const removeToken = () => {
  let host = window.location.host;

  if (host.split('.').length === 1) {
    cookies.remove('sso-access-token', { path: '/' });
    cookies.remove('sso-refresh-token', { path: '/' });
  } else {
    let domainParts = host.split('.');
    domainParts.shift();
    let domain = '.' + domainParts.join('.');
    cookies.remove('sso-access-token', { path: '/', domain });
    cookies.remove('sso-refresh-token', { path: '/', domain });
  }
};
