import Certification from '../pages/certification/Certification';
import Document from '../pages/document/Document';
import Home from '../pages/home/Home';
import Organization from '../pages/organization/Organization';
import Schedule from '../pages/schedule/Schedule';

const routes = [
  { path: '/', exact: true, name: 'Home', element: Home },
  { path: '/organization', exact: true, name: 'Organization Management', element: Organization },
  { path: '/document', exact: true, name: 'Document Management', element: Document },
  { path: '/certification', exact: true, name: 'Skema Sertifikasi', element: Certification },
  { path: '/schedule', exact: true, name: 'Jadwal Kegiatan', element: Schedule },
];

export default routes;
