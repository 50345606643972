import { Form, Input } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SelectWithCreate from '../../../../components/SelectWithCreate';
import UploadS3 from '../../../../components/UploadS3';
import { getTokenData } from '../../../../helpers/token';
import { createCategory, createSubCategory, getCategories, getSubCategories } from '../../services/action';

const DocumentForm = ({ visible }) => {
  const form = Form.useFormInstance();
  const dispatch = useDispatch();
  const [loadingCategory, setLoadingCategory] = useState(false);
  const [loadingSubCategory, setLoadingSubCategory] = useState(false);
  const [categories, setCategories] = useState([]);
  const [dirName, setDirName] = useState('');
  const loadingSubCategories = useSelector(state => state.document.loadingSubCategories);
  const subCategories = useSelector(state => state.document.subCategories);
  const categoryId = Form.useWatch('doc_categories_id', form);
  const subCategoryId = Form.useWatch('doc_sub_categories_id', form);
  const docName = Form.useWatch('document_name', form);

  useEffect(() => {
    dispatch(getCategories({}, false)).then(res => setCategories(res));
  }, []);

  useEffect(() => {
    if (categoryId) {
      const lspName = getTokenData()?.lsp_name || 'lsp-demo';
      dispatch(getSubCategories({ category_id: categoryId }));
      form.resetFields(['doc_sub_categories_id']);
      let categoryName = categories.find(item => item.id === categoryId)?.category;
      setDirName(`${lspName}/${categoryName}`);
    }
  }, [categoryId]);

  useEffect(() => {
    if (subCategoryId) {
      let subName = subCategories.find(item => item.id === subCategoryId)?.sub_category;
      setDirName(`${dirName}/${subName}`);
    }
  }, [subCategoryId]);

  const handleCreateCategory = value => {
    if (!value) {
      return;
    }
    const body = {
      category: value,
    };
    setLoadingCategory(true);
    dispatch(createCategory(body))
      .then(res => setCategories([...categories, res]))
      .finally(() => setLoadingCategory(false));
  };

  const handleCreateSubCategory = value => {
    if (!value) {
      return;
    }
    const body = {
      doc_categories_id: categoryId,
      sub_category: value,
    };
    setLoadingSubCategory(true);
    dispatch(createSubCategory(body)).finally(() => setLoadingSubCategory(false));
  };

  return (
    <div className={`p-6 pt-4 ${visible ? '' : 'hidden'}`}>
      <Form.Item
        label="Kategori Dokumen"
        name="doc_categories_id"
        rules={[{ required: true, message: 'Silahkan pilih kategori!' }]}
      >
        <SelectWithCreate
          placeholder="Pilih/Tambahkan Kategori Dokumen"
          handleCreate={handleCreateCategory}
          loadingCreate={loadingCategory}
          options={categories.map(item => ({ value: item.id, label: item.category }))}
        />
      </Form.Item>

      <Form.Item label="Sub Kategori Dokumen" name="doc_sub_categories_id">
        <SelectWithCreate
          placeholder="Pilih/Tambahkan Sub Kategori Dokumen"
          handleCreate={handleCreateSubCategory}
          loadingCreate={loadingSubCategory}
          loadingSelect={loadingSubCategories}
          disabled={!categoryId || loadingSubCategories}
          options={subCategories.map(item => ({ value: item.id, label: item.sub_category }))}
        />
      </Form.Item>
      <Form.Item
        label="Nama Dokumen"
        name="document_name"
        rules={[{ required: true, message: 'Silahkan input nama dokumen!' }]}
      >
        <Input placeholder="Input Nama Dokumen" />
      </Form.Item>
      <Form.Item
        label="Upload Dokumen"
        name="file_path"
        rules={[{ required: true, message: 'Silahkan upload dokumen!' }]}
      >
        <UploadS3
          label="Upload Dokumen"
          disabled={!docName || !categoryId}
          dirName={dirName}
          fileName={docName}
          accept="application/pdf, 
            application/msword, 
            application/vnd.ms-powerpoint, 
            application/vnd.openxmlformats-officedocument.presentationml.slideshow,
            application/vnd.openxmlformats-officedocument.presentationml.presentation,
            application/vnd.ms-excel, 
            application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          onError={err => form.setFields([{ name: 'file_path', errors: [err] }])}
        />
      </Form.Item>
    </div>
  );
};

export default DocumentForm;
