import { SearchOutlined } from '@ant-design/icons';
import { Space, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { getCategories } from '../../services/action';

const DocumentSearch = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [categories, setCategories] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const documents = useSelector(state => state.document.documents);

  useEffect(() => {
    setLoadingCategories(true);
    dispatch(getCategories({ has_documents: true, search: searchParams.get('search') }, false))
      .then(res => setCategories(res || []))
      .finally(() => setLoadingCategories(false));
  }, [searchParams.get('search')]);

  return (
    <div>
      <div className="mb-5">
        <Typography.Title level={3} style={{ margin: 0 }}>
          <span>Hasil Pencarian '{searchParams.get('search')}'</span>
        </Typography.Title>
        <div className="text-gray-500">
          {categories.length} Kategori Dokumen & {documents.length} Dokumen ditemukan
        </div>
      </div>
      {!loadingCategories && categories.length > 0 && (
        <div className="mb-5">
          <Typography.Title level={5} style={{ margin: 0 }}>
            <span>Berdasarkan Kategori Dokumen</span>
          </Typography.Title>
          <div className="flex flex-wrap mt-3 w-4/5">
            {categories.map((item, idx) => (
              <Space key={idx} className="w-1/3 mb-4">
                <SearchOutlined />
                {item.category}
              </Space>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DocumentSearch;
