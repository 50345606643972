import { DatePicker, Form, Input, Select } from 'antd';
import moment from 'moment';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTuk } from '../../services/action';

const ScheduleForm = ({ visible }) => {
  const dispatch = useDispatch();
  const form = Form.useFormInstance();
  const startDate = Form.useWatch('start_date', form);
  const endDate = Form.useWatch('end_date', form);
  const type = Form.useWatch('type', form);
  const certifications = useSelector(state => state.certification.certifications);
  const tukList = useSelector(state => state.schedule.tukList);

  const disabledStartDate = current => {
    if (!endDate) return false;
    return current && current.isAfter(endDate);
  };

  const disabledEndDate = current => {
    if (!startDate) return false;
    return current && current.isBefore(startDate);
  };

  useEffect(() => {
    dispatch(getTuk());
  }, []);

  return (
    <div className={`p-6 pt-4 ${visible ? '' : 'hidden'}`}>
      <Form.Item
        label="Nama Jadwal Kegiatan"
        name="title"
        rules={[{ required: true, message: 'Silahkan input nama jadwal kegiatan!' }]}
      >
        <Input placeholder="Input Nama Jadwal Kegiatan" />
      </Form.Item>
      <Form.Item
        label="Tanggal Mulai"
        name="start_date"
        rules={[{ required: true, message: 'Silahkan input tanggal mulai!' }]}
      >
        <DatePicker
          showTime={{ format: 'HH:mm', defaultValue: moment('08:00', 'HH:mm') }}
          format="YYYY-MM-DD HH:mm"
          className="w-full"
          placeholder="Input Tanggal Mulai"
          disabledDate={disabledStartDate}
          showNow={!disabledStartDate(moment())}
        />
      </Form.Item>
      <Form.Item
        label="Tanggal Selesai"
        name="end_date"
        rules={[{ required: true, message: 'Silahkan input tanggal selesai!' }]}
      >
        <DatePicker
          showTime={{ format: 'HH:mm', defaultValue: moment('09:00', 'HH:mm') }}
          format="YYYY-MM-DD HH:mm"
          className="w-full"
          placeholder="Input Tanggal Selesai"
          disabledDate={disabledEndDate}
          showNow={!disabledEndDate(moment())}
        />
      </Form.Item>
      <Form.Item
        label="Jenis Kegiatan"
        name="type"
        rules={[{ required: true, message: 'Silahkan pilih jenis kegiatan!' }]}
      >
        <Select
          placeholder="Pilih jenis kegiatan"
          options={[
            { value: 'asesmen', label: 'Asesmen' },
            { value: 'internal', label: 'Internal' },
            { value: 'eksternal', label: 'Eksternal' },
          ]}
          className="input-view"
        />
      </Form.Item>
      {type === 'asesmen' && (
        <Form.Item
          name="certification_schemes_id"
          label="Skema Sertifikasi"
          rules={[{ required: true, message: 'Silahkan pilih skema sertifikasi!' }]}
        >
          <Select
            placeholder="Pilih skema sertifikasi"
            options={certifications.map(item => ({ value: item?.id, label: item?.schema_name }))}
            className="input-view"
          />
        </Form.Item>
      )}
      {type === 'asesmen' && (
        <Form.Item
          name="tuk_id"
          label="TUK Pelaksana"
          rules={[{ required: true, message: 'Silahkan pilih TUK pelaksana!' }]}
        >
          <Select
            placeholder="Pilih TUK pelaksana"
            options={tukList.map(item => ({ value: item?.id, label: item?.tuk_name }))}
            className="input-view"
          />
        </Form.Item>
      )}
    </div>
  );
};

export default ScheduleForm;
