const initialState = {
  list: [],
  count: 0,
  params: { limit: 50, offset: 0 },
  loading: false,
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case 'setUsers':
      return {
        ...state,
        list: action.payload?.data || [],
        count: parseInt(action.payload?.count),
        params: action.params,
      };
    case 'setMoreUsers':
      return {
        ...state,
        list: [...state.list, ...action.payload?.data] || [],
        count: parseInt(action.payload?.count),
        params: action.params,
      };
    case 'setLoadingGetUsers':
      return { ...state, loading: action.payload };

    default:
      return state;
  }
}
