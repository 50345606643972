import { Col, Row, Select, Skeleton, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDivisions, getPositions, getStructures } from '../../../organization/services/action';
import CheckboxUserGroup from './CheckboxUserGroup';

const optionAll = { value: 0, label: 'Semua' };

const MemberForm = ({ visible, onChange, value = [] }) => {
  const dispatch = useDispatch();
  const divisions = useSelector(state => state.organization.divisions);
  const positions = useSelector(state => state.organization.positions);
  const structures = useSelector(state => state.organization.structures?.items || []);
  const loading = useSelector(state => state.organization.loadingStructures);
  const [mounted, setMounted] = useState(false);
  const [divisionId, setDivisionId] = useState(0);
  const [positionId, setPositionId] = useState(0);
  const [userList, setUserList] = useState({});

  useEffect(() => {
    setMounted(true);
    dispatch(getDivisions());
    dispatch(getPositions());
    const maps = [...new Set(value.map(item => item.division_name))];
    const defaultValue = {};
    maps.forEach(item => {
      defaultValue[item] = [...new Set(value.filter(val => val.division_name === item).map(val => val.user_id))];
    });
    setUserList(defaultValue);
  }, []);

  useEffect(() => {
    if (mounted) {
      dispatch(getStructures({ divisions_id: divisionId || null, positions_id: positionId || null }));
      onChange([]);
      setUserList({});
    }
  }, [divisionId, positionId]);

  const mapStructure = [...new Set(structures.map(item => item.division_name))];

  const handleChangeSelect = (name, list = []) => {
    let flatArr = Object.values({ ...userList, [name]: list }).flat(1);
    onChange([...new Set(flatArr)].map(item => ({ user_id: item })));
    setUserList({ ...userList, [name]: list });
  };

  return (
    <div className={visible ? '' : 'hidden'}>
      <div className="p-6 pt-4 border-b-4 border-gray-100">
        <div className="mb-3">
          <div>Filter Berdasarkan Bidang</div>
          <Select
            className="w-full"
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            showSearch
            options={[optionAll, ...divisions.map(item => ({ value: item.id, label: item.division_name }))]}
            value={divisionId}
            onChange={e => setDivisionId(e)}
          />
        </div>
        <div>
          <div>Filter Berdasarkan Jabatan</div>
          <Select
            className="w-full"
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            showSearch
            options={[optionAll, ...positions.map(item => ({ value: item.id, label: item.position_name }))]}
            value={positionId}
            onChange={e => setPositionId(e)}
          />
        </div>
      </div>
      {loading && (
        <div className="p-6 pt-4">
          {[1, 2].map(item => (
            <Row key={item} className="py-3">
              <Col md={4}>
                <Skeleton.Avatar active size="large" />
              </Col>
              <Col md={20}>
                <Space direction="vertical" className="w-full">
                  <Skeleton.Input style={{ height: 18 }} active block />
                  <Skeleton.Input style={{ height: 18 }} active block />
                </Space>
              </Col>
            </Row>
          ))}
        </div>
      )}
      {!loading && (
        <div className="p-6 pt-4" style={{ maxHeight: '50vh', overflow: 'auto' }}>
          {mapStructure.length === 0 && <div className="text-center py-3">Belum ada struktur</div>}
          {mapStructure.map((item, idx) => (
            <CheckboxUserGroup
              key={idx}
              name={item}
              onChangeSelect={list => handleChangeSelect(item, list)}
              initialValue={value.filter(val => val.user_id && val.division_name === item).map(val => val.user_id)}
              items={structures.filter(val => val.users_id && val.division_name === item)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default MemberForm;
