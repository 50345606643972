import React, { useEffect } from 'react';
import { Button, Input, List, Space } from 'antd';
import { UploadOutlined, SearchOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { getCategories, getDocuments } from '../../services/action';
import { useSearchParams } from 'react-router-dom';

const CategoryList = ({ onCreate, selectedCategory, setSelectedCategory }) => {
  const dispatch = useDispatch();
  const categories = useSelector(state => state.document.categories);
  const categoryParams = useSelector(state => state.document.categoryParams);
  const loadingCategories = useSelector(state => state.document.loadingCategories);
  const [searchParams] = useSearchParams();

  const handleSearch = debounce(e => {
    dispatch(getCategories({ ...categoryParams, search: e.target.value }));
  }, 500);

  const handleSelect = item => {
    let selected = item.id === selectedCategory?.id ? null : item;
    setSelectedCategory(selected);
    dispatch(
      getDocuments({
        page: 1,
        limit: 10,
        category_id: selected?.id,
        search: !selected ? searchParams.get('search') : null,
      })
    );
  };

  return (
    <List
      className="category-list"
      loading={loadingCategories}
      header={
        <Space direction="vertical" style={{ display: 'flex' }} size={12}>
          <Button type="primary" block icon={<UploadOutlined />} onClick={onCreate}>
            Upload Dokumen
          </Button>
          <Input placeholder="Cari Kategori" prefix={<SearchOutlined />} onChange={handleSearch} />
        </Space>
      }
      size="small"
      dataSource={categories}
      renderItem={item => (
        <List.Item
          className={`category-item ${item.id === selectedCategory?.id && 'active'}`}
          onClick={() => handleSelect(item)}
        >
          {item.category}
        </List.Item>
      )}
      itemLayout="vertical"
      split={false}
    />
  );
};

export default CategoryList;
