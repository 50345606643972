import ListTable from '../../../../components/ListTable';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Tooltip } from 'antd';
import { getSchedules } from '../../services/action';
import { formatDateTime, getFirstChar } from '../../../../helpers/string';

const ScheduleList = ({ handleView, activeRow }) => {
  const dispatch = useDispatch();
  const schedules = useSelector(state => state.schedule.schedules);
  const loadingList = useSelector(state => state.schedule.loadingList);
  const pagination = useSelector(state => state.schedule.pagination);
  const params = useSelector(state => state.schedule.params);

  const onPaginationChange = (page, limit) => {
    dispatch(getSchedules({ ...params, page, limit }));
  };

  return (
    <ListTable
      loading={loadingList}
      pagination={pagination}
      onPaginationChange={onPaginationChange}
      hovered
      onClick={handleView}
      activeRow={activeRow}
      columns={[
        {
          header: 'Nama Kegiatan',
          key: 'title',
          style: { flex: 1 },
        },
        {
          header: 'Jenis Kegiatan',
          key: 'type',
          style: { width: 140, textTransform: 'capitalize' },
        },
        {
          header: 'Tanggal Mulai - Selesai',
          key: 'start_date',
          style: { width: 280 },
          render: (cell, row) => `${formatDateTime(cell)} - ${formatDateTime(row.end_date)}`,
        },
        {
          header: 'Anggota yang terlibat',
          key: 'participants',
          style: { width: 160, display: 'flex' },
          render: cell => (
            <Avatar.Group
              maxCount={4}
              maxPopoverPlacement="bottom"
              maxStyle={{
                color: '#f56a00',
                backgroundColor: '#fde3cf',
                cursor: 'pointer',
              }}
              className="avatar-group-custom"
            >
              {cell.map((item, idx) => (
                <Tooltip key={idx} title={`${item?.first_name} ${item?.last_name}`}>
                  <Avatar style={{ backgroundColor: item?.bg_color, cursor: 'default' }}>
                    {getFirstChar(item?.first_name) + getFirstChar(item?.last_name)}
                  </Avatar>
                </Tooltip>
              ))}
              {cell?.length === 0 && '-'}
            </Avatar.Group>
          ),
        },
      ]}
      data={schedules}
    />
  );
};

export default ScheduleList;
