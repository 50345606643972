export const indonesiaGeojson = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [97.976814, 4.627501],
              [97.996109, 4.638056],
              [97.953613, 4.683333],
              [97.959999, 4.703611],
              [97.976387, 4.670832],
              [97.903229, 4.8873],
              [97.840553, 4.9075],
              [97.645279, 5.07444],
              [97.649719, 5.11917],
              [97.574875, 5.136448],
              [97.59198, 5.148521],
              [97.536118, 5.18111],
              [97.57077, 5.182097],
              [97.522957, 5.229202],
              [97.554443, 5.212223],
              [97.497223, 5.25023],
              [97.199448, 5.141943],
              [97.142746, 5.155816],
              [97.147942, 5.193611],
              [97.085732, 5.233533],
              [96.978661, 5.263054],
              [96.883331, 5.235],
              [96.860283, 5.243331],
              [96.844719, 5.27639],
              [96.750275, 5.224168],
              [96.712502, 5.235557],
              [96.726433, 5.247711],
              [96.522141, 5.19847],
              [96.465767, 5.23779],
              [96.417221, 5.19889],
              [96.382225, 5.2],
              [96.256943, 5.2625],
              [96.089165, 5.264445],
              [96.113609, 5.283329],
              [95.887222, 5.424444],
              [95.889999, 5.452501],
              [95.909447, 5.43375],
              [95.887222, 5.504171],
              [95.611946, 5.62778],
              [95.515747, 5.59514],
              [95.43222, 5.656944],
              [95.359566, 5.57267],
              [95.318047, 5.583891],
              [95.346947, 5.60528],
              [95.275833, 5.546668],
              [95.224998, 5.576112],
              [95.193466, 5.528031],
              [95.228752, 5.495141],
              [95.252747, 5.393441],
              [95.211945, 5.275833],
              [95.241661, 5.283072],
              [95.240837, 5.223612],
              [95.307327, 5.16943],
              [95.308609, 5.130279],
              [95.283058, 5.108333],
              [95.304169, 5.114999],
              [95.306114, 5.080833],
              [95.363121, 5.033404],
              [95.412224, 4.820278],
              [95.494446, 4.74861],
              [95.538612, 4.653333],
              [95.583046, 4.65667],
              [95.568336, 4.625555],
              [95.610031, 4.61916],
              [95.79673, 4.444719],
              [95.848541, 4.441674],
              [95.800003, 4.440555],
              [96.024147, 4.193898],
              [96.059937, 4.203924],
              [96.12709, 4.12376],
              [96.139603, 4.152119],
              [96.2425, 4.067871],
              [96.38031, 3.85572],
              [96.49585, 3.750257],
              [96.54454, 3.73003],
              [96.741943, 3.752222],
              [96.869446, 3.689781],
              [96.903435, 3.602802],
              [97.001808, 3.547494],
              [97.169724, 3.256945],
              [97.262169, 3.215809],
              [97.315002, 3.057779],
              [97.372223, 2.985833],
              [97.43, 2.931111],
              [97.597839, 2.872699],
              [97.650276, 2.672778],
              [97.662781, 2.4],
              [97.776108, 2.24139],
              [97.805023, 2.268901],
              [97.865791, 2.248934],
              [97.896683, 2.280493],
              [97.881668, 2.2425],
              [97.949997, 2.270561],
              [98.153343, 2.14694],
              [98.187607, 2.322901],
              [98.154373, 2.414939],
              [98.109291, 2.430041],
              [98.075226, 2.575529],
              [98.085129, 2.634219],
              [98.129227, 2.662321],
              [98.086426, 2.737551],
              [98.111717, 2.790251],
              [98.092186, 2.821309],
              [98.044548, 2.824701],
              [98.00679, 2.88956],
              [97.954613, 2.901539],
              [97.930649, 3.06259],
              [97.974823, 3.069951],
              [97.980606, 3.12311],
              [97.926758, 3.22501],
              [97.872261, 3.24905],
              [97.984917, 3.27558],
              [98.02742, 3.33115],
              [97.949707, 3.39154],
              [97.9319, 3.445421],
              [97.955627, 3.48142],
              [97.866966, 3.574901],
              [97.7995, 3.725921],
              [97.910461, 3.822941],
              [97.904312, 3.9115],
              [97.948952, 3.89086],
              [97.95816, 3.928901],
              [98.023132, 3.969779],
              [98.04081, 4.103982],
              [98.073616, 4.149899],
              [98.059311, 4.19073],
              [98.094833, 4.216781],
              [98.066879, 4.25311],
              [98.201218, 4.30419],
              [98.254997, 4.28972],
              [98.267586, 4.312595],
              [98.245003, 4.327499],
              [98.279724, 4.329723],
              [98.279167, 4.38889],
              [98.258354, 4.367801],
              [98.285553, 4.418612],
              [98.249443, 4.405279],
              [98.246391, 4.44061],
              [98.205002, 4.433611],
              [98.237503, 4.458829],
              [98.194107, 4.481671],
              [98.185555, 4.448334],
              [98.171555, 4.469377],
              [98.18721, 4.484157],
              [98.155563, 4.494721],
              [98.179321, 4.49561],
              [98.122505, 4.523224],
              [98.118057, 4.497778],
              [98.087158, 4.523333],
              [98.102219, 4.488062],
              [98.080353, 4.459579],
              [98.079689, 4.509738],
              [98.062508, 4.508731],
              [98.069611, 4.474355],
              [98.052574, 4.509319],
              [98.079262, 4.515678],
              [98.062714, 4.52582],
              [98.085564, 4.532343],
              [98.065407, 4.553231],
              [98.048332, 4.50639],
              [98.01722, 4.516389],
              [98.034721, 4.504444],
              [98.01889, 4.486671],
              [98.036942, 4.476388],
              [98.006668, 4.484167],
              [98.033058, 4.502778],
              [98.015274, 4.516389],
              [98.040001, 4.546391],
              [97.99472, 4.509722],
              [97.976295, 4.558143],
              [97.970619, 4.522869],
              [97.950836, 4.531944],
              [97.972733, 4.5398],
              [97.955276, 4.55028],
              [97.979691, 4.544725],
              [97.981369, 4.573796],
              [97.964165, 4.566667],
              [97.976112, 4.577502],
              [97.984673, 4.56777],
              [98.018059, 4.602221],
              [98.002502, 4.626111],
              [98.001732, 4.609802],
              [97.976814, 4.627501],
            ],
          ],
          [
            [
              [95.054169, 5.648611],
              [95.142365, 5.670001],
              [95.068886, 5.747222],
              [95.03083, 5.75181],
              [95.010834, 5.723611],
              [95.058609, 5.71944],
              [95.03833, 5.707221],
              [95.068611, 5.666944],
              [95.054169, 5.648611],
            ],
          ],
          [
            [
              [95.148888, 5.662222],
              [95.117775, 5.601665],
              [95.192223, 5.604444],
              [95.148888, 5.662222],
            ],
          ],
          [
            [
              [95.078888, 5.644723],
              [95.050552, 5.623194],
              [95.080276, 5.633061],
              [95.078888, 5.644723],
            ],
          ],
          [
            [
              [95.203888, 5.583334],
              [95.182777, 5.574168],
              [95.215782, 5.580112],
              [95.203888, 5.583334],
            ],
          ],
          [
            [
              [95.168892, 5.56222],
              [95.149612, 5.551534],
              [95.169724, 5.550556],
              [95.168892, 5.56222],
            ],
          ],
          [
            [
              [95.371666, 4.878056],
              [95.381386, 4.857781],
              [95.389442, 4.874719],
              [95.371666, 4.878056],
            ],
          ],
          [
            [
              [97.386627, 2.361375],
              [97.380127, 2.33842],
              [97.419815, 2.303168],
              [97.409973, 2.341929],
              [97.386627, 2.361375],
            ],
          ],
          [
            [
              [97.258499, 2.28419],
              [97.246689, 2.293951],
              [97.255745, 2.277728],
              [97.282715, 2.274959],
              [97.258499, 2.28419],
            ],
          ],
          [
            [
              [97.223969, 2.2343],
              [97.213196, 2.23945],
              [97.221931, 2.223517],
              [97.223969, 2.2343],
            ],
          ],
          [
            [
              [97.224876, 2.157896],
              [97.34549, 2.039349],
              [97.341553, 2.086648],
              [97.360626, 2.093075],
              [97.334724, 2.118918],
              [97.339699, 2.16412],
              [97.292847, 2.201464],
              [97.298828, 2.230224],
              [97.235146, 2.235478],
              [97.219284, 2.204771],
              [97.19323, 2.204466],
              [97.168945, 2.236317],
              [97.113335, 2.217835],
              [97.224876, 2.157896],
            ],
          ],
          [
            [
              [97.441765, 2.158211],
              [97.441917, 2.191443],
              [97.428467, 2.18035],
              [97.441765, 2.158211],
            ],
          ],
          [
            [
              [97.119179, 2.113018],
              [97.077538, 2.076351],
              [97.114647, 2.003863],
              [97.162498, 2.069721],
              [97.119179, 2.113018],
            ],
          ],
          [
            [
              [98.253609, 4.443055],
              [98.265198, 4.410993],
              [98.276947, 4.424166],
              [98.253609, 4.443055],
            ],
          ],
          [
            [
              [95.253052, 5.88],
              [95.215836, 5.906388],
              [95.285072, 5.780277],
              [95.32972, 5.771944],
              [95.351112, 5.828332],
              [95.3759, 5.805067],
              [95.333611, 5.89806],
              [95.31028, 5.894446],
              [95.299164, 5.839167],
              [95.253052, 5.88],
            ],
          ],
          [
            [
              [95.727478, 2.738055],
              [95.785843, 2.68083],
              [95.796127, 2.63021],
              [95.864349, 2.636471],
              [95.949165, 2.569444],
              [95.978752, 2.588131],
              [95.996666, 2.566112],
              [96.009544, 2.590002],
              [96.075508, 2.569453],
              [96.229385, 2.441569],
              [96.310127, 2.422871],
              [96.313461, 2.385541],
              [96.336388, 2.388889],
              [96.318886, 2.3525],
              [96.442093, 2.32799],
              [96.4981, 2.362161],
              [96.467499, 2.460278],
              [96.408386, 2.51155],
              [96.406937, 2.460561],
              [96.386391, 2.455834],
              [96.392776, 2.474723],
              [96.369873, 2.48778],
              [96.370552, 2.512778],
              [96.339722, 2.523333],
              [96.350555, 2.545557],
              [96.253326, 2.58556],
              [96.172775, 2.674444],
              [96.152496, 2.666946],
              [96.191948, 2.6175],
              [96.142502, 2.654445],
              [96.107437, 2.636319],
              [96.09269, 2.674674],
              [96.151657, 2.689441],
              [96.114166, 2.715831],
              [96.130836, 2.73111],
              [96.091393, 2.749443],
              [96.082161, 2.731525],
              [96.085281, 2.75361],
              [96.102501, 2.75389],
              [96.026947, 2.756666],
              [95.916656, 2.845281],
              [95.883179, 2.824593],
              [95.873886, 2.870001],
              [95.910835, 2.846667],
              [95.918335, 2.868054],
              [95.87468, 2.912331],
              [95.835281, 2.91417],
              [95.840553, 2.88291],
              [95.785095, 2.924144],
              [95.763054, 2.857221],
              [95.791389, 2.830556],
              [95.71936, 2.8246],
              [95.692223, 2.774719],
              [95.727478, 2.738055],
            ],
          ],
          [
            [
              [96.111946, 2.7525],
              [96.098633, 2.74798],
              [96.127777, 2.733888],
              [96.111946, 2.7525],
            ],
          ],
          [
            [
              [95.741386, 2.716929],
              [95.714996, 2.712501],
              [95.7388, 2.702707],
              [95.741386, 2.716929],
            ],
          ],
          [
            [
              [96.364998, 2.663333],
              [96.352219, 2.656111],
              [96.397499, 2.64556],
              [96.364998, 2.663333],
            ],
          ],
          [
            [
              [95.938614, 2.546388],
              [95.919998, 2.528611],
              [95.960556, 2.521945],
              [95.938614, 2.546388],
            ],
          ],
          [
            [
              [96.379448, 2.527223],
              [96.363052, 2.520279],
              [96.389999, 2.510278],
              [96.379448, 2.527223],
            ],
          ],
          [
            [
              [96.484169, 2.437778],
              [96.500557, 2.411388],
              [96.51722, 2.423055],
              [96.484169, 2.437778],
            ],
          ],
          [
            [
              [96.201706, 2.317932],
              [96.22718, 2.33715],
              [96.194717, 2.39481],
              [96.158279, 2.36555],
              [96.169472, 2.3257],
              [96.201706, 2.317932],
            ],
          ],
          [
            [
              [96.258331, 2.358888],
              [96.256111, 2.373612],
              [96.21833, 2.358332],
              [96.237778, 2.344168],
              [96.258331, 2.358888],
            ],
          ],
          [
            [
              [96.628891, 2.189722],
              [96.609802, 2.17713],
              [96.652206, 2.150421],
              [96.662498, 2.168056],
              [96.628891, 2.189722],
            ],
          ],
          [
            [
              [96.650406, 2.124851],
              [96.611115, 2.105278],
              [96.646507, 2.057211],
              [96.689163, 2.08972],
              [96.650406, 2.124851],
            ],
          ],
        ],
      },
      properties: {
        cartodb_id: 16,
        country: 'Indonesia',
        id_1: 1,
        state: 'Aceh',
        name: 'Special Region of Aceh',
        key: 'indonesia-aceh',
        slug: 'special-region-of-aceh',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [99.171669, -1.502501],
              [99.11528, -1.44222],
              [99.171112, -1.470561],
              [99.152496, -1.406668],
              [99.104759, -1.38887],
              [99.078888, -1.291389],
              [99.050278, -1.294721],
              [99.073608, -1.269721],
              [99.026146, -1.22213],
              [99.041389, -1.20611],
              [99.003052, -1.114166],
              [98.93557, -1.061021],
              [98.919655, -0.989583],
              [98.951942, -1.0125],
              [98.93, -0.933774],
              [98.897987, -0.941042],
              [98.910835, -0.910001],
              [98.795563, -0.9575],
              [98.685799, -0.957908],
              [98.596664, -1.205832],
              [98.803978, -1.51866],
              [98.824341, -1.616271],
              [98.874115, -1.675872],
              [99.106857, -1.812139],
              [99.165558, -1.779999],
              [99.2425, -1.796388],
              [99.263046, -1.773611],
              [99.221252, -1.66444],
              [99.241112, -1.676668],
              [99.200279, -1.641389],
              [99.222221, -1.62889],
              [99.280563, -1.752499],
              [99.303886, -1.68528],
              [99.285835, -1.662779],
              [99.2817, -1.689219],
              [99.274437, -1.662501],
              [99.292778, -1.653056],
              [99.269867, -1.646257],
              [99.293335, -1.63611],
              [99.260559, -1.592219],
              [99.219437, -1.60361],
              [99.194168, -1.568329],
              [99.205002, -1.5225],
              [99.171669, -1.502501],
            ],
          ],
          [
            [
              [99.573509, -1.988301],
              [99.564095, -1.999026],
              [99.578613, -2.003334],
              [99.573509, -1.988301],
            ],
          ],
          [
            [
              [99.56028, -2.002219],
              [99.551727, -2.01787],
              [99.577072, -2.02497],
              [99.56028, -2.002219],
            ],
          ],
          [
            [
              [99.716049, -2.167078],
              [99.691826, -2.075765],
              [99.611389, -2.018331],
              [99.557777, -2.044167],
              [99.571945, -2.149723],
              [99.528053, -2.154999],
              [99.574165, -2.245],
              [99.591667, -2.22333],
              [99.609444, -2.246666],
              [99.599998, -2.284167],
              [99.671417, -2.288399],
              [99.738335, -2.345833],
              [99.836838, -2.364317],
              [99.860001, -2.403334],
              [99.84803, -2.336115],
              [99.782776, -2.290833],
              [99.716049, -2.167078],
            ],
          ],
          [
            [
              [99.732407, -2.364176],
              [99.709694, -2.371136],
              [99.736565, -2.395361],
              [99.750687, -2.370858],
              [99.732407, -2.364176],
            ],
          ],
          [
            [
              [99.996964, -2.796231],
              [100.025002, -2.853889],
              [100.042221, -2.85215],
              [100.050217, -2.816388],
              [100.075981, -2.843291],
              [100.145279, -2.84083],
              [100.141914, -2.809667],
              [100.223343, -2.751109],
              [100.174164, -2.64889],
              [99.994156, -2.502221],
              [99.959213, -2.570232],
              [100.011948, -2.680556],
              [100.013336, -2.741945],
              [99.97451, -2.785681],
              [99.996964, -2.796231],
            ],
          ],
          [
            [
              [100.453888, -3.349166],
              [100.464722, -3.312499],
              [100.398056, -3.22444],
              [100.405556, -3.202501],
              [100.37722, -3.18278],
              [100.41111, -3.194444],
              [100.364998, -3.169169],
              [100.471924, -3.144187],
              [100.447502, -3.024439],
              [100.469719, -3.02056],
              [100.268806, -2.84333],
              [100.259369, -2.80115],
              [100.190002, -2.790556],
              [100.187775, -2.991111],
              [100.231941, -3.057781],
              [100.254723, -3.047499],
              [100.248886, -3.0825],
              [100.31028, -3.099167],
              [100.369156, -3.227221],
              [100.347778, -3.228611],
              [100.326668, -3.207499],
              [100.326385, -3.229999],
              [100.453888, -3.349166],
            ],
          ],
          [
            [
              [100.151947, -2.851667],
              [100.131386, -2.856389],
              [100.149803, -2.863379],
              [100.151947, -2.851667],
            ],
          ],
          [
            [
              [100.176109, -3.05361],
              [100.191948, -3.077222],
              [100.197777, -3.063889],
              [100.176109, -3.05361],
            ],
          ],
          [
            [
              [100.495003, -3.141666],
              [100.491386, -3.185278],
              [100.530281, -3.201389],
              [100.495003, -3.141666],
            ],
          ],
          [
            [
              [100.638046, -3.46694],
              [100.637497, -3.48417],
              [100.676666, -3.488334],
              [100.638046, -3.46694],
            ],
          ],
          [
            [
              [101.125816, -1.67909],
              [101.161957, -1.697939],
              [101.18763, -1.67718],
              [101.221451, -1.714809],
              [101.496307, -1.67422],
              [101.562431, -1.576379],
              [101.699448, -1.47294],
              [101.719383, -1.42338],
              [101.689339, -1.391329],
              [101.716766, -1.35368],
              [101.687271, -1.27825],
              [101.707901, -1.244881],
              [101.814079, -1.21502],
              [101.886116, -1.09967],
              [101.825218, -1.094041],
              [101.818611, -0.97414],
              [101.650337, -0.93408],
              [101.595917, -0.851199],
              [101.395836, -0.738651],
              [101.407646, -0.720709],
              [101.355362, -0.68732],
              [101.359833, -0.65925],
              [101.253769, -0.62543],
              [101.162247, -0.53627],
              [101.068939, -0.50064],
              [101.024826, -0.398439],
              [101.036423, -0.34944],
              [100.996368, -0.31436],
              [100.915039, -0.32568],
              [100.907509, -0.371669],
              [100.844856, -0.337659],
              [100.838783, -0.2906],
              [100.781006, -0.230419],
              [100.733833, -0.04317],
              [100.776741, -0.01239],
              [100.809166, -0.041148],
              [100.845413, 0.01544],
              [100.824219, 0.118061],
              [100.842186, 0.189851],
              [100.812531, 0.268481],
              [100.643806, 0.1791],
              [100.456146, 0.225971],
              [100.376022, 0.35071],
              [100.286179, 0.327399],
              [100.277313, 0.427171],
              [100.206459, 0.584121],
              [100.249313, 0.721701],
              [99.871773, 0.908301],
              [99.833496, 0.886979],
              [99.826553, 0.724851],
              [99.840431, 0.688241],
              [99.918167, 0.6786],
              [99.959976, 0.51734],
              [99.899307, 0.47243],
              [99.77356, 0.48355],
              [99.716553, 0.45406],
              [99.675491, 0.475349],
              [99.640007, 0.547231],
              [99.587463, 0.516631],
              [99.503029, 0.540241],
              [99.403938, 0.491581],
              [99.326866, 0.35652],
              [99.235527, 0.327711],
              [99.165611, 0.229348],
              [99.190208, 0.25309],
              [99.209831, 0.21592],
              [99.263542, 0.201481],
              [99.280067, 0.235671],
              [99.353745, 0.231534],
              [99.398064, 0.151575],
              [99.619957, 0.073],
              [99.738327, -0.027221],
              [99.768333, -0.079167],
              [99.743614, -0.141946],
              [99.817497, -0.299723],
              [99.889046, -0.344274],
              [99.913612, -0.402501],
              [100.080971, -0.547709],
              [100.128334, -0.646111],
              [100.301392, -0.805555],
              [100.34417, -0.890555],
              [100.347496, -0.968331],
              [100.390266, -1.00917],
              [100.377945, -1.041183],
              [100.412498, -1.042781],
              [100.409584, -1.074237],
              [100.364441, -1.075555],
              [100.388046, -1.125281],
              [100.362778, -1.125281],
              [100.348892, -1.170834],
              [100.38028, -1.14583],
              [100.379997, -1.1775],
              [100.429443, -1.19778],
              [100.43222, -1.25472],
              [100.40667, -1.23694],
              [100.40139, -1.271111],
              [100.452499, -1.25833],
              [100.565277, -1.330278],
              [100.56028, -1.371667],
              [100.592499, -1.394169],
              [100.558334, -1.406668],
              [100.584167, -1.420557],
              [100.561386, -1.441939],
              [100.625832, -1.505279],
              [100.643608, -1.621389],
              [100.870003, -1.938331],
              [100.878609, -2.055],
              [100.822502, -2.17333],
              [100.873337, -2.2425],
              [100.889725, -2.325834],
              [101.025993, -2.48505],
              [101.244637, -2.34494],
              [101.306076, -2.23891],
              [101.25956, -2.05159],
              [101.13932, -1.905459],
              [101.125816, -1.67909],
            ],
          ],
          [
            [
              [100.39167, -1.226609],
              [100.400864, -1.20774],
              [100.383781, -1.187757],
              [100.375679, -1.218803],
              [100.39167, -1.226609],
            ],
          ],
          [
            [
              [100.339676, -1.187994],
              [100.327667, -1.20273],
              [100.338287, -1.21192],
              [100.339676, -1.187994],
            ],
          ],
        ],
      },
      properties: {
        cartodb_id: 2,
        country: 'Indonesia',
        id_1: 31,
        state: 'Sumatera Barat',
        name: 'West Sumatera',
        key: 'indonesia-sumaterabarat',
        slug: 'west-sumatera',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [110.702042, -8.185054],
              [110.836296, -8.203611],
              [110.828529, -8.137669],
              [110.786987, -8.151238],
              [110.755783, -8.019719],
              [110.785553, -7.818359],
              [110.67289, -7.787689],
              [110.577057, -7.805489],
              [110.494019, -7.767861],
              [110.447891, -7.543099],
              [110.281677, -7.697289],
              [110.262253, -7.646868],
              [110.128471, -7.656851],
              [110.13121, -7.745999],
              [110.06321, -7.81391],
              [110.032501, -7.89972],
              [110.345497, -8.030547],
              [110.434723, -8.099445],
              [110.702042, -8.185054],
            ],
          ],
        ],
      },
      properties: {
        cartodb_id: 8,
        country: 'Indonesia',
        id_1: 34,
        state: 'Yogyakarta',
        key: 'indonesia-yogyakarta',
        slug: 'special-region-of-yogyakarta',
        name: 'Special Region of Yogyakarta',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [98.713837, 3.769471],
              [98.723389, 3.734012],
              [98.701317, 3.751374],
              [98.718063, 3.79667],
              [98.621628, 3.757211],
              [98.6269, 3.78214],
              [98.709167, 3.797777],
              [98.672653, 3.908407],
              [98.611145, 3.865541],
              [98.621819, 3.914854],
              [98.591606, 3.889779],
              [98.601875, 3.919185],
              [98.573669, 3.908509],
              [98.574539, 3.928591],
              [98.54702, 3.890511],
              [98.56282, 3.926471],
              [98.58313, 3.92922],
              [98.574371, 3.93982],
              [98.542999, 3.91405],
              [98.565956, 3.933589],
              [98.544327, 3.939291],
              [98.532173, 3.883421],
              [98.536491, 3.92984],
              [98.522247, 3.91395],
              [98.498711, 3.921491],
              [98.534081, 3.933559],
              [98.511108, 3.946945],
              [98.502335, 3.936217],
              [98.531944, 3.947501],
              [98.522781, 3.9739],
              [98.50473, 3.951959],
              [98.519592, 3.975491],
              [98.545227, 3.978629],
              [98.536392, 4.01278],
              [98.495003, 4.015834],
              [98.488457, 3.98027],
              [98.491554, 4.029176],
              [98.448387, 4.040282],
              [98.458893, 4.018341],
              [98.450546, 4.0064],
              [98.43528, 4.017511],
              [98.441109, 4.00029],
              [98.433052, 4.017231],
              [98.451111, 4.007791],
              [98.457222, 4.020289],
              [98.436111, 4.04139],
              [98.37751, 3.969649],
              [98.386948, 4.00612],
              [98.400276, 4.012791],
              [98.406471, 3.999581],
              [98.404167, 4.017511],
              [98.430557, 4.026667],
              [98.407501, 4.047231],
              [98.380836, 4.030562],
              [98.401947, 4.048341],
              [98.430557, 4.046023],
              [98.350403, 4.078531],
              [98.315407, 4.07219],
              [98.30854, 4.04061],
              [98.247223, 4.00862],
              [98.262779, 4.032791],
              [98.297226, 4.03917],
              [98.275002, 4.051671],
              [98.252502, 4.029171],
              [98.280563, 4.06223],
              [98.306412, 4.043888],
              [98.310478, 4.07428],
              [98.249168, 4.130832],
              [98.182777, 4.098621],
              [98.183372, 4.109417],
              [98.163086, 4.08086],
              [98.181946, 4.06584],
              [98.160378, 4.078711],
              [98.140266, 4.053061],
              [98.15937, 4.07214],
              [98.130836, 4.072789],
              [98.15361, 4.08362],
              [98.121391, 4.12306],
              [98.160789, 4.083451],
              [98.178047, 4.14417],
              [98.15168, 4.157968],
              [98.144157, 4.19251],
              [98.161209, 4.1543],
              [98.254448, 4.201943],
              [98.241112, 4.266671],
              [98.212219, 4.22362],
              [98.196388, 4.231121],
              [98.211388, 4.22473],
              [98.209999, 4.25334],
              [98.253052, 4.280277],
              [98.201218, 4.30419],
              [98.066879, 4.25311],
              [98.094833, 4.216781],
              [98.059311, 4.19073],
              [98.073616, 4.149899],
              [98.04081, 4.103982],
              [98.023132, 3.969779],
              [97.95816, 3.928901],
              [97.948952, 3.89086],
              [97.904312, 3.9115],
              [97.910461, 3.822941],
              [97.799606, 3.723149],
              [97.866966, 3.574901],
              [97.955627, 3.48142],
              [97.9319, 3.445421],
              [97.949707, 3.39154],
              [98.02742, 3.33115],
              [97.984917, 3.27558],
              [97.872261, 3.24905],
              [97.926758, 3.22501],
              [97.980606, 3.12311],
              [97.974823, 3.069951],
              [97.930649, 3.06259],
              [97.954613, 2.901539],
              [98.00679, 2.88956],
              [98.044548, 2.824701],
              [98.092186, 2.821309],
              [98.111717, 2.790251],
              [98.086426, 2.737551],
              [98.129227, 2.662321],
              [98.085129, 2.634219],
              [98.075226, 2.575529],
              [98.109291, 2.430041],
              [98.154373, 2.414939],
              [98.187607, 2.322901],
              [98.153343, 2.14694],
              [98.260803, 2.064422],
              [98.263054, 2.027222],
              [98.342216, 2.04778],
              [98.358093, 2.00309],
              [98.462776, 1.988891],
              [98.682503, 1.736112],
              [98.733528, 1.717638],
              [98.731392, 1.768061],
              [98.67028, 1.770277],
              [98.7425, 1.78167],
              [98.796387, 1.722777],
              [98.786942, 1.690833],
              [98.826942, 1.675556],
              [98.839996, 1.629166],
              [98.800278, 1.596939],
              [98.807777, 1.56778],
              [98.782776, 1.578332],
              [98.765266, 1.527781],
              [98.712502, 1.559999],
              [98.854156, 1.312221],
              [98.841393, 1.280832],
              [98.973328, 0.922885],
              [98.97049, 0.847172],
              [99.058807, 0.74222],
              [99.045357, 0.63792],
              [99.107132, 0.578533],
              [99.088638, 0.528754],
              [99.129204, 0.477722],
              [99.140778, 0.397731],
              [99.108757, 0.346131],
              [99.150742, 0.326583],
              [99.159637, 0.270899],
              [99.137299, 0.253858],
              [99.165573, 0.233581],
              [99.235527, 0.327711],
              [99.326866, 0.35652],
              [99.403938, 0.491581],
              [99.503029, 0.540241],
              [99.587463, 0.516631],
              [99.640007, 0.547231],
              [99.675491, 0.475349],
              [99.716553, 0.45406],
              [99.77356, 0.48355],
              [99.899307, 0.47243],
              [99.959976, 0.51734],
              [99.918167, 0.6786],
              [99.83252, 0.700031],
              [99.833496, 0.886979],
              [99.877487, 0.907929],
              [100.183716, 0.7619],
              [100.118973, 0.82831],
              [100.13134, 0.902851],
              [100.081421, 0.94205],
              [100.14064, 0.951121],
              [100.161377, 0.999161],
              [100.102188, 1.09181],
              [100.183029, 1.12499],
              [100.130478, 1.174599],
              [100.094643, 1.327261],
              [100.054062, 1.36534],
              [100.315857, 1.416461],
              [100.337547, 1.431191],
              [100.283859, 1.46392],
              [100.29554, 1.47986],
              [100.331718, 1.479493],
              [100.323486, 1.44348],
              [100.386726, 1.45316],
              [100.429291, 1.496842],
              [100.415894, 1.537212],
              [100.455322, 1.629089],
              [100.411362, 1.69775],
              [100.418701, 1.770691],
              [100.285522, 1.813293],
              [100.360291, 2.22052],
              [100.307129, 2.357482],
              [100.329193, 2.530029],
              [100.236633, 2.640685],
              [100.22068, 2.706138],
              [100.162857, 2.707024],
              [100.123466, 2.66303],
              [100.138611, 2.508612],
              [100.092758, 2.613142],
              [100.085793, 2.712325],
              [100.048622, 2.741733],
              [100.009163, 2.733056],
              [99.96833, 2.701388],
              [100.025482, 2.682986],
              [99.996391, 2.6475],
              [100.033607, 2.630001],
              [100.037498, 2.611667],
              [100.001877, 2.57191],
              [99.966125, 2.587281],
              [99.974724, 2.548333],
              [99.947418, 2.507511],
              [99.919472, 2.500534],
              [99.91156, 2.527107],
              [99.919678, 2.501708],
              [99.946426, 2.508345],
              [99.948303, 2.536682],
              [99.975281, 2.5531],
              [99.963928, 2.587525],
              [100.001945, 2.579714],
              [100.006104, 2.601929],
              [100.031128, 2.617222],
              [99.989998, 2.643612],
              [100.015076, 2.671693],
              [99.948334, 2.699722],
              [99.999367, 2.817587],
              [99.989166, 2.959445],
              [99.93528, 2.972778],
              [99.888916, 3.040284],
              [99.862778, 2.980555],
              [99.808899, 2.990008],
              [99.83667, 2.94139],
              [99.801666, 2.987499],
              [99.813263, 2.998959],
              [99.858665, 2.988935],
              [99.859726, 3.036389],
              [99.756104, 3.170288],
              [99.55249, 3.241883],
              [99.485397, 3.347058],
              [99.323738, 3.413481],
              [99.288513, 3.479127],
              [99.054726, 3.619722],
              [98.713837, 3.769471],
            ],
          ],
          [
            [
              [98.266792, 4.174133],
              [98.243607, 4.175321],
              [98.234528, 4.143869],
              [98.271439, 4.140452],
              [98.266792, 4.174133],
            ],
          ],
          [
            [
              [98.203056, 4.13792],
              [98.184227, 4.114468],
              [98.204208, 4.119313],
              [98.203056, 4.13792],
            ],
          ],
          [
            [
              [98.557732, 3.967653],
              [98.54982, 3.983871],
              [98.537933, 3.9415],
              [98.557968, 3.948745],
              [98.557732, 3.967653],
            ],
          ],
          [
            [
              [98.622849, 3.885244],
              [98.663162, 3.909831],
              [98.632256, 3.917024],
              [98.622849, 3.885244],
            ],
          ],
          [
            [
              [98.607498, 1.611945],
              [98.561668, 1.67778],
              [98.547775, 1.680278],
              [98.534447, 1.640282],
              [98.494156, 1.65472],
              [98.484169, 1.700834],
              [98.441109, 1.676666],
              [98.461945, 1.635555],
              [98.607498, 1.611945],
            ],
          ],
          [
            [
              [98.570274, 1.613611],
              [98.568886, 1.596939],
              [98.58667, 1.593056],
              [98.570274, 1.613611],
            ],
          ],
          [
            [
              [97.902145, 0.874084],
              [97.947014, 0.956794],
              [97.917778, 1.028056],
              [97.849007, 1.071949],
              [97.800331, 1.150969],
              [97.689873, 1.178665],
              [97.483055, 1.479723],
              [97.441505, 1.472724],
              [97.422653, 1.517903],
              [97.395317, 1.499773],
              [97.349907, 1.541499],
              [97.346107, 1.482502],
              [97.276817, 1.419023],
              [97.207832, 1.40118],
              [97.187225, 1.417779],
              [97.171463, 1.395746],
              [97.136108, 1.431943],
              [97.059868, 1.414308],
              [97.150558, 1.367221],
              [97.206871, 1.267481],
              [97.262917, 1.243616],
              [97.257103, 1.217153],
              [97.370407, 1.077289],
              [97.406372, 0.938909],
              [97.485954, 0.93932],
              [97.600037, 0.842671],
              [97.702202, 0.564379],
              [97.738792, 0.581461],
              [97.754738, 0.553715],
              [97.779587, 0.57985],
              [97.781113, 0.553056],
              [97.826965, 0.543915],
              [97.820351, 0.567675],
              [97.900436, 0.624911],
              [97.902145, 0.874084],
            ],
          ],
          [
            [
              [98.616386, 0.083611],
              [98.749588, 0.070841],
              [98.788544, 0.098752],
              [98.852776, 0.079723],
              [98.854721, 0.11667],
              [98.776108, 0.174444],
              [98.722221, 0.157221],
              [98.709999, 0.178332],
              [98.611656, 0.181391],
              [98.525558, 0.14389],
              [98.536667, 0.101111],
              [98.616386, 0.083611],
            ],
          ],
          [
            [
              [98.371811, -0.112985],
              [98.557739, -0.391363],
              [98.540787, -0.331401],
              [98.568192, -0.330833],
              [98.573608, -0.280556],
              [98.493767, -0.209989],
              [98.484444, -0.124722],
              [98.418747, -0.03695],
              [98.414444, 0.002221],
              [98.294144, -0.019153],
              [98.349716, -0.04222],
              [98.380836, -0.103891],
              [98.371811, -0.112985],
            ],
          ],
          [
            [
              [98.526665, -0.031389],
              [98.525833, -0.056111],
              [98.545555, -0.071666],
              [98.526665, -0.031389],
            ],
          ],
          [
            [
              [98.27639, -0.03778],
              [98.26339, -0.05917],
              [98.282776, -0.094444],
              [98.27639, -0.03778],
            ],
          ],
          [
            [
              [98.31295, -0.047371],
              [98.296623, -0.072229],
              [98.357338, -0.089569],
              [98.31295, -0.047371],
            ],
          ],
          [
            [
              [98.32917, -0.091939],
              [98.32, -0.125556],
              [98.345833, -0.143334],
              [98.32917, -0.091939],
            ],
          ],
          [
            [
              [98.308266, -0.095415],
              [98.303337, -0.112779],
              [98.32, -0.11556],
              [98.308266, -0.095415],
            ],
          ],
          [
            [
              [98.199997, -0.107501],
              [98.181656, -0.12028],
              [98.187233, -0.14417],
              [98.207153, -0.125563],
              [98.199997, -0.107501],
            ],
          ],
          [
            [
              [98.39389, -0.336939],
              [98.34771, -0.516601],
              [98.28746, -0.522969],
              [98.420296, -0.59855],
              [98.43045, -0.541234],
              [98.475563, -0.53472],
              [98.467499, -0.575834],
              [98.506668, -0.55611],
              [98.481667, -0.516111],
              [98.507217, -0.490829],
              [98.500275, -0.396666],
              [98.522224, -0.360556],
              [98.447281, -0.247232],
              [98.417221, -0.24083],
              [98.441109, -0.298404],
              [98.39389, -0.336939],
            ],
          ],
          [
            [
              [98.502777, -0.595277],
              [98.488892, -0.617223],
              [98.51767, -0.637993],
              [98.502777, -0.595277],
            ],
          ],
          [
            [
              [97.321114, 0.859445],
              [97.309998, 0.850557],
              [97.330002, 0.846388],
              [97.321114, 0.859445],
            ],
          ],
          [
            [
              [97.347496, 0.8425],
              [97.332497, 0.829167],
              [97.357483, 0.811378],
              [97.347496, 0.8425],
            ],
          ],
        ],
      },
      properties: {
        cartodb_id: 20,
        country: 'Indonesia',
        id_1: 33,
        state: 'Sumatera Utara',
        key: 'indonesia-sumaterautara',
        name: 'North Sumatera',
        slug: 'north-sumatera',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [105.347542, -1.84469],
              [105.125, -1.958774],
              [105.124825, -2.076],
              [105.242271, -2.072449],
              [105.297943, -2.15414],
              [105.357903, -2.12055],
              [105.446793, -2.134225],
              [105.566666, -2.070277],
              [105.539337, -2.017189],
              [105.587692, -1.999288],
              [105.593498, -1.956794],
              [105.605553, -1.961111],
              [105.594887, -1.9575],
              [105.603821, -1.986885],
              [105.592293, -1.99852],
              [105.541908, -2.021795],
              [105.570557, -2.066666],
              [105.560097, -2.08637],
              [105.591087, -2.12321],
              [105.650345, -2.115287],
              [105.765167, -2.13517],
              [105.826645, -2.308743],
              [105.801826, -2.36656],
              [105.931686, -2.47032],
              [105.938805, -2.530546],
              [105.889969, -2.587289],
              [105.887161, -2.644124],
              [105.950691, -2.821329],
              [106.089096, -2.836419],
              [106.142265, -2.87555],
              [106.284538, -2.905669],
              [106.335754, -2.967757],
              [106.422501, -2.970556],
              [106.512779, -3.114722],
              [106.58667, -3.076665],
              [106.658607, -3.102219],
              [106.739502, -3.085629],
              [106.741386, -3.007013],
              [106.693886, -3.003058],
              [106.605553, -2.960831],
              [106.592842, -2.90014],
              [106.70887, -2.643377],
              [106.758423, -2.60005],
              [106.848923, -2.57113],
              [106.376129, -2.473896],
              [106.299767, -2.420712],
              [106.235832, -2.329166],
              [106.157722, -2.090021],
              [106.150253, -1.980094],
              [106.186813, -1.895171],
              [106.025047, -1.67499],
              [106.048759, -1.60039],
              [105.981339, -1.5506],
              [105.923058, -1.546944],
              [105.915688, -1.501259],
              [105.7033, -1.527359],
              [105.734558, -1.63501],
              [105.766945, -1.65139],
              [105.740616, -1.68714],
              [105.754677, -1.72788],
              [105.826805, -1.803413],
              [105.871513, -1.798168],
              [105.80867, -1.813231],
              [105.775452, -1.766819],
              [105.743507, -1.775413],
              [105.754997, -1.791071],
              [105.695358, -1.781209],
              [105.652603, -1.71668],
              [105.610397, -1.703687],
              [105.660316, -1.71436],
              [105.72438, -1.643971],
              [105.641907, -1.643452],
              [105.605141, -1.529878],
              [105.562706, -1.528816],
              [105.535645, -1.564876],
              [105.46991, -1.560391],
              [105.344009, -1.64798],
              [105.366234, -1.680075],
              [105.319389, -1.698463],
              [105.394882, -1.735369],
              [105.390793, -1.793259],
              [105.347542, -1.84469],
            ],
          ],
          [
            [
              [107.046669, -2.83778],
              [107.019447, -2.878889],
              [107.085281, -2.912221],
              [107.092499, -2.859999],
              [107.055832, -2.818888],
              [107.046669, -2.83778],
            ],
          ],
          [
            [
              [106.842216, -2.84194],
              [106.828926, -2.851161],
              [106.854156, -2.858311],
              [106.842216, -2.84194],
            ],
          ],
          [
            [
              [106.736992, -2.866796],
              [106.722275, -2.903219],
              [106.747986, -2.887019],
              [106.736992, -2.866796],
            ],
          ],
          [
            [
              [106.880554, -3.021943],
              [106.910614, -2.944043],
              [106.806664, -2.88639],
              [106.709488, -2.965829],
              [106.822777, -3.03222],
              [106.880554, -3.021943],
            ],
          ],
          [
            [
              [106.69722, -2.97611],
              [106.695831, -2.992221],
              [106.709724, -2.992777],
              [106.69722, -2.97611],
            ],
          ],
          [
            [
              [105.785156, -2.397301],
              [105.790741, -2.423189],
              [105.805237, -2.40716],
              [105.785156, -2.397301],
            ],
          ],
          [
            [
              [107.077431, -2.406666],
              [107.061943, -2.41139],
              [107.086548, -2.418932],
              [107.077431, -2.406666],
            ],
          ],
          [
            [
              [105.811226, -1.794125],
              [105.804146, -1.805099],
              [105.81881, -1.803351],
              [105.811226, -1.794125],
            ],
          ],
          [
            [
              [108.101944, -3.168057],
              [108.208893, -3.145279],
              [108.179443, -3.121667],
              [108.213333, -3.069999],
              [108.208054, -3.012223],
              [108.297226, -2.864444],
              [108.270279, -2.751111],
              [108.190002, -2.67861],
              [108.163116, -2.6857],
              [108.087219, -2.597778],
              [108.040558, -2.599722],
              [108.02417, -2.663333],
              [107.989998, -2.57222],
              [107.903679, -2.577013],
              [107.816666, -2.526389],
              [107.768059, -2.558055],
              [107.65889, -2.559167],
              [107.611107, -2.70583],
              [107.64257, -2.749443],
              [107.638817, -2.789652],
              [107.639168, -2.749167],
              [107.604446, -2.763891],
              [107.613541, -2.780761],
              [107.581413, -2.796421],
              [107.597778, -2.857499],
              [107.52861, -2.920556],
              [107.61528, -2.911109],
              [107.560829, -2.999166],
              [107.582222, -3.086669],
              [107.629097, -3.142708],
              [107.584717, -3.196391],
              [107.606667, -3.241939],
              [107.828613, -3.167778],
              [107.848892, -3.119723],
              [107.809784, -3.085768],
              [107.846947, -3.06],
              [107.964165, -3.168057],
              [107.985275, -3.274444],
              [108.013893, -3.24361],
              [108.081665, -3.245278],
              [108.068611, -3.1875],
              [108.101944, -3.168057],
            ],
          ],
          [
            [
              [108.253052, -3.044167],
              [108.234726, -3.063889],
              [108.241943, -3.076665],
              [108.272781, -3.05528],
              [108.253052, -3.044167],
            ],
          ],
          [
            [
              [108.362396, -3.27093],
              [108.383057, -3.303888],
              [108.392502, -3.2975],
              [108.395279, -3.284167],
              [108.362396, -3.27093],
            ],
          ],
          [
            [
              [107.416656, -2.79083],
              [107.381989, -2.801344],
              [107.398613, -2.8175],
              [107.370827, -2.822779],
              [107.432777, -2.820554],
              [107.416656, -2.79083],
            ],
          ],
          [
            [
              [107.489723, -2.820554],
              [107.477776, -2.826111],
              [107.485832, -2.84389],
              [107.506111, -2.826111],
              [107.489723, -2.820554],
            ],
          ],
          [
            [
              [107.459167, -2.82083],
              [107.33889, -2.883889],
              [107.411942, -2.94639],
              [107.412498, -2.919166],
              [107.471664, -2.904723],
              [107.486389, -2.850277],
              [107.459167, -2.82083],
            ],
          ],
          [
            [
              [107.504997, -2.843056],
              [107.483612, -2.86306],
              [107.491386, -2.876667],
              [107.504997, -2.843056],
            ],
          ],
          [
            [
              [107.466393, -2.923056],
              [107.428047, -2.94556],
              [107.4375, -2.985559],
              [107.465836, -2.966946],
              [107.466393, -2.923056],
            ],
          ],
          [
            [
              [107.531387, -2.967499],
              [107.515831, -2.97278],
              [107.525276, -3.005281],
              [107.531387, -2.967499],
            ],
          ],
          [
            [
              [107.503334, -3.033054],
              [107.487778, -3.030556],
              [107.492775, -3.052778],
              [107.503334, -3.033054],
            ],
          ],
          [
            [
              [107.85083, -3.099445],
              [107.839722, -3.109444],
              [107.855553, -3.126945],
              [107.863335, -3.106666],
              [107.85083, -3.099445],
            ],
          ],
          [
            [
              [107.527496, -3.193056],
              [107.522499, -3.255555],
              [107.551392, -3.214722],
              [107.527496, -3.193056],
            ],
          ],
        ],
      },
      properties: {
        cartodb_id: 7,
        country: 'Indonesia',
        id_1: 3,
        state: 'Bangka-Belitung',
        key: 'indonesia-bangkabelitung',
        name: 'Bangka Belitung Islands',
        slug: 'bangka-belitung-islands',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [134.233337, -1.741944],
              [134.203339, -1.736939],
              [134.148331, -1.826945],
              [134.149719, -1.90222],
              [134.13472, -1.907501],
              [134.165833, -1.951112],
              [134.217102, -1.831198],
              [134.198441, -1.791215],
              [134.22139, -1.79278],
              [134.233337, -1.741944],
            ],
          ],
          [
            [
              [134.661392, -2.823219],
              [134.661575, -2.611106],
              [134.630829, -2.474167],
              [134.601669, -2.488334],
              [134.531952, -2.449719],
              [134.551117, -2.471945],
              [134.512833, -2.473574],
              [134.569168, -2.484439],
              [134.513885, -2.483609],
              [134.460281, -2.550001],
              [134.524231, -2.79222],
              [134.514725, -2.831111],
              [134.461395, -2.863056],
              [134.367218, -2.668889],
              [134.375549, -2.644443],
              [134.318893, -2.584999],
              [134.339722, -2.551667],
              [134.286942, -2.518334],
              [134.320007, -2.507321],
              [134.279099, -2.481319],
              [134.259171, -2.429439],
              [134.213882, -2.418611],
              [134.172775, -2.347221],
              [134.102783, -2.051111],
              [134.156525, -1.966227],
              [134.13591, -1.960028],
              [134.121948, -1.86278],
              [134.102814, -1.860669],
              [134.095322, -1.684829],
              [134.067322, -1.673561],
              [134.200836, -1.55],
              [134.213638, -1.407771],
              [134.278992, -1.343317],
              [134.190002, -1.20111],
              [134.102615, -1.128109],
              [134.070816, -1.030581],
              [134.010406, -0.959727],
              [134.046051, -0.926905],
              [134.052551, -0.868969],
              [134.136871, -0.863292],
              [134.014206, -0.781619],
              [133.972809, -0.719501],
              [133.872284, -0.714018],
              [133.81662, -0.749301],
              [133.756668, -0.719219],
              [133.721848, -0.751336],
              [133.572876, -0.720641],
              [133.547165, -0.746956],
              [133.3965, -0.740975],
              [133.151016, -0.54533],
              [132.972412, -0.49383],
              [132.953613, -0.452833],
              [132.904938, -0.463862],
              [132.798859, -0.416281],
              [132.766495, -0.493181],
              [132.798798, -0.416224],
              [132.719208, -0.362848],
              [132.419174, -0.34333],
              [132.170563, -0.425557],
              [132.094193, -0.479821],
              [132.070786, -0.539999],
              [131.977356, -0.577887],
              [131.847595, -0.705874],
              [131.507767, -0.774713],
              [131.54393, -0.740925],
              [131.508682, -0.729509],
              [131.225891, -0.821073],
              [131.241806, -0.876727],
              [131.300446, -0.917804],
              [131.267975, -0.921084],
              [131.278793, -0.974999],
              [131.265518, -0.992109],
              [131.283783, -0.990725],
              [131.240097, -1.028044],
              [131.248886, -1.082777],
              [131.176544, -1.220246],
              [131.051117, -1.254166],
              [130.999207, -1.38162],
              [130.931381, -1.426291],
              [130.996048, -1.459965],
              [131.166672, -1.462778],
              [131.208664, -1.523113],
              [131.283615, -1.504167],
              [131.337219, -1.438056],
              [131.29277, -1.4125],
              [131.272217, -1.36833],
              [131.258667, -1.371336],
              [131.313614, -1.373333],
              [131.29361, -1.35389],
              [131.301117, -1.32889],
              [131.279449, -1.31472],
              [131.301666, -1.327499],
              [131.296951, -1.35528],
              [131.324722, -1.351109],
              [131.348892, -1.28611],
              [131.33667, -1.324439],
              [131.354721, -1.321389],
              [131.336395, -1.366391],
              [131.361511, -1.36567],
              [131.380554, -1.401945],
              [131.420273, -1.34194],
              [131.435272, -1.347779],
              [131.412018, -1.358509],
              [131.389603, -1.41061],
              [131.422012, -1.425539],
              [131.432495, -1.377499],
              [131.452225, -1.399999],
              [131.430557, -1.403055],
              [131.426468, -1.425751],
              [131.379562, -1.451961],
              [131.449127, -1.5144],
              [131.478943, -1.487039],
              [131.460831, -1.486667],
              [131.460556, -1.465278],
              [131.450562, -1.472781],
              [131.44722, -1.44111],
              [131.464996, -1.488329],
              [131.500702, -1.468124],
              [131.475006, -1.456667],
              [131.515411, -1.47501],
              [131.514236, -1.376528],
              [131.550278, -1.385278],
              [131.536179, -1.348839],
              [131.585007, -1.345559],
              [131.569443, -1.241109],
              [131.588882, -1.275],
              [131.588608, -1.342501],
              [131.537506, -1.353055],
              [131.556671, -1.381668],
              [131.526382, -1.396389],
              [131.539444, -1.425566],
              [131.524445, -1.479721],
              [131.575882, -1.470919],
              [131.561386, -1.433055],
              [131.577408, -1.454696],
              [131.641296, -1.448009],
              [131.609726, -1.410556],
              [131.633331, -1.360278],
              [131.63916, -1.372223],
              [131.611389, -1.411666],
              [131.669174, -1.465],
              [131.65152, -1.402579],
              [131.733337, -1.36444],
              [131.720001, -1.352221],
              [131.734161, -1.315001],
              [131.717773, -1.289999],
              [131.734726, -1.311388],
              [131.72139, -1.352499],
              [131.733887, -1.366944],
              [131.679443, -1.410834],
              [131.653366, -1.40664],
              [131.686661, -1.43801],
              [131.712875, -1.414859],
              [131.744675, -1.453821],
              [131.741592, -1.50602],
              [131.694778, -1.555593],
              [131.726898, -1.583829],
              [131.79834, -1.548612],
              [131.790558, -1.491666],
              [131.821396, -1.474166],
              [131.81778, -1.45083],
              [131.796234, -1.440886],
              [131.821487, -1.429119],
              [131.82309, -1.44144],
              [131.796921, -1.447015],
              [131.818893, -1.450279],
              [131.834167, -1.498888],
              [131.824173, -1.507221],
              [131.794724, -1.49111],
              [131.807785, -1.545834],
              [131.779449, -1.596668],
              [131.809998, -1.614445],
              [131.882294, -1.553396],
              [131.864166, -1.527222],
              [131.876389, -1.508055],
              [131.920837, -1.524722],
              [131.871765, -1.527441],
              [131.887161, -1.550018],
              [131.869995, -1.583611],
              [131.953888, -1.555557],
              [131.933334, -1.542499],
              [131.969452, -1.549171],
              [131.957504, -1.482499],
              [131.974655, -1.447619],
              [131.958328, -1.425833],
              [131.986115, -1.41694],
              [131.981949, -1.46639],
              [132.01062, -1.448485],
              [131.962372, -1.479219],
              [132.001724, -1.554855],
              [131.936859, -1.573067],
              [131.948059, -1.593611],
              [131.918213, -1.58029],
              [131.880676, -1.638016],
              [131.896179, -1.672173],
              [131.942215, -1.675277],
              [131.942215, -1.657778],
              [131.961304, -1.6558],
              [131.942215, -1.65972],
              [131.94194, -1.676668],
              [131.900589, -1.673671],
              [131.91156, -1.719559],
              [132.026917, -1.667155],
              [132.014725, -1.640616],
              [132.038208, -1.631901],
              [132.038483, -1.645047],
              [132.014526, -1.641959],
              [132.015976, -1.658186],
              [132.053116, -1.662997],
              [132.047653, -1.627985],
              [132.103378, -1.568281],
              [132.096527, -1.54285],
              [132.071335, -1.551363],
              [132.07605, -1.51959],
              [132.106369, -1.56858],
              [132.069809, -1.597887],
              [132.073654, -1.616485],
              [132.049942, -1.644265],
              [132.101486, -1.604793],
              [132.133224, -1.603972],
              [132.122223, -1.582501],
              [132.148895, -1.574444],
              [132.124725, -1.582779],
              [132.137741, -1.599489],
              [132.121613, -1.61576],
              [132.068665, -1.631956],
              [132.071838, -1.666731],
              [132.076401, -1.647827],
              [132.096176, -1.649316],
              [132.074951, -1.650911],
              [132.078308, -1.670291],
              [132.004608, -1.684923],
              [132.054718, -1.722499],
              [132.063065, -1.696742],
              [132.089722, -1.702777],
              [132.039963, -1.729309],
              [132.08812, -1.752583],
              [132.091644, -1.789774],
              [132.089127, -1.769547],
              [132.074203, -1.769335],
              [132.08667, -1.754163],
              [132.067825, -1.765203],
              [132.026947, -1.71501],
              [132.009491, -1.76774],
              [132.038574, -1.791144],
              [132.008072, -1.771559],
              [131.995148, -1.73766],
              [131.920944, -1.813186],
              [131.947922, -1.844259],
              [131.958054, -1.82444],
              [132.014908, -1.80432],
              [132.017746, -1.814355],
              [131.958893, -1.826667],
              [131.950836, -1.86278],
              [131.973618, -1.84361],
              [131.984161, -1.859999],
              [131.99028, -1.844439],
              [132.009308, -1.858568],
              [132.032043, -1.834575],
              [132.073334, -1.84528],
              [132.041595, -1.8419],
              [132.035492, -1.853579],
              [132.030762, -1.836219],
              [132.008865, -1.860058],
              [131.988617, -1.84611],
              [131.986389, -1.861667],
              [131.97084, -1.8475],
              [131.984726, -1.875556],
              [131.973328, -1.913889],
              [131.987778, -1.929722],
              [132.002701, -1.908547],
              [132.033707, -1.930064],
              [131.997498, -1.910831],
              [131.967209, -1.965001],
              [132.01001, -2.006187],
              [132.03334, -1.974165],
              [132.020554, -2.004444],
              [132.04306, -2.026943],
              [132.061111, -2.017501],
              [132.054184, -1.966268],
              [132.08667, -1.868329],
              [132.134171, -1.895834],
              [132.163239, -1.879998],
              [132.169983, -1.898204],
              [132.204422, -1.867981],
              [132.222351, -1.88199],
              [132.257645, -1.837499],
              [132.292892, -1.839029],
              [132.288116, -1.816461],
              [132.324722, -1.811939],
              [132.325989, -1.82558],
              [132.339996, -1.81417],
              [132.324417, -1.827086],
              [132.323944, -1.813297],
              [132.310471, -1.826728],
              [132.288437, -1.817299],
              [132.291748, -1.84095],
              [132.257721, -1.83868],
              [132.259964, -1.85276],
              [132.223038, -1.884167],
              [132.202835, -1.869528],
              [132.170273, -1.8993],
              [132.163971, -1.881699],
              [132.133606, -1.89722],
              [132.086563, -1.870383],
              [132.08194, -1.89306],
              [132.095276, -1.901666],
              [132.074722, -1.949722],
              [132.102493, -1.970555],
              [132.085007, -2.021667],
              [132.113312, -1.985411],
              [132.181229, -1.9905],
              [132.238266, -1.934189],
              [132.272705, -1.934654],
              [132.245895, -1.945861],
              [132.238815, -1.934907],
              [132.231949, -1.962309],
              [132.136383, -2.023611],
              [132.115204, -1.99272],
              [132.038605, -2.070001],
              [132.134171, -2.14028],
              [132.107498, -2.089444],
              [132.134171, -2.083329],
              [132.141388, -2.148611],
              [132.19722, -2.15194],
              [132.315552, -2.283611],
              [132.574524, -2.189304],
              [132.64917, -2.289999],
              [132.714722, -2.29778],
              [132.825424, -2.2425],
              [132.897217, -2.28111],
              [132.944443, -2.262499],
              [132.960007, -2.293055],
              [133.158218, -2.197325],
              [133.263107, -2.20194],
              [133.282623, -2.190207],
              [133.261108, -2.17694],
              [133.274445, -2.157777],
              [133.262772, -2.177779],
              [133.298935, -2.193211],
              [133.317551, -2.168719],
              [133.277267, -2.201835],
              [133.325668, -2.218295],
              [133.385483, -2.192149],
              [133.372223, -2.202501],
              [133.382889, -2.211253],
              [133.428345, -2.216499],
              [133.407776, -2.200238],
              [133.419235, -2.199747],
              [133.426117, -2.157499],
              [133.420273, -2.2],
              [133.40921, -2.200785],
              [133.430252, -2.215015],
              [133.415833, -2.224722],
              [133.454727, -2.227779],
              [133.45166, -2.199166],
              [133.487305, -2.23156],
              [133.474167, -2.174723],
              [133.500427, -2.184388],
              [133.508896, -2.164444],
              [133.487411, -2.159286],
              [133.476395, -2.125277],
              [133.460007, -2.14],
              [133.475006, -2.123889],
              [133.488831, -2.131351],
              [133.488647, -2.158529],
              [133.511093, -2.164902],
              [133.500473, -2.186073],
              [133.478729, -2.172865],
              [133.479172, -2.187219],
              [133.527573, -2.203779],
              [133.540558, -2.17865],
              [133.523605, -2.164444],
              [133.543091, -2.172551],
              [133.529022, -2.204816],
              [133.497498, -2.193612],
              [133.508606, -2.230172],
              [133.555054, -2.223849],
              [133.58017, -2.160141],
              [133.568054, -2.175279],
              [133.525162, -2.113699],
              [133.500076, -2.12599],
              [133.454163, -2.094167],
              [133.499939, -2.124657],
              [133.524567, -2.111629],
              [133.57135, -2.174381],
              [133.579315, -2.143179],
              [133.623062, -2.136109],
              [133.581665, -2.151667],
              [133.615005, -2.178889],
              [133.642502, -2.127499],
              [133.646393, -2.139444],
              [133.626938, -2.149167],
              [133.640274, -2.158055],
              [133.668884, -2.131109],
              [133.657776, -2.156389],
              [133.575562, -2.190556],
              [133.580276, -2.22361],
              [133.61055, -2.213609],
              [133.623215, -2.232686],
              [133.620285, -2.19361],
              [133.657349, -2.209099],
              [133.639725, -2.193056],
              [133.672745, -2.140027],
              [133.665833, -2.15667],
              [133.679443, -2.153889],
              [133.648331, -2.175554],
              [133.679443, -2.17111],
              [133.643616, -2.193612],
              [133.660599, -2.20266],
              [133.696869, -2.195697],
              [133.732498, -2.122779],
              [133.736389, -2.145281],
              [133.717285, -2.156594],
              [133.752716, -2.169155],
              [133.764999, -2.141388],
              [133.754166, -2.163334],
              [133.769608, -2.16131],
              [133.77594, -2.12671],
              [133.734161, -2.113056],
              [133.734161, -2.099167],
              [133.776947, -2.125065],
              [133.761322, -2.088129],
              [133.781311, -2.112055],
              [133.814728, -2.098333],
              [133.781387, -2.151111],
              [133.824402, -2.130756],
              [133.841949, -2.066666],
              [133.83667, -2.094445],
              [133.875275, -2.108609],
              [133.878616, -2.079719],
              [133.893326, -2.097223],
              [133.886948, -2.08028],
              [133.907227, -2.0575],
              [133.888611, -2.080556],
              [133.904999, -2.104167],
              [133.91861, -2.054719],
              [133.934723, -2.051667],
              [133.923889, -2.088888],
              [133.949158, -2.04542],
              [133.947784, -2.08139],
              [133.925827, -2.09083],
              [133.926941, -2.112222],
              [133.901642, -2.135758],
              [133.935623, -2.126735],
              [133.96611, -2.076109],
              [133.968887, -2.093059],
              [133.939438, -2.127777],
              [133.969162, -2.108334],
              [133.937225, -2.160277],
              [133.986115, -2.141666],
              [134.011322, -2.155835],
              [134.003326, -2.16528],
              [133.959717, -2.147501],
              [133.937225, -2.195609],
              [133.963058, -2.181945],
              [133.94278, -2.231945],
              [133.98555, -2.225],
              [134.000488, -2.241946],
              [133.991394, -2.299722],
              [133.949173, -2.32528],
              [133.958054, -2.348612],
              [133.986664, -2.363332],
              [133.996674, -2.34194],
              [134.02861, -2.343611],
              [134.020203, -2.316109],
              [134.031387, -2.338055],
              [133.998337, -2.343609],
              [133.988327, -2.389445],
              [133.948059, -2.420309],
              [133.986389, -2.446945],
              [133.974716, -2.485556],
              [133.949799, -2.50006],
              [134.01416, -2.579723],
              [133.974762, -2.548512],
              [133.947815, -2.500559],
              [133.972229, -2.483334],
              [133.964447, -2.469166],
              [133.903061, -2.482219],
              [133.871948, -2.455829],
              [133.853333, -2.473055],
              [133.821396, -2.445279],
              [133.820694, -2.4666],
              [133.792007, -2.482524],
              [133.858612, -2.49194],
              [133.871048, -2.507089],
              [133.859375, -2.49312],
              [133.821396, -2.506389],
              [133.78389, -2.487222],
              [133.807739, -2.543266],
              [133.799164, -2.638889],
              [133.843887, -2.661945],
              [133.863739, -2.605839],
              [133.88916, -2.650834],
              [133.864441, -2.63333],
              [133.845276, -2.663611],
              [133.895828, -2.67778],
              [133.85611, -2.674168],
              [133.849167, -2.703612],
              [133.92778, -2.713332],
              [133.851395, -2.709444],
              [133.852493, -2.676112],
              [133.836609, -2.66672],
              [133.810837, -2.735],
              [133.830276, -2.67028],
              [133.798615, -2.64861],
              [133.760559, -2.672221],
              [133.759995, -2.723333],
              [133.776382, -2.75389],
              [133.757782, -2.720833],
              [133.758606, -2.675],
              [133.741394, -2.664167],
              [133.731674, -2.702222],
              [133.752502, -2.624444],
              [133.69017, -2.61626],
              [133.669449, -2.67639],
              [133.709381, -2.704859],
              [133.685272, -2.70389],
              [133.694443, -2.738332],
              [133.683105, -2.713164],
              [133.666382, -2.733061],
              [133.71167, -2.7825],
              [133.649719, -2.746941],
              [133.654449, -2.803333],
              [133.625839, -2.813056],
              [133.648056, -2.868056],
              [133.627777, -2.848889],
              [133.625, -2.809719],
              [133.603333, -2.80778],
              [133.631104, -2.800277],
              [133.60611, -2.778333],
              [133.617996, -2.761741],
              [133.632507, -2.797779],
              [133.642502, -2.789166],
              [133.648331, -2.741325],
              [133.63916, -2.719441],
              [133.604446, -2.726668],
              [133.636948, -2.717221],
              [133.656662, -2.738891],
              [133.675827, -2.704722],
              [133.63916, -2.675278],
              [133.664993, -2.685001],
              [133.666656, -2.554531],
              [133.650558, -2.617499],
              [133.658585, -2.584484],
              [133.641525, -2.531818],
              [133.603653, -2.557449],
              [133.626938, -2.55694],
              [133.636108, -2.578059],
              [133.625549, -2.557777],
              [133.618896, -2.57028],
              [133.603928, -2.562764],
              [133.614258, -2.538354],
              [133.591934, -2.554992],
              [133.604996, -2.584723],
              [133.589981, -2.557057],
              [133.568771, -2.568179],
              [133.608612, -2.605559],
              [133.595001, -2.613609],
              [133.568832, -2.592563],
              [133.575272, -2.629444],
              [133.603607, -2.613055],
              [133.626389, -2.64306],
              [133.600006, -2.64556],
              [133.605835, -2.624439],
              [133.575272, -2.632501],
              [133.55722, -2.605279],
              [133.534927, -2.610388],
              [133.527771, -2.641389],
              [133.559174, -2.667499],
              [133.513062, -2.64639],
              [133.523056, -2.688059],
              [133.504166, -2.639167],
              [133.502502, -2.6825],
              [133.490555, -2.643329],
              [133.464554, -2.650738],
              [133.479996, -2.68],
              [133.457504, -2.6575],
              [133.472504, -2.638055],
              [133.438614, -2.643609],
              [133.438889, -2.686391],
              [133.477219, -2.700556],
              [133.488052, -2.727222],
              [133.475006, -2.701939],
              [133.458618, -2.711666],
              [133.427216, -2.67667],
              [133.458054, -2.616389],
              [133.449448, -2.51639],
              [133.424225, -2.50341],
              [133.401413, -2.537306],
              [133.419724, -2.541944],
              [133.435837, -2.6025],
              [133.399765, -2.667089],
              [133.408615, -2.725555],
              [133.407501, -2.693609],
              [133.381393, -2.701112],
              [133.424362, -2.760695],
              [133.410965, -2.764287],
              [133.402496, -2.73041],
              [133.390549, -2.741111],
              [133.38028, -2.72278],
              [133.38916, -2.669167],
              [133.354446, -2.689169],
              [133.319107, -2.671611],
              [133.308884, -2.701112],
              [133.31218, -2.667299],
              [133.284164, -2.70861],
              [133.229721, -2.722223],
              [133.280716, -2.690391],
              [133.264725, -2.680834],
              [133.29834, -2.644168],
              [133.291458, -2.671319],
              [133.371948, -2.671668],
              [133.376663, -2.634445],
              [133.353333, -2.653334],
              [133.373886, -2.621112],
              [133.352707, -2.625625],
              [133.358887, -2.611667],
              [133.373611, -2.618334],
              [133.373032, -2.587],
              [133.342773, -2.607501],
              [133.375763, -2.581443],
              [133.381012, -2.528588],
              [133.364182, -2.500979],
              [133.330276, -2.519166],
              [133.345276, -2.53889],
              [133.324997, -2.531111],
              [133.362228, -2.504723],
              [133.323822, -2.46779],
              [133.325409, -2.51521],
              [133.306396, -2.544722],
              [133.3125, -2.523888],
              [133.293335, -2.51139],
              [133.276108, -2.5425],
              [133.238327, -2.524167],
              [133.209167, -2.547222],
              [133.235275, -2.52333],
              [133.259018, -2.529652],
              [133.259171, -2.51611],
              [133.286942, -2.525001],
              [133.280548, -2.498611],
              [133.30986, -2.509443],
              [133.303406, -2.443925],
              [133.215057, -2.411247],
              [133.042374, -2.49503],
              [132.791946, -2.78972],
              [132.71611, -2.798055],
              [132.579727, -2.689167],
              [132.483719, -2.677746],
              [132.464996, -2.692221],
              [132.494446, -2.71111],
              [132.43158, -2.735032],
              [132.458328, -2.712223],
              [132.418411, -2.695058],
              [132.438614, -2.677778],
              [132.196671, -2.651666],
              [132.111115, -2.690833],
              [132.200562, -2.704166],
              [132.095276, -2.708334],
              [132.096664, -2.745555],
              [132.074173, -2.724719],
              [132.067215, -2.748055],
              [132.049728, -2.729719],
              [131.950562, -2.76694],
              [132.041107, -2.769999],
              [132.064728, -2.787222],
              [131.978149, -2.82965],
              [132.037506, -2.83806],
              [132.028885, -2.87583],
              [131.976044, -2.879119],
              [131.98172, -2.924059],
              [131.98027, -2.901945],
              [132.008331, -2.888329],
              [132.046387, -2.923334],
              [132.057785, -2.887778],
              [132.087494, -2.960001],
              [132.121384, -2.94167],
              [132.101105, -2.928889],
              [132.152771, -2.94139],
              [132.20694, -2.906389],
              [132.350555, -2.937219],
              [132.350281, -2.964999],
              [132.419998, -2.990829],
              [132.561325, -3.109818],
              [132.556488, -3.151881],
              [132.645004, -3.191944],
              [132.596161, -3.229589],
              [132.663101, -3.315729],
              [132.728088, -3.337392],
              [132.728226, -3.280229],
              [132.7733, -3.256519],
              [132.843002, -3.299095],
              [132.797775, -3.355281],
              [132.816193, -3.44364],
              [132.853043, -3.477164],
              [132.895554, -3.462307],
              [132.907013, -3.546569],
              [132.939285, -3.552016],
              [132.903168, -3.573576],
              [132.89035, -3.644141],
              [132.725403, -3.636369],
              [132.845184, -3.955489],
              [132.811829, -3.92068],
              [132.808365, -3.945114],
              [132.838837, -3.957399],
              [132.841507, -3.99035],
              [132.917496, -4.0475],
              [132.91243, -4.08514],
              [132.956924, -4.104989],
              [133.01889, -4.119169],
              [133.028061, -4.07667],
              [133.075562, -4.054166],
              [133.25, -4.07361],
              [133.329849, -3.989291],
              [133.289993, -3.948888],
              [133.324722, -3.983333],
              [133.402802, -3.903609],
              [133.346725, -3.905551],
              [133.297318, -3.840675],
              [133.242783, -3.850833],
              [133.233887, -3.838056],
              [133.281952, -3.848055],
              [133.26889, -3.82889],
              [133.284729, -3.8075],
              [133.260559, -3.756388],
              [133.243332, -3.758888],
              [133.236664, -3.742219],
              [133.216385, -3.754166],
              [133.236389, -3.740831],
              [133.283615, -3.783056],
              [133.272568, -3.834909],
              [133.312469, -3.841423],
              [133.352539, -3.90133],
              [133.367279, -3.873406],
              [133.400955, -3.887039],
              [133.458252, -3.85824],
              [133.394775, -3.74674],
              [133.402466, -3.595294],
              [133.368378, -3.594658],
              [133.328339, -3.554167],
              [133.269165, -3.606945],
              [133.231949, -3.594444],
              [133.246948, -3.583329],
              [133.269165, -3.605559],
              [133.330551, -3.54444],
              [133.377609, -3.592874],
              [133.432709, -3.596908],
              [133.42337, -3.571976],
              [133.443054, -3.552499],
              [133.425552, -3.544444],
              [133.44809, -3.561629],
              [133.461105, -3.542778],
              [133.421112, -3.51167],
              [133.467499, -3.515278],
              [133.459717, -3.528889],
              [133.471664, -3.538334],
              [133.428284, -3.571705],
              [133.439117, -3.59356],
              [133.409897, -3.691449],
              [133.58223, -3.571995],
              [133.596909, -3.526084],
              [133.550522, -3.454699],
              [133.487686, -3.454665],
              [133.439163, -3.41583],
              [133.427505, -3.428334],
              [133.405838, -3.394167],
              [133.428894, -3.362779],
              [133.389999, -3.336945],
              [133.417221, -3.34778],
              [133.402771, -3.32694],
              [133.430832, -3.359167],
              [133.419449, -3.38361],
              [133.442215, -3.35833],
              [133.417496, -3.414167],
              [133.441391, -3.407778],
              [133.483383, -3.441008],
              [133.518051, -3.434166],
              [133.495377, -3.375529],
              [133.513199, -3.333668],
              [133.488617, -3.307779],
              [133.514999, -3.33222],
              [133.538422, -3.428124],
              [133.634171, -3.41527],
              [133.664597, -3.340282],
              [133.648178, -3.234598],
              [133.672165, -3.209457],
              [133.648102, -3.123012],
              [133.73053, -3.030303],
              [133.793335, -3.028612],
              [133.825806, -2.942955],
              [133.79805, -2.902699],
              [133.854446, -2.924166],
              [133.85527, -2.953029],
              [133.90744, -2.932629],
              [133.850159, -2.969469],
              [133.885895, -3.071519],
              [133.914993, -3.056112],
              [133.897507, -3.068333],
              [133.921387, -3.070279],
              [133.900909, -3.09315],
              [133.922653, -3.12164],
              [133.902847, -3.120379],
              [133.891922, -3.100387],
              [133.885284, -3.114722],
              [133.896698, -3.076146],
              [133.835022, -3.018876],
              [133.827225, -3.088335],
              [133.798782, -3.119128],
              [133.771439, -3.114444],
              [133.778549, -3.213245],
              [133.764084, -3.177371],
              [133.701843, -3.17016],
              [133.701096, -3.208869],
              [133.668091, -3.22749],
              [133.676376, -3.316964],
              [133.664291, -3.31969],
              [133.701111, -3.43861],
              [133.69072, -3.410664],
              [133.636154, -3.4471],
              [133.621094, -3.47574],
              [133.707214, -3.60352],
              [133.690689, -3.671041],
              [133.751892, -3.645586],
              [133.816177, -3.727199],
              [133.774185, -3.574173],
              [133.787811, -3.597464],
              [133.832779, -3.556],
              [133.839478, -3.631398],
              [133.933197, -3.749494],
              [133.94194, -3.773333],
              [133.917694, -3.750667],
              [133.913696, -3.784431],
              [133.959717, -3.8625],
              [133.985596, -3.822526],
              [133.998611, -3.852777],
              [134.040817, -3.8414],
              [134.034973, -3.814554],
              [133.997223, -3.816256],
              [134.041534, -3.78193],
              [134.055283, -3.821945],
              [134.117065, -3.792155],
              [134.098358, -3.766667],
              [134.136383, -3.741941],
              [134.172089, -3.776201],
              [134.190826, -3.83283],
              [134.105377, -3.89813],
              [134.167755, -3.894139],
              [134.163391, -3.92699],
              [134.249115, -3.976414],
              [134.291107, -4.048056],
              [134.334351, -4.020581],
              [134.269501, -3.937431],
              [134.34967, -3.966287],
              [134.360718, -3.915693],
              [134.332489, -3.879719],
              [134.438339, -3.919166],
              [134.474854, -3.972669],
              [134.446457, -3.981382],
              [134.506393, -4.044443],
              [134.604172, -3.999722],
              [134.674438, -3.920278],
              [134.933716, -3.936239],
              [134.91806, -3.965],
              [134.963226, -3.948615],
              [134.946579, -3.982702],
              [134.870102, -3.9765],
              [134.868347, -3.999378],
              [134.865906, -3.981649],
              [134.850372, -3.991606],
              [134.830399, -3.964681],
              [134.833359, -3.95104],
              [134.76889, -3.934001],
              [134.768448, -3.96234],
              [134.815201, -3.986519],
              [134.761459, -3.970805],
              [134.765839, -3.94222],
              [134.746674, -3.97333],
              [134.739716, -3.950554],
              [134.734665, -3.96432],
              [134.686951, -3.969166],
              [134.684601, -3.985601],
              [134.661392, -3.982499],
              [134.675003, -4.04389],
              [134.72139, -4.057779],
              [134.675201, -4.088319],
              [134.656387, -4.07361],
              [134.648819, -4.099074],
              [134.65416, -4.052778],
              [134.629166, -4.118611],
              [134.687775, -4.141108],
              [134.712494, -4.133612],
              [134.69696, -4.119509],
              [134.688614, -4.134166],
              [134.656387, -4.12889],
              [134.740234, -4.122816],
              [134.713333, -4.13028],
              [134.735275, -4.171738],
              [134.718613, -4.16],
              [134.723328, -4.198055],
              [134.81604, -4.229375],
              [134.815277, -4.252777],
              [134.87616, -4.251943],
              [135.25766, -3.75588],
              [134.205246, -3.391239],
              [134.27594, -3.23835],
              [134.425034, -3.110369],
              [134.426788, -3.006559],
              [134.661392, -2.823219],
            ],
          ],
          [
            [
              [134.348053, -2.165],
              [134.411667, -2.073333],
              [134.388184, -1.99321],
              [134.315552, -2.078889],
              [134.348053, -2.165],
            ],
          ],
          [
            [
              [134.737503, -2.016111],
              [134.723892, -2.025829],
              [134.738892, -2.053055],
              [134.737503, -2.016111],
            ],
          ],
          [
            [
              [134.575348, -2.337501],
              [134.563309, -2.280778],
              [134.562027, -2.36854],
              [134.502853, -2.360739],
              [134.564194, -2.44134],
              [134.562027, -2.403001],
              [134.590775, -2.395468],
              [134.575348, -2.337501],
            ],
          ],
          [
            [
              [134.521225, -2.34373],
              [134.501572, -2.342704],
              [134.530029, -2.351376],
              [134.521225, -2.34373],
            ],
          ],
          [
            [
              [134.377228, -2.521666],
              [134.36972, -2.549444],
              [134.387772, -2.57611],
              [134.39389, -2.53639],
              [134.377228, -2.521666],
            ],
          ],
          [
            [
              [132.471497, -2.624542],
              [132.441116, -2.641111],
              [132.460342, -2.668185],
              [132.508774, -2.653585],
              [132.50528, -2.633611],
              [132.471497, -2.624542],
            ],
          ],
          [
            [
              [132.556946, -2.641109],
              [132.536392, -2.646939],
              [132.574997, -2.651666],
              [132.556946, -2.641109],
            ],
          ],
          [
            [
              [132.286942, -2.985559],
              [132.321152, -2.992014],
              [132.249451, -2.967777],
              [132.169586, -2.968609],
              [132.286942, -2.985559],
            ],
          ],
          [
            [
              [132.132507, -2.965555],
              [132.110001, -2.970834],
              [132.143616, -2.970556],
              [132.132507, -2.965555],
            ],
          ],
          [
            [
              [132.442215, -3.098333],
              [132.472504, -3.13028],
              [132.545273, -3.150277],
              [132.547226, -3.135],
              [132.442215, -3.098333],
            ],
          ],
          [
            [
              [132.745026, -3.42548],
              [132.75528, -3.414509],
              [132.743271, -3.392599],
              [132.745026, -3.42548],
            ],
          ],
          [
            [
              [132.708725, -3.46011],
              [132.663986, -3.403756],
              [132.629837, -3.405946],
              [132.681183, -3.450738],
              [132.699402, -3.511349],
              [132.724411, -3.495833],
              [132.708725, -3.46011],
            ],
          ],
          [
            [
              [132.752228, -3.443889],
              [132.740555, -3.441667],
              [132.747772, -3.475833],
              [132.752228, -3.443889],
            ],
          ],
          [
            [
              [133.776947, -3.05389],
              [133.75972, -3.076112],
              [133.792496, -3.109722],
              [133.776947, -3.05389],
            ],
          ],
          [
            [
              [133.613937, -3.420899],
              [133.596985, -3.431069],
              [133.616882, -3.452399],
              [133.628311, -3.428546],
              [133.613937, -3.420899],
            ],
          ],
          [
            [
              [133.912811, -3.85633],
              [133.925018, -3.874051],
              [133.872772, -3.715555],
              [133.883392, -3.796141],
              [133.912811, -3.85633],
            ],
          ],
          [
            [
              [134.035431, -3.848358],
              [134.005981, -3.857598],
              [134.023331, -3.868332],
              [134.035431, -3.848358],
            ],
          ],
          [
            [
              [134.051834, -3.924399],
              [134.083618, -3.983841],
              [134.211349, -4.02673],
              [134.1772, -4.00864],
              [134.098984, -3.915533],
              [134.051834, -3.924399],
            ],
          ],
          [
            [
              [134.683655, -3.936996],
              [134.69278, -3.96389],
              [134.738419, -3.953898],
              [134.683655, -3.936996],
            ],
          ],
          [
            [
              [134.842865, -3.952499],
              [134.83139, -3.963612],
              [134.86055, -3.984723],
              [134.865402, -3.970419],
              [134.842865, -3.952499],
            ],
          ],
          [
            [
              [134.413757, -4.029144],
              [134.452011, -4.0193],
              [134.376709, -3.977389],
              [134.362503, -4.032501],
              [134.413757, -4.029144],
            ],
          ],
          [
            [
              [134.229965, -4.015519],
              [134.262711, -4.046109],
              [134.246048, -4.005008],
              [134.228058, -4.002801],
              [134.229965, -4.015519],
            ],
          ],
          [
            [
              [134.354019, -4.023539],
              [134.339447, -4.027222],
              [134.36055, -4.033611],
              [134.354019, -4.023539],
            ],
          ],
          [
            [
              [133.507156, -4.199229],
              [133.318481, -4.098261],
              [133.347946, -4.163886],
              [133.331375, -4.182169],
              [133.417084, -4.205079],
              [133.460098, -4.251956],
              [133.548859, -4.234501],
              [133.63472, -4.314309],
              [133.63562, -4.295962],
              [133.555069, -4.21874],
              [133.507156, -4.199229],
            ],
          ],
          [
            [
              [131.26123, -0.938987],
              [131.25647, -0.924368],
              [131.246231, -0.947331],
              [131.26123, -0.938987],
            ],
          ],
          [
            [
              [134.098724, -0.888909],
              [134.089935, -0.896301],
              [134.110764, -0.92172],
              [134.098724, -0.888909],
            ],
          ],
          [
            [
              [131.055069, 0.378053],
              [131.032379, 0.387192],
              [131.049698, 0.361611],
              [131.066177, 0.36676],
              [131.055069, 0.378053],
            ],
          ],
          [
            [
              [129.860672, 0.324739],
              [129.882324, 0.277128],
              [129.925873, 0.281691],
              [129.907227, 0.318011],
              [129.860672, 0.324739],
            ],
          ],
          [
            [
              [130.037506, 0.186941],
              [129.997498, 0.180001],
              [130.073059, 0.158611],
              [130.037506, 0.186941],
            ],
          ],
          [
            [
              [130.076111, 0.149722],
              [130.063034, 0.138165],
              [130.080765, 0.12944],
              [130.076111, 0.149722],
            ],
          ],
          [
            [
              [130.225571, 0.105692],
              [130.212143, 0.110941],
              [130.230438, 0.093781],
              [130.225571, 0.105692],
            ],
          ],
          [
            [
              [130.249466, 0.109571],
              [130.249146, 0.098547],
              [130.276596, 0.099476],
              [130.249466, 0.109571],
            ],
          ],
          [
            [
              [130.872772, 0.0425],
              [130.880386, 0.01578],
              [130.904861, 0.018996],
              [130.894058, 0.04013],
              [130.872772, 0.0425],
            ],
          ],
          [
            [
              [130.104645, -0.06848],
              [130.131912, -0.097147],
              [130.16571, -0.066144],
              [130.155273, -0.03592],
              [130.116226, -0.035371],
              [130.142288, -0.017031],
              [130.110275, 0.00389],
              [130.141388, 0.015],
              [130.088333, 0.002499],
              [130.104645, -0.06848],
            ],
          ],
          [
            [
              [130.429993, -0.201666],
              [130.395004, -0.23],
              [130.358063, -0.200556],
              [130.331116, -0.218609],
              [130.21817, -0.208118],
              [130.291382, -0.22889],
              [130.317703, -0.273057],
              [130.429306, -0.2614],
              [130.436951, -0.290281],
              [130.527466, -0.246599],
              [130.552979, -0.43076],
              [130.59137, -0.378951],
              [130.560013, -0.367291],
              [130.578339, -0.321111],
              [130.679718, -0.29222],
              [130.705002, -0.324721],
              [130.672501, -0.339166],
              [130.727036, -0.439642],
              [130.879807, -0.420643],
              [130.944687, -0.354012],
              [130.919556, -0.305492],
              [130.85527, -0.310556],
              [130.826172, -0.2881],
              [130.815231, -0.311892],
              [130.731461, -0.294659],
              [130.675797, -0.173181],
              [130.62056, -0.145353],
              [130.622772, -0.120556],
              [130.666092, -0.116912],
              [130.620605, -0.104208],
              [130.621017, -0.080327],
              [130.698608, -0.07833],
              [130.679993, -0.098451],
              [130.789993, -0.168889],
              [130.799652, -0.241049],
              [130.868683, -0.235299],
              [130.89505, -0.299295],
              [130.919434, -0.30087],
              [130.959106, -0.351223],
              [131.007202, -0.33551],
              [131.023697, -0.357139],
              [131.103653, -0.307919],
              [131.241455, -0.381259],
              [131.245956, -0.34062],
              [131.276535, -0.34576],
              [131.340775, -0.290732],
              [131.295715, -0.238772],
              [131.318298, -0.198056],
              [131.299164, -0.159734],
              [131.194, -0.130536],
              [131.133499, -0.070622],
              [131.033615, -0.060277],
              [131.048615, -0.020278],
              [130.977158, -0.03512],
              [130.967407, -0.061795],
              [130.956085, -0.03378],
              [130.93837, -0.04075],
              [130.797775, -0.004999],
              [130.739716, -0.025279],
              [130.769775, -0.05362],
              [130.708328, -0.061939],
              [130.726669, -0.027221],
              [130.633896, -0.062499],
              [130.569473, -0.039024],
              [130.578049, -0.075],
              [130.512131, -0.049782],
              [130.525864, -0.068019],
              [130.446945, -0.100281],
              [130.437775, -0.073888],
              [130.414169, -0.113889],
              [130.404984, -0.076803],
              [130.368683, -0.07578],
              [130.362503, -0.134999],
              [130.352783, -0.078889],
              [130.345276, -0.109721],
              [130.29744, -0.092657],
              [130.281281, -0.111369],
              [130.301117, -0.172778],
              [130.353561, -0.192139],
              [130.373062, -0.1825],
              [130.335556, -0.141667],
              [130.366394, -0.160279],
              [130.371948, -0.137777],
              [130.38916, -0.171944],
              [130.425278, -0.12944],
              [130.424438, -0.166111],
              [130.376663, -0.182222],
              [130.42778, -0.17611],
              [130.451111, -0.197221],
              [130.429993, -0.201666],
            ],
          ],
          [
            [
              [130.94278, -0.01583],
              [130.947495, -0.033333],
              [130.956345, -0.022414],
              [130.94278, -0.01583],
            ],
          ],
          [
            [
              [131.081421, -0.038067],
              [131.064194, -0.040531],
              [131.08017, -0.049059],
              [131.081421, -0.038067],
            ],
          ],
          [
            [
              [130.064453, -0.064405],
              [130.013748, -0.07078],
              [130.064148, -0.076769],
              [130.064453, -0.064405],
            ],
          ],
          [
            [
              [130.269058, -0.106489],
              [130.250992, -0.109274],
              [130.261581, -0.122509],
              [130.269058, -0.106489],
            ],
          ],
          [
            [
              [130.256943, -0.148329],
              [130.256607, -0.188025],
              [130.297226, -0.191945],
              [130.256943, -0.148329],
            ],
          ],
          [
            [
              [130.239166, -0.165662],
              [130.204163, -0.181388],
              [130.250275, -0.180278],
              [130.239166, -0.165662],
            ],
          ],
          [
            [
              [130.223724, -0.277919],
              [130.200058, -0.305715],
              [130.230957, -0.32022],
              [130.242355, -0.302928],
              [130.223724, -0.277919],
            ],
          ],
          [
            [
              [130.870697, -0.293889],
              [130.852783, -0.3075],
              [130.900497, -0.308881],
              [130.870697, -0.293889],
            ],
          ],
          [
            [
              [130.499634, -0.302474],
              [130.498611, -0.332778],
              [130.525558, -0.321667],
              [130.520157, -0.303769],
              [130.499634, -0.302474],
            ],
          ],
          [
            [
              [130.668701, -0.307126],
              [130.65799, -0.322116],
              [130.669388, -0.332299],
              [130.680466, -0.320065],
              [130.668701, -0.307126],
            ],
          ],
          [
            [
              [130.14856, -0.309423],
              [130.134171, -0.314743],
              [130.149689, -0.330528],
              [130.14856, -0.309423],
            ],
          ],
          [
            [
              [130.184723, -0.317186],
              [130.229416, -0.345949],
              [130.225327, -0.326553],
              [130.184723, -0.317186],
            ],
          ],
          [
            [
              [130.524445, -0.46778],
              [130.477219, -0.43889],
              [130.468613, -0.483611],
              [130.513062, -0.487499],
              [130.456711, -0.509812],
              [130.544998, -0.511945],
              [130.577225, -0.463055],
              [130.614716, -0.486667],
              [130.571198, -0.517119],
              [130.592773, -0.534445],
              [130.660553, -0.510001],
              [130.69722, -0.44778],
              [130.614716, -0.395278],
              [130.524445, -0.46778],
            ],
          ],
          [
            [
              [129.854721, -0.42],
              [129.874451, -0.498334],
              [129.924683, -0.412727],
              [129.890366, -0.397428],
              [129.854721, -0.42],
            ],
          ],
          [
            [
              [130.454437, -0.430277],
              [130.4375, -0.445],
              [130.454727, -0.446939],
              [130.454437, -0.430277],
            ],
          ],
          [
            [
              [130.26973, -0.561667],
              [130.250549, -0.545555],
              [130.27861, -0.585832],
              [130.26973, -0.561667],
            ],
          ],
          [
            [
              [130.650284, -0.568055],
              [130.556107, -0.60778],
              [130.660553, -0.57278],
              [130.650284, -0.568055],
            ],
          ],
          [
            [
              [130.279999, -0.593889],
              [130.281387, -0.607438],
              [130.290558, -0.594723],
              [130.279999, -0.593889],
            ],
          ],
          [
            [
              [130.22084, -0.740556],
              [130.236664, -0.72194],
              [130.21611, -0.727777],
              [130.22084, -0.740556],
            ],
          ],
          [
            [
              [130.889587, -0.772389],
              [130.850967, -0.766],
              [130.861191, -0.800967],
              [130.804077, -0.751899],
              [130.815277, -0.825834],
              [130.809723, -0.802286],
              [130.786148, -0.813076],
              [130.797607, -0.793161],
              [130.717178, -0.829693],
              [130.671143, -0.797819],
              [130.688599, -0.82039],
              [130.663498, -0.82606],
              [130.635941, -0.790829],
              [130.585312, -0.833034],
              [130.566559, -0.793749],
              [130.544174, -0.839999],
              [130.486481, -0.829595],
              [130.50618, -0.797555],
              [130.484329, -0.789238],
              [130.433334, -0.861776],
              [130.4505, -0.893901],
              [130.387772, -0.92278],
              [130.454559, -0.898081],
              [130.47171, -0.917752],
              [130.471329, -0.89566],
              [130.523148, -0.897598],
              [130.508804, -0.920535],
              [130.62883, -0.90996],
              [130.850052, -0.860466],
              [130.900223, -0.832679],
              [130.863983, -0.805019],
              [130.927017, -0.784391],
              [130.889587, -0.772389],
            ],
          ],
          [
            [
              [130.783142, -0.756945],
              [130.7603, -0.77286],
              [130.773056, -0.801391],
              [130.792221, -0.781667],
              [130.783142, -0.756945],
            ],
          ],
          [
            [
              [130.748337, -0.7803],
              [130.749695, -0.766659],
              [130.717148, -0.77464],
              [130.732666, -0.799159],
              [130.748337, -0.7803],
            ],
          ],
          [
            [
              [130.526215, -0.798327],
              [130.511948, -0.8175],
              [130.538895, -0.827778],
              [130.552475, -0.795921],
              [130.526215, -0.798327],
            ],
          ],
          [
            [
              [130.640656, -0.970106],
              [130.717178, -1.078529],
              [130.680222, -1.108429],
              [130.737045, -1.165127],
              [130.755585, -1.248669],
              [130.810043, -1.26672],
              [130.858017, -1.345639],
              [130.907471, -1.322],
              [130.973907, -1.35391],
              [131.000839, -1.281944],
              [131.044449, -1.24167],
              [131.033691, -1.19709],
              [131.04805, -1.185559],
              [131.087753, -1.012213],
              [131.051208, -0.931365],
              [131.03035, -0.911651],
              [130.936493, -0.939985],
              [130.927383, -0.893019],
              [130.90155, -0.888422],
              [130.640656, -0.970106],
            ],
          ],
          [
            [
              [131.104111, -0.943588],
              [131.096207, -0.95061],
              [131.114868, -0.935128],
              [131.104111, -0.943588],
            ],
          ],
          [
            [
              [130.647385, -0.937229],
              [130.636673, -0.944445],
              [130.661636, -0.94984],
              [130.647385, -0.937229],
            ],
          ],
          [
            [
              [131.079468, -1.07547],
              [131.114166, -1.078333],
              [131.103058, -1.066944],
              [131.145508, -0.997522],
              [131.096985, -1.00298],
              [131.079468, -1.07547],
            ],
          ],
          [
            [
              [131.162384, -1.037279],
              [131.157288, -1.078201],
              [131.170502, -1.061511],
              [131.162384, -1.037279],
            ],
          ],
          [
            [
              [131.126389, -1.050277],
              [131.110001, -1.055833],
              [131.121948, -1.076669],
              [131.137222, -1.06139],
              [131.126389, -1.050277],
            ],
          ],
          [
            [
              [131.172394, -1.071803],
              [131.174484, -1.060615],
              [131.161728, -1.082853],
              [131.172394, -1.071803],
            ],
          ],
          [
            [
              [129.765549, -1.170556],
              [129.732498, -1.193334],
              [129.742218, -1.219721],
              [129.887573, -1.197153],
              [129.832779, -1.25111],
              [129.978333, -1.171944],
              [129.866943, -1.143888],
              [129.765549, -1.170556],
            ],
          ],
          [
            [
              [129.771118, -1.14694],
              [129.763062, -1.159999],
              [129.782227, -1.153054],
              [129.771118, -1.14694],
            ],
          ],
          [
            [
              [129.746948, -1.156389],
              [129.672913, -1.17882],
              [129.703568, -1.203722],
              [129.67305, -1.198056],
              [129.715836, -1.199166],
              [129.746948, -1.156389],
            ],
          ],
          [
            [
              [129.334717, -1.154445],
              [129.300552, -1.178057],
              [129.353058, -1.161667],
              [129.334717, -1.154445],
            ],
          ],
          [
            [
              [129.357483, -1.161041],
              [129.345276, -1.182499],
              [129.372223, -1.187777],
              [129.373672, -1.169423],
              [129.357483, -1.161041],
            ],
          ],
          [
            [
              [129.390549, -1.162219],
              [129.37645, -1.175764],
              [129.383606, -1.1975],
              [129.396942, -1.19639],
              [129.390549, -1.162219],
            ],
          ],
          [
            [
              [129.463608, -1.167778],
              [129.44278, -1.171939],
              [129.446945, -1.194168],
              [129.475555, -1.186389],
              [129.463608, -1.167778],
            ],
          ],
          [
            [
              [129.660828, -1.25944],
              [129.651382, -1.27167],
              [129.663605, -1.275834],
              [129.660828, -1.25944],
            ],
          ],
          [
            [
              [129.755554, -1.265277],
              [129.751663, -1.276666],
              [129.769165, -1.27167],
              [129.755554, -1.265277],
            ],
          ],
          [
            [
              [129.719727, -1.269999],
              [129.714722, -1.279444],
              [129.730835, -1.278888],
              [129.719727, -1.269999],
            ],
          ],
          [
            [
              [128.925278, -1.377777],
              [128.911667, -1.38389],
              [128.916672, -1.398609],
              [128.925278, -1.377777],
            ],
          ],
          [
            [
              [130.261948, -1.458889],
              [130.20723, -1.483889],
              [130.258606, -1.476111],
              [130.261948, -1.458889],
            ],
          ],
          [
            [
              [129.910553, -1.62722],
              [129.914444, -1.65139],
              [129.923615, -1.638611],
              [129.910553, -1.62722],
            ],
          ],
          [
            [
              [129.862778, -1.66472],
              [129.858612, -1.674445],
              [129.871109, -1.670833],
              [129.862778, -1.66472],
            ],
          ],
          [
            [
              [130.186661, -2.06472],
              [130.265839, -2.05389],
              [130.285202, -2.031591],
              [130.26416, -2.01111],
              [130.304443, -2.01083],
              [130.343887, -1.95611],
              [130.326309, -1.985992],
              [130.405273, -2.000278],
              [130.371674, -2.042223],
              [130.478882, -2.00778],
              [130.382782, -1.981944],
              [130.407227, -1.970829],
              [130.439438, -1.9825],
              [130.444717, -1.954166],
              [130.427536, -1.972821],
              [130.432495, -1.952778],
              [130.340088, -1.922295],
              [130.408188, -1.89784],
              [130.410004, -1.859719],
              [130.429718, -1.872222],
              [130.455826, -1.834167],
              [130.352402, -1.736864],
              [130.3703, -1.676137],
              [130.252975, -1.704291],
              [130.258179, -1.729099],
              [130.058838, -1.724479],
              [129.719162, -1.879169],
              [129.862778, -1.976666],
              [130.016113, -2.022779],
              [130.101669, -2.018055],
              [130.085007, -2.02806],
              [130.117493, -2.038888],
              [130.09166, -2.043611],
              [130.186661, -2.06472],
            ],
          ],
          [
            [
              [129.70195, -1.773055],
              [129.691605, -1.755847],
              [129.700562, -1.78778],
              [129.70195, -1.773055],
            ],
          ],
          [
            [
              [129.625, -1.772779],
              [129.613327, -1.779167],
              [129.628891, -1.794166],
              [129.625, -1.772779],
            ],
          ],
          [
            [
              [129.655853, -1.80393],
              [129.641388, -1.822519],
              [129.656952, -1.839179],
              [129.655853, -1.80393],
            ],
          ],
          [
            [
              [130.451385, -1.954166],
              [130.45694, -1.98722],
              [130.466949, -1.97917],
              [130.451385, -1.954166],
            ],
          ],
          [
            [
              [130.483612, -1.97028],
              [130.471939, -1.98611],
              [130.492493, -1.981671],
              [130.483612, -1.97028],
            ],
          ],
          [
            [
              [130.549438, -1.985559],
              [130.544724, -1.995833],
              [130.599716, -2.004305],
              [130.549438, -1.985559],
            ],
          ],
          [
            [
              [130.614716, -1.999721],
              [130.600616, -2.004653],
              [130.638611, -2.006388],
              [130.614716, -1.999721],
            ],
          ],
          [
            [
              [130.661667, -2.00944],
              [130.663055, -2.022219],
              [130.673615, -2.012779],
              [130.661667, -2.00944],
            ],
          ],
          [
            [
              [130.439163, -2.053609],
              [130.424728, -2.06361],
              [130.440277, -2.066388],
              [130.439163, -2.053609],
            ],
          ],
          [
            [
              [130.449722, -2.066391],
              [130.400284, -2.080909],
              [130.409729, -2.089444],
              [130.476105, -2.06583],
              [130.449722, -2.066391],
            ],
          ],
          [
            [
              [130.196106, -2.14083],
              [130.169998, -2.14361],
              [130.211945, -2.144171],
              [130.196106, -2.14083],
            ],
          ],
          [
            [
              [130.310562, -2.193056],
              [130.2117, -2.193263],
              [130.279449, -2.212499],
              [130.271118, -2.195556],
              [130.310562, -2.193056],
            ],
          ],
          [
            [
              [130.41333, -2.205279],
              [130.410004, -2.215278],
              [130.49028, -2.222778],
              [130.41333, -2.205279],
            ],
          ],
          [
            [
              [130.544174, -2.218056],
              [130.500443, -2.225796],
              [130.553894, -2.221112],
              [130.544174, -2.218056],
            ],
          ],
          [
            [
              [130.602219, -2.244439],
              [130.571106, -2.2525],
              [130.623642, -2.253854],
              [130.602219, -2.244439],
            ],
          ],
          [
            [
              [131.957993, -1.837777],
              [131.923889, -1.870834],
              [131.97171, -1.923842],
              [131.983337, -1.874444],
              [131.948608, -1.86306],
              [131.957993, -1.837777],
            ],
          ],
          [
            [
              [132.086395, -1.95444],
              [132.058136, -1.96633],
              [132.063049, -2.024999],
              [132.10083, -1.970833],
              [132.086395, -1.95444],
            ],
          ],
          [
            [
              [131.057785, -1.193612],
              [131.037781, -1.197222],
              [131.046387, -1.213331],
              [131.057785, -1.193612],
            ],
          ],
          [
            [
              [131.181107, -1.20028],
              [131.185837, -1.189719],
              [131.14444, -1.22139],
              [131.168884, -1.22139],
              [131.181107, -1.20028],
            ],
          ],
          [
            [
              [131.022217, -1.298055],
              [131.011108, -1.289723],
              [131.01358, -1.308412],
              [131.022217, -1.298055],
            ],
          ],
          [
            [
              [131.336533, -1.352317],
              [131.299835, -1.355891],
              [131.318268, -1.370726],
              [131.305161, -1.380453],
              [131.272385, -1.38123],
              [131.31813, -1.423989],
              [131.362946, -1.397369],
              [131.336533, -1.352317],
            ],
          ],
          [
            [
              [131.713577, -1.428579],
              [131.669449, -1.46722],
              [131.655273, -1.44333],
              [131.605881, -1.463616],
              [131.623611, -1.484167],
              [131.594162, -1.46389],
              [131.58696, -1.490177],
              [131.638779, -1.544687],
              [131.718613, -1.520834],
              [131.740555, -1.457071],
              [131.713577, -1.428579],
            ],
          ],
          [
            [
              [133.872208, -2.126975],
              [133.863007, -2.102526],
              [133.837692, -2.106504],
              [133.836166, -2.130742],
              [133.872208, -2.126975],
            ],
          ],
          [
            [
              [133.896286, -2.104794],
              [133.873978, -2.110467],
              [133.874084, -2.134626],
              [133.914978, -2.118496],
              [133.896286, -2.104794],
            ],
          ],
          [
            [
              [133.931396, -2.13389],
              [133.881393, -2.14361],
              [133.871109, -2.161939],
              [133.929718, -2.16139],
              [133.931396, -2.13389],
            ],
          ],
          [
            [
              [133.837708, -2.146894],
              [133.775406, -2.194772],
              [133.840256, -2.189849],
              [133.835831, -2.159443],
              [133.859116, -2.152401],
              [133.837708, -2.146894],
            ],
          ],
          [
            [
              [133.853683, -2.157839],
              [133.836441, -2.162299],
              [133.850632, -2.195109],
              [133.862961, -2.171997],
              [133.853683, -2.157839],
            ],
          ],
          [
            [
              [133.921387, -2.168056],
              [133.895279, -2.183055],
              [133.934174, -2.187777],
              [133.921387, -2.168056],
            ],
          ],
          [
            [
              [133.877823, -2.17168],
              [133.852081, -2.198414],
              [133.889481, -2.191091],
              [133.877823, -2.17168],
            ],
          ],
          [
            [
              [133.723892, -2.175554],
              [133.73056, -2.202779],
              [133.674438, -2.231945],
              [133.699448, -2.234167],
              [133.766113, -2.177501],
              [133.723892, -2.175554],
            ],
          ],
          [
            [
              [133.828888, -2.241109],
              [133.806671, -2.24028],
              [133.829437, -2.263333],
              [133.804993, -2.252222],
              [133.815323, -2.290874],
              [133.843887, -2.285279],
              [133.85083, -2.235828],
              [133.871674, -2.2475],
              [133.848053, -2.24028],
              [133.849716, -2.283889],
              [133.888885, -2.265833],
              [133.882507, -2.240833],
              [133.904449, -2.193329],
              [133.854294, -2.205279],
              [133.864716, -2.230279],
              [133.842239, -2.20143],
              [133.763062, -2.239721],
              [133.79277, -2.2775],
              [133.806671, -2.237499],
              [133.828888, -2.241109],
            ],
          ],
          [
            [
              [133.921112, -2.186946],
              [133.912506, -2.240833],
              [133.884171, -2.243609],
              [133.91777, -2.272219],
              [133.934448, -2.2475],
              [133.921112, -2.186946],
            ],
          ],
          [
            [
              [133.751938, -2.214439],
              [133.674728, -2.26417],
              [133.747498, -2.25028],
              [133.751938, -2.214439],
            ],
          ],
          [
            [
              [133.620895, -2.233558],
              [133.60466, -2.21703],
              [133.596176, -2.225513],
              [133.620895, -2.233558],
            ],
          ],
          [
            [
              [133.986664, -2.267221],
              [133.987503, -2.228889],
              [133.954727, -2.233055],
              [133.964996, -2.245699],
              [133.926117, -2.305278],
              [133.82695, -2.330278],
              [133.945282, -2.32389],
              [133.989716, -2.299444],
              [133.986664, -2.267221],
            ],
          ],
          [
            [
              [133.727783, -2.24861],
              [133.712784, -2.257777],
              [133.720276, -2.269443],
              [133.746674, -2.258611],
              [133.727783, -2.24861],
            ],
          ],
          [
            [
              [133.907791, -2.267632],
              [133.866943, -2.290279],
              [133.924438, -2.304036],
              [133.907791, -2.267632],
            ],
          ],
          [
            [
              [133.94194, -2.32722],
              [133.851395, -2.36],
              [133.895538, -2.38916],
              [133.986115, -2.386669],
              [133.94194, -2.32722],
            ],
          ],
          [
            [
              [133.814728, -2.335001],
              [133.804718, -2.353612],
              [133.847198, -2.34589],
              [133.814728, -2.335001],
            ],
          ],
          [
            [
              [133.946106, -2.466666],
              [133.974442, -2.476111],
              [133.985001, -2.449445],
              [133.946945, -2.431666],
              [133.940002, -2.394999],
              [133.838882, -2.394723],
              [133.901108, -2.477777],
              [133.946106, -2.466666],
            ],
          ],
          [
            [
              [133.726669, -2.434444],
              [133.768616, -2.481946],
              [133.822495, -2.44361],
              [133.853882, -2.471945],
              [133.869446, -2.455829],
              [133.833893, -2.408888],
              [133.726669, -2.434444],
            ],
          ],
          [
            [
              [133.295517, -2.411639],
              [133.368332, -2.496388],
              [133.358612, -2.444445],
              [133.295517, -2.411639],
            ],
          ],
          [
            [
              [133.617493, -2.497779],
              [133.597504, -2.45639],
              [133.559357, -2.444075],
              [133.545273, -2.473055],
              [133.543381, -2.437881],
              [133.48555, -2.41722],
              [133.56395, -2.514045],
              [133.617493, -2.497779],
            ],
          ],
          [
            [
              [133.431396, -2.4875],
              [133.419449, -2.486112],
              [133.425003, -2.500555],
              [133.442505, -2.49583],
              [133.431396, -2.4875],
            ],
          ],
          [
            [
              [133.662216, -2.492222],
              [133.606659, -2.515301],
              [133.646942, -2.514722],
              [133.663269, -2.54998],
              [133.684677, -2.51224],
              [133.662216, -2.492222],
            ],
          ],
          [
            [
              [133.696945, -2.49833],
              [133.70639, -2.54861],
              [133.750549, -2.556667],
              [133.734161, -2.504167],
              [133.696945, -2.49833],
            ],
          ],
          [
            [
              [133.469162, -2.563333],
              [133.463608, -2.62139],
              [133.497498, -2.64306],
              [133.513885, -2.621944],
              [133.481674, -2.607501],
              [133.514816, -2.587125],
              [133.473831, -2.502535],
              [133.469162, -2.563333],
            ],
          ],
          [
            [
              [133.765839, -2.512219],
              [133.761108, -2.550279],
              [133.799164, -2.633054],
              [133.800278, -2.538612],
              [133.765839, -2.512219],
            ],
          ],
          [
            [
              [133.556335, -2.52021],
              [133.499847, -2.54526],
              [133.531143, -2.608399],
              [133.568878, -2.590512],
              [133.556335, -2.52021],
            ],
          ],
          [
            [
              [133.788895, -2.627781],
              [133.752502, -2.55694],
              [133.73027, -2.57722],
              [133.693604, -2.538329],
              [133.71167, -2.58806],
              [133.693054, -2.610559],
              [133.753891, -2.62167],
              [133.75, -2.671109],
              [133.796112, -2.648056],
              [133.788895, -2.627781],
            ],
          ],
          [
            [
              [133.399719, -2.549166],
              [133.411942, -2.612223],
              [133.401108, -2.62028],
              [133.41806, -2.641109],
              [133.43306, -2.599444],
              [133.399719, -2.549166],
            ],
          ],
          [
            [
              [133.405533, -2.615578],
              [133.389877, -2.580671],
              [133.394867, -2.664128],
              [133.405533, -2.615578],
            ],
          ],
          [
            [
              [133.516907, -2.594562],
              [133.498611, -2.606391],
              [133.514725, -2.621112],
              [133.512497, -2.64528],
              [133.528336, -2.610833],
              [133.516907, -2.594562],
            ],
          ],
        ],
      },
      properties: {
        cartodb_id: 5,
        country: 'Indonesia',
        id_1: 7,
        state: 'Irian Jaya Barat',
        key: 'indonesia-irianjayabarat',
        name: 'Special Region of West Papua',
        slug: 'special-region-of-west-papua',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [113.592079, -7.714859],
              [113.485832, -7.698609],
              [113.279724, -7.783056],
              [113.254723, -7.740278],
              [113.15139, -7.739722],
              [113.02861, -7.644441],
              [112.987503, -7.656944],
              [112.889252, -7.615961],
              [112.848892, -7.58],
              [112.871948, -7.532499],
              [112.809082, -7.479919],
              [112.838058, -7.47361],
              [112.842781, -7.27778],
              [112.763611, -7.195829],
              [112.718887, -7.1975],
              [112.705276, -7.224166],
              [112.659447, -7.201666],
              [112.659447, -7.221668],
              [112.64917, -7.199166],
              [112.673058, -7.17],
              [112.621948, -7.116388],
              [112.654167, -7.113056],
              [112.623047, -7.109999],
              [112.650558, -7.018889],
              [112.593887, -6.929166],
              [112.618614, -6.909167],
              [112.54528, -6.833331],
              [112.512817, -6.907163],
              [112.430878, -6.867303],
              [112.328835, -6.86293],
              [112.1222, -6.901907],
              [112.046669, -6.881475],
              [111.964157, -6.762219],
              [111.839722, -6.80111],
              [111.692879, -6.754612],
              [111.655281, -6.826329],
              [111.619156, -6.826409],
              [111.609039, -6.903619],
              [111.566597, -6.94502],
              [111.621803, -6.98183],
              [111.604263, -7.140271],
              [111.541611, -7.22794],
              [111.448273, -7.25188],
              [111.420197, -7.30742],
              [111.45295, -7.32056],
              [111.459091, -7.37659],
              [111.20414, -7.24505],
              [111.148041, -7.261489],
              [111.116676, -7.418],
              [111.154701, -7.571369],
              [111.193329, -7.608829],
              [111.179886, -7.70701],
              [111.230743, -7.74767],
              [111.284523, -7.745249],
              [111.314003, -7.850789],
              [111.248383, -7.944731],
              [111.183167, -7.916109],
              [111.14222, -7.93493],
              [111.111771, -8.06415],
              [111.077927, -8.04819],
              [111.026451, -8.083409],
              [110.956131, -8.063708],
              [110.911171, -8.121149],
              [110.901146, -8.192949],
              [110.992226, -8.25361],
              [111.064163, -8.251944],
              [111.093613, -8.22083],
              [111.153221, -8.284841],
              [111.257225, -8.248056],
              [111.291389, -8.274168],
              [111.415001, -8.278334],
              [111.424797, -8.257259],
              [111.454979, -8.274499],
              [111.455276, -8.320834],
              [111.479027, -8.304588],
              [111.533607, -8.33361],
              [111.553886, -8.30583],
              [111.568054, -8.338611],
              [111.606941, -8.339999],
              [111.616821, -8.320239],
              [111.678741, -8.385019],
              [111.726944, -8.371111],
              [111.689163, -8.335814],
              [111.724068, -8.318169],
              [111.712906, -8.291244],
              [111.74662, -8.306268],
              [111.748337, -8.344721],
              [111.782028, -8.325699],
              [111.770279, -8.26778],
              [111.791023, -8.252959],
              [111.899445, -8.304444],
              [111.9375, -8.306666],
              [111.950554, -8.277778],
              [112.034164, -8.320277],
              [112.143646, -8.31587],
              [112.262497, -8.350556],
              [112.331665, -8.331666],
              [112.372154, -8.375492],
              [112.556946, -8.399999],
              [112.653389, -8.447706],
              [112.709167, -8.417779],
              [112.754723, -8.425555],
              [112.771042, -8.386748],
              [112.810555, -8.403056],
              [112.831947, -8.368889],
              [112.847221, -8.408888],
              [112.898613, -8.402224],
              [112.898331, -8.38083],
              [112.912781, -8.399445],
              [112.980835, -8.324722],
              [113.078339, -8.287089],
              [113.236397, -8.283989],
              [113.309723, -8.299236],
              [113.389175, -8.400594],
              [113.480408, -8.377279],
              [113.485458, -8.415411],
              [113.639488, -8.454617],
              [113.646263, -8.49529],
              [113.717636, -8.48497],
              [113.712502, -8.529167],
              [113.796417, -8.48946],
              [113.811012, -8.56172],
              [113.82972, -8.527499],
              [113.836113, -8.554721],
              [113.902222, -8.577498],
              [113.96003, -8.56337],
              [113.956223, -8.615029],
              [114.015091, -8.589911],
              [114.050003, -8.647087],
              [114.091667, -8.615277],
              [114.221123, -8.644735],
              [114.223412, -8.597115],
              [114.254173, -8.585829],
              [114.285072, -8.603959],
              [114.229721, -8.60194],
              [114.317093, -8.623077],
              [114.371567, -8.673268],
              [114.345123, -8.742929],
              [114.531952, -8.780528],
              [114.587502, -8.763888],
              [114.605492, -8.715309],
              [114.570427, -8.66032],
              [114.481377, -8.617821],
              [114.448692, -8.638862],
              [114.423073, -8.604269],
              [114.387184, -8.446678],
              [114.378349, -8.527027],
              [114.352219, -8.527501],
              [114.367226, -8.482499],
              [114.340279, -8.422531],
              [114.431259, -8.059984],
              [114.421944, -7.920556],
              [114.465385, -7.887235],
              [114.451942, -7.80194],
              [114.375832, -7.750555],
              [114.294998, -7.747779],
              [114.240898, -7.698089],
              [114.108032, -7.706919],
              [114.042778, -7.606388],
              [113.931107, -7.70028],
              [113.84111, -7.684166],
              [113.760834, -7.738056],
              [113.71167, -7.708056],
              [113.666946, -7.736666],
              [113.592079, -7.714859],
            ],
          ],
          [
            [
              [114.609726, -5.042778],
              [114.587776, -5.061667],
              [114.603058, -5.097778],
              [114.609726, -5.042778],
            ],
          ],
          [
            [
              [114.415558, -5.42722],
              [114.413887, -5.450561],
              [114.437767, -5.458889],
              [114.415558, -5.42722],
            ],
          ],
          [
            [
              [114.409996, -5.53194],
              [114.394165, -5.568333],
              [114.435837, -5.587781],
              [114.452774, -5.547499],
              [114.409996, -5.53194],
            ],
          ],
          [
            [
              [115.356903, -6.993239],
              [115.404022, -6.987329],
              [115.331825, -6.931931],
              [115.369453, -6.928877],
              [115.376396, -6.89977],
              [115.412163, -6.94238],
              [115.428688, -6.91788],
              [115.437225, -6.946389],
              [115.448318, -6.911443],
              [115.454353, -6.93158],
              [115.476837, -6.924209],
              [115.489937, -6.94994],
              [115.519547, -6.945236],
              [115.548424, -6.966576],
              [115.575668, -6.929155],
              [115.404549, -6.836154],
              [115.232811, -6.832528],
              [115.253052, -6.861667],
              [115.203888, -6.893609],
              [115.203331, -6.938889],
              [115.269127, -6.936193],
              [115.245827, -6.97556],
              [115.292725, -7.012915],
              [115.30275, -6.949225],
              [115.356903, -6.993239],
            ],
          ],
          [
            [
              [113.871948, -6.87],
              [113.506943, -6.896944],
              [112.863609, -6.891109],
              [112.822372, -6.909729],
              [112.772499, -6.999999],
              [112.722778, -7.038334],
              [112.683052, -7.03139],
              [112.672783, -7.072501],
              [112.705559, -7.097779],
              [112.691391, -7.154723],
              [112.721657, -7.17611],
              [112.863892, -7.163055],
              [113.10083, -7.22972],
              [113.150558, -7.223334],
              [113.087776, -7.19162],
              [113.086945, -7.157777],
              [113.095421, -7.144667],
              [113.08889, -7.190555],
              [113.12278, -7.195278],
              [113.108063, -7.162499],
              [113.140831, -7.144166],
              [113.171944, -7.17361],
              [113.14222, -7.212779],
              [113.169823, -7.197956],
              [113.176109, -7.227781],
              [113.472496, -7.222219],
              [113.511673, -7.255],
              [113.5522, -7.234306],
              [113.603889, -7.127777],
              [113.684525, -7.109238],
              [113.892517, -7.13331],
              [113.864777, -7.092849],
              [113.914528, -7.04382],
              [113.948891, -7.049723],
              [113.906387, -7.021667],
              [114.124748, -6.978247],
              [114.099442, -6.93361],
              [114.002197, -6.887376],
              [113.871948, -6.87],
            ],
          ],
          [
            [
              [116.25264, -6.927639],
              [116.236946, -6.947221],
              [116.27079, -6.939819],
              [116.25264, -6.927639],
            ],
          ],
          [
            [
              [115.629448, -7.004165],
              [115.685783, -7.012099],
              [115.57251, -6.945111],
              [115.57209, -6.975179],
              [115.629448, -7.004165],
            ],
          ],
          [
            [
              [115.921097, -6.95136],
              [115.901711, -6.959339],
              [115.935921, -6.96166],
              [115.921097, -6.95136],
            ],
          ],
          [
            [
              [114.177223, -6.964028],
              [114.16098, -6.997401],
              [114.178627, -7.00556],
              [114.187767, -6.974719],
              [114.177223, -6.964028],
            ],
          ],
          [
            [
              [115.463928, -6.972538],
              [115.430069, -6.97847],
              [115.427849, -6.992913],
              [115.476303, -7.006461],
              [115.463928, -6.972538],
            ],
          ],
          [
            [
              [115.545563, -6.987651],
              [115.504219, -7.014089],
              [115.528893, -7.034444],
              [115.555557, -7.018333],
              [115.545563, -6.987651],
            ],
          ],
          [
            [
              [115.6325, -7.03389],
              [115.597191, -7.038191],
              [115.59626, -7.05373],
              [115.633331, -7.063612],
              [115.6325, -7.03389],
            ],
          ],
          [
            [
              [115.659447, -7.040278],
              [115.649338, -7.05989],
              [115.669769, -7.066039],
              [115.659447, -7.040278],
            ],
          ],
          [
            [
              [114.271393, -7.09444],
              [114.315552, -7.162499],
              [114.40667, -7.16889],
              [114.336945, -7.056668],
              [114.271393, -7.09444],
            ],
          ],
          [
            [
              [113.951668, -7.054999],
              [113.941666, -7.09],
              [114.064896, -7.111084],
              [114.051155, -7.074219],
              [113.951668, -7.054999],
            ],
          ],
          [
            [
              [115.686943, -7.056109],
              [115.692497, -7.072779],
              [115.712395, -7.068954],
              [115.686943, -7.056109],
            ],
          ],
          [
            [
              [115.839775, -7.206334],
              [115.894707, -7.19371],
              [115.907333, -7.102989],
              [115.869598, -7.132782],
              [115.885315, -7.163539],
              [115.810204, -7.153508],
              [115.781067, -7.10914],
              [115.774902, -7.149237],
              [115.764839, -7.119756],
              [115.741486, -7.152667],
              [115.839775, -7.206334],
            ],
          ],
          [
            [
              [114.592781, -7.166112],
              [114.615189, -7.141087],
              [114.575546, -7.127499],
              [114.474792, -7.146019],
              [114.549721, -7.14222],
              [114.592781, -7.166112],
            ],
          ],
          [
            [
              [114.677498, -7.158456],
              [114.656487, -7.169879],
              [114.685837, -7.164165],
              [114.677498, -7.158456],
            ],
          ],
          [
            [
              [113.923607, -7.17361],
              [113.882225, -7.206111],
              [113.929733, -7.20361],
              [113.953888, -7.232501],
              [113.923607, -7.17361],
            ],
          ],
          [
            [
              [113.755547, -7.20583],
              [113.792778, -7.236111],
              [113.813126, -7.221522],
              [113.755547, -7.20583],
            ],
          ],
          [
            [
              [112.685837, -5.714719],
              [112.614998, -5.7475],
              [112.568886, -5.808891],
              [112.620552, -5.872499],
              [112.635559, -5.845829],
              [112.690277, -5.868892],
              [112.734169, -5.831388],
              [112.727501, -5.73667],
              [112.685837, -5.714719],
            ],
          ],
          [
            [
              [113.395546, -8.454719],
              [113.294167, -8.444438],
              [113.263535, -8.486839],
              [113.304169, -8.509443],
              [113.400558, -8.496667],
              [113.419998, -8.469723],
              [113.395546, -8.454719],
            ],
          ],
          [
            [
              [112.698059, -8.429439],
              [112.675003, -8.456112],
              [112.706665, -8.464166],
              [112.712502, -8.434166],
              [112.698059, -8.429439],
            ],
          ],
        ],
      },
      properties: {
        cartodb_id: 4,
        country: 'Indonesia',
        id_1: 12,
        state: 'Jawa Timur',
        key: 'indonesia-jawatimur',
        name: 'East Java',
        slug: 'east-java',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [108.924622, 0.558608],
              [108.907219, 0.481111],
              [108.946663, 0.408334],
              [108.917778, 0.324167],
              [109.086372, 0.250868],
              [109.175751, 0.07457],
              [109.348007, -0.023269],
              [109.169235, 0.031522],
              [109.158646, -0.041857],
              [109.193687, -0.096224],
              [109.181473, -0.192989],
              [109.205444, -0.217722],
              [109.087189, -0.281064],
              [109.115837, -0.34431],
              [109.162613, -0.370221],
              [109.099533, -0.34867],
              [109.128685, -0.542469],
              [109.163322, -0.574667],
              [109.193291, -0.564114],
              [109.174377, -0.536785],
              [109.252464, -0.605481],
              [109.327324, -0.580918],
              [109.373093, -0.584957],
              [109.405373, -0.52877],
              [109.386276, -0.583241],
              [109.418449, -0.58944],
              [109.467453, -0.546529],
              [109.443016, -0.584633],
              [109.435997, -0.573479],
              [109.417542, -0.591949],
              [109.381279, -0.590299],
              [109.407097, -0.627438],
              [109.460396, -0.626068],
              [109.469696, -0.60151],
              [109.503052, -0.636666],
              [109.587463, -0.657192],
              [109.615265, -0.620564],
              [109.610832, -0.642223],
              [109.661423, -0.628801],
              [109.694443, -0.571109],
              [109.665276, -0.627499],
              [109.691948, -0.60861],
              [109.756111, -0.610278],
              [109.75972, -0.579166],
              [109.757217, -0.611391],
              [109.691109, -0.610278],
              [109.607559, -0.646099],
              [109.583054, -0.676667],
              [109.648048, -0.68404],
              [109.551666, -0.707777],
              [109.666901, -0.699297],
              [109.578331, -0.71722],
              [109.629448, -0.732777],
              [109.658058, -0.723054],
              [109.605835, -0.734266],
              [109.676941, -0.745832],
              [109.631668, -0.751519],
              [109.635277, -0.796666],
              [109.712219, -0.825556],
              [109.719963, -0.870262],
              [109.749367, -0.866303],
              [109.770134, -0.924965],
              [109.727783, -0.919999],
              [109.73275, -0.98825],
              [109.762489, -0.993449],
              [109.819176, -1.092006],
              [109.956146, -1.113297],
              [109.904793, -1.205901],
              [110.062775, -1.349723],
              [110.074165, -1.405555],
              [110.035553, -1.522222],
              [110.028336, -1.677778],
              [109.943649, -1.777688],
              [109.911804, -1.772984],
              [109.925003, -1.824445],
              [109.900993, -1.829039],
              [110.066948, -1.931388],
              [110.108353, -2.025998],
              [110.133606, -2.03028],
              [110.11264, -2.040069],
              [110.091827, -2.224431],
              [110.062111, -2.245629],
              [110.102379, -2.249945],
              [110.139999, -2.307596],
              [110.147148, -2.427315],
              [110.195496, -2.517949],
              [110.264099, -2.548373],
              [110.29583, -2.4675],
              [110.357498, -2.446389],
              [110.339722, -2.385834],
              [110.368332, -2.353329],
              [110.393608, -2.3525],
              [110.384377, -2.291328],
              [110.398888, -2.306944],
              [110.394997, -2.3525],
              [110.369164, -2.354166],
              [110.341942, -2.387781],
              [110.359444, -2.446945],
              [110.343063, -2.465],
              [110.297501, -2.471389],
              [110.281982, -2.510084],
              [110.362503, -2.528055],
              [110.361115, -2.539168],
              [110.281326, -2.52119],
              [110.2705, -2.549836],
              [110.2444, -2.562205],
              [110.197189, -2.525017],
              [110.148659, -2.620558],
              [110.213829, -2.647901],
              [110.218056, -2.739723],
              [110.259605, -2.763615],
              [110.224182, -2.886907],
              [110.25779, -2.958415],
              [110.30677, -2.996146],
              [110.476173, -2.86667],
              [110.619316, -2.834366],
              [110.596443, -2.853543],
              [110.647278, -2.988716],
              [110.610596, -2.990001],
              [110.660278, -3.041667],
              [110.726944, -3.001944],
              [110.775589, -2.923407],
              [110.969063, -2.85623],
              [111.011696, -2.794629],
              [111.139252, -2.74909],
              [111.172127, -2.694359],
              [111.118568, -2.65266],
              [111.136292, -2.6113],
              [111.10318, -2.424131],
              [111.031776, -2.243429],
              [111.078987, -2.15783],
              [111.037148, -2.07326],
              [111.080872, -2.04857],
              [111.084633, -1.99388],
              [111.004158, -1.990881],
              [110.973938, -1.95433],
              [111.004929, -1.698119],
              [110.976723, -1.666849],
              [110.913467, -1.717321],
              [110.859451, -1.675359],
              [110.857643, -1.629531],
              [110.983383, -1.536631],
              [111.013313, -1.56207],
              [111.058273, -1.535279],
              [111.077171, -1.561929],
              [111.118416, -1.55175],
              [111.106239, -1.491641],
              [111.146736, -1.47199],
              [111.19136, -1.513311],
              [111.184662, -1.4311],
              [111.206253, -1.440369],
              [111.219727, -1.407579],
              [111.278343, -1.424281],
              [111.408928, -1.240509],
              [111.573433, -1.19982],
              [111.553772, -1.110259],
              [111.623146, -1.0463],
              [111.624687, -0.984569],
              [111.730446, -0.92433],
              [111.853928, -0.904665],
              [111.872597, -0.863976],
              [111.918953, -0.871431],
              [111.908051, -0.77366],
              [111.954117, -0.818651],
              [112.0289, -0.79598],
              [112.022758, -0.703299],
              [112.157761, -0.72885],
              [112.147186, -0.68017],
              [112.197029, -0.615789],
              [112.243538, -0.63516],
              [112.3022, -0.732871],
              [112.433113, -0.672569],
              [112.475708, -0.731581],
              [112.488258, -0.69251],
              [112.691406, -0.651499],
              [112.699158, -0.622159],
              [112.743896, -0.62011],
              [112.754082, -0.568529],
              [112.846123, -0.5719],
              [112.992569, -0.487565],
              [113.062393, -0.499649],
              [113.146019, -0.44562],
              [113.21804, -0.503209],
              [113.266602, -0.42323],
              [113.207962, -0.338049],
              [113.28756, -0.24261],
              [113.355873, -0.219359],
              [113.409073, -0.161861],
              [113.397453, -0.124708],
              [113.441429, -0.06608],
              [113.41732, -0.045511],
              [113.440208, 0.014371],
              [113.410477, 0.04983],
              [113.419342, 0.125381],
              [113.270958, 0.24151],
              [113.343407, 0.279291],
              [113.453087, 0.274881],
              [113.658348, 0.443241],
              [113.672935, 0.50568],
              [113.774666, 0.56566],
              [113.845802, 0.70646],
              [113.894852, 0.738571],
              [113.888802, 0.787089],
              [113.923447, 0.824581],
              [113.87458, 0.892771],
              [113.920357, 0.98758],
              [113.896263, 1.05272],
              [113.943588, 1.06366],
              [113.988136, 1.145151],
              [114.118729, 1.15532],
              [114.162727, 1.267369],
              [114.149651, 1.379601],
              [114.204193, 1.407621],
              [114.13726, 1.464041],
              [113.976212, 1.45192],
              [113.823013, 1.37288],
              [113.806946, 1.301171],
              [113.629097, 1.218739],
              [113.538239, 1.322579],
              [113.424042, 1.283389],
              [113.321083, 1.37811],
              [113.172157, 1.379261],
              [113.097412, 1.440809],
              [112.973518, 1.40812],
              [112.977798, 1.448871],
              [113.030907, 1.47269],
              [113.063766, 1.53465],
              [112.99469, 1.579499],
              [112.879753, 1.585801],
              [112.839851, 1.53765],
              [112.493286, 1.58011],
              [112.429108, 1.524921],
              [112.203743, 1.44583],
              [112.194183, 1.411089],
              [112.221977, 1.386969],
              [112.172127, 1.300991],
              [112.145088, 1.149511],
              [111.944458, 1.121131],
              [111.868263, 1.005091],
              [111.724678, 1.012099],
              [111.665512, 1.04635],
              [111.559639, 0.9922],
              [111.554001, 0.953111],
              [111.480698, 1.03549],
              [111.392883, 1.014449],
              [111.234451, 1.083181],
              [110.909462, 1.02729],
              [110.851242, 0.949551],
              [110.802422, 0.945931],
              [110.802811, 0.904469],
              [110.766266, 0.930071],
              [110.75251, 0.902441],
              [110.664963, 0.878961],
              [110.635223, 0.90836],
              [110.621696, 0.87248],
              [110.567879, 0.85529],
              [110.444, 0.91073],
              [110.393257, 0.993581],
              [110.275291, 0.997711],
              [110.277786, 1.04738],
              [110.240021, 1.111871],
              [110.207787, 1.118501],
              [110.189217, 1.178481],
              [110.041298, 1.204711],
              [110.06147, 1.259051],
              [109.978653, 1.298621],
              [109.959183, 1.39434],
              [109.923912, 1.421881],
              [109.83036, 1.42439],
              [109.836273, 1.482401],
              [109.800568, 1.469451],
              [109.661377, 1.619261],
              [109.680527, 1.78126],
              [109.599693, 1.810401],
              [109.537933, 1.92155],
              [109.620117, 1.989331],
              [109.643837, 2.082131],
              [109.58667, 1.989999],
              [109.337273, 1.94229],
              [109.336121, 1.832768],
              [109.293495, 1.780328],
              [109.409996, 1.88306],
              [109.402779, 1.858889],
              [109.370552, 1.842222],
              [109.390457, 1.81425],
              [109.408287, 1.85022],
              [109.445267, 1.86139],
              [109.435738, 1.844253],
              [109.408279, 1.847489],
              [109.428886, 1.834721],
              [109.391708, 1.80917],
              [109.422462, 1.812372],
              [109.436943, 1.772221],
              [109.422066, 1.809717],
              [109.392693, 1.80282],
              [109.365616, 1.829589],
              [109.305641, 1.781201],
              [109.241913, 1.649601],
              [109.050652, 1.510369],
              [109.039703, 1.338214],
              [108.962219, 1.21667],
              [108.970001, 1.175279],
              [108.904243, 1.164868],
              [108.961349, 1.066277],
              [108.971107, 0.943611],
              [108.936386, 0.883055],
              [108.839996, 0.815],
              [108.882225, 0.765834],
              [108.87278, 0.707778],
              [108.897217, 0.693061],
              [108.921669, 0.646111],
              [108.924622, 0.558608],
            ],
          ],
          [
            [
              [108.705002, 0.803333],
              [108.694908, 0.773506],
              [108.726936, 0.73167],
              [108.705002, 0.803333],
            ],
          ],
          [
            [
              [109.49778, -1.31],
              [109.776947, -1.14861],
              [109.761421, -1.005139],
              [109.71434, -0.988797],
              [109.674271, -1.021236],
              [109.643326, -1.01083],
              [109.636223, -0.98541],
              [109.553238, -1.0029],
              [109.489601, -0.972629],
              [109.458611, -1.003055],
              [109.425858, -1.205933],
              [109.379448, -1.256388],
              [109.49778, -1.31],
            ],
          ],
          [
            [
              [109.2603, -1.182289],
              [109.227432, -1.200349],
              [109.223892, -1.242777],
              [109.269363, -1.216544],
              [109.2603, -1.182289],
            ],
          ],
          [
            [
              [109.158249, -1.26604],
              [109.152641, -1.27628],
              [109.176392, -1.29556],
              [109.158249, -1.26604],
            ],
          ],
          [
            [
              [109.145988, -1.287829],
              [109.136108, -1.331112],
              [109.163322, -1.294582],
              [109.145988, -1.287829],
            ],
          ],
          [
            [
              [109.044441, -1.483333],
              [109.053055, -1.501945],
              [109.054878, -1.479671],
              [109.044441, -1.483333],
            ],
          ],
          [
            [
              [108.973053, -1.593055],
              [108.897217, -1.533609],
              [108.876945, -1.567777],
              [108.833611, -1.553056],
              [108.789436, -1.581391],
              [108.840279, -1.58583],
              [108.818611, -1.649721],
              [108.857498, -1.67806],
              [108.958336, -1.641111],
              [108.973053, -1.593055],
            ],
          ],
          [
            [
              [108.769447, -1.574439],
              [108.764168, -1.592499],
              [108.77861, -1.583611],
              [108.769447, -1.574439],
            ],
          ],
          [
            [
              [108.745277, -1.699167],
              [108.684723, -1.702499],
              [108.676613, -1.719789],
              [108.787224, -1.711944],
              [108.745277, -1.699167],
            ],
          ],
          [
            [
              [110.117813, -2.703883],
              [110.098358, -2.674172],
              [110.043961, -2.748019],
              [110.120171, -2.750097],
              [110.117813, -2.703883],
            ],
          ],
          [
            [
              [110.201103, -2.882189],
              [110.166893, -2.852269],
              [110.131187, -2.891759],
              [110.17263, -2.915],
              [110.201103, -2.882189],
            ],
          ],
          [
            [
              [108.861115, 0.512221],
              [108.836937, 0.500281],
              [108.85778, 0.47611],
              [108.861115, 0.512221],
            ],
          ],
          [
            [
              [109.194298, 0.048339],
              [109.179123, 0.035615],
              [109.238937, 0.018492],
              [109.194298, 0.048339],
            ],
          ],
          [
            [
              [109.171043, -0.103818],
              [109.16198, -0.059329],
              [109.124168, -0.11597],
              [109.176346, -0.194459],
              [109.186943, -0.136667],
              [109.171043, -0.103818],
            ],
          ],
          [
            [
              [109.123947, -0.143598],
              [109.113052, -0.117778],
              [109.090836, -0.1525],
              [109.123337, -0.16917],
              [109.123947, -0.143598],
            ],
          ],
          [
            [
              [109.147499, -0.168055],
              [109.12722, -0.146666],
              [109.160278, -0.185559],
              [109.147499, -0.168055],
            ],
          ],
          [
            [
              [109.110878, -0.168969],
              [109.084724, -0.186667],
              [109.17289, -0.201508],
              [109.110878, -0.168969],
            ],
          ],
          [
            [
              [109.146492, -0.205814],
              [109.059647, -0.216191],
              [109.05658, -0.27509],
              [109.094414, -0.328586],
              [109.078018, -0.264679],
              [109.19313, -0.2187],
              [109.146492, -0.205814],
            ],
          ],
          [
            [
              [109.298515, -0.593946],
              [109.281273, -0.612892],
              [109.306602, -0.601791],
              [109.298515, -0.593946],
            ],
          ],
          [
            [
              [109.373016, -0.622112],
              [109.360077, -0.653593],
              [109.476555, -0.685879],
              [109.373016, -0.622112],
            ],
          ],
          [
            [
              [109.48156, -0.62791],
              [109.458176, -0.636258],
              [109.519554, -0.662794],
              [109.486748, -0.664328],
              [109.451736, -0.631559],
              [109.427071, -0.643105],
              [109.492493, -0.6956],
              [109.582588, -0.685852],
              [109.583557, -0.65339],
              [109.546196, -0.655159],
              [109.48156, -0.62791],
            ],
          ],
          [
            [
              [109.377922, -0.655031],
              [109.3489, -0.657873],
              [109.404785, -0.674974],
              [109.377922, -0.655031],
            ],
          ],
          [
            [
              [109.308243, -0.702499],
              [109.247597, -0.66196],
              [109.239723, -0.815141],
              [109.309662, -0.908027],
              [109.405251, -0.933441],
              [109.441368, -0.852097],
              [109.584587, -0.898949],
              [109.612068, -0.971437],
              [109.633728, -0.962418],
              [109.682083, -1.014159],
              [109.721664, -0.973055],
              [109.721016, -0.91287],
              [109.748512, -0.871205],
              [109.716309, -0.870442],
              [109.7118, -0.832339],
              [109.674637, -0.830735],
              [109.55986, -0.762832],
              [109.551666, -0.74333],
              [109.466942, -0.754721],
              [109.39389, -0.706939],
              [109.308243, -0.702499],
            ],
          ],
          [
            [
              [109.444023, -0.670589],
              [109.411629, -0.674908],
              [109.478752, -0.717117],
              [109.444878, -0.708306],
              [109.463112, -0.731745],
              [109.558746, -0.716981],
              [109.444023, -0.670589],
            ],
          ],
          [
            [
              [109.580223, -0.71941],
              [109.570358, -0.71226],
              [109.605263, -0.744351],
              [109.606232, -0.726168],
              [109.580223, -0.71941],
            ],
          ],
          [
            [
              [109.569862, -0.713247],
              [109.550278, -0.732499],
              [109.566673, -0.74444],
              [109.569862, -0.713247],
            ],
          ],
          [
            [
              [109.601585, -0.748656],
              [109.574738, -0.733399],
              [109.566322, -0.757335],
              [109.634735, -0.791805],
              [109.626289, -0.751024],
              [109.601585, -0.748656],
            ],
          ],
          [
            [
              [109.093063, 2.08139],
              [109.101944, 2.059444],
              [109.10833, 2.072221],
              [109.093063, 2.08139],
            ],
          ],
        ],
      },
      properties: {
        cartodb_id: 10,
        country: 'Indonesia',
        id_1: 13,
        state: 'Kalimantan Barat',
        key: 'indonesia-kalimantanbarat',
        name: 'West Kalimantan',
        slug: 'west-kalimantan',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [114.512779, -3.54225],
              [114.556969, -3.557613],
              [114.61496, -3.649973],
              [114.603622, -3.821678],
              [114.638199, -4.039249],
              [114.619507, -4.160951],
              [114.686737, -4.17718],
              [115.062767, -3.988329],
              [115.715927, -3.731917],
              [115.843552, -3.627496],
              [115.968842, -3.60867],
              [116.011391, -3.421945],
              [116.031387, -3.364167],
              [116.060837, -3.334169],
              [116.056503, -3.315501],
              [116.084999, -3.28556],
              [116.078331, -3.258333],
              [116.086388, -3.279167],
              [116.108055, -3.284167],
              [116.183609, -3.232223],
              [116.166107, -3.16472],
              [116.106392, -3.147499],
              [116.068611, -3.18639],
              [116.060837, -3.15306],
              [116.089996, -3.147499],
              [116.06955, -3.135727],
              [116.097221, -3.145833],
              [116.112267, -3.11191],
              [116.108055, -3.142223],
              [116.180267, -3.16139],
              [116.210556, -3.128055],
              [116.274445, -3.129721],
              [116.173889, -3.062501],
              [116.157501, -3.01444],
              [116.109253, -3.0091],
              [116.141937, -3.012499],
              [116.131554, -2.99479],
              [116.049301, -3.035837],
              [116.090279, -2.99639],
              [116.056213, -3.008487],
              [116.084999, -2.989445],
              [116.055832, -2.969439],
              [116.114792, -2.972425],
              [116.077782, -2.946591],
              [116.122124, -2.964762],
              [116.091461, -2.906599],
              [116.105003, -2.9175],
              [116.131348, -2.887178],
              [116.098053, -2.862222],
              [116.132744, -2.864236],
              [116.1157, -2.814533],
              [116.143608, -2.793889],
              [116.154762, -2.899472],
              [116.198891, -2.91056],
              [116.212219, -3.000556],
              [116.269722, -3.00333],
              [116.364998, -2.877779],
              [116.368057, -2.663889],
              [116.415207, -2.577569],
              [116.381943, -2.587223],
              [116.356392, -2.54944],
              [116.31604, -2.610003],
              [116.223053, -2.684722],
              [116.261391, -2.651666],
              [116.223335, -2.623334],
              [116.236664, -2.615277],
              [116.272499, -2.638055],
              [116.269096, -2.60944],
              [116.324234, -2.573471],
              [116.311386, -2.535829],
              [116.294716, -2.580559],
              [116.302223, -2.532777],
              [116.272217, -2.54528],
              [116.229446, -2.527499],
              [116.272781, -2.5425],
              [116.300003, -2.52111],
              [116.399437, -2.528889],
              [116.410278, -2.491388],
              [116.475563, -2.51278],
              [116.480827, -2.57139],
              [116.511948, -2.562221],
              [116.554512, -2.41632],
              [116.386978, -2.35975],
              [116.017441, -2.32992],
              [115.967827, -2.292201],
              [115.959961, -2.319559],
              [115.89679, -2.337991],
              [115.890732, -2.378129],
              [115.835663, -2.3866],
              [115.816231, -2.324439],
              [115.836327, -2.301231],
              [115.782158, -2.197769],
              [115.818848, -2.06488],
              [115.706558, -2.035679],
              [115.729721, -2.00763],
              [115.700058, -1.98661],
              [115.732048, -1.889379],
              [115.7267, -1.78695],
              [115.667618, -1.706629],
              [115.668503, -1.63895],
              [115.629227, -1.606361],
              [115.636803, -1.546889],
              [115.53669, -1.52833],
              [115.647369, -1.38778],
              [115.674507, -1.39444],
              [115.65419, -1.421765],
              [115.67115, -1.443829],
              [115.707825, -1.414875],
              [115.757111, -1.302411],
              [115.689468, -1.36627],
              [115.524307, -1.429798],
              [115.400528, -1.43489],
              [115.31028, -1.806879],
              [115.351013, -1.93042],
              [115.278229, -2.001201],
              [115.326027, -2.10331],
              [115.291229, -2.22627],
              [115.248199, -2.209019],
              [114.875748, -2.46402],
              [114.865448, -2.520449],
              [114.797592, -2.592399],
              [114.760803, -2.72184],
              [114.680443, -2.74145],
              [114.664398, -2.801799],
              [114.59111, -2.820949],
              [114.49762, -3.000579],
              [114.479446, -3.123969],
              [114.397667, -3.256829],
              [114.351906, -3.411489],
              [114.351067, -3.461931],
              [114.435326, -3.502075],
              [114.518059, -3.501941],
              [114.512779, -3.54225],
            ],
          ],
          [
            [
              [116.149437, -2.89861],
              [116.150002, -2.93667],
              [116.203613, -2.942778],
              [116.149437, -2.89861],
            ],
          ],
          [
            [
              [116.135277, -2.97472],
              [116.124443, -2.990833],
              [116.169716, -3.038059],
              [116.176941, -2.998329],
              [116.135277, -2.97472],
            ],
          ],
          [
            [
              [116.17778, -3.037223],
              [116.174164, -3.060557],
              [116.186943, -3.044445],
              [116.17778, -3.037223],
            ],
          ],
          [
            [
              [116.045181, -3.546808],
              [116.005417, -3.682081],
              [116.081665, -3.832222],
              [116.056641, -3.903609],
              [116.077217, -3.930831],
              [116.03318, -4.028957],
              [116.048134, -4.06251],
              [116.068886, -4.037219],
              [116.095963, -4.055996],
              [116.091125, -4.085864],
              [116.108055, -4.036389],
              [116.209526, -3.98563],
              [116.208046, -3.95222],
              [116.272781, -3.907499],
              [116.31028, -3.916944],
              [116.316948, -3.863056],
              [116.295555, -3.862778],
              [116.272224, -3.815832],
              [116.33889, -3.728334],
              [116.300278, -3.713059],
              [116.308334, -3.591112],
              [116.271164, -3.526647],
              [116.312233, -3.506109],
              [116.314163, -3.474999],
              [116.249443, -3.375],
              [116.285278, -3.204441],
              [116.114998, -3.305279],
              [116.11911, -3.346442],
              [116.038986, -3.481638],
              [116.045181, -3.546808],
            ],
          ],
          [
            [
              [116.071716, -3.34868],
              [116.057442, -3.35341],
              [116.057205, -3.386803],
              [116.071716, -3.34868],
            ],
          ],
          [
            [
              [116.39917, -3.61745],
              [116.434647, -3.422796],
              [116.409248, -3.360349],
              [116.312263, -3.555291],
              [116.34185, -3.5659],
              [116.360558, -3.653193],
              [116.39917, -3.61745],
            ],
          ],
          [
            [
              [116.03981, -3.420327],
              [116.018524, -3.431251],
              [116.025688, -3.458072],
              [116.03981, -3.420327],
            ],
          ],
          [
            [
              [116.343056, -3.616944],
              [116.35083, -3.603055],
              [116.332497, -3.591939],
              [116.343056, -3.616944],
            ],
          ],
          [
            [
              [116.321388, -3.815557],
              [116.297081, -3.846599],
              [116.314445, -3.843889],
              [116.321388, -3.815557],
            ],
          ],
          [
            [
              [115.800987, -4.362469],
              [115.796562, -4.371985],
              [115.813164, -4.373197],
              [115.800987, -4.362469],
            ],
          ],
          [
            [
              [115.752777, -4.69028],
              [115.718063, -4.706108],
              [115.710281, -4.726666],
              [115.752777, -4.69028],
            ],
          ],
          [
            [
              [115.855553, -4.753608],
              [115.764442, -4.837501],
              [115.854446, -4.785833],
              [115.835556, -4.77639],
              [115.855553, -4.753608],
            ],
          ],
          [
            [
              [115.676666, -4.89111],
              [115.633888, -4.93889],
              [115.66111, -4.926945],
              [115.676666, -4.89111],
            ],
          ],
        ],
      },
      properties: {
        cartodb_id: 3,
        country: 'Indonesia',
        id_1: 14,
        state: 'Kalimantan Selatan',
        key: 'indonesia-kalimantanselatan',
        name: 'South Kalimantan',
        slug: 'south-kalimantan',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [117.27233051494136, -0.8267141762182177],
              [117.26282872606487, -0.8353682002821756],
              [117.26839299973722, -0.8845819993597388],
              [117.2774709687103, -0.8809178456820064],
              [117.27303061467114, -0.8504031247043713],
              [117.26789724666071, -0.8443712179573026],
              [117.27620810382098, -0.8312452565633066],
              [117.27233051494136, -0.8267141762182177],
            ],
          ],
          [
            [
              [117.47590405612732, -0.8172677550028311],
              [117.46705370556413, -0.8264819559774423],
              [117.48209883279492, -0.839220243919101],
              [117.49335663340196, -0.8349175805818163],
              [117.47590405612732, -0.8172677550028311],
            ],
          ],
          [
            [
              [117.27598533275693, -0.8094139998561332],
              [117.2647892822996, -0.8161223784449589],
              [117.26395544079105, -0.8286752207847599],
              [117.27264436034898, -0.8260247568370005],
              [117.28191885187357, -0.832049146151121],
              [117.2845507151435, -0.8243542841228759],
              [117.27598533275693, -0.8094139998561332],
            ],
          ],
          [
            [
              [117.28657459803935, -0.7958787111335823],
              [117.28025961618835, -0.8132116102275972],
              [117.28790990331947, -0.825478496935034],
              [117.27648751688548, -0.8582137663522076],
              [117.28042856102934, -0.8741160122441443],
              [117.29381868928112, -0.8717610898024191],
              [117.30248398364904, -0.8478515322223643],
              [117.29613854086108, -0.8184210428967731],
              [117.29736828731666, -0.8068412535209859],
              [117.28657459803935, -0.7958787111335823],
            ],
          ],
          [
            [
              [117.44077678611984, -0.7799014267094435],
              [117.40654021006276, -0.7530828572880068],
              [117.410720996377, -0.781957759842669],
              [117.42771628457888, -0.7799489576781866],
              [117.43577957007119, -0.7851477162940341],
              [117.44394981103505, -0.8054317271923992],
              [117.46267303053594, -0.8204396917383292],
              [117.46645213915497, -0.8084321362340461],
              [117.44077678611984, -0.7799014267094435],
            ],
          ],
          [
            [
              [117.47207113297645, -0.7462227504794896],
              [117.470247020084, -0.7640682487506183],
              [117.48246523638159, -0.7702378012335203],
              [117.50087446298619, -0.7993763607304345],
              [117.52583235308066, -0.8137595158888757],
              [117.54439715070805, -0.8108887540127512],
              [117.55264599871396, -0.7921447808569155],
              [117.51760041053058, -0.7780566015308636],
              [117.4980757413025, -0.74966693717937],
              [117.48101066391109, -0.7500000001998046],
              [117.47207113297645, -0.7462227504794896],
            ],
          ],
          [
            [
              [117.46416061621949, -0.7503679784993551],
              [117.44257578904148, -0.7412219605250243],
              [117.43960682029854, -0.7535722368691609],
              [117.45438900195097, -0.7656379919997107],
              [117.46617813011665, -0.7827514385280097],
              [117.49243230120976, -0.80239574097061],
              [117.48239414497391, -0.7724160617518123],
              [117.46707865365704, -0.7619382332628106],
              [117.46416061621949, -0.7503679784993551],
            ],
          ],
          [
            [
              [117.38738846630474, -0.7395510894111794],
              [117.38214946482606, -0.7579825841289107],
              [117.38314251061843, -0.7746217578877577],
              [117.3926877286558, -0.7766466021589054],
              [117.41107011589315, -0.8199402775217663],
              [117.41410053261359, -0.8339262722795979],
              [117.42694712231196, -0.8313358371790969],
              [117.43995302691076, -0.8150355846244679],
              [117.4259518866703, -0.7857271162134225],
              [117.40764649399057, -0.7829929469668855],
              [117.39885485027116, -0.75179178156543],
              [117.38738846630474, -0.7395510894111794],
            ],
          ],
          [
            [
              [117.46009969424551, -0.7026600312353821],
              [117.44237615483576, -0.7116309449121445],
              [117.44689408529382, -0.7116185378652062],
              [117.44769091790158, -0.7142156198549401],
              [117.44557482391906, -0.7162983426943015],
              [117.44640725529064, -0.7181533751780762],
              [117.44915371737022, -0.718488222453459],
              [117.45194792175926, -0.7207140804903815],
              [117.45264982103242, -0.7250585659000421],
              [117.44605935615448, -0.7410420817266186],
              [117.49640629831208, -0.7480226707096449],
              [117.49824683732174, -0.7486902977190084],
              [117.50328058473121, -0.7561023526413919],
              [117.51259119302915, -0.7669924663292136],
              [117.51501532459758, -0.772760921179497],
              [117.55372727519364, -0.784963666440035],
              [117.58380621798267, -0.7807108318393716],
              [117.59805912432921, -0.785301706308644],
              [117.611492279745, -0.774049681147801],
              [117.61680436193183, -0.7585029039858],
              [117.6091108291015, -0.7307825375839911],
              [117.59141892424407, -0.7358680355813476],
              [117.57118099820457, -0.7295033428050033],
              [117.54261492553849, -0.7279567993667229],
              [117.5151735126476, -0.7363204512278116],
              [117.48982472053444, -0.7146541976330809],
              [117.47204180968185, -0.7132021333717375],
              [117.46009969424551, -0.7026600312353821],
            ],
          ],
          [
            [
              [117.40427302637909, -0.702104285284463],
              [117.38670782790405, -0.6947611517057339],
              [117.37919516582429, -0.7121401482492047],
              [117.38283402876743, -0.7206854110028758],
              [117.40124010594616, -0.7353093857195176],
              [117.42168731411834, -0.7584834066838084],
              [117.43910866783085, -0.7565151522524047],
              [117.4304705662637, -0.7220137087655871],
              [117.40427302637909, -0.702104285284463],
            ],
          ],
          [
            [
              [117.36774506228483, -0.6960032188733862],
              [117.36180388813898, -0.6935338927748944],
              [117.36221235481639, -0.6865633438231953],
              [117.35316199952672, -0.6831039999495943],
              [117.34875713633289, -0.693716026173604],
              [117.35726674636328, -0.6961325701619216],
              [117.36465071905548, -0.7048032559654303],
              [117.36544253254692, -0.7196562286519566],
              [117.37506523527247, -0.7158612245158338],
              [117.37806855182225, -0.6938294333816089],
              [117.36774506228483, -0.6960032188733862],
            ],
          ],
          [
            [
              [117.32034899990651, -0.7194399992565081],
              [117.31505400034189, -0.6860739993155676],
              [117.30676400025845, -0.6773979994180763],
              [117.29087899976605, -0.7281540000070663],
              [117.29298244928066, -0.7548404913954982],
              [117.3002002677282, -0.7711160809423063],
              [117.29126732432758, -0.7839641347370048],
              [117.29891413467965, -0.8017297165531545],
              [117.30529761069624, -0.8316389509767532],
              [117.34360285908474, -0.8323501168643475],
              [117.31966699993211, -0.7975979999476976],
              [117.34340320599324, -0.8129941127880329],
              [117.34619340120457, -0.8367994605266063],
              [117.31631299994501, -0.8491039991502021],
              [117.31051999989813, -0.8575089991409754],
              [117.31492399974286, -0.8782449994071726],
              [117.33216499981495, -0.9082259988307442],
              [117.343904999816, -0.9137789995011758],
              [117.36179600018534, -0.8879929992774578],
              [117.37568428809948, -0.8935365777508082],
              [117.39170699997817, -0.8793869999100821],
              [117.39021499952264, -0.8670829999804255],
              [117.3746250000438, -0.8478550000082237],
              [117.37043300008293, -0.8254319992872752],
              [117.3593909998948, -0.8130839991272296],
              [117.3485019995677, -0.7876979993631608],
              [117.32893099996579, -0.7665479997903049],
              [117.32748599987906, -0.7438299998545403],
              [117.32034899990651, -0.7194399992565081],
            ],
          ],
          [
            [
              [117.45409800022264, -0.6810959999803572],
              [117.43498377977767, -0.6630016979699462],
              [117.42771636731652, -0.6818455624227227],
              [117.40544948890283, -0.7010769141681976],
              [117.4218069176552, -0.7085464996212067],
              [117.44056015382807, -0.7295811602422759],
              [117.4464073614107, -0.7189108957211943],
              [117.44530150466073, -0.7173551018564126],
              [117.44545586429672, -0.7152606014955154],
              [117.44618077452321, -0.7117777025788428],
              [117.44027400034486, -0.7137659993013585],
              [117.43668300022034, -0.7114139996563154],
              [117.44798399976185, -0.7015519999247886],
              [117.46709094199343, -0.6991813789174444],
              [117.45409800022264, -0.6810959999803572],
            ],
          ],
          [
            [
              [117.49393087481008, -0.6598851217009383],
              [117.4749447160259, -0.6753022292144806],
              [117.47433399721626, -0.6862803496583751],
              [117.5089966765554, -0.6939895261956508],
              [117.52820665501315, -0.6898391819326548],
              [117.53061827611259, -0.6709076551641147],
              [117.52292731984005, -0.6602614457091249],
              [117.50888831634279, -0.6663034401515802],
              [117.49393087481008, -0.6598851217009383],
            ],
          ],
          [
            [
              [117.32948286804003, -0.6346442176564437],
              [117.3206941399227, -0.6396161861380278],
              [117.32350335039939, -0.6409729249604652],
              [117.32471091777472, -0.6424829649168373],
              [117.32341919633893, -0.6461198970155806],
              [117.3254330095258, -0.6497595846370814],
              [117.32499172838561, -0.6542698159991573],
              [117.34881011989114, -0.6789523013076177],
              [117.36318254523837, -0.6868217918921573],
              [117.36422939387148, -0.6945199184594344],
              [117.37543373517883, -0.6920774434192367],
              [117.3833787355403, -0.693803937601615],
              [117.36789652970117, -0.6637400503630033],
              [117.34471095064987, -0.6426290633805252],
              [117.32948286804003, -0.6346442176564437],
            ],
          ],
          [
            [
              [117.36430914215327, -0.6458856784812497],
              [117.3606669571144, -0.6514428025432721],
              [117.37745607393867, -0.671117924752366],
              [117.4108938145979, -0.6961213996827951],
              [117.41512415985221, -0.6763369756708357],
              [117.42503598292433, -0.6810147075626674],
              [117.43252910993056, -0.6599362652464151],
              [117.41216459621921, -0.6385421779791045],
              [117.39558571301029, -0.6304718894660937],
              [117.37979116902727, -0.6341310492085199],
              [117.37483918258226, -0.6309133082025369],
              [117.36972196819431, -0.6518485829457177],
              [117.36430914215327, -0.6458856784812497],
            ],
          ],
          [
            [
              [117.49051676863405, -0.6275616284663101],
              [117.46417615920245, -0.6263827215852871],
              [117.45915511381747, -0.6320157538239073],
              [117.47228643427161, -0.6597442482983524],
              [117.4889799999271, -0.647026999996001],
              [117.49796321003419, -0.6600572213636156],
              [117.51029246832286, -0.6630001835115991],
              [117.51313186364462, -0.6423903599271057],
              [117.49833454100633, -0.6390900009027973],
              [117.49051676863405, -0.6275616284663101],
            ],
          ],
          [
            [
              [117.32256377110127, -0.6328287571413966],
              [117.31141955306998, -0.6192444283876171],
              [117.30044300014436, -0.6326759488359244],
              [117.30713899956527, -0.6632999482339983],
              [117.31995199958521, -0.6878529482548856],
              [117.32364199977849, -0.7163329486066345],
              [117.33884000006606, -0.7377679999931388],
              [117.33201800023062, -0.7500089997155328],
              [117.3380240002075, -0.7630279993426257],
              [117.3538909998698, -0.7777639999086432],
              [117.36400200029209, -0.8074109992181775],
              [117.37644400029046, -0.818536999682749],
              [117.38567899988595, -0.8405479993905942],
              [117.40195899970809, -0.8609379998887334],
              [117.40998048021504, -0.8844199981843417],
              [117.41957248997846, -0.8649960008647213],
              [117.43624818970227, -0.8638187361457312],
              [117.43416527800525, -0.8441427739697929],
              [117.41123638255306, -0.8383775279005476],
              [117.38946799834946, -0.7768419286120434],
              [117.37322691758823, -0.7804720213666201],
              [117.3784596319066, -0.7653818219134791],
              [117.37230205659466, -0.7516663594149122],
              [117.3870199807875, -0.7310498376797909],
              [117.37512945406115, -0.7178135942219228],
              [117.36831389517022, -0.720463876506642],
              [117.36349862995792, -0.7198897726947848],
              [117.35660690928148, -0.6964438048376564],
              [117.3506383318936, -0.6950199891811053],
              [117.34845059881616, -0.693853211456485],
              [117.34723175753959, -0.6914057172999719],
              [117.34686000028887, -0.686086948653724],
              [117.35188900017727, -0.6835169488480801],
              [117.33161276661599, -0.6703360928363509],
              [117.32484262438766, -0.6549989593344776],
              [117.32498829567339, -0.650286370619483],
              [117.32018093190459, -0.6483103712266143],
              [117.32340513633801, -0.6455142863551941],
              [117.32341178412662, -0.6413242756947284],
              [117.32137420275728, -0.641679657190366],
              [117.32001872298576, -0.6400615798779654],
              [117.3224629597978, -0.6368250996985694],
              [117.32256377110127, -0.6328287571413966],
            ],
          ],
          [
            [
              [117.49987228997429, -0.6140052686282047],
              [117.49178401232234, -0.6028295545402784],
              [117.47875180974825, -0.6228985887987619],
              [117.5008292972343, -0.6244348376975495],
              [117.50189120501511, -0.6391175552309392],
              [117.51512126023806, -0.638814635686856],
              [117.52610819763527, -0.6535776626789698],
              [117.55738762923579, -0.65293402688377],
              [117.56877429369877, -0.6263153371830299],
              [117.56125979160618, -0.6193266812812794],
              [117.5102837799725, -0.6077073163395426],
              [117.49987228997429, -0.6140052686282047],
            ],
          ],
          [
            [
              [117.37279536171661, -0.5990033861972393],
              [117.36326799971846, -0.5885759485211679],
              [117.33821299993008, -0.5998049483396244],
              [117.34486500001981, -0.6084809482371156],
              [117.34563400040884, -0.6063459487065188],
              [117.34877300037374, -0.605756948524288],
              [117.3520440001306, -0.6080729483078926],
              [117.3628771543572, -0.6053097813212958],
              [117.37511576188297, -0.6223493728252265],
              [117.38039439918225, -0.6201371979732357],
              [117.38397069808934, -0.6206635549790462],
              [117.38900294902692, -0.6309465831182592],
              [117.3987995581532, -0.6300075011452009],
              [117.41416098953812, -0.638106600339313],
              [117.42315469653204, -0.6472258787712235],
              [117.45474352639269, -0.6665917376185462],
              [117.46327657365498, -0.6812977754719327],
              [117.47571923735302, -0.6676043661485096],
              [117.45722173779302, -0.6341748093199726],
              [117.42143521436367, -0.6305453847616604],
              [117.41170850414426, -0.6182906766733254],
              [117.39488341893468, -0.6238932524715892],
              [117.37974533597855, -0.6127252887410464],
              [117.37279536171661, -0.5990033861972393],
            ],
          ],
          [
            [
              [117.43294580900192, -0.5873717554021596],
              [117.42382077131163, -0.584589777296344],
              [117.41632271512128, -0.5973876983834998],
              [117.40788067924996, -0.5965027591949479],
              [117.4086524891211, -0.6057416348684797],
              [117.42198199497318, -0.6290672626440141],
              [117.4335258510373, -0.6232256785222035],
              [117.44391734910641, -0.6283832266045692],
              [117.44691897493078, -0.6088549178201674],
              [117.43410197562571, -0.6050794307710703],
              [117.42469297430137, -0.5927664861843596],
              [117.43294580900192, -0.5873717554021596],
            ],
          ],
          [
            [
              [117.34614492324965, -0.5898620896636544],
              [117.32639949564032, -0.5811830212793438],
              [117.30634540361757, -0.577715232771709],
              [117.2904780909912, -0.5920159488769059],
              [117.3257983833887, -0.6299735238590074],
              [117.3677068428965, -0.6436299116910504],
              [117.37495003751428, -0.6303436101694047],
              [117.38600310386016, -0.6314013747735885],
              [117.38588866512987, -0.6272841930110644],
              [117.38175550870983, -0.6206634767380024],
              [117.37649597480947, -0.6239959127810835],
              [117.37134197635123, -0.6213549609626057],
              [117.35778034828456, -0.608445911549552],
              [117.35055499981343, -0.6081369485612527],
              [117.34879399954355, -0.606136948960966],
              [117.34527900022579, -0.6081369485612527],
              [117.34828300026027, -0.6108689486430308],
              [117.34321500037208, -0.6124529488444637],
              [117.34242499991387, -0.6070149489806909],
              [117.3376659998861, -0.5994429487330422],
              [117.34614492324965, -0.5898620896636544],
            ],
          ],
          [
            [
              [117.47524392226921, -0.5558609543195416],
              [117.45466544455473, -0.5548180186365812],
              [117.44630974899576, -0.5653525286961667],
              [117.417699292988, -0.5649261978856543],
              [117.38717625957679, -0.5752487611213724],
              [117.37405163817266, -0.5921027595346686],
              [117.38751365283292, -0.5933040541387413],
              [117.3910490002113, -0.6008801705833662],
              [117.3997605179336, -0.593132491371307],
              [117.41885100796776, -0.5921865646581637],
              [117.42374411130174, -0.5834084368498225],
              [117.43292111361859, -0.5862746031903612],
              [117.43341828132873, -0.6041411545905362],
              [117.44878556010553, -0.6089146031263795],
              [117.45580645490406, -0.6286455021881352],
              [117.47249599969075, -0.6090619993117912],
              [117.46324331222911, -0.595798989331854],
              [117.47314867187379, -0.5894464895637839],
              [117.50132292611227, -0.5890682949657844],
              [117.50470429516713, -0.570377070645236],
              [117.48057842095739, -0.565766276192619],
              [117.47524392226921, -0.5558609543195416],
            ],
          ],
          [
            [
              [117.36095671597968, -0.5831391249706144],
              [117.38740491040835, -0.5717700782265069],
              [117.41829604352768, -0.5530586857097433],
              [117.41304195772022, -0.5408281163244624],
              [117.39720403853562, -0.5452315594888546],
              [117.38647016766413, -0.5520730278478823],
              [117.38046780274692, -0.5612329268580538],
              [117.37008338414091, -0.5694046004418851],
              [117.3525696223902, -0.5740452343999891],
              [117.36095671597968, -0.5831391249706144],
            ],
          ],
          [
            [
              [117.50361612088739, -0.5273191174832164],
              [117.4856976875983, -0.5262515925369371],
              [117.43735409771352, -0.5436885180106401],
              [117.44005422492262, -0.5619211312551329],
              [117.45319728512663, -0.5533404271189966],
              [117.47354157398456, -0.5483389258007492],
              [117.4833721280213, -0.5635494905165501],
              [117.49855479217001, -0.5562720623057658],
              [117.49817778467707, -0.5451925334086241],
              [117.51025978336236, -0.5391375491585109],
              [117.50361612088739, -0.5273191174832164],
            ],
          ],
          [
            [
              [117.55598244213604, -0.5080365628370487],
              [117.5487961386309, -0.5053761604813758],
              [117.53193523162565, -0.5291658384326752],
              [117.52763651181567, -0.5320721226303249],
              [117.52365954964853, -0.5283014496521332],
              [117.52010708637295, -0.5270139541306094],
              [117.5162171471037, -0.5277563183993266],
              [117.50832772574404, -0.5272902069773977],
              [117.50503316464892, -0.5264929094201989],
              [117.51378839212066, -0.5403064339946582],
              [117.49842572686589, -0.5458503218348483],
              [117.49871129938697, -0.5564932379725178],
              [117.51102500030356, -0.5676399992864845],
              [117.50873523464338, -0.5962416644212567],
              [117.51956899681181, -0.6025790888538154],
              [117.5328437177634, -0.5973813554651315],
              [117.55245560324352, -0.5978150508238969],
              [117.57807884686133, -0.5775543026888954],
              [117.58376805255364, -0.5596689014985827],
              [117.5858256393418, -0.5222478053918849],
              [117.58254482888083, -0.5126495894069762],
              [117.56855379791955, -0.5062526676264838],
              [117.55598244213604, -0.5080365628370487],
            ],
          ],
          [
            [
              [117.53878255996722, -0.5163095207677202],
              [117.5356877239094, -0.5047562389092377],
              [117.51535850409175, -0.511261209736972],
              [117.50137233216742, -0.521837613814796],
              [117.5015014379411, -0.5235844137895924],
              [117.50779904219212, -0.5268598067346488],
              [117.52313144256209, -0.5269010595362147],
              [117.53075381024018, -0.5284211053497074],
              [117.53878255996722, -0.5163095207677202],
            ],
          ],
          [
            [
              [117.55093414099792, -0.44031768021670814],
              [117.54344448087818, -0.4420416706864785],
              [117.5189316886466, -0.46255721294500063],
              [117.49382342381205, -0.47532036444323467],
              [117.47665134688225, -0.49522068768453664],
              [117.45793899209093, -0.5027234122556479],
              [117.42352010906586, -0.5283546751281278],
              [117.41558863270927, -0.5404661796704318],
              [117.42186662184713, -0.5494421403424781],
              [117.41912890117794, -0.561529424343405],
              [117.43623494178917, -0.5639984644575406],
              [117.43607744711653, -0.5433328838054763],
              [117.49660368601133, -0.516190089001384],
              [117.50974536036017, -0.506537823761505],
              [117.56091125574994, -0.488890198823583],
              [117.57496345140225, -0.48759643143012],
              [117.58751701589767, -0.47887050476992954],
              [117.59122399978787, -0.4644140000060588],
              [117.56292599973517, -0.465763999309047],
              [117.56575599983955, -0.451358999618094],
              [117.55093414099792, -0.44031768021670814],
            ],
          ],
          [
            [
              [117.6201877670519, -0.41765194797898175],
              [117.6065922705169, -0.40767010728956166],
              [117.59328939089278, -0.41068709533311676],
              [117.55237845130455, -0.438312882733328],
              [117.56627599953777, -0.45024599955320355],
              [117.56342100007919, -0.4642739998451475],
              [117.59149599974069, -0.462794999989228],
              [117.59232900038307, -0.4753369992030798],
              [117.60422915794936, -0.47745683616403767],
              [117.62043468940942, -0.4612744019920001],
              [117.62628208764829, -0.44138283994607264],
              [117.62656581565989, -0.4219226215317349],
              [117.6201877670519, -0.41765194797898175],
            ],
          ],
          [
            [
              [117.5240086421851, -0.3505724464611717],
              [117.51475951424027, -0.35244002988343937],
              [117.49619507814043, -0.3852629975892228],
              [117.4693633282551, -0.41839947795347143],
              [117.4570293026602, -0.46496268717822886],
              [117.44384330702303, -0.47774748355948304],
              [117.44262639659098, -0.5003870293379009],
              [117.46871326320661, -0.49096169452656113],
              [117.48457539214064, -0.4767329889362486],
              [117.49831434223313, -0.45302872679428674],
              [117.50996624104926, -0.4423791763578606],
              [117.5374969413308, -0.39409697959189316],
              [117.53965540867023, -0.37699761734609183],
              [117.5240086421851, -0.3505724464611717],
            ],
          ],
          [
            [
              [117.50990888678575, -0.3501423681756819],
              [117.4984149853633, -0.35108867170481517],
              [117.48539510799787, -0.37823225161707796],
              [117.4975642752712, -0.37887466073698306],
              [117.50990888678575, -0.3501423681756819],
            ],
          ],
          [
            [
              [117.48890789318477, -0.3287690489523811],
              [117.47727527853135, -0.32916648094828815],
              [117.45809588691566, -0.35298406036605456],
              [117.46495516724724, -0.36211049200557],
              [117.46726679722497, -0.37561838468064934],
              [117.46144889315201, -0.3878845177562198],
              [117.455051054063, -0.3884126770033731],
              [117.44563369713751, -0.3813884718005056],
              [117.43080075505088, -0.39825644747702427],
              [117.4391845067596, -0.4238768222575118],
              [117.43415607164548, -0.43366575567006294],
              [117.44485089755096, -0.44082488436089307],
              [117.45297563102065, -0.4388097563651172],
              [117.45558585030676, -0.42471312341189105],
              [117.47892260719266, -0.3825886467486157],
              [117.4938736788273, -0.3466945104432284],
              [117.48890789318477, -0.3287690489523811],
            ],
          ],
          [
            [
              [118.01303441660514, 0.7437243441032706],
              [117.99684531575883, 0.7326065103821975],
              [118.0011710296194, 0.7199569693387389],
              [118.01626202407317, 0.7138469915454948],
              [118.02252499183498, 0.7352099838537356],
              [118.01303441660514, 0.7437243441032706],
            ],
          ],
          [
            [
              [118.00525658312483, 0.9293443235816881],
              [117.99262787397731, 0.9322682066124912],
              [118.00042600155882, 0.9031229984277047],
              [118.00968238073733, 0.9045979288513877],
              [118.00525658312483, 0.9293443235816881],
            ],
          ],
          [
            [
              [117.95482427192269, 1.0458957529866666],
              [117.94626175387691, 1.0350906710976346],
              [117.95400879367094, 1.0098108551868563],
              [117.95971713873507, 1.0057334657269053],
              [117.96562935381166, 0.992074210856174],
              [117.97772511212429, 0.9761738850168058],
              [117.98710317317295, 0.9786695864323178],
              [117.9745996118827, 1.01184955081618],
              [117.9745996118827, 1.0230623718310312],
              [117.95482427192269, 1.0458957529866666],
            ],
          ],
          [
            [
              [118.46831485704513, 1.4661547232619228],
              [118.46116464603165, 1.4514622453863808],
              [118.47839640467043, 1.4389145543632367],
              [118.48053712658714, 1.4304436736366597],
              [118.46577114352351, 1.4229770838254012],
              [118.480182371919, 1.3930329161362351],
              [118.50434920125474, 1.3802271196858555],
              [118.52007267901104, 1.3859830352889162],
              [118.51611801463469, 1.4059530586867481],
              [118.49811502798616, 1.4159393717046669],
              [118.48872569033836, 1.4457652461692305],
              [118.46831485704513, 1.4661547232619228],
            ],
          ],
          [
            [
              [117.87218007335173, 1.9497810436602663],
              [117.87288454918269, 1.971959414395485],
              [117.85648343586195, 1.9582804077147102],
              [117.84899129361338, 1.957442945535547],
              [117.84315496782756, 1.9494309555742007],
              [117.83673880055528, 1.9539753863550686],
              [117.82733942737275, 1.9496740486184763],
              [117.82649989765218, 1.9357974950584094],
              [117.83599829518528, 1.9117298024884235],
              [117.85979232855323, 1.8928410888452163],
              [117.8709449039842, 1.90694008971343],
              [117.87218007335173, 1.9497810436602663],
            ],
          ],
          [
            [
              [117.85020899275082, 1.9858463973933453],
              [117.8384927861133, 2.0068604518484108],
              [117.8288007690403, 2.0103513232550085],
              [117.82057979192393, 1.996473754360295],
              [117.82164755069402, 1.98749931713985],
              [117.81974003017831, 1.9858176667519842],
              [117.81188940377308, 1.9877393713749143],
              [117.81616224379263, 1.9700246300326967],
              [117.82092261708522, 1.9657162542018227],
              [117.82436395203479, 1.9576905100090585],
              [117.83685317633308, 1.9545814277906857],
              [117.84327688082351, 1.9505073954999261],
              [117.84791578718387, 1.9586375941497067],
              [117.86314512434808, 1.967372661519164],
              [117.85020899275082, 1.9858463973933453],
            ],
          ],
          [
            [
              [117.8829344640543, 2.053160440302122],
              [117.87841797790736, 2.0562748096348287],
              [117.8703234427727, 2.0469436473314886],
              [117.86318982604973, 2.0426415496678487],
              [117.86413591464077, 2.036896721806329],
              [117.8587801649727, 2.0342671617001997],
              [117.86081792530706, 2.0253633617298306],
              [117.88388083952918, 2.039880216398899],
              [117.8829344640543, 2.053160440302122],
            ],
          ],
          [
            [
              [117.85642236200249, 2.077834840705407],
              [117.83299990961825, 2.095315707054283],
              [117.81325510793693, 2.089943609510044],
              [117.81265278879528, 2.0814404206389554],
              [117.81919858524657, 2.081084212666326],
              [117.82335656925034, 2.0786847746826425],
              [117.82442459691765, 2.074857647662384],
              [117.82406631870572, 2.072107773554933],
              [117.8213270871754, 2.0663648306724554],
              [117.8159715245663, 2.059899521745592],
              [117.80538179793166, 2.055716500616086],
              [117.80657175028614, 2.0494920436229904],
              [117.806327605035, 2.046742092173872],
              [117.80299393961866, 2.040999373222519],
              [117.80168178288807, 2.0262816846520764],
              [117.82595053239118, 2.032493242669034],
              [117.84272779165428, 2.041937824770855],
              [117.86366243957002, 2.037376424682293],
              [117.86247246922915, 2.044197878629234],
              [117.8693770978748, 2.0469441635423777],
              [117.87830366508217, 2.056871918104605],
              [117.88246138108798, 2.05639921555138],
              [117.88531485979297, 2.0458589048645877],
              [117.8842389621584, 2.0411917058326026],
              [117.89281636938006, 2.0384183935940428],
              [117.91050702417988, 2.0455221213488812],
              [117.8771211312353, 2.0699170969965053],
              [117.85642236200249, 2.077834840705407],
            ],
          ],
          [
            [
              [117.77761854735809, 2.0836202675613436],
              [117.77130144400655, 2.0990293585181234],
              [117.75522594731967, 2.0951834806331817],
              [117.73557262827421, 2.08372199707253],
              [117.71241768282982, 2.0758532052612395],
              [117.72198503588072, 2.0621162921332257],
              [117.705497823266, 2.050308990467556],
              [117.71440142808353, 2.032628170653595],
              [117.72406021700544, 2.0466096921836083],
              [117.7414701448314, 2.052138110785222],
              [117.76068878735771, 2.0258221086034496],
              [117.78466784634406, 2.021956861120316],
              [117.78908536659651, 2.034520074441957],
              [117.7798538842494, 2.050862305550993],
              [117.75221261861748, 2.0561496743680436],
              [117.751053054355, 2.0629350340246333],
              [117.75621815764191, 2.072232280195635],
              [117.76113149212472, 2.0731526427803146],
              [117.77878563624813, 2.0729721281612115],
              [117.77761854735809, 2.0836202675613436],
            ],
          ],
          [
            [
              [117.82750694319088, 2.096159935732487],
              [117.82584407604338, 2.106962042664179],
              [117.79562377421792, 2.1097460307547635],
              [117.778457784799, 2.091996774331733],
              [117.77978528666176, 2.0727273803643698],
              [117.7687835617121, 2.0651066935191125],
              [117.75662997609254, 2.0719787792972966],
              [117.75212866060929, 2.058076599555477],
              [117.77078236251612, 2.0553899145130003],
              [117.80149841382081, 2.0418866596417047],
              [117.80525228026863, 2.047330647191643],
              [117.80474860596348, 2.0537809122593558],
              [117.80508401351642, 2.0569650411038083],
              [117.80633534819788, 2.057715250158708],
              [117.81499493456863, 2.0601442713410734],
              [117.81991604099255, 2.065415705070791],
              [117.82308203680657, 2.071610746139072],
              [117.82366196462806, 2.0758893193366816],
              [117.8135071357458, 2.079495026074653],
              [117.8090970537861, 2.0846084597126833],
              [117.80976886174369, 2.0883713670449993],
              [117.82467633784279, 2.0953201371146974],
              [117.82750694319088, 2.096159935732487],
            ],
          ],
          [
            [
              [117.78316512866786, 2.098996288647868],
              [117.78923030583405, 2.1093422225655445],
              [117.77770206020193, 2.1195161602426538],
              [117.77150721698456, 2.1049184403337904],
              [117.78316512866786, 2.098996288647868],
            ],
          ],
          [
            [
              [117.89746078678593, 2.0794854464962214],
              [117.91421621410711, 2.0889368184794534],
              [117.89795663339203, 2.103538683363581],
              [117.90001693613272, 2.1232126913126876],
              [117.88015763096996, 2.139380556467529],
              [117.86445602652452, 2.1453690412483297],
              [117.85053225675836, 2.128623349987663],
              [117.83206203363602, 2.1124316842748385],
              [117.86415850471133, 2.0860807002641764],
              [117.89746078678593, 2.0794854464962214],
            ],
          ],
          [
            [
              [118.53199678372471, 2.162427242423348],
              [118.51624525161708, 2.160980249439035],
              [118.50516439532066, 2.1377935965972483],
              [118.52829263809485, 2.1406046704692017],
              [118.53976482898372, 2.137683607712347],
              [118.53199678372471, 2.162427242423348],
            ],
          ],
          [
            [
              [117.80384082489422, 2.1650779608792163],
              [117.79647071794932, 2.1804063151993205],
              [117.7795793769867, 2.1808856151790224],
              [117.77576458506758, 2.1714341640554835],
              [117.76790637557883, 2.1646896066400814],
              [117.75149528158204, 2.169058243343329],
              [117.7311551583839, 2.168109341672846],
              [117.71354676816884, 2.161893811350353],
              [117.72365559403045, 2.1505175988081646],
              [117.72904958467063, 2.1225343425858796],
              [117.72606661977875, 2.108414228334027],
              [117.7339096352215, 2.0860296178726117],
              [117.74961872922108, 2.098352434317462],
              [117.77114869505624, 2.103842103830061],
              [117.7661593408809, 2.1177307640635377],
              [117.77960178179683, 2.1209535691519363],
              [117.78792558919463, 2.1131694862828],
              [117.80236829566036, 2.1207157092626403],
              [117.80384082489422, 2.1650779608792163],
            ],
          ],
          [
            [
              [117.77448248817632, 2.172285180816516],
              [117.77681739081004, 2.180498059537854],
              [117.75258649467105, 2.184445621068619],
              [117.73043840410912, 2.181778868599963],
              [117.73692315907658, 2.1714627553019454],
              [117.75374606522882, 2.1699527108489747],
              [117.76806665185461, 2.1660102961383245],
              [117.77448248817632, 2.172285180816516],
            ],
          ],
          [
            [
              [117.83913402521512, 2.19038811272128],
              [117.84542848183901, 2.168963165217974],
              [117.86636345710792, 2.157336007650656],
              [117.88338498572853, 2.160573717203306],
              [117.88183570115677, 2.1754011932105186],
              [117.83913402521512, 2.19038811272128],
            ],
          ],
          [
            [
              [117.87445819459003, 2.152790195511159],
              [117.84198762353003, 2.169562145176883],
              [117.82713334502796, 2.190630023157098],
              [117.81118778598761, 2.1908739921410643],
              [117.80166627407937, 2.1819052260453873],
              [117.80760933140562, 2.1696895485338814],
              [117.81056224455199, 2.122900617569485],
              [117.82970423965901, 2.1172726916814213],
              [117.85279110282715, 2.13305472519545],
              [117.8666002755823, 2.147765047419057],
              [117.88027974091733, 2.1426099490899446],
              [117.9004898770063, 2.127635939347158],
              [117.9014284463658, 2.1072998343199743],
              [117.91281779800306, 2.0985121763959],
              [117.94796507612739, 2.114654210323579],
              [117.95677425033148, 2.129502409408701],
              [117.94610376008052, 2.136863361255166],
              [117.9132234183262, 2.142970484600255],
              [117.89027422787342, 2.1542102690887077],
              [117.87445819459003, 2.152790195511159],
            ],
          ],
          [
            [
              [117.91788497860898, 2.156676853154295],
              [117.92680339436117, 2.1737005770200426],
              [117.90552498329146, 2.2056642038400014],
              [117.89266966926994, 2.18903627250603],
              [117.90538026708577, 2.164857566723356],
              [117.91788497860898, 2.156676853154295],
            ],
          ],
          [
            [
              [118.57917498084078, 2.3057793924050998],
              [118.56706370382688, 2.3108813713782297],
              [118.56047444853698, 2.276544886037698],
              [118.56460168382068, 2.2417160191789662],
              [118.5966102893334, 2.1964300208288705],
              [118.62612267811915, 2.186464377828827],
              [118.60638138739114, 2.210395570399328],
              [118.59276398966642, 2.214950711206427],
              [118.57231917728825, 2.248437427145575],
              [118.56420997511032, 2.276858697271109],
              [118.56920212437865, 2.3043654791835593],
              [118.57917498084078, 2.3057793924050998],
            ],
          ],
          [
            [
              [118.20380603472074, 2.3826490288296895],
              [118.19397043728588, 2.3788314984749377],
              [118.21317826636391, 2.3495085536768556],
              [118.22035936729105, 2.360793750331368],
              [118.20380603472074, 2.3826490288296895],
            ],
          ],
          [
            [
              [117.98593392680937, 2.435721765746962],
              [117.97195792351943, 2.425344374443398],
              [117.95837634399311, 2.4277689214985685],
              [117.95187568091796, 2.414129836622692],
              [117.91935357240948, 2.395732575498016],
              [117.89868407341135, 2.404681319087672],
              [117.85696421072976, 2.39190943966895],
              [117.83017831727557, 2.3663867762288078],
              [117.82104078440477, 2.363132129744031],
              [117.81899427186818, 2.3391406223419153],
              [117.80901027460459, 2.3267096480318514],
              [117.79783086700093, 2.325750083999253],
              [117.74173198514302, 2.336141771825339],
              [117.72082870917768, 2.346972762283201],
              [117.70835577789694, 2.3899416690728117],
              [117.69970757424517, 2.403711459616204],
              [117.68108063166585, 2.4157958280151206],
              [117.66725055057248, 2.448952677124396],
              [117.63791506922507, 2.478019859229164],
              [117.6223814959094, 2.5102690531743406],
              [117.61178313308517, 2.521811781488566],
              [117.59039793561635, 2.5202241804016694],
              [117.57448505703985, 2.504078438569138],
              [117.5407941239497, 2.5198995979881147],
              [117.49855600895273, 2.52293414979124],
              [117.48673832371469, 2.539727329752509],
              [117.47031859532694, 2.544730598238573],
              [117.45168446176854, 2.5576197015880098],
              [117.42794724037287, 2.5913456272988924],
              [117.40273447071934, 2.6096855602404503],
              [117.37446647384877, 2.6039748544559984],
              [117.33544188563462, 2.621915776192168],
              [117.31459780664352, 2.626341573804609],
              [117.28240369977095, 2.6217492136553346],
              [117.24202064513668, 2.6037179855966315],
              [117.21796788760798, 2.596475878259241],
              [117.2009314311407, 2.5962774194673557],
              [117.14461192759848, 2.604940766802997],
              [117.07413251660239, 2.6080991120844033],
              [117.00087549730642, 2.5827800443636306],
              [116.95298612980616, 2.5508460924732503],
              [116.91652673002466, 2.533530129411986],
              [116.84862549457102, 2.519400410400692],
              [116.77653924688877, 2.482268965240621],
              [116.75409447178254, 2.475823604176014],
              [116.7499873309506, 2.4646609788211435],
              [116.73658751969833, 2.4613722075613964],
              [116.72694190224706, 2.4436226624559367],
              [116.70797890820006, 2.4377413193064967],
              [116.69256000024393, 2.4397971731011125],
              [116.64613195732409, 2.4305458292266735],
              [116.58116315385655, 2.4280521539837423],
              [116.56898031329092, 2.433382147068471],
              [116.56450692715305, 2.4544998636778246],
              [116.55260943068072, 2.4546189348160397],
              [116.52750195365229, 2.467172419271776],
              [116.5170522667488, 2.4795814051577736],
              [116.50392498514998, 2.4799414640273767],
              [116.49156800705794, 2.494402622782843],
              [116.4490393741678, 2.509853889245619],
              [116.43710399735164, 2.510082318844013],
              [116.42402789886853, 2.4986686981019943],
              [116.44138844582017, 2.484506146641422],
              [116.44287323011554, 2.4667458492415903],
              [116.42753046505902, 2.4360032130778677],
              [116.41877404868342, 2.4278178671009414],
              [116.40186939775458, 2.392706606824504],
              [116.37499767660154, 2.355547093823361],
              [116.36006854212962, 2.3239994061477205],
              [116.33037401994511, 2.283390644162921],
              [116.32010440879151, 2.2796586825022587],
              [116.31008545308123, 2.263509384750364],
              [116.32261307753151, 2.251490208206576],
              [116.32169022562346, 2.1297040814782235],
              [116.31601225336283, 2.1167748647682174],
              [116.29567416533052, 2.1188089028086665],
              [116.28619088630046, 2.1026293024004303],
              [116.26551400117023, 2.105003361515969],
              [116.23401655771806, 2.103215939164329],
              [116.22083725933214, 2.091965842149932],
              [116.22558387389677, 2.0525471979948406],
              [116.23289382698863, 2.028139744253849],
              [116.22937818735352, 2.0106005406824465],
              [116.19395935623936, 1.9810038944142434],
              [116.19527406884322, 1.9553962594299037],
              [116.17953744479735, 1.9106968852531168],
              [116.17563888732491, 1.8709498284718507],
              [116.18412555385135, 1.8467056817218577],
              [116.17519365816088, 1.8156614983794839],
              [116.16817346303492, 1.7786364744937373],
              [116.15738521735398, 1.7524250122045828],
              [116.16186433936787, 1.7443182516860247],
              [116.14238126018711, 1.7018892474908398],
              [116.14131207868957, 1.6867347923767966],
              [116.13078922923955, 1.6774952350171475],
              [116.12935223132047, 1.6626557953287602],
              [116.1150839789425, 1.654798444692517],
              [116.10293203998174, 1.6656819726449612],
              [116.0765520960025, 1.6540164589924302],
              [116.05488830565082, 1.6253433436204432],
              [116.03623285456263, 1.605871908861559],
              [116.04577145988503, 1.579477487596023],
              [116.04082283420655, 1.5598521815330173],
              [116.00947217899795, 1.5272086771387876],
              [115.97274677054713, 1.5312360247228298],
              [115.96274087299298, 1.521757966257212],
              [115.95020660525074, 1.4884599982303826],
              [115.91198651276738, 1.4785554920423465],
              [115.88268939919647, 1.4530952595386566],
              [115.85249998818392, 1.43599288366471],
              [115.84836567433649, 1.4227273888579361],
              [115.85505790829586, 1.402264023465932],
              [115.83194739295789, 1.378023264462115],
              [115.82567156489085, 1.3665721087159],
              [115.8124952990189, 1.3792724812400934],
              [115.79298371852383, 1.3615157541487974],
              [115.7690106499216, 1.355388642471894],
              [115.75845179071757, 1.3312668565802142],
              [115.77103914004431, 1.3067450234640887],
              [115.7721991269882, 1.2709043914245512],
              [115.76050752400306, 1.2599315320146616],
              [115.71810690870689, 1.2658182836868832],
              [115.70848347393326, 1.2626162295554195],
              [115.69142878281218, 1.2709070327334189],
              [115.66699654735282, 1.2736943950803266],
              [115.65271350922114, 1.2813765955534677],
              [115.6317890838992, 1.2851984804256062],
              [115.59955083020668, 1.2804067747529189],
              [115.59377598927051, 1.2832141820888978],
              [115.57184375938209, 1.2719278387987742],
              [115.53853974431377, 1.2624641883716663],
              [115.4951683513384, 1.255495005490161],
              [115.46389479590812, 1.2394197606134867],
              [115.4363746437748, 1.2285489625646164],
              [115.42632603112145, 1.2185179847172662],
              [115.41753466529258, 1.1924667645296267],
              [115.40816449669421, 1.190328357467763],
              [115.40633790796812, 1.1727658650526678],
              [115.41542188261383, 1.160293143313936],
              [115.41815044638611, 1.1439773855479416],
              [115.41088366794702, 1.1277153523816423],
              [115.39389609772684, 1.1050018463581637],
              [115.37844163687214, 1.099530700181731],
              [115.37147528595574, 1.0719765150042235],
              [115.36452103811553, 1.065893949446263],
              [115.34225068472142, 1.0607393016774722],
              [115.31663535333894, 1.0593745615868215],
              [115.29964545207599, 1.0648039990053917],
              [115.28796700257521, 1.0469506478541462],
              [115.25240368638106, 1.046624899019946],
              [115.2300077948239, 1.0581766358430968],
              [115.20031823779652, 1.061927278221333],
              [115.18553021324885, 1.0676532293131231],
              [115.18979126405566, 1.087810033806079],
              [115.17354149494406, 1.1160348596210952],
              [115.14195932366397, 1.116026792702371],
              [115.12290523458898, 1.1294028924295958],
              [115.09327377466502, 1.1174408597079832],
              [115.06035755985351, 1.122818140045183],
              [115.0454526513189, 1.115960779766226],
              [115.01564552951766, 1.12078534666648],
              [115.00679722131474, 1.1273422236647548],
              [114.9993980923482, 1.153279244341718],
              [114.98914503501544, 1.1572735346459808],
              [114.99028364057756, 1.182889632250749],
              [114.97432216353707, 1.1932353206535709],
              [114.93587968759755, 1.1781109433653683],
              [114.91914849430759, 1.194321873455749],
              [114.89608083436303, 1.2093574804633818],
              [114.88042781938839, 1.2107804813342113],
              [114.85852113384328, 1.1923601930685095],
              [114.84826798837707, 1.1680904408212314],
              [114.80956235102178, 1.1931352656800414],
              [114.75679669211377, 1.2477069584094238],
              [114.75776510097865, 1.2548935254159232],
              [114.72731287029274, 1.264000733147384],
              [114.71592885882978, 1.278230747251257],
              [114.72558478341114, 1.310535346628967],
              [114.7145468643464, 1.3324879866313495],
              [114.70408907455123, 1.3370102734054399],
              [114.69984943210568, 1.35396884858352],
              [114.68854371562009, 1.3590564204175166],
              [114.67243306893101, 1.3528382775645014],
              [114.63882155766885, 1.3862095853041865],
              [114.6464117817784, 1.4087940646944048],
              [114.6337998485842, 1.4323276094316952],
              [114.60400587487129, 1.4442305207219874],
              [114.58958656426864, 1.463283702381034],
              [114.58546676098229, 1.4454422321733205],
              [114.5692285031929, 1.4298677657848202],
              [114.5260019802073, 1.4428451016202075],
              [114.51197971489194, 1.462059134221704],
              [114.47456327007887, 1.473996781826088],
              [114.45880120061497, 1.4871328614923982],
              [114.414297657411, 1.5114452317121163],
              [114.40540746598185, 1.5229135726033292],
              [114.38687375521965, 1.5186206534204416],
              [114.38648955674773, 1.501517396210886],
              [114.37914576306662, 1.4929416815134573],
              [114.33666015284382, 1.4858423928122306],
              [114.32483053180363, 1.4762436768043017],
              [114.30716589079327, 1.471890210764684],
              [114.30062778624335, 1.4586956284005623],
              [114.26935930838545, 1.4501666505705089],
              [114.24967770206433, 1.4526553957299484],
              [114.23831916479764, 1.44827575042558],
              [114.22278191955002, 1.4255188880862875],
              [114.22299072953865, 1.4165428230928683],
              [114.20538782878532, 1.4103666938678998],
              [114.19765655410674, 1.3969001538191037],
              [114.17874755265746, 1.394646593065886],
              [114.16565441921614, 1.3815464152349364],
              [114.16907164783834, 1.369966057487602],
              [114.19043074068622, 1.3600269984472675],
              [114.20299125334373, 1.349370617659929],
              [114.20918670899948, 1.3339041877281375],
              [114.20901272076048, 1.3088389104873954],
              [114.1963223199192, 1.2988002182555078],
              [114.18700366089035, 1.2688401154790085],
              [114.2078015300167, 1.2361225546122796],
              [114.20897344107152, 1.2185271173326555],
              [114.20117482335945, 1.2139593104738537],
              [114.19775666483827, 1.1959531654063085],
              [114.22046554025258, 1.160452624589027],
              [114.18018787537017, 1.1520228617657153],
              [114.16287952057348, 1.138232462358303],
              [114.1282315775253, 1.127437811705704],
              [114.09370742975409, 1.106362194159999],
              [114.07846659655627, 1.105617657129244],
              [114.05461915155263, 1.1275044172950857],
              [114.03631021554406, 1.1108909632474138],
              [113.9887679755617, 1.1265689514959831],
              [113.97184986358036, 1.1197739636975257],
              [113.96281893454625, 1.11006139082059],
              [113.96215106831721, 1.0881880404457434],
              [113.9482388206651, 1.0612406296513655],
              [113.90265380672781, 1.0586908403100779],
              [113.88969530629345, 1.0473933796008623],
              [113.89336127300442, 1.019242624647461],
              [113.90813835010488, 1.0137889846739654],
              [113.91572475659234, 1.0029986948339342],
              [113.91188690605588, 0.9679100749900158],
              [113.8843712721166, 0.9370199922788629],
              [113.87216629024215, 0.9167375210198543],
              [113.87851815868612, 0.8964992397481524],
              [113.87119985988568, 0.8886867041281334],
              [113.91081871291328, 0.8510902537123002],
              [113.9263923321405, 0.8403579980232507],
              [113.9097767628873, 0.8117954183239249],
              [113.8881432266287, 0.7952557194759038],
              [113.87395538982946, 0.7797888452790289],
              [113.89472921468155, 0.7555886142231998],
              [113.89486671202917, 0.7385810152017598],
              [113.87264991326299, 0.7118232408499239],
              [113.86021399897697, 0.7128339043612186],
              [113.84168221186462, 0.702718638454428],
              [113.83634925641309, 0.6782975905613853],
              [113.84323861097323, 0.6656452661162007],
              [113.88014197097527, 0.6604249615427875],
              [113.88933776462625, 0.6412416785606752],
              [113.91220832555382, 0.6568041695767874],
              [113.93183874156477, 0.6539136298102335],
              [113.94802831365587, 0.6601030779946768],
              [113.96170019680665, 0.6569672832135893],
              [113.97751590183748, 0.6674166418645768],
              [114.01171196073847, 0.6502201528378464],
              [114.02778597354393, 0.6711524283419408],
              [114.05402054031572, 0.6699554963491323],
              [114.07479619978483, 0.6899051563979128],
              [114.09145017008939, 0.6977617480063145],
              [114.10255086183292, 0.6914729490174523],
              [114.12294780599655, 0.6948502324523247],
              [114.1308862278172, 0.6697062051772491],
              [114.14389228170353, 0.6689896037872245],
              [114.15767692275188, 0.659763996711149],
              [114.17657412419658, 0.6396731961611408],
              [114.17948950593598, 0.6159647413797416],
              [114.15858478655866, 0.5988142778566612],
              [114.16489955976692, 0.5841325882483943],
              [114.17804240413375, 0.5813194000702993],
              [114.19357954236204, 0.5864325333348006],
              [114.2029683179336, 0.5395783574552979],
              [114.21941258252468, 0.5294829296934722],
              [114.24769966840506, 0.5226097962051881],
              [114.25642943876767, 0.5323015236963897],
              [114.26928559275598, 0.5288055045704141],
              [114.29083845083392, 0.5380823397282484],
              [114.31191559812646, 0.5342983073210803],
              [114.319796555067, 0.5405255396220241],
              [114.3637688075845, 0.5298164432742851],
              [114.37646821333749, 0.5151292687008322],
              [114.38921440631987, 0.5119654600379704],
              [114.40069543934317, 0.4969267917380762],
              [114.41005289062844, 0.49542720100458837],
              [114.43135752413048, 0.5039427771375244],
              [114.4372060753002, 0.5113529605707186],
              [114.43596910009398, 0.5440140557041673],
              [114.46261019370559, 0.5613308362491694],
              [114.49038877592136, 0.5710416617433225],
              [114.5249766127805, 0.5587300481532225],
              [114.54649221374473, 0.593886201686928],
              [114.53982709434501, 0.6197499644893014],
              [114.54528424886905, 0.6306128116319769],
              [114.5671692229813, 0.6251847861490205],
              [114.60201380009687, 0.6332600083427451],
              [114.60657940901262, 0.6425587608781598],
              [114.61988269423102, 0.6425123234849934],
              [114.63852730489123, 0.6697469903313618],
              [114.65272651091982, 0.676263419421332],
              [114.65599920679801, 0.6899820826070595],
              [114.66808325953491, 0.7038549255644284],
              [114.69148636210593, 0.7103621186169562],
              [114.7007912183401, 0.7024828416098785],
              [114.7159153258317, 0.7013624482184468],
              [114.73689190463779, 0.7127336281544672],
              [114.75000002438173, 0.7258570903325108],
              [114.76579465829695, 0.730203063220813],
              [114.78214249667894, 0.7274340713253764],
              [114.80975734562503, 0.7428224499961402],
              [114.81292837134731, 0.7499999680241558],
              [114.83425143015938, 0.7537362680143929],
              [114.86205044497217, 0.7366982161498186],
              [114.87997048804766, 0.7468010084598404],
              [114.8913118689477, 0.7587498382345075],
              [114.91735663470104, 0.7774846895667906],
              [114.94137375209709, 0.77215415598954],
              [114.95812155316719, 0.7624179768082513],
              [114.97913733252199, 0.7389088945299136],
              [114.9893489383029, 0.7177897794747992],
              [114.99575684889953, 0.6901332388579249],
              [115.01075086585979, 0.606699075801771],
              [115.03249557181323, 0.5731747214363168],
              [115.0497112613657, 0.5394148382242747],
              [115.06667850690758, 0.524491406353377],
              [115.0823653112102, 0.5000000017986963],
              [115.0940112286354, 0.46996692083678226],
              [115.1327772212918, 0.4324947756134634],
              [115.133437238238, 0.415183631119703],
              [115.1286699986216, 0.37463400135811753],
              [115.08487742525301, 0.342343564503949],
              [115.07687620916977, 0.33074989319305814],
              [115.05534000114017, 0.3177040002394733],
              [115.04890853010465, 0.2995491810499402],
              [115.03756982828497, 0.3027468455904909],
              [115.03605644914592, 0.31574186119797787],
              [115.02346646660419, 0.3299461708791114],
              [114.99636211422023, 0.3206742891871386],
              [114.97928427365036, 0.32997732069685526],
              [114.9547651807685, 0.3192054300865834],
              [114.94953417606143, 0.33216009311382777],
              [114.93393276238714, 0.33224821228520796],
              [114.89719808822122, 0.3119646132763023],
              [114.88524014022039, 0.29712336684997354],
              [114.88488297986567, 0.2811191153549544],
              [114.87237053417289, 0.2705888051293073],
              [114.86394122910451, 0.2376325255206666],
              [114.83330884802501, 0.22726005800529947],
              [114.82999005289867, 0.1989630867770984],
              [114.80239278839656, 0.17154059022612955],
              [114.81342349579779, 0.16213342711603218],
              [114.80900393768161, 0.14030659777131405],
              [114.79309047274717, 0.11948450270767808],
              [114.79467950015885, 0.08393383907548468],
              [114.8141484410977, 0.06725484513503943],
              [114.83358040731002, 0.07505292325384971],
              [114.84947333712489, 0.06793171717504265],
              [114.86046434755167, 0.05476938629817596],
              [114.87501819673241, 0.055493521309017524],
              [114.89469522100774, 0.04036379755126518],
              [114.88912310075057, 0.024374890288981987],
              [114.89402227270978, -0.002117777413445765],
              [114.89346695483619, -0.02756507586752832],
              [114.90521749219351, -0.04792116832720694],
              [114.91164672391722, -0.07969083386484499],
              [114.92455121376759, -0.09955558352334037],
              [114.91700659967489, -0.13320046413008413],
              [114.93033369984039, -0.16073262491067908],
              [114.92241775027901, -0.18244368632701935],
              [114.92876529927923, -0.19087085370688328],
              [114.94773810852689, -0.19044012161430146],
              [114.95963996401315, -0.18140244856408572],
              [114.97929327496468, -0.17845927057879862],
              [115.00081579621212, -0.1869298806094548],
              [115.02694646161001, -0.1852426921964252],
              [115.0447915902597, -0.18981582394104635],
              [115.06159914318596, -0.16935403685926076],
              [115.08123719534035, -0.15319693658932465],
              [115.10493152357105, -0.14450706374992706],
              [115.11901672164447, -0.14720155131050205],
              [115.14951286082987, -0.13534743543573313],
              [115.14609500088363, -0.12671120895510057],
              [115.15861294772947, -0.09852041078829643],
              [115.18232068215377, -0.09399670938057625],
              [115.18251707070635, -0.08256981374586303],
              [115.22524194466007, -0.07339749240031779],
              [115.24595575235617, -0.054997122319264236],
              [115.26239580452284, -0.046852824997813514],
              [115.28742731761668, -0.04651052144333789],
              [115.30978730733841, -0.05993801472754967],
              [115.31004843898438, -0.08420758171445186],
              [115.3151849501254, -0.1007616238497917],
              [115.30871510681675, -0.11746447590479647],
              [115.28637888887465, -0.12869692462334115],
              [115.28433494210401, -0.14585700639565857],
              [115.27070898373267, -0.14397187630100916],
              [115.2601316488566, -0.15899691177799014],
              [115.26634912890927, -0.16873652996684996],
              [115.26868370329032, -0.18969211320040813],
              [115.25896013800002, -0.21519371901484874],
              [115.2387173340378, -0.23291629974738726],
              [115.23852890538478, -0.2464740895075579],
              [115.25013871682836, -0.25753504380719505],
              [115.25458178592078, -0.2832190869912097],
              [115.25384151077719, -0.3008722175787284],
              [115.23285580565096, -0.2947650708512697],
              [115.22189161300764, -0.30683876159952206],
              [115.2326099067219, -0.3232579252130563],
              [115.23166148169207, -0.33829842453263836],
              [115.23743775614764, -0.3517783213124517],
              [115.23403149925434, -0.37084343877364745],
              [115.25232974951837, -0.4099125838985742],
              [115.25573256830342, -0.43573668659877285],
              [115.25309754211798, -0.45188281154247534],
              [115.28540040580413, -0.48780179251866684],
              [115.30253163102998, -0.5016796599882696],
              [115.3186443299719, -0.5293390172817567],
              [115.31686391873495, -0.5465224823266226],
              [115.33520435709545, -0.5689412787169772],
              [115.35156941929336, -0.5798368216119911],
              [115.35735247733498, -0.6052144864593174],
              [115.3564293205568, -0.6326647163035659],
              [115.3663399025645, -0.6796397664620031],
              [115.36849902910694, -0.7031820957770378],
              [115.36704945596773, -0.7494720154211336],
              [115.37969897003154, -0.8016109071176061],
              [115.39253159595648, -0.8222751486791253],
              [115.40268631961806, -0.8274375863754813],
              [115.44791335931416, -0.8387708248136505],
              [115.48215403717916, -0.8428162523680953],
              [115.49765697239138, -0.8499946724161305],
              [115.53359902997136, -0.8830725511572837],
              [115.557852863319, -0.9236323576471364],
              [115.58238135321699, -0.947523255194028],
              [115.6309882043422, -0.976745844644995],
              [115.64891725592668, -0.9896177619501145],
              [115.6566934211645, -1.0048243363739857],
              [115.66422082133386, -1.035728539340596],
              [115.65524246666655, -1.0520492622636084],
              [115.67874047739122, -1.0648072912234738],
              [115.6916979427059, -1.0515466752387965],
              [115.70643750368788, -1.071801550800899],
              [115.74110959802954, -1.0934428823830444],
              [115.74909344011019, -1.1234508003141173],
              [115.758697561943, -1.1360727078891273],
              [115.79212553596483, -1.1156163005517783],
              [115.82073742099521, -1.1312894531457118],
              [115.84696093779712, -1.1572596597054599],
              [115.84120839465174, -1.1690728006691415],
              [115.81861014754031, -1.1820016468584527],
              [115.78364466377025, -1.2130490012103792],
              [115.77049158691727, -1.231230668760304],
              [115.75463009290456, -1.2829590154461812],
              [115.75758265171805, -1.30744616350961],
              [115.75398994737827, -1.3150423402317415],
              [115.74471992368558, -1.3348671267164605],
              [115.74159515998872, -1.36403570288536],
              [115.7221071291408, -1.3751254157053268],
              [115.71748917878267, -1.3978117945786153],
              [115.69904548116403, -1.4149444524244927],
              [115.6877165001165, -1.4450292075318316],
              [115.67883333329178, -1.4563055548574084],
              [115.63525946425295, -1.4638232207649935],
              [115.61889672230598, -1.4878552102497338],
              [115.62737132802442, -1.5030359462518845],
              [115.64719851390032, -1.511441892329401],
              [115.63141932674057, -1.530270241678238],
              [115.64539849024823, -1.5609510395588018],
              [115.63088247914311, -1.5783974312963664],
              [115.63996853122285, -1.5853772702448055],
              [115.63652150266523, -1.60348628696255],
              [115.6654484759664, -1.6281206468503342],
              [115.67447858481887, -1.6659136557905185],
              [115.66762667982744, -1.6793759015765772],
              [115.66591440662467, -1.7080954066776144],
              [115.6793399798562, -1.7102153533558067],
              [115.6908865473763, -1.7239436311935492],
              [115.70774264570662, -1.7275772555858566],
              [115.70168389689434, -1.7426786192228292],
              [115.68960072189589, -1.7532238707849501],
              [115.69008613906408, -1.7790479456061803],
              [115.68083629166165, -1.7891570493582662],
              [115.69435486198734, -1.8008066647956866],
              [115.70811834288725, -1.7882081782647674],
              [115.72672172682633, -1.8036336431780455],
              [115.72170698568891, -1.8256783332884083],
              [115.72974115293198, -1.8443941864425142],
              [115.72553301014034, -1.853118697569812],
              [115.73053737939665, -1.8698523469082602],
              [115.72395225559217, -1.9222646343054066],
              [115.70621607531166, -1.9497067306810436],
              [115.7067794043461, -1.9603443848853885],
              [115.6961962139842, -1.9744231932756975],
              [115.71062182253911, -1.9798968746409855],
              [115.71689171597995, -1.9902360681400069],
              [115.73552144106168, -1.9849097029203904],
              [115.7476063805301, -1.9951180774372688],
              [115.74308128877055, -2.0094065168971724],
              [115.72471057833059, -2.026200929828974],
              [115.74098911315707, -2.0364090957031635],
              [115.75367176184238, -2.0504681019212967],
              [115.77667436572949, -2.050854094540796],
              [115.78493119521852, -2.0429552869568965],
              [115.80376968981943, -2.037827129618279],
              [115.82156568670712, -2.054590585187327],
              [115.8365955938117, -2.0591879851373847],
              [115.83201030233363, -2.0813992944418374],
              [115.81215100976135, -2.0938492894131286],
              [115.8082905529667, -2.170914481618013],
              [115.79960068462401, -2.184475020605646],
              [115.80711889552072, -2.1986309827336186],
              [115.80211892304987, -2.206727209493067],
              [115.81704122357371, -2.218701456806741],
              [115.81463285622135, -2.2341891485103247],
              [115.8220234605144, -2.2577982434941077],
              [115.83938794110065, -2.290349990959271],
              [115.85132467229585, -2.3012908911616705],
              [115.83264928683514, -2.3129779759527764],
              [115.83116277404281, -2.3253153101533712],
              [115.84488374129171, -2.3530533014686625],
              [115.85808061063176, -2.386906717770785],
              [115.87183888355764, -2.372384646134492],
              [115.88425769004039, -2.3745528971983845],
              [115.89127671874564, -2.351257472254474],
              [115.8903077118315, -2.3356261444309894],
              [115.91970893002315, -2.336917308287127],
              [115.93599997172134, -2.3340338138094125],
              [115.94539416480893, -2.341479561832273],
              [115.97044729041022, -2.3351176029949556],
              [115.96687404829879, -2.327860325893255],
              [115.98341690556583, -2.3139077067751828],
              [115.99317075552597, -2.3213936221179097],
              [116.00612699956139, -2.3146786541964843],
              [116.03434217025506, -2.3249118841760605],
              [116.08147950397597, -2.317039655155895],
              [116.1481577702649, -2.319511344672776],
              [116.16113900016478, -2.3065289996135903],
              [116.22664600021278, -2.308784654887859],
              [116.23623299985309, -2.327163654379092],
              [116.27293199971211, -2.3392936543785936],
              [116.30809199964608, -2.340669654880628],
              [116.37429890157307, -2.3549788363042126],
              [116.45450760294364, -2.3705188478664923],
              [116.49283823739484, -2.38097350622229],
              [116.53876748573089, -2.3999790885640664],
              [116.55893137508292, -2.4051927633353216],
              [116.57617162152314, -2.336304022812328],
              [116.57942743532794, -2.306538673620821],
              [116.58913083601931, -2.2607622758582693],
              [116.59574454387564, -2.202063903626197],
              [116.5863767072193, -2.170044513443486],
              [116.57003247871603, -2.1963728606189648],
              [116.51589199202192, -2.2214798655033405],
              [116.50454468189162, -2.2324358896045737],
              [116.49750152437593, -2.223436298346428],
              [116.53741275329594, -2.2007416789852527],
              [116.54786485038255, -2.189753576965984],
              [116.55237516718023, -2.173778370875084],
              [116.54233772770408, -2.1673364263349413],
              [116.50493596881995, -2.1666997476951337],
              [116.48028491571631, -2.1839163563547004],
              [116.43176538156717, -2.207002263543586],
              [116.41102719504909, -2.210523842301427],
              [116.40672304333384, -2.2042632577430936],
              [116.4509384239692, -2.1901769418123536],
              [116.49476251767612, -2.164352028823032],
              [116.5242887380349, -2.154871402792935],
              [116.51481013278146, -2.126735837388935],
              [116.50217341786902, -2.109271333305344],
              [116.49093392566022, -2.119260775963994],
              [116.46107342010237, -2.1326913997882],
              [116.43161791613397, -2.134745185142151],
              [116.39312187117753, -2.126869800400925],
              [116.387264510249, -2.116396024257085],
              [116.36947298209247, -2.1188559749232354],
              [116.36450557497233, -2.108387675650647],
              [116.39324260696083, -2.0835450004013865],
              [116.4269150257079, -2.075639536935057],
              [116.45770763270116, -2.0625581396463986],
              [116.4604820699916, -2.035801410306817],
              [116.45520507575372, -1.9941565762649134],
              [116.45476449338673, -1.9590896651558865],
              [116.45718585864051, -1.91952960890427],
              [116.44396371565301, -1.876632454524099],
              [116.43854568433596, -1.86912525492653],
              [116.42084931884108, -1.8640030169256647],
              [116.42657906057525, -1.8522008899590787],
              [116.45003610088543, -1.8211656314886682],
              [116.45385720154866, -1.8012042829512325],
              [116.45068450398674, -1.7732516144703823],
              [116.41175025986661, -1.7675587035712397],
              [116.39198169809697, -1.7689673449669385],
              [116.37503041821924, -1.7622953358610403],
              [116.36825882358585, -1.7779216832390148],
              [116.3543671776032, -1.7756488706987739],
              [116.31576518261716, -1.7863516852819998],
              [116.30033913674185, -1.795260388356212],
              [116.26243610833978, -1.8103140483545985],
              [116.25313062078158, -1.8060538159307953],
              [116.24999999980025, -1.7897317044545389],
              [116.26421865726525, -1.7616868194911035],
              [116.27934859416234, -1.750341418621531],
              [116.27964051499782, -1.7355631373288816],
              [116.30884588782749, -1.7239687933251275],
              [116.31292040485289, -1.704235220578937],
              [116.38040392410187, -1.685270827186855],
              [116.42871027665797, -1.657441508818522],
              [116.45177361985668, -1.6479812092653674],
              [116.48137220056662, -1.6456793063548503],
              [116.51912803513073, -1.6310994991425218],
              [116.53438013953189, -1.616583859457478],
              [116.55899381501263, -1.6002855827134113],
              [116.54946766530054, -1.5348696767940737],
              [116.54299949922756, -1.5212811086362308],
              [116.53267796751425, -1.5156455726850027],
              [116.53991026684537, -1.4646561251799994],
              [116.55581638162073, -1.4479477818645705],
              [116.58100369236661, -1.43055403554024],
              [116.62589285587069, -1.408977443454205],
              [116.65149848626618, -1.4051367573554217],
              [116.7110409387883, -1.3807216458872063],
              [116.74901924134497, -1.370952729584758],
              [116.76200465399165, -1.3622081941527995],
              [116.76569630623965, -1.3517650147435916],
              [116.76008525163309, -1.298523933732497],
              [116.76257429266957, -1.2776639933985052],
              [116.77236326655157, -1.2654986815188636],
              [116.77822065086241, -1.248233828728189],
              [116.76687612323451, -1.2363824755707356],
              [116.78014654362812, -1.2248811493454923],
              [116.7918630578339, -1.2357642923881258],
              [116.81579354263795, -1.24089131028569],
              [116.815875282918, -1.2593164414005855],
              [116.80626521117063, -1.27096363766168],
              [116.81081164384238, -1.2812338414685769],
              [116.84229548235066, -1.2787394827408889],
              [116.85431281798219, -1.274530981119824],
              [116.8841287477436, -1.2766494052457915],
              [116.9327605748407, -1.2534053639663512],
              [116.97255127392225, -1.2238538483763364],
              [116.9818240090708, -1.21214881311721],
              [117.00018557610349, -1.2007159612725218],
              [117.01105521582565, -1.183952945472015],
              [117.02255745576201, -1.1408558753983016],
              [117.05153326764628, -1.119725754439969],
              [117.06483519219046, -1.1064425007207888],
              [117.07945491311386, -1.0832147263784577],
              [117.0987957753049, -1.0589916849182828],
              [117.12064497618474, -1.0164044396131544],
              [117.14511583213005, -0.9936281797965876],
              [117.15753783929995, -0.9687708339068308],
              [117.1788822156418, -0.9450285079592504],
              [117.2031374150597, -0.9280650413685407],
              [117.24878006205029, -0.9071503557042888],
              [117.25148422901407, -0.8436669498697711],
              [117.25011870381525, -0.8348053946531877],
              [117.25749541268317, -0.804246678841082],
              [117.27079297101886, -0.8046982293397491],
              [117.28207730432416, -0.7910772253336518],
              [117.29024510272939, -0.725565949009308],
              [117.30385677536992, -0.683061509680897],
              [117.30223500004524, -0.648003999185164],
              [117.29534263275639, -0.607318951308514],
              [117.27710322423445, -0.5814296045925289],
              [117.31110099971147, -0.5669289485120999],
              [117.33100343006436, -0.5784245757335498],
              [117.3677525356511, -0.5692860311253867],
              [117.37759583128263, -0.5624982001317562],
              [117.38576006387586, -0.5513037153941127],
              [117.39469620256773, -0.5452605869065223],
              [117.41256936128707, -0.5391013748285332],
              [117.42818407323648, -0.5184665617823612],
              [117.43883893327018, -0.4970453878343051],
              [117.4373158990096, -0.4849168834073794],
              [117.45270643695255, -0.46341281265114276],
              [117.45457138805921, -0.4473959031982986],
              [117.43507479116874, -0.43518645978724635],
              [117.42685357663129, -0.43462752394100335],
              [117.42482325908622, -0.4286369311494127],
              [117.43510490316885, -0.42740767212632136],
              [117.43804303865534, -0.4209794197643646],
              [117.42842534155636, -0.3985616737821829],
              [117.43666293904346, -0.3796568325967087],
              [117.44846249215038, -0.38043918791811393],
              [117.45780344357411, -0.38712069296553864],
              [117.46403753590357, -0.3759323101279506],
              [117.46288504301037, -0.36403122985734626],
              [117.45291402476255, -0.35598248370462215],
              [117.44340978433411, -0.29883471624913227],
              [117.42688920507283, -0.27685870736343077],
              [117.41725962073497, -0.2467027286479606],
              [117.41974576865232, -0.21298060167339372],
              [117.44276153321823, -0.20067632834980031],
              [117.44480580464426, -0.17949202593621294],
              [117.47221898331952, -0.10516722268914691],
              [117.47321737738014, -0.088722549805766],
              [117.48172919794422, -0.06895057690752537],
              [117.50101172111408, -0.05415079732136974],
              [117.51772447380552, -0.03150952844191579],
              [117.51463783866541, -0.008475394323966157],
              [117.50724451302392, 0.010644200469528187],
              [117.51880869376623, 0.02663417882433805],
              [117.50453192570785, 0.03448639972759793],
              [117.49088871024583, 0.029780156628987697],
              [117.49906163838398, 0.060104662000583176],
              [117.47239263880931, 0.06558693998533727],
              [117.4858746651837, 0.09139628750870088],
              [117.48011636907529, 0.100937925345022],
              [117.49470246524663, 0.11019402933101219],
              [117.50962159566018, 0.10949295923273894],
              [117.52162835616377, 0.12476909801551983],
              [117.51427582197164, 0.1414443615680625],
              [117.4999133943877, 0.1549787356801744],
              [117.48839927260838, 0.1580901993033308],
              [117.4854011496434, 0.17265250121198505],
              [117.50672817086843, 0.2046809169907533],
              [117.52807193567139, 0.22033777027195356],
              [117.52562109064093, 0.23794578187238358],
              [117.51702880863263, 0.24645996135819814],
              [117.5303398136316, 0.2557059227901277],
              [117.51889460420273, 0.2773352223426855],
              [117.53336172816125, 0.2859964607681036],
              [117.52369398918631, 0.3178258862557186],
              [117.54382183910661, 0.3189139247378989],
              [117.55318892662774, 0.3339121963908269],
              [117.55345865669176, 0.36191078425531487],
              [117.56664086798173, 0.39132092372170746],
              [117.55907319167454, 0.4176349860658206],
              [117.57392103013149, 0.4156124440590929],
              [117.58591351209895, 0.40552352918757606],
              [117.60325978411186, 0.41732565525484233],
              [117.61213517270016, 0.43243523329942946],
              [117.60442571917167, 0.4506143152984805],
              [117.60116585751837, 0.47214843780022875],
              [117.60489347995019, 0.48762681432606314],
              [117.63471703866048, 0.5157163830047011],
              [117.64422536291022, 0.5351485317793845],
              [117.64784384181985, 0.5533317344720103],
              [117.65902887917946, 0.5618761734467057],
              [117.65904932706485, 0.5784907030835029],
              [117.6684009408508, 0.5880475358651438],
              [117.67340642706506, 0.6153902823356816],
              [117.68460748674613, 0.621456597144288],
              [117.70847823857991, 0.6237951321396622],
              [117.71416895783125, 0.6436526854231488],
              [117.7106283197412, 0.6559749622747404],
              [117.71163328784598, 0.6970389592805759],
              [117.72148449391432, 0.7150905911578889],
              [117.73470751374077, 0.7266478093489468],
              [117.75142167027388, 0.7558392022174303],
              [117.81476134016373, 0.7986252237751046],
              [117.85259762538021, 0.8113686648320595],
              [117.87320494705068, 0.8104406750971407],
              [117.87287182287639, 0.821895400252572],
              [117.89174213818922, 0.8321651534990906],
              [117.91439936553832, 0.8372286477838884],
              [117.92501898024182, 0.8051608093239224],
              [117.9605109766004, 0.7912839356051791],
              [117.9732730372209, 0.7728789780824172],
              [118.00000001878595, 0.7741690150880345],
              [118.02946113902044, 0.7804561260494438],
              [118.02994844386569, 0.8020798749863047],
              [118.019742180957, 0.8165288254450047],
              [118.02851798851441, 0.8300949636117139],
              [118.0194162981237, 0.8525990175306788],
              [118.02156798187696, 0.8589109228008738],
              [118.0104321050577, 0.8849660208651926],
              [117.99653036058771, 0.8940399177080849],
              [117.9869890150311, 0.9187009964538788],
              [117.97712102221749, 0.9300610167026093],
              [117.9791960117866, 0.9449529875903409],
              [117.96882118624876, 0.9588755180907356],
              [117.97153932238189, 0.980413515771204],
              [117.96394054302175, 0.9856126808805925],
              [117.95875720777894, 1.0054229604013472],
              [117.95114259765285, 1.0096088260864917],
              [117.94074426833333, 1.0364045230503507],
              [117.92954606579838, 1.0372043953665866],
              [117.91907831421292, 1.0640747108792539],
              [117.90368938066047, 1.0726194673146097],
              [117.88852827056326, 1.089485814318948],
              [117.89142397783439, 1.1059954137573982],
              [117.9018561072736, 1.1124387873202295],
              [117.92486815584073, 1.1046146900159783],
              [117.95074266194433, 1.0795975876586112],
              [117.98433726685141, 1.0592008620301954],
              [117.98713681681068, 1.0356046525327542],
              [117.99593540419562, 1.0200071563052688],
              [117.99073623908623, 1.0000103681840073],
              [118.00753354153983, 0.987612359422883],
              [118.00845301109769, 0.9610120185898268],
              [118.02192102153799, 0.9514180033382331],
              [118.04804569655175, 0.9481765373174085],
              [118.05625469020185, 0.9358583218041758],
              [118.0520495079785, 0.9134186557463977],
              [118.06490118334295, 0.90344801161649],
              [118.10053298111222, 0.893161010272479],
              [118.1744324428031, 0.8768301115204622],
              [118.21111540415268, 0.8670648032980921],
              [118.32032368289174, 0.8497349627983226],
              [118.34227421038656, 0.8379566300944248],
              [118.33966012131759, 0.8174046347161834],
              [118.36102768128251, 0.8044603660530925],
              [118.37889743336996, 0.8038892956545283],
              [118.40459561209786, 0.8195223546729835],
              [118.42376575328694, 0.8392919344752272],
              [118.44268246822139, 0.8436106552076126],
              [118.4710218487794, 0.8301904949954064],
              [118.50261835165566, 0.8204397539914794],
              [118.52791678083486, 0.8160044397531578],
              [118.5464099519578, 0.8084472711246917],
              [118.57278056726943, 0.8147468943537319],
              [118.59332007376247, 0.8118058801372854],
              [118.62960128996758, 0.8474551085664643],
              [118.65149233180568, 0.8461083333329498],
              [118.66384328174217, 0.8556662084288291],
              [118.67749186975402, 0.8570510546624632],
              [118.69232542967495, 0.8481709068619239],
              [118.7093399040134, 0.8291725101033194],
              [118.70627634817504, 0.8179652495966252],
              [118.73128686299845, 0.8222708833945944],
              [118.75896026500425, 0.8059751966980002],
              [118.78618364805698, 0.8075470794342436],
              [118.79511138618159, 0.8016983645878781],
              [118.81709998467659, 0.82100174123741],
              [118.82689552900547, 0.8437229265718429],
              [118.83768426121969, 0.8499441145293076],
              [118.86552102597466, 0.8794263175705623],
              [118.88818995170493, 0.8726303944771985],
              [118.90477003640808, 0.8873426016797907],
              [118.90079133495226, 0.8944114816046635],
              [118.93532346060931, 0.9159095152119789],
              [118.96424580869723, 0.9505473282966364],
              [118.9875522287524, 0.9884438788819807],
              [118.97916228880865, 1.0056194784561967],
              [118.98895834035523, 1.026175144867068],
              [118.9805434181443, 1.037625429170248],
              [118.96636060136075, 1.0359260837218471],
              [118.945911635014, 1.058044889659982],
              [118.92029911671091, 1.0643738595659897],
              [118.87192860651999, 1.0673820567365624],
              [118.85318777379678, 1.0473450122625536],
              [118.83641126996417, 1.0514499335676533],
              [118.83230789009713, 1.0761463592671134],
              [118.82308747840443, 1.1046442120608049],
              [118.79806268684354, 1.1159147272828704],
              [118.76888717420377, 1.1675091694127104],
              [118.76808291589396, 1.1949788569887119],
              [118.75142668919045, 1.2147188652834302],
              [118.73026221502926, 1.2298012766077022],
              [118.71179544800157, 1.2514301651700066],
              [118.67042332257836, 1.2792807650952795],
              [118.65759951000939, 1.2839475846133723],
              [118.64491550064986, 1.3028469471289554],
              [118.60767214835107, 1.3248002166567403],
              [118.5803277246448, 1.354317035502845],
              [118.55083385559806, 1.366889299601553],
              [118.54435851203573, 1.359873692816734],
              [118.51642890576943, 1.362874528137013],
              [118.51673228666573, 1.3754618774637493],
              [118.50205251890873, 1.3747664281282255],
              [118.46846165877855, 1.3854350522858567],
              [118.45477949098074, 1.3862576324838187],
              [118.44199191119776, 1.3756371454379632],
              [118.42958482557935, 1.4002129720510652],
              [118.42888030658094, 1.4264221401696773],
              [118.41912653036513, 1.43022832647614],
              [118.38923235778714, 1.422838185334058],
              [118.38518954186395, 1.4344030846347096],
              [118.41430946471007, 1.4383779738644762],
              [118.42153181664014, 1.4523030530435221],
              [118.40717795329999, 1.4865899443705075],
              [118.3763497385496, 1.4829989334540414],
              [118.37635102458023, 1.5010448231601003],
              [118.34703236235373, 1.511099844382386],
              [118.27513744813484, 1.5495469446358356],
              [118.2457731839853, 1.5625447310545155],
              [118.2113685363289, 1.5887822763809822],
              [118.17690002421682, 1.6088069362911028],
              [118.16355434315847, 1.6271862838199809],
              [118.13462905518679, 1.6338887376751359],
              [118.11506100536121, 1.6520940304152418],
              [118.07276706494815, 1.7098970942755045],
              [118.06144484320498, 1.7185560214433053],
              [118.05249833622929, 1.7341596492484541],
              [118.06362038867007, 1.7469626308208035],
              [118.0666591448038, 1.7618290141977013],
              [118.04814926157917, 1.7832008693247303],
              [118.01196418290078, 1.7833382281767172],
              [117.95845028535246, 1.7960044111746925],
              [117.92942771861442, 1.8056647037631137],
              [117.91096642306206, 1.8211724107781606],
              [117.89838424933373, 1.8380528213806429],
              [117.88843637783668, 1.8600337522559016],
              [117.88966102693496, 1.8726919142005158],
              [117.85874713107535, 1.8833634206853844],
              [117.84488352955134, 1.893660116721037],
              [117.83314008784487, 1.9100033667619982],
              [117.82459277193891, 1.9371101383222822],
              [117.81568143205254, 1.9495621675600319],
              [117.82032802311983, 1.9655989457348255],
              [117.81557478055174, 1.969545469447894],
              [117.81355308660568, 1.9803205940202702],
              [117.80760962458453, 1.9831911967164046],
              [117.8064191794017, 1.98618610550443],
              [117.80796828590769, 1.9881031633303792],
              [117.81153853417607, 1.9884542073958187],
              [117.81950380795445, 1.9865414807048296],
              [117.8190306683307, 1.9945567217153553],
              [117.82415769522152, 2.0098694656034013],
              [117.7896653690616, 2.0137222583755943],
              [117.76694481774916, 2.0130094584198446],
              [117.74399569204763, 2.0301360080703716],
              [117.73686245393912, 2.043546127351931],
              [117.7213895587812, 2.0273961820881823],
              [117.71139534701774, 2.0260798390133914],
              [117.7012941447091, 2.0360173476225327],
              [117.69880302533943, 2.0577754076090287],
              [117.71759798376002, 2.05923246040976],
              [117.71105945562942, 2.0735921954235437],
              [117.71296679178408, 2.0822126352049963],
              [117.72629529039534, 2.0838801140760665],
              [117.7193983437586, 2.1044730906606333],
              [117.72339635096091, 2.1370294462519723],
              [117.71865828175441, 2.149687697229467],
              [117.70375851554309, 2.1655260507866387],
              [117.68293367394995, 2.1781067855997094],
              [117.74134053813543, 2.1918331262978086],
              [117.79038231489199, 2.188967360155573],
              [117.80513010557002, 2.1989284741698043],
              [117.86891965321945, 2.1988925021872774],
              [117.89215080721408, 2.194518508222643],
              [117.90831000560229, 2.20883765413447],
              [117.93386092394258, 2.180001252455895],
              [118.01049789765989, 2.1999726653062908],
              [118.02068208940693, 2.1980055017525615],
              [118.0533106551627, 2.227128504776715],
              [118.07584701722612, 2.2586336068555397],
              [118.09449360999213, 2.2755681314641265],
              [118.09478135527536, 2.310455610737904],
              [118.0864762070114, 2.32552483170565],
              [118.06499596109484, 2.349109656685357],
              [118.05391797903155, 2.3672750806804856],
              [118.04156500202328, 2.3674583373324367],
              [118.0308934658608, 2.358328044004054],
              [118.01508345893455, 2.3663088373829737],
              [118.01663254925279, 2.3964170000445506],
              [117.99809527987338, 2.4143591727377043],
              [117.99967408469126, 2.4334812982233984],
              [117.98593392680937, 2.435721765746962],
            ],
          ],
        ],
      },
      properties: {
        cartodb_id: 6,
        country: 'Indonesia',
        id_1: 16,
        state: 'Kalimantan Timur',
        key: 'indonesia-kalimantantimur',
        name: 'East Kalimantan',
        slug: 'east-kalimantan',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [103.991943, 1.134721],
              [103.920227, 1.14296],
              [103.937225, 1.08389],
              [103.888046, 1.0925],
              [103.910004, 1.038056],
              [103.94265, 1.008161],
              [103.990829, 1.017414],
              [104.008575, 0.985876],
              [104.040527, 0.9825],
              [104.030563, 1.00639],
              [104.100166, 0.982464],
              [104.079712, 1.024154],
              [104.101379, 1.042414],
              [104.136948, 1.039722],
              [104.138885, 1.169166],
              [104.098892, 1.199444],
              [104.071953, 1.18778],
              [104.101944, 1.140834],
              [104.071663, 1.118888],
              [104.013611, 1.189443],
              [103.991943, 1.134721],
            ],
          ],
          [
            [
              [103.888046, 1.158889],
              [103.868889, 1.147222],
              [103.889458, 1.14181],
              [103.888046, 1.158889],
            ],
          ],
          [
            [
              [103.802505, 1.101735],
              [103.790558, 1.096945],
              [103.812241, 1.084147],
              [103.802505, 1.101735],
            ],
          ],
          [
            [
              [103.849442, 1.069171],
              [103.83255, 1.06309],
              [103.838646, 1.027541],
              [103.882149, 1.05321],
              [103.849442, 1.069171],
            ],
          ],
          [
            [
              [104.181389, 1.068332],
              [104.154999, 1.041389],
              [104.167503, 1.022221],
              [104.181389, 1.068332],
            ],
          ],
          [
            [
              [103.749954, 1.026713],
              [103.782646, 0.97739],
              [103.806206, 0.972775],
              [103.791252, 1.04645],
              [103.749954, 1.026713],
            ],
          ],
          [
            [
              [103.903107, 1.03268],
              [103.885101, 1.02966],
              [103.903259, 1.002832],
              [103.919678, 1.012794],
              [103.903107, 1.03268],
            ],
          ],
          [
            [
              [103.832474, 0.989007],
              [103.941902, 0.909791],
              [103.974998, 0.954444],
              [103.925278, 1.008061],
              [103.900421, 0.998828],
              [103.877739, 1.012594],
              [103.868256, 1.037678],
              [103.832474, 0.989007],
            ],
          ],
          [
            [
              [104.178833, 1.02863],
              [104.155243, 1.016117],
              [104.155556, 0.998333],
              [104.174721, 0.998611],
              [104.178833, 1.02863],
            ],
          ],
          [
            [
              [103.991943, 1.000556],
              [103.992226, 1.011944],
              [103.981392, 1.005834],
              [103.991943, 1.000556],
            ],
          ],
          [
            [
              [104.245827, 0.997499],
              [104.232224, 0.979722],
              [104.258888, 0.97389],
              [104.245827, 0.997499],
            ],
          ],
          [
            [
              [104.152237, 0.97107],
              [104.153069, 0.93375],
              [104.198067, 0.945872],
              [104.172226, 0.973541],
              [104.152237, 0.97107],
            ],
          ],
          [
            [
              [104.0625, 0.967223],
              [104.044579, 0.938139],
              [104.068428, 0.914281],
              [104.079666, 0.949464],
              [104.0625, 0.967223],
            ],
          ],
          [
            [
              [104.178719, 0.78228],
              [104.245346, 0.820324],
              [104.265961, 0.85921],
              [104.184509, 0.88475],
              [104.167175, 0.867541],
              [104.140556, 0.942221],
              [104.119728, 0.961952],
              [104.078018, 0.959827],
              [104.094444, 0.926755],
              [104.09214, 0.870374],
              [104.14537, 0.856911],
              [104.178719, 0.78228],
            ],
          ],
          [
            [
              [104.168579, 0.941861],
              [104.161392, 0.910555],
              [104.191391, 0.928334],
              [104.168579, 0.941861],
            ],
          ],
          [
            [
              [104.21447, 0.916711],
              [104.204201, 0.922235],
              [104.193329, 0.902286],
              [104.21447, 0.916711],
            ],
          ],
          [
            [
              [104.048843, 0.921121],
              [104.035652, 0.892237],
              [104.051514, 0.900766],
              [104.048843, 0.921121],
            ],
          ],
          [
            [
              [104.193886, 0.78806],
              [104.170685, 0.77976],
              [104.196388, 0.720833],
              [104.218613, 0.724445],
              [104.253304, 0.692524],
              [104.248886, 0.723611],
              [104.309097, 0.707096],
              [104.264015, 0.743706],
              [104.276863, 0.796511],
              [104.193886, 0.78806],
            ],
          ],
          [
            [
              [104.30864, 0.76319],
              [104.299721, 0.755833],
              [104.357208, 0.741919],
              [104.30864, 0.76319],
            ],
          ],
          [
            [
              [104.264999, 0.621389],
              [104.289169, 0.610554],
              [104.285561, 0.66168],
              [104.212471, 0.718914],
              [104.264999, 0.621389],
            ],
          ],
          [
            [
              [104.29319, 0.689987],
              [104.270416, 0.682853],
              [104.301392, 0.671667],
              [104.29319, 0.689987],
            ],
          ],
          [
            [
              [104.326897, 0.678119],
              [104.314339, 0.684932],
              [104.312317, 0.66227],
              [104.329605, 0.660429],
              [104.326897, 0.678119],
            ],
          ],
          [
            [
              [104.085518, 0.643431],
              [104.085831, 0.6025],
              [104.104195, 0.624462],
              [104.085518, 0.643431],
            ],
          ],
          [
            [
              [104.198395, 0.608487],
              [104.197502, 0.541668],
              [104.203529, 0.572165],
              [104.230003, 0.570278],
              [104.198395, 0.608487],
            ],
          ],
          [
            [
              [104.22525, 0.557619],
              [104.2192, 0.541424],
              [104.238327, 0.53083],
              [104.22525, 0.557619],
            ],
          ],
          [
            [
              [103.388893, 1.16639],
              [103.379448, 1.150279],
              [103.40889, 1.129167],
              [103.388893, 1.16639],
            ],
          ],
          [
            [
              [103.375, 1.13806],
              [103.310898, 1.0875],
              [103.312225, 1.043889],
              [103.350723, 1.004977],
              [103.438919, 0.988355],
              [103.425003, 1.045559],
              [103.389725, 1.066666],
              [103.375, 1.13806],
            ],
          ],
          [
            [
              [103.444168, 0.962781],
              [103.425835, 0.95611],
              [103.475281, 0.919721],
              [103.473892, 0.949719],
              [103.444168, 0.962781],
            ],
          ],
          [
            [
              [103.418053, 0.95],
              [103.406944, 0.940555],
              [103.419327, 0.913091],
              [103.439163, 0.930834],
              [103.418053, 0.95],
            ],
          ],
          [
            [
              [103.526588, 0.867764],
              [103.489807, 0.926629],
              [103.501663, 0.86169],
              [103.526588, 0.867764],
            ],
          ],
          [
            [
              [103.448593, 0.92195],
              [103.409561, 0.892529],
              [103.486595, 0.854903],
              [103.47905, 0.908221],
              [103.448593, 0.92195],
            ],
          ],
          [
            [
              [103.752777, 0.90139],
              [103.744156, 0.894441],
              [103.765831, 0.882501],
              [103.752777, 0.90139],
            ],
          ],
          [
            [
              [103.932793, 0.76699],
              [103.906944, 0.833333],
              [103.896111, 0.804721],
              [103.898209, 0.854561],
              [103.810516, 0.899544],
              [103.835861, 0.838657],
              [103.918098, 0.755371],
              [103.932793, 0.76699],
            ],
          ],
          [
            [
              [103.505753, 0.723843],
              [103.486664, 0.794171],
              [103.412231, 0.84503],
              [103.405396, 0.882045],
              [103.370277, 0.894444],
              [103.34333, 0.800561],
              [103.400017, 0.649092],
              [103.458176, 0.638205],
              [103.505753, 0.723843],
            ],
          ],
          [
            [
              [103.748337, 0.886391],
              [103.719788, 0.85222],
              [103.829681, 0.752109],
              [103.83432, 0.812035],
              [103.748337, 0.886391],
            ],
          ],
          [
            [
              [103.422485, 0.875584],
              [103.421257, 0.841823],
              [103.472771, 0.822776],
              [103.527649, 0.76686],
              [103.494286, 0.845531],
              [103.422485, 0.875584],
            ],
          ],
          [
            [
              [103.671112, 0.864168],
              [103.654678, 0.873375],
              [103.693024, 0.823861],
              [103.671112, 0.864168],
            ],
          ],
          [
            [
              [103.701981, 0.857501],
              [103.691391, 0.856667],
              [103.696854, 0.836451],
              [103.701981, 0.857501],
            ],
          ],
          [
            [
              [103.699379, 0.80447],
              [103.661285, 0.828747],
              [103.713066, 0.752],
              [103.728027, 0.766115],
              [103.699379, 0.80447],
            ],
          ],
          [
            [
              [103.54023, 0.793539],
              [103.529892, 0.776238],
              [103.543266, 0.754735],
              [103.554886, 0.778246],
              [103.54023, 0.793539],
            ],
          ],
          [
            [
              [103.966095, 0.76825],
              [103.962479, 0.777925],
              [103.95089, 0.763291],
              [103.966095, 0.76825],
            ],
          ],
          [
            [
              [103.654968, 0.74142],
              [103.674232, 0.712245],
              [103.687973, 0.723951],
              [103.654968, 0.74142],
            ],
          ],
          [
            [
              [103.746147, 0.736406],
              [103.692604, 0.728509],
              [103.693207, 0.710456],
              [103.741257, 0.684986],
              [103.746147, 0.736406],
            ],
          ],
          [
            [
              [103.509483, 0.702729],
              [103.495583, 0.692653],
              [103.516907, 0.69439],
              [103.509483, 0.702729],
            ],
          ],
          [
            [
              [103.520882, 0.6907],
              [103.488167, 0.681338],
              [103.46209, 0.622588],
              [103.490433, 0.625259],
              [103.520882, 0.6907],
            ],
          ],
          [
            [
              [103.683189, 0.632263],
              [103.673744, 0.613337],
              [103.706596, 0.615174],
              [103.683189, 0.632263],
            ],
          ],
          [
            [
              [103.64637, 0.621533],
              [103.63105, 0.621476],
              [103.664047, 0.614349],
              [103.64637, 0.621533],
            ],
          ],
          [
            [
              [103.595352, 0.531329],
              [103.576202, 0.558519],
              [103.597855, 0.490893],
              [103.618752, 0.494904],
              [103.623047, 0.507661],
              [103.595352, 0.531329],
            ],
          ],
          [
            [
              [103.636169, 0.532426],
              [103.61972, 0.526666],
              [103.634186, 0.514899],
              [103.636169, 0.532426],
            ],
          ],
          [
            [
              [104.221107, 1.058612],
              [104.250557, 1.00417],
              [104.331375, 0.994548],
              [104.316818, 1.039859],
              [104.37925, 1.010575],
              [104.416809, 1.068339],
              [104.416954, 1.039773],
              [104.498337, 1.03639],
              [104.481018, 1.014196],
              [104.445686, 1.017259],
              [104.440353, 0.99803],
              [104.495552, 0.97972],
              [104.415306, 0.978571],
              [104.40889, 0.963611],
              [104.498665, 0.935507],
              [104.476883, 0.919293],
              [104.438118, 0.932751],
              [104.438919, 0.906104],
              [104.491501, 0.875025],
              [104.476234, 0.849759],
              [104.557312, 0.813413],
              [104.594337, 0.810601],
              [104.621002, 0.900818],
              [104.660164, 0.9152],
              [104.634163, 0.986188],
              [104.657051, 1.045382],
              [104.577156, 1.156674],
              [104.584167, 1.228332],
              [104.545555, 1.2175],
              [104.562225, 1.190556],
              [104.499443, 1.169166],
              [104.39389, 1.204445],
              [104.371727, 1.179231],
              [104.311668, 1.182501],
              [104.334999, 1.145],
              [104.238609, 1.124171],
              [104.221107, 1.058612],
            ],
          ],
          [
            [
              [107.41111, 1.103226],
              [107.403893, 1.113334],
              [107.388336, 1.088888],
              [107.41111, 1.103226],
            ],
          ],
          [
            [
              [107.504906, 1.048235],
              [107.503738, 1.035955],
              [107.519447, 1.046341],
              [107.504906, 1.048235],
            ],
          ],
          [
            [
              [104.821388, 0.968055],
              [104.854164, 0.9825],
              [104.849442, 1.01333],
              [104.78611, 1.02889],
              [104.821388, 0.968055],
            ],
          ],
          [
            [
              [107.531166, 1.02283],
              [107.544998, 0.972221],
              [107.574539, 1.007085],
              [107.547775, 0.949444],
              [107.603615, 0.991111],
              [107.587502, 1.01111],
              [107.531166, 1.02283],
            ],
          ],
          [
            [
              [107.42778, 0.97889],
              [107.438332, 0.932501],
              [107.468613, 0.923334],
              [107.471107, 0.969441],
              [107.42778, 0.97889],
            ],
          ],
          [
            [
              [104.627151, 0.89593],
              [104.628212, 0.854511],
              [104.661942, 0.880279],
              [104.627151, 0.89593],
            ],
          ],
          [
            [
              [104.683609, 0.895834],
              [104.660278, 0.862499],
              [104.676392, 0.84639],
              [104.695831, 0.848888],
              [104.683609, 0.895834],
            ],
          ],
          [
            [
              [104.45945, 0.884146],
              [104.416557, 0.87629],
              [104.485085, 0.870221],
              [104.45945, 0.884146],
            ],
          ],
          [
            [
              [104.648331, 0.86889],
              [104.62603, 0.803713],
              [104.666389, 0.82],
              [104.648331, 0.86889],
            ],
          ],
          [
            [
              [104.623245, 0.849223],
              [104.621719, 0.86092],
              [104.613647, 0.846101],
              [104.623245, 0.849223],
            ],
          ],
          [
            [
              [104.364296, 0.846365],
              [104.353302, 0.845016],
              [104.356056, 0.81376],
              [104.364296, 0.846365],
            ],
          ],
          [
            [
              [104.548058, 0.795278],
              [104.508789, 0.773499],
              [104.50972, 0.743335],
              [104.587502, 0.776111],
              [104.548058, 0.795278],
            ],
          ],
          [
            [
              [104.593887, 0.794171],
              [104.585556, 0.751667],
              [104.636665, 0.78389],
              [104.593887, 0.794171],
            ],
          ],
          [
            [
              [104.706322, 0.790191],
              [104.687912, 0.772549],
              [104.750153, 0.753349],
              [104.74704, 0.786283],
              [104.706322, 0.790191],
            ],
          ],
          [
            [
              [104.696114, 0.763334],
              [104.734398, 0.717881],
              [104.762222, 0.733334],
              [104.757629, 0.74733],
              [104.696114, 0.763334],
            ],
          ],
          [
            [
              [104.659164, 0.753333],
              [104.615585, 0.738065],
              [104.601768, 0.699288],
              [104.620163, 0.69751],
              [104.659164, 0.753333],
            ],
          ],
          [
            [
              [107.241669, 0.14639],
              [107.204437, 0.12694],
              [107.22139, 0.098888],
              [107.251389, 0.141109],
              [107.241669, 0.14639],
            ],
          ],
          [
            [
              [104.408302, 0.472981],
              [104.40168, 0.46247],
              [104.426178, 0.459885],
              [104.408302, 0.472981],
            ],
          ],
          [
            [
              [104.521347, 0.424976],
              [104.533157, 0.361604],
              [104.52916, 0.404983],
              [104.5625, 0.4125],
              [104.521347, 0.424976],
            ],
          ],
          [
            [
              [104.324257, 0.38417],
              [104.305267, 0.385],
              [104.325531, 0.369253],
              [104.324257, 0.38417],
            ],
          ],
          [
            [
              [104.430405, 0.384874],
              [104.408951, 0.377782],
              [104.433197, 0.372036],
              [104.430405, 0.384874],
            ],
          ],
          [
            [
              [104.414612, 0.301367],
              [104.398056, 0.313888],
              [104.43354, 0.307211],
              [104.424026, 0.326898],
              [104.328743, 0.375651],
              [104.354446, 0.313888],
              [104.389725, 0.308612],
              [104.398888, 0.284444],
              [104.414612, 0.301367],
            ],
          ],
          [
            [
              [104.468361, 0.364601],
              [104.459511, 0.34875],
              [104.490631, 0.346131],
              [104.468361, 0.364601],
            ],
          ],
          [
            [
              [104.302498, 0.346945],
              [104.301392, 0.30944],
              [104.319168, 0.308056],
              [104.32222, 0.336388],
              [104.302498, 0.346945],
            ],
          ],
          [
            [
              [104.327499, 0.32806],
              [104.355278, 0.27861],
              [104.386673, 0.273609],
              [104.369446, 0.30528],
              [104.327499, 0.32806],
            ],
          ],
          [
            [
              [104.362251, 0.268636],
              [104.351685, 0.274184],
              [104.363892, 0.255834],
              [104.362251, 0.268636],
            ],
          ],
          [
            [
              [104.498802, 0.261466],
              [104.489418, 0.266601],
              [104.512192, 0.250579],
              [104.498802, 0.261466],
            ],
          ],
          [
            [
              [104.443611, 0.250834],
              [104.447258, 0.234139],
              [104.473717, 0.227603],
              [104.443611, 0.250834],
            ],
          ],
          [
            [
              [104.515556, 0.225561],
              [104.47583, 0.242777],
              [104.52417, 0.19726],
              [104.527908, 0.184785],
              [104.504723, 0.191389],
              [104.536842, 0.15057],
              [104.54528, 0.16806],
              [104.569168, 0.142219],
              [104.545097, 0.145139],
              [104.564049, 0.115879],
              [104.619156, 0.075001],
              [104.605606, 0.095834],
              [104.627777, 0.095834],
              [104.634163, 0.077501],
              [104.621956, 0.074787],
              [104.702049, 0.018291],
              [104.704475, 0.046888],
              [104.514519, 0.247661],
              [104.531113, 0.20944],
              [104.515556, 0.225561],
            ],
          ],
          [
            [
              [104.232468, 0.199581],
              [104.200279, 0.170834],
              [104.227524, 0.154723],
              [104.241783, 0.17658],
              [104.232468, 0.199581],
            ],
          ],
          [
            [
              [104.473442, 0.177131],
              [104.461945, 0.170278],
              [104.483856, 0.161489],
              [104.473442, 0.177131],
            ],
          ],
          [
            [
              [104.298744, 0.166706],
              [104.300751, 0.128406],
              [104.336937, 0.10628],
              [104.336342, 0.130701],
              [104.298744, 0.166706],
            ],
          ],
          [
            [
              [104.435089, 0.064922],
              [104.494789, 0.014492],
              [104.462502, 0.060278],
              [104.486755, 0.043938],
              [104.492004, 0.072191],
              [104.401947, 0.162431],
              [104.432777, 0.089441],
              [104.378044, 0.123239],
              [104.435089, 0.064922],
            ],
          ],
          [
            [
              [104.488892, 0.151944],
              [104.516388, 0.108055],
              [104.516388, 0.130833],
              [104.488892, 0.151944],
            ],
          ],
          [
            [
              [104.54187, 0.102252],
              [104.534019, 0.095916],
              [104.56105, 0.072029],
              [104.54187, 0.102252],
            ],
          ],
          [
            [
              [104.743607, 0.08667],
              [104.740501, 0.075097],
              [104.754639, 0.078303],
              [104.743607, 0.08667],
            ],
          ],
          [
            [
              [104.491394, 0.088336],
              [104.480873, 0.087456],
              [104.498795, 0.071992],
              [104.491394, 0.088336],
            ],
          ],
          [
            [
              [104.769371, 0.059421],
              [104.738609, 0.048333],
              [104.739166, 0.0325],
              [104.784454, 0.030865],
              [104.769371, 0.059421],
            ],
          ],
          [
            [
              [104.539162, 0.044531],
              [104.521446, 0.051195],
              [104.543839, 0.032846],
              [104.539162, 0.044531],
            ],
          ],
          [
            [
              [104.659157, -0.110831],
              [104.624443, -0.079445],
              [104.668053, -0.077779],
              [104.653336, -0.051667],
              [104.552177, 0.02917],
              [104.565834, -0.011944],
              [104.548897, 0.0027],
              [104.531387, -0.02444],
              [104.493614, 0.003333],
              [104.504997, -0.129166],
              [104.426109, -0.184169],
              [104.429169, -0.236666],
              [104.498611, -0.24194],
              [104.521667, -0.276239],
              [104.703331, -0.20472],
              [104.849167, -0.322499],
              [104.915833, -0.339722],
              [104.929733, -0.324721],
              [104.899796, -0.294237],
              [104.921944, -0.277777],
              [105.001732, -0.30332],
              [104.997498, -0.278056],
              [104.896942, -0.231112],
              [104.827225, -0.132779],
              [104.823334, -0.195277],
              [104.788055, -0.204863],
              [104.682777, -0.083889],
              [104.659157, -0.110831],
            ],
          ],
          [
            [
              [104.532501, 0.026389],
              [104.513245, 0.013289],
              [104.522987, -0.001248],
              [104.545479, 0.006588],
              [104.532501, 0.026389],
            ],
          ],
          [
            [
              [104.842224, -0.035555],
              [104.837502, -0.053059],
              [104.879166, -0.081111],
              [104.842224, -0.035555],
            ],
          ],
          [
            [
              [104.731598, -0.098751],
              [104.745003, -0.139445],
              [104.766388, -0.148334],
              [104.731598, -0.098751],
            ],
          ],
          [
            [
              [104.470001, -0.277219],
              [104.385834, -0.298611],
              [104.459717, -0.328329],
              [104.46389, -0.31333],
              [104.491943, -0.307778],
              [104.470001, -0.277219],
            ],
          ],
          [
            [
              [104.233887, -0.331943],
              [104.221107, -0.343335],
              [104.233055, -0.355555],
              [104.233887, -0.331943],
            ],
          ],
          [
            [
              [104.320831, -0.360834],
              [104.276947, -0.395556],
              [104.2425, -0.485833],
              [104.30722, -0.539719],
              [104.330559, -0.514445],
              [104.355835, -0.679443],
              [104.389168, -0.666944],
              [104.395279, -0.604722],
              [104.428886, -0.57639],
              [104.503326, -0.64694],
              [104.517776, -0.57167],
              [104.597427, -0.45986],
              [104.480415, -0.335485],
              [104.440277, -0.340278],
              [104.430275, -0.383611],
              [104.385834, -0.413334],
              [104.346947, -0.3875],
              [104.346107, -0.44639],
              [104.311119, -0.416878],
              [104.337776, -0.38889],
              [104.324722, -0.339439],
              [104.320831, -0.360834],
            ],
          ],
          [
            [
              [104.156113, -0.3375],
              [104.142776, -0.350277],
              [104.157501, -0.36528],
              [104.177498, -0.350833],
              [104.156113, -0.3375],
            ],
          ],
          [
            [
              [104.186111, -0.360556],
              [104.185555, -0.38889],
              [104.234169, -0.386112],
              [104.230278, -0.364439],
              [104.186111, -0.360556],
            ],
          ],
          [
            [
              [104.196114, -0.392222],
              [104.195274, -0.42361],
              [104.223053, -0.429445],
              [104.229156, -0.39389],
              [104.196114, -0.392222],
            ],
          ],
          [
            [
              [107.964722, 4.66889],
              [108.007179, 4.775322],
              [107.927498, 4.693334],
              [107.964722, 4.66889],
            ],
          ],
          [
            [
              [108.120285, 3.726185],
              [108.089989, 3.67947],
              [108.156494, 3.630646],
              [108.223572, 3.663732],
              [108.296234, 3.657469],
              [108.309113, 3.730069],
              [108.387474, 3.793217],
              [108.410088, 3.858323],
              [108.407944, 3.886981],
              [108.378372, 3.856221],
              [108.367233, 3.895001],
              [108.352882, 3.876487],
              [108.362556, 3.90342],
              [108.389877, 3.893811],
              [108.402809, 3.96678],
              [108.233963, 4.128587],
              [108.242348, 4.216281],
              [108.218857, 4.23043],
              [108.07386, 4.06799],
              [107.961945, 4.003333],
              [108.061745, 3.83516],
              [108.208305, 3.802454],
              [108.275383, 3.710817],
              [108.18882, 3.783804],
              [108.120285, 3.726185],
            ],
          ],
          [
            [
              [107.860283, 4.160001],
              [107.84417, 4.111667],
              [107.863892, 4.1275],
              [107.860283, 4.160001],
            ],
          ],
          [
            [
              [107.897781, 3.913612],
              [107.894302, 3.892223],
              [107.911942, 3.88139],
              [107.921669, 3.900555],
              [107.897781, 3.913612],
            ],
          ],
          [
            [
              [108.002502, 3.817779],
              [108.012573, 3.778473],
              [108.040001, 3.765833],
              [108.02861, 3.80722],
              [108.002502, 3.817779],
            ],
          ],
          [
            [
              [108.116943, 3.763059],
              [108.113892, 3.751388],
              [108.126389, 3.755278],
              [108.116943, 3.763059],
            ],
          ],
          [
            [
              [108.05246, 3.715161],
              [108.044151, 3.676598],
              [108.085945, 3.682095],
              [108.089447, 3.694444],
              [108.05246, 3.715161],
            ],
          ],
          [
            [
              [108.053886, 3.669722],
              [108.050545, 3.631025],
              [108.081947, 3.633611],
              [108.083054, 3.654445],
              [108.053886, 3.669722],
            ],
          ],
          [
            [
              [108.105278, 3.637221],
              [108.076401, 3.612536],
              [108.07, 3.583609],
              [108.121407, 3.606961],
              [108.105278, 3.637221],
            ],
          ],
          [
            [
              [108.044167, 3.593891],
              [108.033058, 3.574905],
              [108.044701, 3.556943],
              [108.044167, 3.593891],
            ],
          ],
          [
            [
              [108.087624, 3.583575],
              [108.080826, 3.574171],
              [108.095558, 3.572221],
              [108.087624, 3.583575],
            ],
          ],
          [
            [
              [106.343887, 3.417499],
              [106.329086, 3.40451],
              [106.320557, 3.32194],
              [106.338264, 3.351805],
              [106.343887, 3.417499],
            ],
          ],
          [
            [
              [106.279671, 3.402523],
              [106.268394, 3.394186],
              [106.290833, 3.387221],
              [106.279671, 3.402523],
            ],
          ],
          [
            [
              [106.440002, 3.395834],
              [106.427223, 3.3725],
              [106.443336, 3.379166],
              [106.440002, 3.395834],
            ],
          ],
          [
            [
              [106.185555, 3.300832],
              [106.199997, 3.288056],
              [106.20137, 3.325823],
              [106.212502, 3.297222],
              [106.226669, 3.31167],
              [106.226967, 3.394551],
              [106.171387, 3.332779],
              [106.185555, 3.300832],
            ],
          ],
          [
            [
              [106.260559, 3.34778],
              [106.226936, 3.259441],
              [106.284164, 3.224999],
              [106.260559, 3.289166],
              [106.273056, 3.29694],
              [106.288055, 3.250001],
              [106.302643, 3.38014],
              [106.269447, 3.381671],
              [106.264488, 3.336977],
              [106.260559, 3.34778],
            ],
          ],
          [
            [
              [106.407776, 3.308333],
              [106.392776, 3.306391],
              [106.394157, 3.289441],
              [106.407776, 3.308333],
            ],
          ],
          [
            [
              [106.449928, 3.250835],
              [106.437233, 3.24],
              [106.454933, 3.225108],
              [106.449928, 3.250835],
            ],
          ],
          [
            [
              [106.202011, 3.228541],
              [106.210556, 3.120833],
              [106.236664, 3.095555],
              [106.248611, 3.121111],
              [106.278267, 3.07611],
              [106.288063, 3.179441],
              [106.226669, 3.235834],
              [106.202011, 3.228541],
            ],
          ],
          [
            [
              [106.304733, 3.234441],
              [106.291664, 3.230277],
              [106.297379, 3.215611],
              [106.304733, 3.234441],
            ],
          ],
          [
            [
              [106.486107, 3.21139],
              [106.47683, 3.19026],
              [106.498055, 3.183612],
              [106.501877, 3.202296],
              [106.486107, 3.21139],
            ],
          ],
          [
            [
              [106.316948, 3.163056],
              [106.284447, 3.123055],
              [106.308411, 3.083893],
              [106.343544, 3.123333],
              [106.333611, 3.158612],
              [106.311386, 3.135834],
              [106.316948, 3.163056],
            ],
          ],
          [
            [
              [106.086945, 3.158334],
              [106.073891, 3.146389],
              [106.089157, 3.12278],
              [106.099167, 3.13861],
              [106.086945, 3.158334],
            ],
          ],
          [
            [
              [106.419167, 3.150555],
              [106.402779, 3.134444],
              [106.436386, 3.128612],
              [106.419167, 3.150555],
            ],
          ],
          [
            [
              [106.395554, 3.15139],
              [106.379448, 3.138332],
              [106.396942, 3.136391],
              [106.395554, 3.15139],
            ],
          ],
          [
            [
              [106.118614, 3.133888],
              [106.109726, 3.124999],
              [106.122498, 3.116945],
              [106.118614, 3.133888],
            ],
          ],
          [
            [
              [105.652222, 3.120833],
              [105.639168, 3.090277],
              [105.674446, 3.090347],
              [105.652222, 3.120833],
            ],
          ],
          [
            [
              [105.687767, 3.118059],
              [105.673889, 3.109444],
              [105.694717, 3.102219],
              [105.687767, 3.118059],
            ],
          ],
          [
            [
              [105.960831, 3.102222],
              [105.946663, 3.098055],
              [105.961388, 3.091111],
              [105.960831, 3.102222],
            ],
          ],
          [
            [
              [105.701668, 3.10028],
              [105.711388, 3.073609],
              [105.735275, 3.083056],
              [105.701668, 3.10028],
            ],
          ],
          [
            [
              [106.356041, 3.092223],
              [106.34111, 3.064445],
              [106.353615, 3.048056],
              [106.356041, 3.092223],
            ],
          ],
          [
            [
              [105.98278, 3.087781],
              [105.963333, 3.040277],
              [105.977554, 3.016952],
              [105.991669, 3.071941],
              [105.98278, 3.087781],
            ],
          ],
          [
            [
              [105.835556, 2.99167],
              [105.7425, 2.97222],
              [105.739998, 3.05],
              [105.719032, 3.029859],
              [105.690552, 3.057779],
              [105.70472, 3.031945],
              [105.675003, 3.008611],
              [105.713058, 2.983333],
              [105.714722, 2.959167],
              [105.693054, 2.850277],
              [105.72583, 2.816667],
              [105.769997, 2.839166],
              [105.722557, 2.873294],
              [105.76722, 2.871391],
              [105.78389, 2.907499],
              [105.821945, 2.88639],
              [105.79583, 2.908611],
              [105.828056, 2.910556],
              [105.82, 2.96889],
              [105.852219, 2.957777],
              [105.835556, 2.99167],
            ],
          ],
          [
            [
              [108.845383, 3.052106],
              [108.842628, 3.011941],
              [108.864662, 3.013659],
              [108.868889, 3.042221],
              [108.845383, 3.052106],
            ],
          ],
          [
            [
              [106.408333, 3.037223],
              [106.392502, 3.029171],
              [106.407776, 3.012779],
              [106.408333, 3.037223],
            ],
          ],
          [
            [
              [107.785553, 3.02028],
              [107.747498, 3.006042],
              [107.757477, 2.971379],
              [107.813263, 2.98029],
              [107.785553, 3.02028],
            ],
          ],
          [
            [
              [108.867599, 3.010284],
              [108.774979, 2.89059],
              [108.817139, 2.843667],
              [108.884453, 2.879908],
              [108.890396, 2.99946],
              [108.867599, 3.010284],
            ],
          ],
          [
            [
              [106.12722, 2.939444],
              [106.111946, 2.92694],
              [106.149445, 2.918888],
              [106.12722, 2.939444],
            ],
          ],
          [
            [
              [105.68898, 2.924182],
              [105.67778, 2.918888],
              [105.6875, 2.908889],
              [105.68898, 2.924182],
            ],
          ],
          [
            [
              [106.221657, 2.745001],
              [106.254448, 2.72389],
              [106.228333, 2.804441],
              [106.196114, 2.811112],
              [106.222221, 2.767223],
              [106.200279, 2.75889],
              [106.221657, 2.745001],
            ],
          ],
          [
            [
              [108.921387, 2.767501],
              [108.886551, 2.726805],
              [108.914436, 2.737501],
              [108.921387, 2.767501],
            ],
          ],
          [
            [
              [106.167778, 2.762223],
              [106.184723, 2.739167],
              [106.193336, 2.746109],
              [106.167778, 2.762223],
            ],
          ],
          [
            [
              [108.559059, 2.722721],
              [108.578888, 2.696112],
              [108.545631, 2.708569],
              [108.569534, 2.680347],
              [108.535767, 2.67608],
              [108.535835, 2.652683],
              [108.583336, 2.686391],
              [108.559059, 2.722721],
            ],
          ],
          [
            [
              [109.053337, 2.495001],
              [109.113327, 2.503611],
              [109.120514, 2.530496],
              [108.975914, 2.555733],
              [108.979179, 2.520744],
              [109.031776, 2.516169],
              [109.006645, 2.489467],
              [109.053337, 2.495001],
            ],
          ],
          [
            [
              [108.949013, 2.500911],
              [108.949013, 2.533502],
              [108.906662, 2.501989],
              [108.933647, 2.44834],
              [108.973106, 2.454099],
              [108.974968, 2.49068],
              [108.949013, 2.500911],
            ],
          ],
          [
            [
              [108.985863, 2.506519],
              [108.988022, 2.48843],
              [108.99971, 2.492658],
              [108.985863, 2.506519],
            ],
          ],
          [
            [
              [108.99913, 2.465561],
              [108.99913, 2.483391],
              [108.984047, 2.483391],
              [108.984047, 2.465561],
              [108.99913, 2.465561],
            ],
          ],
          [
            [
              [104.392174, 1.015839],
              [104.365883, 0.98954],
              [104.397118, 0.996733],
              [104.392174, 1.015839],
            ],
          ],
        ],
      },
      properties: {
        cartodb_id: 9,
        country: 'Indonesia',
        id_1: 18,
        state: 'Kepulauan Riau',
        key: 'indonesia-kepulauanriau',
        name: 'Riau Islands',
        slug: 'riau-islands',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [105.816109, -5.576168],
              [105.820274, -5.326111],
              [105.863892, -5.257222],
              [105.862259, -5.010503],
              [105.902496, -4.936987],
              [105.860756, -4.79471],
              [105.868118, -4.681011],
              [105.916115, -4.645775],
              [105.88472, -4.596334],
              [105.91288, -4.437148],
              [105.831627, -4.40745],
              [105.860207, -4.38561],
              [105.821106, -4.32374],
              [105.816193, -4.13085],
              [105.774658, -4.11207],
              [105.75592, -4.13929],
              [105.700241, -4.135269],
              [105.706932, -4.09951],
              [105.649826, -4.083841],
              [105.661049, -4.047099],
              [105.603981, -4.051101],
              [105.62825, -4.004789],
              [105.587097, -3.95424],
              [105.607468, -3.939779],
              [105.561096, -3.92872],
              [105.545303, -3.88211],
              [105.521347, -3.892521],
              [105.523346, -3.839611],
              [105.430122, -3.82491],
              [105.411293, -3.7784],
              [105.356903, -3.80017],
              [105.332626, -3.72732],
              [105.282669, -3.800941],
              [105.277618, -3.877269],
              [105.14061, -3.936611],
              [105.104767, -4.01881],
              [105.119156, -4.046499],
              [105.012337, -4.172759],
              [104.790436, -4.26234],
              [104.643257, -4.24593],
              [104.621758, -4.216279],
              [104.369888, -4.35331],
              [104.323013, -4.45639],
              [104.342293, -4.6013],
              [104.288582, -4.680341],
              [104.367622, -4.797659],
              [104.334213, -4.87058],
              [104.194489, -4.861441],
              [104.152519, -4.915281],
              [104.112068, -4.92016],
              [104.059723, -4.88585],
              [103.962349, -4.90974],
              [103.934792, -4.884859],
              [103.912117, -4.95982],
              [103.844406, -4.91568],
              [103.86615, -4.845619],
              [103.811981, -4.78751],
              [103.720436, -4.800879],
              [103.593361, -4.91072],
              [103.655556, -4.953889],
              [103.651665, -4.988056],
              [103.709473, -4.962867],
              [103.747498, -4.995176],
              [103.747078, -5.04242],
              [103.784447, -5.032499],
              [103.917931, -5.135561],
              [103.935822, -5.178639],
              [103.90638, -5.232224],
              [104.004547, -5.2698],
              [103.995346, -5.319068],
              [104.069443, -5.408611],
              [104.298813, -5.574919],
              [104.320694, -5.615422],
              [104.300415, -5.641329],
              [104.421127, -5.711989],
              [104.564522, -5.866027],
              [104.575829, -5.903603],
              [104.55323, -5.918878],
              [104.583199, -5.94295],
              [104.73275, -5.929052],
              [104.642937, -5.690741],
              [104.526817, -5.543985],
              [104.603241, -5.496449],
              [104.707863, -5.521889],
              [104.776688, -5.605234],
              [104.902603, -5.657041],
              [104.937317, -5.703328],
              [105.029778, -5.72815],
              [105.028854, -5.753013],
              [105.086021, -5.755313],
              [105.108757, -5.77276],
              [105.088547, -5.795772],
              [105.118889, -5.775],
              [105.151642, -5.810341],
              [105.222321, -5.780905],
              [105.155281, -5.75111],
              [105.219101, -5.718774],
              [105.174568, -5.667322],
              [105.209793, -5.647141],
              [105.18972, -5.650001],
              [105.169724, -5.586666],
              [105.244812, -5.587931],
              [105.245903, -5.52097],
              [105.266197, -5.520519],
              [105.250641, -5.46953],
              [105.295197, -5.447589],
              [105.408875, -5.62243],
              [105.52375, -5.695258],
              [105.561081, -5.6697],
              [105.584457, -5.805627],
              [105.705185, -5.848783],
              [105.717697, -5.908195],
              [105.799446, -5.794444],
              [105.816109, -5.576168],
            ],
          ],
          [
            [
              [105.241646, -5.635952],
              [105.21257, -5.622743],
              [105.213242, -5.637924],
              [105.241646, -5.635952],
            ],
          ],
          [
            [
              [105.217499, -5.66139],
              [105.205276, -5.672223],
              [105.215279, -5.690556],
              [105.240837, -5.668334],
              [105.217499, -5.66139],
            ],
          ],
          [
            [
              [105.274536, -5.81453],
              [105.227783, -5.830281],
              [105.279503, -5.840751],
              [105.308296, -5.814659],
              [105.291077, -5.788219],
              [105.274536, -5.81453],
            ],
          ],
          [
            [
              [105.528336, -5.848876],
              [105.491577, -5.876326],
              [105.504662, -5.902967],
              [105.544319, -5.865373],
              [105.528336, -5.848876],
            ],
          ],
          [
            [
              [105.496391, -5.921389],
              [105.462776, -5.945277],
              [105.485909, -5.97187],
              [105.516571, -5.945929],
              [105.496391, -5.921389],
            ],
          ],
          [
            [
              [105.401947, -6.07222],
              [105.357498, -6.108889],
              [105.391945, -6.093888],
              [105.401947, -6.07222],
            ],
          ],
          [
            [
              [105.424164, -6.091944],
              [105.423332, -6.111109],
              [105.43528, -6.103054],
              [105.424164, -6.091944],
            ],
          ],
          [
            [
              [105.446663, -6.132847],
              [105.420212, -6.141597],
              [105.427223, -6.163611],
              [105.460281, -6.160001],
              [105.446663, -6.132847],
            ],
          ],
          [
            [
              [104.807137, -5.8114],
              [104.856941, -5.846668],
              [104.852661, -5.801308],
              [104.781593, -5.760421],
              [104.807137, -5.8114],
            ],
          ],
        ],
      },
      properties: {
        cartodb_id: 11,
        country: 'Indonesia',
        id_1: 19,
        state: 'Lampung',
        key: 'indonesia-lampung',
        name: 'Lampung',
        slug: 'lampung',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [128.289719, -3.62194],
              [128.348053, -3.636389],
              [128.357391, -3.609375],
              [128.32222, -3.565],
              [128.345001, -3.507222],
              [128.285004, -3.48972],
              [128.224167, -3.515556],
              [128.170395, -3.586339],
              [128.026428, -3.591919],
              [127.919998, -3.68222],
              [127.916946, -3.719999],
              [127.943611, -3.775278],
              [127.995277, -3.78333],
              [128.03833, -3.734166],
              [128.239441, -3.63083],
              [128.095276, -3.794445],
              [128.158478, -3.750576],
              [128.263885, -3.73],
              [128.301392, -3.679975],
              [128.256943, -3.62611],
              [128.289719, -3.62194],
            ],
          ],
          [
            [
              [126.303337, -3.671112],
              [126.515274, -3.795001],
              [126.741112, -3.8575],
              [126.810837, -3.805],
              [126.953331, -3.772221],
              [127.050278, -3.689719],
              [127.220276, -3.662777],
              [127.251106, -3.594171],
              [127.268333, -3.387501],
              [127.164719, -3.335831],
              [127.134445, -3.379166],
              [127.076111, -3.37389],
              [127.03083, -3.27389],
              [127.117226, -3.281111],
              [127.107498, -3.22583],
              [126.98674, -3.138895],
              [126.88961, -3.126907],
              [126.790268, -3.058653],
              [126.300224, -3.093173],
              [126.202606, -3.18582],
              [126.09021, -3.112341],
              [126.016945, -3.175001],
              [126.00972, -3.366389],
              [126.073326, -3.477219],
              [126.150558, -3.524723],
              [126.181114, -3.612221],
              [126.303337, -3.671112],
            ],
          ],
          [
            [
              [127.174721, -3.822777],
              [127.143608, -3.843333],
              [127.15139, -3.873055],
              [127.223335, -3.905277],
              [127.238335, -3.84],
              [127.174721, -3.822777],
            ],
          ],
          [
            [
              [134.577774, -5.328055],
              [134.502335, -5.389457],
              [134.556671, -5.39889],
              [134.580551, -5.352223],
              [134.603058, -5.351111],
              [134.577774, -5.328055],
            ],
          ],
          [
            [
              [134.601105, -5.358055],
              [134.578049, -5.362499],
              [134.562775, -5.392778],
              [134.581665, -5.391112],
              [134.601105, -5.358055],
            ],
          ],
          [
            [
              [134.620285, -5.396944],
              [134.61055, -5.381667],
              [134.593338, -5.393334],
              [134.608063, -5.408055],
              [134.620285, -5.396944],
            ],
          ],
          [
            [
              [134.492783, -5.485279],
              [134.532364, -5.483711],
              [134.48584, -5.492499],
              [134.519165, -5.511945],
              [134.505768, -5.552889],
              [134.531387, -5.56944],
              [134.552505, -5.558056],
              [134.610001, -5.566667],
              [134.623489, -5.582019],
              [134.649399, -5.560205],
              [134.698959, -5.568305],
              [134.675308, -5.53818],
              [134.698883, -5.547777],
              [134.705673, -5.51617],
              [134.65416, -5.493889],
              [134.648315, -5.440008],
              [134.616989, -5.421953],
              [134.605576, -5.47054],
              [134.602997, -5.424829],
              [134.572037, -5.418543],
              [134.559982, -5.44936],
              [134.500565, -5.41946],
              [134.492783, -5.485279],
            ],
          ],
          [
            [
              [134.670273, -5.459167],
              [134.653839, -5.442848],
              [134.666672, -5.475834],
              [134.670273, -5.459167],
            ],
          ],
          [
            [
              [134.272614, -5.484723],
              [134.251938, -5.488331],
              [134.255493, -5.554122],
              [134.286072, -5.520829],
              [134.272614, -5.484723],
            ],
          ],
          [
            [
              [134.503067, -5.509811],
              [134.493317, -5.517228],
              [134.509064, -5.525075],
              [134.503067, -5.509811],
            ],
          ],
          [
            [
              [134.303802, -5.700769],
              [134.250854, -5.662261],
              [134.211258, -5.719202],
              [134.260284, -5.759445],
              [134.31369, -5.75189],
              [134.336395, -5.771424],
              [134.333817, -5.7983],
              [134.368057, -5.76889],
              [134.442871, -5.803141],
              [134.374161, -5.799439],
              [134.371109, -5.848334],
              [134.29834, -5.898333],
              [134.297852, -5.927164],
              [134.335831, -5.946667],
              [134.302216, -6.019722],
              [134.431396, -6.017781],
              [134.467606, -5.958799],
              [134.53334, -5.916667],
              [134.585602, -5.933252],
              [134.625, -5.858057],
              [134.587219, -5.935278],
              [134.609116, -5.938565],
              [134.633331, -5.866941],
              [134.637253, -5.929476],
              [134.669998, -5.923331],
              [134.712784, -5.977777],
              [134.745041, -5.9672],
              [134.730942, -5.939905],
              [134.750275, -5.941389],
              [134.701248, -5.911249],
              [134.772018, -5.88906],
              [134.759171, -5.867223],
              [134.769165, -5.8525],
              [134.705002, -5.832127],
              [134.670334, -5.85152],
              [134.714172, -5.81667],
              [134.654449, -5.747222],
              [134.660828, -5.725279],
              [134.638062, -5.71389],
              [134.614716, -5.725001],
              [134.546417, -5.651838],
              [134.527771, -5.564723],
              [134.505554, -5.556666],
              [134.503708, -5.532049],
              [134.489441, -5.574443],
              [134.452988, -5.531769],
              [134.415283, -5.640249],
              [134.354309, -5.686862],
              [134.360245, -5.717511],
              [134.303802, -5.700769],
            ],
          ],
          [
            [
              [134.710632, -5.570544],
              [134.712265, -5.547691],
              [134.698334, -5.575833],
              [134.710632, -5.570544],
            ],
          ],
          [
            [
              [134.730011, -5.689861],
              [134.766754, -5.668211],
              [134.744553, -5.616728],
              [134.687225, -5.606944],
              [134.685837, -5.58227],
              [134.645172, -5.576729],
              [134.645081, -5.601948],
              [134.605164, -5.56952],
              [134.530579, -5.57272],
              [134.547195, -5.649796],
              [134.615005, -5.7225],
              [134.637772, -5.71139],
              [134.715256, -5.742454],
              [134.730011, -5.689861],
            ],
          ],
          [
            [
              [134.244217, -5.62679],
              [134.278183, -5.645819],
              [134.344391, -5.568729],
              [134.304794, -5.563754],
              [134.244217, -5.62679],
            ],
          ],
          [
            [
              [134.670273, -5.754445],
              [134.697784, -5.805001],
              [134.739914, -5.801564],
              [134.741028, -5.740011],
              [134.720673, -5.747931],
              [134.675278, -5.72694],
              [134.670273, -5.754445],
            ],
          ],
          [
            [
              [134.810272, -5.736389],
              [134.788605, -5.731389],
              [134.782776, -5.758333],
              [134.804535, -5.757569],
              [134.810272, -5.736389],
            ],
          ],
          [
            [
              [134.197784, -5.752501],
              [134.194717, -5.828056],
              [134.242783, -5.829444],
              [134.25, -5.773334],
              [134.197784, -5.752501],
            ],
          ],
          [
            [
              [134.756012, -5.795517],
              [134.751938, -5.783611],
              [134.74794, -5.808323],
              [134.756012, -5.795517],
            ],
          ],
          [
            [
              [134.794174, -5.811109],
              [134.766937, -5.823888],
              [134.813675, -5.819724],
              [134.794174, -5.811109],
            ],
          ],
          [
            [
              [134.797272, -5.865881],
              [134.776672, -5.913795],
              [134.804794, -5.87616],
              [134.797272, -5.865881],
            ],
          ],
          [
            [
              [134.628052, -5.908888],
              [134.61412, -5.93606],
              [134.634995, -5.929719],
              [134.628052, -5.908888],
            ],
          ],
          [
            [
              [134.615906, -5.944155],
              [134.56926, -5.982126],
              [134.552704, -5.973682],
              [134.596878, -5.944648],
              [134.536118, -5.918609],
              [134.428604, -6.02111],
              [134.347229, -6.044722],
              [134.311661, -6.02694],
              [134.279999, -6.048057],
              [134.290833, -6.070556],
              [134.271118, -6.110833],
              [134.345001, -6.219999],
              [134.363617, -6.208057],
              [134.373337, -6.23],
              [134.384171, -6.220555],
              [134.395554, -6.234998],
              [134.424164, -6.217777],
              [134.454437, -6.22389],
              [134.435135, -6.233159],
              [134.468613, -6.26111],
              [134.477783, -6.297499],
              [134.511383, -6.28333],
              [134.520279, -6.318333],
              [134.562775, -6.290828],
              [134.547501, -6.351111],
              [134.629074, -6.379359],
              [134.722458, -6.327347],
              [134.70195, -6.30167],
              [134.729721, -6.301111],
              [134.767502, -6.159167],
              [134.678055, -6.190833],
              [134.71167, -6.174722],
              [134.699997, -6.142219],
              [134.732224, -6.160555],
              [134.782501, -6.093329],
              [134.728333, -6.02444],
              [134.689438, -6.054999],
              [134.721115, -6.025559],
              [134.717224, -6.013329],
              [134.64473, -5.971945],
              [134.6586, -5.944901],
              [134.615906, -5.944155],
            ],
          ],
          [
            [
              [134.229446, -6.043888],
              [134.226669, -6.01583],
              [134.161942, -6.039444],
              [134.151672, -6.004167],
              [134.115829, -6.107501],
              [134.150833, -6.15111],
              [134.198608, -6.1275],
              [134.164169, -6.187779],
              [134.229446, -6.201939],
              [134.283615, -6.280556],
              [134.335007, -6.283612],
              [134.339447, -6.31583],
              [134.354446, -6.298609],
              [134.361389, -6.308334],
              [134.337784, -6.331665],
              [134.353333, -6.341388],
              [134.358887, -6.320277],
              [134.422501, -6.27889],
              [134.386948, -6.241667],
              [134.366104, -6.253334],
              [134.359451, -6.22389],
              [134.326385, -6.232778],
              [134.303894, -6.17028],
              [134.237228, -6.133055],
              [134.279999, -6.060833],
              [134.245834, -6.073057],
              [134.229446, -6.043888],
            ],
          ],
          [
            [
              [134.274719, -6.119439],
              [134.264725, -6.128057],
              [134.282776, -6.145278],
              [134.274719, -6.119439],
            ],
          ],
          [
            [
              [134.888611, -6.139721],
              [134.875275, -6.162779],
              [134.886871, -6.163951],
              [134.888611, -6.139721],
            ],
          ],
          [
            [
              [134.223053, -6.221939],
              [134.095551, -6.166667],
              [134.136948, -6.246389],
              [134.117493, -6.243055],
              [134.138062, -6.317501],
              [134.115555, -6.417222],
              [134.139725, -6.463333],
              [134.198059, -6.425001],
              [134.215759, -6.428264],
              [134.183609, -6.458331],
              [134.216949, -6.44528],
              [134.195557, -6.46556],
              [134.234024, -6.509169],
              [134.23584, -6.610278],
              [134.261108, -6.639166],
              [134.296661, -6.63111],
              [134.297501, -6.66111],
              [134.234726, -6.614723],
              [134.201111, -6.486945],
              [134.145279, -6.537779],
              [134.181671, -6.485001],
              [134.136673, -6.482221],
              [134.117218, -6.4475],
              [134.051117, -6.77361],
              [134.095001, -6.850278],
              [134.149719, -6.866111],
              [134.151108, -6.846939],
              [134.190552, -6.846112],
              [134.170563, -6.821388],
              [134.193329, -6.835279],
              [134.176117, -6.857501],
              [134.150284, -6.850278],
              [134.164993, -6.887223],
              [134.150284, -6.869722],
              [134.19722, -6.941945],
              [134.213882, -6.906388],
              [134.377502, -6.818056],
              [134.33194, -6.810833],
              [134.447784, -6.694445],
              [134.431946, -6.657778],
              [134.401947, -6.673055],
              [134.428604, -6.646667],
              [134.463882, -6.68056],
              [134.531113, -6.588889],
              [134.46611, -6.566111],
              [134.50444, -6.521944],
              [134.460831, -6.433889],
              [134.434998, -6.443061],
              [134.404724, -6.398057],
              [134.326385, -6.409721],
              [134.338608, -6.387222],
              [134.294724, -6.348055],
              [134.303894, -6.292499],
              [134.266663, -6.315559],
              [134.286942, -6.288059],
              [134.223053, -6.221939],
            ],
          ],
          [
            [
              [134.863892, -6.185555],
              [134.851395, -6.209723],
              [134.860275, -6.224444],
              [134.875275, -6.195829],
              [134.863892, -6.185555],
            ],
          ],
          [
            [
              [134.216171, -6.194418],
              [134.207581, -6.206261],
              [134.222351, -6.217804],
              [134.216171, -6.194418],
            ],
          ],
          [
            [
              [134.418884, -6.225],
              [134.401657, -6.244596],
              [134.440277, -6.262779],
              [134.418884, -6.225],
            ],
          ],
          [
            [
              [134.366394, -6.225829],
              [134.370834, -6.248333],
              [134.382507, -6.23944],
              [134.366394, -6.225829],
            ],
          ],
          [
            [
              [134.40416, -6.24889],
              [134.399445, -6.258888],
              [134.420837, -6.260139],
              [134.40416, -6.24889],
            ],
          ],
          [
            [
              [134.422775, -6.263889],
              [134.409164, -6.269999],
              [134.429443, -6.28361],
              [134.422775, -6.263889],
            ],
          ],
          [
            [
              [134.464996, -6.441944],
              [134.505554, -6.420556],
              [134.48111, -6.488611],
              [134.505554, -6.497778],
              [134.516388, -6.531388],
              [134.545273, -6.537499],
              [134.573929, -6.483442],
              [134.548889, -6.475281],
              [134.597778, -6.461945],
              [134.611389, -6.429167],
              [134.596115, -6.385],
              [134.546661, -6.363612],
              [134.536118, -6.343057],
              [134.525558, -6.359444],
              [134.503326, -6.303055],
              [134.451385, -6.274441],
              [134.376114, -6.321111],
              [134.361664, -6.369169],
              [134.384171, -6.373333],
              [134.368332, -6.397223],
              [134.405838, -6.396666],
              [134.438049, -6.437777],
              [134.468338, -6.42833],
              [134.464996, -6.441944],
            ],
          ],
          [
            [
              [134.800552, -6.378889],
              [134.825562, -6.433611],
              [134.807495, -6.431941],
              [134.801392, -6.45],
              [134.844727, -6.47722],
              [134.901672, -6.349999],
              [134.891113, -6.286666],
              [134.863892, -6.344723],
              [134.877777, -6.296667],
              [134.851944, -6.283612],
              [134.854172, -6.360556],
              [134.841949, -6.327499],
              [134.800552, -6.378889],
            ],
          ],
          [
            [
              [134.337097, -6.326129],
              [134.325562, -6.289419],
              [134.296539, -6.327809],
              [134.316147, -6.36144],
              [134.337097, -6.326129],
            ],
          ],
          [
            [
              [134.826385, -6.294999],
              [134.786942, -6.33389],
              [134.790558, -6.361112],
              [134.828888, -6.322779],
              [134.826385, -6.294999],
            ],
          ],
          [
            [
              [134.544174, -6.305278],
              [134.523056, -6.324721],
              [134.541382, -6.33417],
              [134.544174, -6.305278],
            ],
          ],
          [
            [
              [134.35527, -6.352777],
              [134.348618, -6.367222],
              [134.363327, -6.359722],
              [134.35527, -6.352777],
            ],
          ],
          [
            [
              [134.754715, -6.359719],
              [134.731674, -6.383612],
              [134.742783, -6.434445],
              [134.754715, -6.359719],
            ],
          ],
          [
            [
              [134.771698, -6.36694],
              [134.767471, -6.407909],
              [134.788681, -6.421297],
              [134.771698, -6.36694],
            ],
          ],
          [
            [
              [134.749161, -6.505001],
              [134.69278, -6.44222],
              [134.63945, -6.479167],
              [134.681671, -6.563611],
              [134.723328, -6.541667],
              [134.748337, -6.550278],
              [134.749161, -6.505001],
            ],
          ],
          [
            [
              [134.633606, -6.44861],
              [134.587219, -6.478332],
              [134.590271, -6.518055],
              [134.625839, -6.502222],
              [134.633606, -6.44861],
            ],
          ],
          [
            [
              [134.582504, -6.486941],
              [134.581116, -6.47583],
              [134.55278, -6.534167],
              [134.582504, -6.486941],
            ],
          ],
          [
            [
              [134.823883, -6.48083],
              [134.774445, -6.515555],
              [134.8125, -6.563059],
              [134.85083, -6.495834],
              [134.823883, -6.48083],
            ],
          ],
          [
            [
              [134.577774, -6.507501],
              [134.563614, -6.545834],
              [134.593338, -6.526666],
              [134.577774, -6.507501],
            ],
          ],
          [
            [
              [134.723892, -6.545277],
              [134.707581, -6.558316],
              [134.725281, -6.571668],
              [134.735275, -6.559998],
              [134.723892, -6.545277],
            ],
          ],
          [
            [
              [134.689438, -6.590831],
              [134.664169, -6.679999],
              [134.624725, -6.71139],
              [134.623062, -6.758333],
              [134.671661, -6.784445],
              [134.704163, -6.709166],
              [134.74028, -6.701945],
              [134.761948, -6.623889],
              [134.722778, -6.582778],
              [134.689438, -6.590831],
            ],
          ],
          [
            [
              [134.60083, -6.598329],
              [134.583054, -6.609444],
              [134.590561, -6.63111],
              [134.60083, -6.598329],
            ],
          ],
          [
            [
              [134.770828, -6.600556],
              [134.762497, -6.633334],
              [134.796387, -6.63639],
              [134.770828, -6.600556],
            ],
          ],
          [
            [
              [134.512497, -6.66222],
              [134.497223, -6.7125],
              [134.528061, -6.692501],
              [134.512497, -6.66222],
            ],
          ],
          [
            [
              [134.493896, -6.682219],
              [134.473053, -6.695001],
              [134.481384, -6.71],
              [134.493896, -6.682219],
            ],
          ],
          [
            [
              [134.505524, -6.710168],
              [134.48056, -6.762222],
              [134.530273, -6.748059],
              [134.505524, -6.710168],
            ],
          ],
          [
            [
              [134.535004, -6.74722],
              [134.513062, -6.783889],
              [134.553055, -6.758333],
              [134.535004, -6.74722],
            ],
          ],
          [
            [
              [134.666946, -6.843056],
              [134.585556, -6.885001],
              [134.606949, -6.903888],
              [134.683884, -6.855832],
              [134.666946, -6.843056],
            ],
          ],
          [
            [
              [134.756668, -6.869722],
              [134.758057, -6.85],
              [134.750275, -6.873056],
              [134.748611, -6.861389],
              [134.704437, -6.8725],
              [134.686386, -6.859723],
              [134.715561, -6.876944],
              [134.756668, -6.869722],
            ],
          ],
          [
            [
              [134.541672, -6.903056],
              [134.520554, -6.916111],
              [134.54805, -6.912223],
              [134.541672, -6.903056],
            ],
          ],
          [
            [
              [134.510834, -6.92972],
              [134.490555, -6.953612],
              [134.530273, -6.948055],
              [134.510834, -6.92972],
            ],
          ],
          [
            [
              [134.67778, -7.003609],
              [134.669174, -7.016388],
              [134.682495, -7.025555],
              [134.67778, -7.003609],
            ],
          ],
          [
            [
              [134.490005, -7.063056],
              [134.471939, -7.088612],
              [134.522781, -7.101389],
              [134.531662, -7.072223],
              [134.490005, -7.063056],
            ],
          ],
          [
            [
              [128.993607, -2.7325],
              [128.982773, -2.728334],
              [128.978333, -2.749167],
              [128.993607, -2.7325],
            ],
          ],
          [
            [
              [128.753159, -2.849721],
              [128.508331, -2.833055],
              [128.397781, -2.870281],
              [128.162506, -2.86361],
              [128.134445, -2.936111],
              [128.071106, -2.9725],
              [128.117935, -2.971319],
              [128.121948, -3.000556],
              [128.087219, -3.021112],
              [128.113617, -3.063889],
              [127.998611, -3.070829],
              [127.912781, -3.150277],
              [127.836937, -3.15806],
              [127.94278, -3.352222],
              [127.894997, -3.489166],
              [127.92083, -3.568056],
              [127.960281, -3.528055],
              [127.945831, -3.466945],
              [127.986389, -3.380278],
              [128.054993, -3.340833],
              [128.090561, -3.131666],
              [128.183884, -3.064999],
              [128.201584, -3.126079],
              [128.158051, -3.160556],
              [128.178894, -3.227779],
              [128.193771, -3.20042],
              [128.269058, -3.20393],
              [128.404999, -3.429166],
              [128.460342, -3.458569],
              [128.676117, -3.4325],
              [128.698883, -3.411669],
              [128.670837, -3.391389],
              [128.679718, -3.35972],
              [128.886383, -3.203609],
              [128.966385, -3.226945],
              [128.96611, -3.290555],
              [128.910187, -3.32841],
              [128.935272, -3.356666],
              [129.083771, -3.341389],
              [129.531952, -3.470277],
              [129.566437, -3.414064],
              [129.49733, -3.327309],
              [129.522507, -3.29833],
              [129.927628, -3.338101],
              [130.017075, -3.483291],
              [130.414993, -3.631111],
              [130.483612, -3.707223],
              [130.567215, -3.720277],
              [130.618332, -3.79194],
              [130.849167, -3.871111],
              [130.821106, -3.833056],
              [130.821945, -3.70139],
              [130.882507, -3.593056],
              [130.806107, -3.486666],
              [130.837494, -3.454722],
              [130.804169, -3.406388],
              [130.663834, -3.400535],
              [130.600555, -3.148331],
              [130.551392, -3.10722],
              [130.494781, -3.10324],
              [130.38472, -2.987777],
              [130.312775, -2.971939],
              [130.196671, -3.00972],
              [130.157501, -2.986667],
              [130.034729, -3.003334],
              [129.910782, -2.943404],
              [129.900925, -2.913839],
              [129.880081, -2.932604],
              [129.878326, -2.896388],
              [129.813446, -2.914061],
              [129.785767, -2.86451],
              [129.548386, -2.80024],
              [129.528336, -2.772499],
              [129.519592, -2.798132],
              [129.376648, -2.789524],
              [129.347427, -2.857137],
              [129.223251, -2.922249],
              [129.219055, -2.953749],
              [129.186386, -2.921388],
              [129.195602, -2.960343],
              [129.131241, -2.969129],
              [129.08371, -2.939113],
              [129.079163, -2.89111],
              [129.064667, -2.90563],
              [129.078049, -2.855279],
              [129.050552, -2.79444],
              [128.919724, -2.851667],
              [128.753159, -2.849721],
            ],
          ],
          [
            [
              [128.649719, -3.631389],
              [128.654724, -3.573057],
              [128.732224, -3.62],
              [128.73027, -3.51278],
              [128.694717, -3.493889],
              [128.681946, -3.543891],
              [128.567215, -3.49694],
              [128.554718, -3.530277],
              [128.649719, -3.631389],
            ],
          ],
          [
            [
              [128.450562, -3.51],
              [128.414444, -3.532221],
              [128.404449, -3.635279],
              [128.575836, -3.58639],
              [128.551117, -3.539166],
              [128.450562, -3.51],
            ],
          ],
          [
            [
              [128.772507, -3.638609],
              [128.761383, -3.688889],
              [128.785278, -3.696109],
              [128.806671, -3.646666],
              [128.772507, -3.638609],
            ],
          ],
          [
            [
              [130.008057, -6.298055],
              [130.003052, -6.311666],
              [130.029724, -6.322777],
              [130.008057, -6.298055],
            ],
          ],
          [
            [
              [131.579437, -6.66833],
              [131.513885, -6.76139],
              [131.52861, -6.804167],
              [131.585556, -6.773888],
              [131.592499, -6.755831],
              [131.559448, -6.751945],
              [131.567505, -6.718332],
              [131.600006, -6.729999],
              [131.624725, -6.697501],
              [131.579437, -6.66833],
            ],
          ],
          [
            [
              [129.531952, -6.712222],
              [129.49472, -6.720001],
              [129.491943, -6.759168],
              [129.531387, -6.743056],
              [129.531952, -6.712222],
            ],
          ],
          [
            [
              [131.537216, -6.811111],
              [131.508057, -6.829167],
              [131.527802, -6.831503],
              [131.537216, -6.811111],
            ],
          ],
          [
            [
              [131.488098, -6.866706],
              [131.469162, -6.878888],
              [131.464172, -6.920833],
              [131.512772, -6.885001],
              [131.488098, -6.866706],
            ],
          ],
          [
            [
              [129.147217, -6.950829],
              [129.122772, -6.970277],
              [129.146393, -6.991111],
              [129.163055, -6.975277],
              [129.147217, -6.950829],
            ],
          ],
          [
            [
              [132.001663, -6.985556],
              [131.918335, -7.046667],
              [131.909439, -7.080277],
              [131.96167, -7.054443],
              [132.001663, -6.985556],
            ],
          ],
          [
            [
              [128.631104, -7.071667],
              [128.594162, -7.065556],
              [128.523895, -7.14139],
              [128.647049, -7.217882],
              [128.689728, -7.164721],
              [128.655487, -7.139029],
              [128.701385, -7.115556],
              [128.631104, -7.071667],
            ],
          ],
          [
            [
              [131.432495, -7.070001],
              [131.432495, -7.090834],
              [131.446396, -7.093334],
              [131.432495, -7.070001],
            ],
          ],
          [
            [
              [131.444443, -7.09722],
              [131.434998, -7.141668],
              [131.455276, -7.121944],
              [131.444443, -7.09722],
            ],
          ],
          [
            [
              [131.752777, -7.178611],
              [131.871384, -7.167778],
              [131.949448, -7.254722],
              [131.986938, -7.213611],
              [131.91806, -7.102777],
              [131.740005, -7.12],
              [131.713608, -7.155277],
              [131.752777, -7.178611],
            ],
          ],
          [
            [
              [131.260559, -7.453609],
              [131.183609, -7.589999],
              [131.201111, -7.584723],
              [131.172501, -7.647499],
              [131.196106, -7.649443],
              [131.155838, -7.672221],
              [131.238693, -7.724999],
              [131.272781, -7.703609],
              [131.248611, -7.735834],
              [131.170563, -7.722223],
              [131.122498, -7.681666],
              [131.105835, -7.742501],
              [131.117783, -7.723609],
              [131.116394, -7.759166],
              [131.141937, -7.73583],
              [131.131104, -7.769723],
              [131.15535, -7.772145],
              [131.111938, -7.786944],
              [131.136383, -7.783056],
              [131.114716, -7.795],
              [131.150833, -7.825001],
              [131.136108, -7.837219],
              [131.107224, -7.822779],
              [131.078613, -7.87083],
              [131.118896, -7.901944],
              [131.109451, -7.999165],
              [131.23111, -8.0075],
              [131.293884, -7.92472],
              [131.292496, -8.03111],
              [131.341949, -7.991667],
              [131.33139, -7.955556],
              [131.355743, -7.93315],
              [131.32695, -7.935001],
              [131.381104, -7.895556],
              [131.363327, -7.87083],
              [131.461105, -7.810556],
              [131.45195, -7.776945],
              [131.479721, -7.782777],
              [131.486115, -7.729168],
              [131.553055, -7.723889],
              [131.620834, -7.646946],
              [131.668228, -7.451498],
              [131.70694, -7.422779],
              [131.657776, -7.429999],
              [131.648056, -7.395279],
              [131.681671, -7.379168],
              [131.631393, -7.258889],
              [131.653061, -7.231388],
              [131.743057, -7.221944],
              [131.718048, -7.18917],
              [131.718887, -7.165],
              [131.690826, -7.161945],
              [131.710281, -7.14417],
              [131.650284, -7.179167],
              [131.65416, -7.118056],
              [131.532501, -7.14472],
              [131.535278, -7.226388],
              [131.482498, -7.234167],
              [131.457504, -7.286671],
              [131.445557, -7.28083],
              [131.452362, -7.345835],
              [131.383606, -7.345828],
              [131.343048, -7.447501],
              [131.323883, -7.41861],
              [131.260559, -7.453609],
            ],
          ],
          [
            [
              [131.486938, -7.163055],
              [131.453613, -7.20222],
              [131.489716, -7.208333],
              [131.486938, -7.163055],
            ],
          ],
          [
            [
              [131.388885, -7.200278],
              [131.364716, -7.231945],
              [131.375, -7.240279],
              [131.405838, -7.23583],
              [131.388885, -7.200278],
            ],
          ],
          [
            [
              [131.470001, -7.23222],
              [131.453888, -7.241111],
              [131.471115, -7.24639],
              [131.470001, -7.23222],
            ],
          ],
          [
            [
              [131.257217, -7.303329],
              [131.207504, -7.358061],
              [131.270004, -7.38028],
              [131.260834, -7.358329],
              [131.30278, -7.33861],
              [131.257217, -7.303329],
            ],
          ],
          [
            [
              [128.555832, -7.35389],
              [128.559952, -7.383482],
              [128.569168, -7.3625],
              [128.555832, -7.35389],
            ],
          ],
          [
            [
              [131.325836, -7.388888],
              [131.315826, -7.407778],
              [131.327896, -7.416375],
              [131.325836, -7.388888],
            ],
          ],
          [
            [
              [131.135559, -7.408334],
              [130.988617, -7.429439],
              [130.96875, -7.490139],
              [131.058884, -7.484441],
              [131.031952, -7.512499],
              [131.059448, -7.52444],
              [131.170273, -7.423611],
              [131.135559, -7.408334],
            ],
          ],
          [
            [
              [130.823883, -7.471944],
              [130.808884, -7.48417],
              [130.836395, -7.498059],
              [130.823883, -7.471944],
            ],
          ],
          [
            [
              [127.368057, -7.655],
              [127.409721, -7.600278],
              [127.47139, -7.588611],
              [127.486664, -7.520556],
              [127.365295, -7.501803],
              [127.370834, -7.585833],
              [127.322502, -7.628612],
              [127.368057, -7.655],
            ],
          ],
          [
            [
              [130.967499, -7.563889],
              [130.955139, -7.540979],
              [130.997498, -7.529445],
              [130.979446, -7.508055],
              [130.936951, -7.530833],
              [130.838608, -7.499722],
              [130.843613, -7.562499],
              [130.910004, -7.57639],
              [130.945007, -7.545556],
              [130.967499, -7.563889],
            ],
          ],
          [
            [
              [131.001114, -7.507499],
              [130.990829, -7.51389],
              [131.003326, -7.52111],
              [131.001114, -7.507499],
            ],
          ],
          [
            [
              [127.294724, -7.510833],
              [127.273888, -7.519722],
              [127.294441, -7.530555],
              [127.294724, -7.510833],
            ],
          ],
          [
            [
              [131.137497, -7.515829],
              [131.127502, -7.534167],
              [131.155273, -7.544444],
              [131.137497, -7.515829],
            ],
          ],
          [
            [
              [129.675003, -7.52722],
              [129.660828, -7.555001],
              [129.716599, -7.572429],
              [129.725555, -7.55111],
              [129.675003, -7.52722],
            ],
          ],
          [
            [
              [127.590279, -7.549722],
              [127.602219, -7.621665],
              [127.618332, -7.596109],
              [127.590279, -7.549722],
            ],
          ],
          [
            [
              [126.824173, -7.690279],
              [126.808891, -7.654439],
              [126.722221, -7.666669],
              [126.638046, -7.60361],
              [126.625, -7.556389],
              [126.470276, -7.588889],
              [126.323586, -7.694791],
              [126.237, -7.687519],
              [126.184311, -7.727495],
              [125.948059, -7.660278],
              [125.915276, -7.744167],
              [125.861664, -7.76861],
              [125.870834, -7.814722],
              [125.806221, -7.861574],
              [125.826668, -7.915555],
              [125.788857, -8.00694],
              [125.948555, -7.915327],
              [126.071098, -7.887846],
              [126.173889, -7.925556],
              [126.420547, -7.93083],
              [126.467758, -7.974339],
              [126.549721, -7.823057],
              [126.695557, -7.750831],
              [126.825645, -7.75311],
              [126.824173, -7.690279],
            ],
          ],
          [
            [
              [131.098618, -7.635001],
              [131.045837, -7.672499],
              [130.970001, -7.680556],
              [130.96666, -7.740556],
              [131.040558, -7.724167],
              [131.036392, -7.687779],
              [131.102493, -7.69556],
              [131.124161, -7.639718],
              [131.098618, -7.635001],
            ],
          ],
          [
            [
              [125.925835, -7.658888],
              [125.909439, -7.677981],
              [125.931114, -7.672221],
              [125.925835, -7.658888],
            ],
          ],
          [
            [
              [130.002228, -7.695831],
              [129.991104, -7.726945],
              [130.026108, -7.745279],
              [130.002228, -7.695831],
            ],
          ],
          [
            [
              [131.09166, -7.723328],
              [131.063889, -7.754167],
              [131.070282, -7.766388],
              [131.092773, -7.750277],
              [131.09166, -7.723328],
            ],
          ],
          [
            [
              [130.073059, -7.753889],
              [130.038895, -7.752777],
              [130.028885, -7.76611],
              [130.08194, -7.78972],
              [130.073059, -7.753889],
            ],
          ],
          [
            [
              [129.755554, -8.05528],
              [129.793335, -8.043055],
              [129.861664, -7.926671],
              [129.857773, -7.843612],
              [129.685837, -7.78639],
              [129.615005, -7.803889],
              [129.58194, -7.900281],
              [129.684448, -8.021667],
              [129.755554, -8.05528],
            ],
          ],
          [
            [
              [129.518326, -7.84229],
              [129.514481, -7.925499],
              [129.544174, -7.951112],
              [129.570831, -7.908611],
              [129.518326, -7.84229],
            ],
          ],
          [
            [
              [125.734169, -7.966109],
              [125.721909, -7.980944],
              [125.737503, -8.059444],
              [125.764168, -8.014167],
              [125.734169, -7.966109],
            ],
          ],
          [
            [
              [131.086105, -8.010554],
              [131.080826, -8.035834],
              [131.100006, -8.052221],
              [131.10611, -8.036666],
              [131.086105, -8.010554],
            ],
          ],
          [
            [
              [131.289444, -8.040559],
              [131.281113, -8.033056],
              [131.267502, -8.06],
              [131.280838, -8.0625],
              [131.289444, -8.040559],
            ],
          ],
          [
            [
              [131.208054, -8.048609],
              [131.191116, -8.08056],
              [131.210556, -8.072221],
              [131.208054, -8.048609],
            ],
          ],
          [
            [
              [130.877228, -8.218888],
              [130.757217, -8.341669],
              [130.817505, -8.341669],
              [130.898605, -8.287221],
              [130.888351, -8.24562],
              [131.054993, -8.207219],
              [131.167221, -8.119723],
              [131.125, -8.11611],
              [131.06778, -8.162219],
              [131.08223, -8.111666],
              [131.018326, -8.078329],
              [130.985001, -8.115834],
              [130.998337, -8.143891],
              [130.936386, -8.125555],
              [130.913055, -8.155555],
              [130.924438, -8.209723],
              [130.877228, -8.218888],
            ],
          ],
          [
            [
              [129.83667, -8.208889],
              [129.924728, -8.091108],
              [129.84639, -8.14361],
              [129.8125, -8.204723],
              [129.83667, -8.208889],
            ],
          ],
          [
            [
              [128.126114, -8.16528],
              [128.088882, -8.136388],
              [127.950836, -8.146945],
              [127.761948, -8.11297],
              [127.805267, -8.19389],
              [128.006393, -8.258611],
              [128.045563, -8.259721],
              [128.074997, -8.19472],
              [128.126114, -8.16528],
            ],
          ],
          [
            [
              [127.65889, -8.238609],
              [127.741669, -8.214722],
              [127.751389, -8.182777],
              [127.687462, -8.154757],
              [127.600555, -8.212499],
              [127.65889, -8.238609],
            ],
          ],
          [
            [
              [128.760559, -8.161109],
              [128.784729, -8.200557],
              [128.79834, -8.19972],
              [128.789444, -8.163329],
              [128.760559, -8.161109],
            ],
          ],
          [
            [
              [128.686386, -8.162778],
              [128.693329, -8.185829],
              [128.718887, -8.18667],
              [128.686386, -8.162778],
            ],
          ],
          [
            [
              [128.965836, -8.234723],
              [129.016113, -8.261667],
              [129.033051, -8.22472],
              [128.964218, -8.185054],
              [128.826111, -8.198054],
              [128.90834, -8.243056],
              [128.965836, -8.234723],
            ],
          ],
          [
            [
              [128.099442, -8.207777],
              [128.085007, -8.262499],
              [128.234726, -8.27111],
              [128.215271, -8.206109],
              [128.099442, -8.207777],
            ],
          ],
          [
            [
              [132.024475, -5.169119],
              [132.020065, -5.15394],
              [132.013367, -5.182081],
              [132.024475, -5.169119],
            ],
          ],
          [
            [
              [133.131668, -5.510279],
              [133.19194, -5.326945],
              [133.15416, -5.272218],
              [133.119659, -5.280763],
              [133.073883, -5.388055],
              [133.004623, -5.648923],
              [132.973404, -5.64253],
              [132.940826, -5.68778],
              [132.936584, -5.77805],
              [132.882217, -5.841945],
              [132.887466, -5.90337],
              [132.867691, -5.904022],
              [132.845001, -6.00806],
              [132.952744, -5.874496],
              [132.96225, -5.744025],
              [133.083618, -5.626945],
              [133.131668, -5.510279],
            ],
          ],
          [
            [
              [132.019302, -5.31234],
              [131.973053, -5.331111],
              [131.97084, -5.385831],
              [132.010544, -5.366],
              [132.019302, -5.31234],
            ],
          ],
          [
            [
              [132.318878, -5.521109],
              [132.325241, -5.581101],
              [132.378525, -5.553165],
              [132.376755, -5.535226],
              [132.355438, -5.553165],
              [132.318878, -5.521109],
            ],
          ],
          [
            [
              [132.811096, -5.64993],
              [132.798615, -5.522219],
              [132.751877, -5.573579],
              [132.741272, -5.638273],
              [132.773636, -5.691395],
              [132.79863, -5.652747],
              [132.800064, -5.698943],
              [132.811096, -5.64993],
            ],
          ],
          [
            [
              [132.738831, -5.528553],
              [132.686417, -5.539044],
              [132.733154, -5.553311],
              [132.738831, -5.528553],
            ],
          ],
          [
            [
              [132.731796, -5.588319],
              [132.72789, -5.57337],
              [132.713409, -5.585303],
              [132.731796, -5.588319],
            ],
          ],
          [
            [
              [132.268112, -5.580624],
              [132.294693, -5.655546],
              [132.319443, -5.629999],
              [132.301697, -5.615317],
              [132.307129, -5.59021],
              [132.268112, -5.580624],
            ],
          ],
          [
            [
              [132.775253, -5.794017],
              [132.773056, -5.813889],
              [132.77861, -5.727223],
              [132.76091, -5.71784],
              [132.763931, -5.746926],
              [132.738647, -5.691361],
              [132.741669, -5.644445],
              [132.719391, -5.661078],
              [132.690643, -5.656661],
              [132.700836, -5.628609],
              [132.711624, -5.651998],
              [132.710968, -5.591031],
              [132.671112, -5.63028],
              [132.61055, -5.593611],
              [132.649139, -5.712461],
              [132.698654, -5.75516],
              [132.714691, -5.83186],
              [132.704437, -5.845626],
              [132.674881, -5.747999],
              [132.689713, -5.834973],
              [132.662781, -5.901668],
              [132.694595, -5.911076],
              [132.693604, -5.951943],
              [132.718857, -5.892761],
              [132.723618, -5.952189],
              [132.780365, -5.941657],
              [132.81395, -5.838629],
              [132.804749, -5.789778],
              [132.775253, -5.794017],
            ],
          ],
          [
            [
              [132.323227, -5.600519],
              [132.313385, -5.611174],
              [132.331207, -5.619659],
              [132.323227, -5.600519],
            ],
          ],
          [
            [
              [132.19339, -5.763149],
              [132.194519, -5.716429],
              [132.169891, -5.740649],
              [132.19339, -5.763149],
            ],
          ],
          [
            [
              [132.799179, -5.72593],
              [132.782928, -5.72815],
              [132.801636, -5.76912],
              [132.799179, -5.72593],
            ],
          ],
          [
            [
              [132.787323, -5.73803],
              [132.776642, -5.735801],
              [132.783203, -5.776719],
              [132.787323, -5.73803],
            ],
          ],
          [
            [
              [132.568604, -5.816667],
              [132.583511, -5.838048],
              [132.59729, -5.831769],
              [132.568604, -5.816667],
            ],
          ],
          [
            [
              [132.606064, -5.841854],
              [132.590302, -5.844113],
              [132.594254, -5.859381],
              [132.60994, -5.857086],
              [132.606064, -5.841854],
            ],
          ],
          [
            [
              [132.529694, -5.838337],
              [132.527817, -5.856623],
              [132.553589, -5.87017],
              [132.529694, -5.838337],
            ],
          ],
          [
            [
              [132.531433, -5.919338],
              [132.528671, -5.874877],
              [132.516449, -5.904332],
              [132.531433, -5.919338],
            ],
          ],
          [
            [
              [132.469452, -6.000001],
              [132.42778, -6.008056],
              [132.432785, -6.032221],
              [132.465561, -6.02333],
              [132.469452, -6.000001],
            ],
          ],
          [
            [
              [127.851936, -3.017499],
              [127.828331, -3.034444],
              [127.924156, -3.034169],
              [128.002502, -2.934999],
              [127.914406, -2.914448],
              [127.941177, -2.939651],
              [127.877777, -2.960277],
              [127.851936, -3.017499],
            ],
          ],
          [
            [
              [127.919266, -2.922427],
              [127.874458, -2.958611],
              [127.910004, -2.949191],
              [127.919266, -2.922427],
            ],
          ],
          [
            [
              [127.653053, -3.209999],
              [127.637779, -3.226389],
              [127.676109, -3.252223],
              [127.790001, -3.256945],
              [127.799446, -3.181389],
              [127.752502, -3.151389],
              [127.653053, -3.209999],
            ],
          ],
          [
            [
              [127.815834, -3.150555],
              [127.774719, -3.157222],
              [127.806107, -3.172499],
              [127.834724, -3.15639],
              [127.815834, -3.150555],
            ],
          ],
          [
            [
              [127.548889, -3.245556],
              [127.541664, -3.253055],
              [127.559723, -3.261667],
              [127.548889, -3.245556],
            ],
          ],
          [
            [
              [127.640831, -3.37139],
              [127.657776, -3.354171],
              [127.572502, -3.255831],
              [127.525002, -3.259167],
              [127.487503, -3.298056],
              [127.509178, -3.330522],
              [127.640831, -3.37139],
            ],
          ],
          [
            [
              [130.780548, -3.297778],
              [130.765549, -3.320556],
              [130.798889, -3.333059],
              [130.797501, -3.306389],
              [130.780548, -3.297778],
            ],
          ],
          [
            [
              [130.932495, -3.873889],
              [130.910004, -3.893056],
              [130.922501, -3.906667],
              [130.956665, -3.88306],
              [130.932495, -3.873889],
            ],
          ],
          [
            [
              [131.416672, -3.94861],
              [131.367111, -3.973494],
              [131.434998, -4.07972],
              [131.447495, -4.038889],
              [131.416672, -3.94861],
            ],
          ],
          [
            [
              [131.20723, -3.978889],
              [131.219162, -4.036111],
              [131.242783, -4.05583],
              [131.242783, -4.001944],
              [131.20723, -3.978889],
            ],
          ],
          [
            [
              [131.283386, -4.071102],
              [131.299515, -4.126871],
              [131.400421, -4.172629],
              [131.37738, -4.127796],
              [131.283386, -4.071102],
            ],
          ],
          [
            [
              [131.623947, -4.435529],
              [131.608063, -4.424444],
              [131.651733, -4.531182],
              [131.680466, -4.539649],
              [131.680557, -4.504722],
              [131.623947, -4.435529],
            ],
          ],
          [
            [
              [129.895004, -4.501939],
              [129.903885, -4.531944],
              [129.907501, -4.505829],
              [129.895004, -4.501939],
            ],
          ],
          [
            [
              [129.879166, -4.505551],
              [129.865829, -4.526111],
              [129.890549, -4.535695],
              [129.893494, -4.512298],
              [129.879166, -4.505551],
            ],
          ],
          [
            [
              [129.907608, -4.564009],
              [129.950455, -4.5601],
              [129.946671, -4.507685],
              [129.921112, -4.544445],
              [129.860748, -4.548698],
              [129.907608, -4.564009],
            ],
          ],
          [
            [
              [129.764999, -4.516111],
              [129.775833, -4.53833],
              [129.784729, -4.523333],
              [129.764999, -4.516111],
            ],
          ],
          [
            [
              [129.692886, -4.541211],
              [129.66806, -4.563334],
              [129.685455, -4.56307],
              [129.692886, -4.541211],
            ],
          ],
          [
            [
              [130.045914, -4.583119],
              [130.030151, -4.588067],
              [130.045364, -4.608466],
              [130.045914, -4.583119],
            ],
          ],
          [
            [
              [131.74028, -4.706108],
              [131.721939, -4.711389],
              [131.740005, -4.788333],
              [131.759445, -4.745],
              [131.74028, -4.706108],
            ],
          ],
        ],
      },
      properties: {
        cartodb_id: 14,
        country: 'Indonesia',
        id_1: 21,
        state: 'Maluku',
        key: 'indonesia-maluku',
        name: 'Maluku',
        slug: 'maluku',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [127.572632, 0.864781],
              [127.628372, 0.86071],
              [127.638008, 0.816914],
              [127.599365, 0.750352],
              [127.547615, 0.730218],
              [127.527023, 0.553289],
              [127.561668, 0.515624],
              [127.578804, 0.414818],
              [127.622307, 0.395641],
              [127.621582, 0.360141],
              [127.709442, 0.352755],
              [127.734138, 0.310791],
              [127.685028, 0.09903],
              [127.712807, 0.103071],
              [127.721947, 0.072396],
              [127.660881, -0.210333],
              [127.687027, -0.269732],
              [127.863892, -0.365918],
              [128.020554, -0.687222],
              [128.217773, -0.773059],
              [128.233337, -0.750279],
              [128.228333, -0.778055],
              [128.282776, -0.836111],
              [128.225281, -0.836669],
              [128.264999, -0.868609],
              [128.314728, -0.862221],
              [128.453049, -0.914445],
              [128.214722, -0.698889],
              [128.048889, -0.416944],
              [127.889999, -0.005412],
              [127.887833, 0.083199],
              [127.929756, 0.109429],
              [127.921341, 0.273263],
              [127.86351, 0.298247],
              [127.879387, 0.353684],
              [127.904602, 0.358614],
              [127.917061, 0.455099],
              [127.968208, 0.481601],
              [128.07872, 0.477141],
              [128.171829, 0.455692],
              [128.179001, 0.428201],
              [128.28154, 0.396471],
              [128.458328, 0.402431],
              [128.55954, 0.317777],
              [128.842987, 0.261582],
              [128.902893, 0.20836],
              [128.8367, 0.291924],
              [128.669403, 0.346011],
              [128.678741, 0.550262],
              [128.571442, 0.562599],
              [128.435013, 0.632571],
              [128.324539, 0.651802],
              [128.198914, 0.789312],
              [128.249298, 0.840531],
              [128.312515, 0.816101],
              [128.28009, 0.866419],
              [128.33461, 0.911724],
              [128.442154, 0.929617],
              [128.618607, 1.046703],
              [128.699631, 1.0648],
              [128.690475, 1.196627],
              [128.758545, 1.394479],
              [128.683533, 1.463291],
              [128.69722, 1.535229],
              [128.731415, 1.5345],
              [128.720078, 1.57407],
              [128.630829, 1.584445],
              [128.513687, 1.533121],
              [128.471619, 1.551562],
              [128.415909, 1.527166],
              [128.343628, 1.459227],
              [128.189438, 1.38743],
              [128.094223, 1.28516],
              [128.096527, 1.240327],
              [128.179718, 1.24231],
              [128.194717, 1.16556],
              [128.164032, 1.12475],
              [127.981392, 1.0875],
              [127.93309, 1.004861],
              [127.932816, 0.882378],
              [127.881882, 0.828353],
              [127.797249, 0.788379],
              [127.70401, 0.87753],
              [127.653976, 0.875638],
              [127.626587, 0.97231],
              [127.654999, 1.026111],
              [127.758331, 1.068057],
              [127.826668, 1.147779],
              [127.903336, 1.149445],
              [127.918892, 1.226944],
              [128.011459, 1.313706],
              [127.987503, 1.365555],
              [128.024445, 1.404425],
              [128.037231, 1.581977],
              [127.987854, 1.615291],
              [128.022202, 1.690769],
              [128.011734, 1.73787],
              [127.960808, 1.768303],
              [127.92778, 1.812222],
              [127.861664, 1.805833],
              [127.841393, 1.84611],
              [127.851669, 1.925278],
              [127.952217, 1.991389],
              [127.954445, 2.039723],
              [128.065002, 2.19972],
              [127.984482, 2.180077],
              [127.95388, 2.221313],
              [127.929169, 2.162174],
              [127.837723, 2.091571],
              [127.693054, 1.930833],
              [127.645279, 1.824167],
              [127.550278, 1.71111],
              [127.583611, 1.664167],
              [127.540833, 1.65056],
              [127.553055, 1.597778],
              [127.50972, 1.55694],
              [127.535835, 1.506111],
              [127.524445, 1.441666],
              [127.406944, 1.233333],
              [127.431389, 1.14028],
              [127.396942, 1.063334],
              [127.419998, 1.031939],
              [127.496109, 1.05389],
              [127.471947, 1.006666],
              [127.511368, 0.973308],
              [127.491714, 0.893051],
              [127.545883, 0.880684],
              [127.52652, 0.85841],
              [127.548134, 0.879663],
              [127.572632, 0.864781],
            ],
          ],
          [
            [
              [126.384773, 1.352299],
              [126.356812, 1.322069],
              [126.393486, 1.280069],
              [126.416801, 1.33168],
              [126.384773, 1.352299],
            ],
          ],
          [
            [
              [126.138519, 0.982963],
              [126.126663, 0.9775],
              [126.161942, 0.957222],
              [126.138519, 0.982963],
            ],
          ],
          [
            [
              [127.421112, 0.369167],
              [127.346535, 0.341464],
              [127.385277, 0.277219],
              [127.440826, 0.315561],
              [127.421112, 0.369167],
            ],
          ],
          [
            [
              [127.124817, 0.143573],
              [127.113495, 0.132631],
              [127.134743, 0.12857],
              [127.124817, 0.143573],
            ],
          ],
          [
            [
              [127.468681, -0.057014],
              [127.466507, 0.072852],
              [127.440552, 0.103055],
              [127.459167, 0.131667],
              [127.426247, 0.139391],
              [127.403893, 0.017779],
              [127.431664, 0.021111],
              [127.44278, 0.006109],
              [127.415131, -0.014024],
              [127.447708, -0.021737],
              [127.468681, -0.057014],
            ],
          ],
          [
            [
              [127.174866, -0.012222],
              [127.163612, -0.015556],
              [127.202774, -0.024167],
              [127.174866, -0.012222],
            ],
          ],
          [
            [
              [127.421944, -0.017222],
              [127.410278, -0.104167],
              [127.416893, -0.06783],
              [127.43972, -0.078889],
              [127.421944, -0.017222],
            ],
          ],
          [
            [
              [127.22155, -0.103711],
              [127.198181, -0.1168],
              [127.219154, -0.137105],
              [127.260551, -0.126267],
              [127.271431, -0.07302],
              [127.24015, -0.078159],
              [127.22155, -0.103711],
            ],
          ],
          [
            [
              [127.132217, -0.1775],
              [127.090279, -0.215833],
              [127.092224, -0.255],
              [127.133057, -0.223557],
              [127.152405, -0.184148],
              [127.132217, -0.1775],
            ],
          ],
          [
            [
              [127.312248, -0.22778],
              [127.297958, -0.227919],
              [127.305412, -0.242159],
              [127.312248, -0.22778],
            ],
          ],
          [
            [
              [127.059166, -0.2275],
              [127.042778, -0.273611],
              [127.021019, -0.262067],
              [127.03714, -0.29202],
              [127.091942, -0.256944],
              [127.059166, -0.2275],
            ],
          ],
          [
            [
              [127.113449, -0.390504],
              [127.102806, -0.425292],
              [127.156166, -0.472789],
              [127.11071, -0.484969],
              [127.127899, -0.518684],
              [127.170509, -0.530768],
              [127.228218, -0.493669],
              [127.263611, -0.492221],
              [127.257317, -0.472979],
              [127.278603, -0.451489],
              [127.263092, -0.407442],
              [127.288239, -0.375017],
              [127.253372, -0.336367],
              [127.256134, -0.293453],
              [127.304955, -0.270117],
              [127.261948, -0.279444],
              [127.23999, -0.24509],
              [127.110275, -0.280556],
              [127.114998, -0.30278],
              [127.139687, -0.311816],
              [127.113449, -0.390504],
            ],
          ],
          [
            [
              [127.545563, -0.296111],
              [127.540077, -0.310059],
              [127.568893, -0.31],
              [127.545563, -0.296111],
            ],
          ],
          [
            [
              [127.653328, -0.656565],
              [127.596687, -0.596369],
              [127.663292, -0.517344],
              [127.692581, -0.456847],
              [127.615646, -0.41221],
              [127.568573, -0.315406],
              [127.520653, -0.301209],
              [127.452133, -0.418334],
              [127.371437, -0.316402],
              [127.320633, -0.329881],
              [127.337738, -0.383115],
              [127.291237, -0.470795],
              [127.306831, -0.513283],
              [127.345863, -0.539422],
              [127.360153, -0.579129],
              [127.389824, -0.588722],
              [127.408478, -0.621257],
              [127.480026, -0.630041],
              [127.441948, -0.737299],
              [127.474213, -0.823986],
              [127.633743, -0.741408],
              [127.682022, -0.83826],
              [127.769165, -0.869999],
              [127.870422, -0.826662],
              [127.899422, -0.796171],
              [127.879829, -0.77516],
              [127.906044, -0.784739],
              [127.872421, -0.750883],
              [127.862762, -0.708571],
              [127.798172, -0.678349],
              [127.6716, -0.709446],
              [127.653328, -0.656565],
            ],
          ],
          [
            [
              [127.744156, -0.326109],
              [127.726936, -0.39722],
              [127.759819, -0.372006],
              [127.744156, -0.326109],
            ],
          ],
          [
            [
              [127.302147, -0.344365],
              [127.289932, -0.333176],
              [127.280548, -0.34568],
              [127.302147, -0.344365],
            ],
          ],
          [
            [
              [127.733612, -0.415829],
              [127.733772, -0.430088],
              [127.746925, -0.426904],
              [127.733612, -0.415829],
            ],
          ],
          [
            [
              [127.278625, -0.485537],
              [127.273056, -0.510555],
              [127.290833, -0.527778],
              [127.299721, -0.497222],
              [127.278625, -0.485537],
            ],
          ],
          [
            [
              [127.266159, -0.501342],
              [127.252327, -0.51226],
              [127.266273, -0.531828],
              [127.266159, -0.501342],
            ],
          ],
          [
            [
              [128.335281, -0.547499],
              [128.315826, -0.553061],
              [128.386948, -0.556944],
              [128.366669, -0.537499],
              [128.417221, -0.56333],
              [128.369171, -0.526666],
              [128.335281, -0.547499],
            ],
          ],
          [
            [
              [127.233887, -0.532501],
              [127.22451, -0.547657],
              [127.249763, -0.551379],
              [127.233887, -0.532501],
            ],
          ],
          [
            [
              [128.347778, -0.565831],
              [128.329163, -0.577222],
              [128.354721, -0.583059],
              [128.347778, -0.565831],
            ],
          ],
          [
            [
              [128.420837, -0.568609],
              [128.429718, -0.591111],
              [128.441666, -0.571946],
              [128.420837, -0.568609],
            ],
          ],
          [
            [
              [127.414482, -0.6561],
              [127.38578, -0.611735],
              [127.324211, -0.605647],
              [127.348145, -0.658402],
              [127.414482, -0.6561],
            ],
          ],
          [
            [
              [127.292435, -0.689414],
              [127.25029, -0.641942],
              [127.25766, -0.605339],
              [127.194336, -0.613772],
              [127.21151, -0.671282],
              [127.167389, -0.708224],
              [127.16539, -0.750919],
              [127.202019, -0.77939],
              [127.334442, -0.786667],
              [127.292435, -0.689414],
            ],
          ],
          [
            [
              [127.322754, -0.619607],
              [127.308968, -0.625601],
              [127.332451, -0.65801],
              [127.322754, -0.619607],
            ],
          ],
          [
            [
              [128.592499, -0.626668],
              [128.577499, -0.635556],
              [128.596664, -0.654443],
              [128.607773, -0.643611],
              [128.592499, -0.626668],
            ],
          ],
          [
            [
              [128.094452, -0.83759],
              [128.079971, -0.851105],
              [128.103607, -0.861457],
              [128.111923, -0.852849],
              [128.094452, -0.83759],
            ],
          ],
          [
            [
              [128.356384, -0.976943],
              [128.307495, -0.945833],
              [128.338882, -0.973333],
              [128.333633, -1.023456],
              [128.416946, -1.025833],
              [128.404724, -0.990001],
              [128.356384, -0.976943],
            ],
          ],
          [
            [
              [128.382111, -0.985014],
              [128.371964, -0.98647],
              [128.392654, -0.989859],
              [128.382111, -0.985014],
            ],
          ],
          [
            [
              [128.34111, -1.034168],
              [128.337494, -1.052223],
              [128.354172, -1.052499],
              [128.360001, -1.04333],
              [128.34111, -1.034168],
            ],
          ],
          [
            [
              [128.228882, -1.0375],
              [128.237778, -1.06861],
              [128.251389, -1.046945],
              [128.228882, -1.0375],
            ],
          ],
          [
            [
              [128.393616, -1.106667],
              [128.453613, -1.130833],
              [128.399536, -1.083096],
              [128.343399, -1.089799],
              [128.365829, -1.12194],
              [128.393616, -1.106667],
            ],
          ],
          [
            [
              [128.337219, -1.107219],
              [128.329025, -1.115909],
              [128.345551, -1.122222],
              [128.337219, -1.107219],
            ],
          ],
          [
            [
              [128.401382, -1.121666],
              [128.385284, -1.125832],
              [128.392776, -1.136667],
              [128.401382, -1.121666],
            ],
          ],
          [
            [
              [127.411667, -1.136109],
              [127.395279, -1.146944],
              [127.43, -1.221109],
              [127.444168, -1.187223],
              [127.411667, -1.136109],
            ],
          ],
          [
            [
              [128.426117, -1.145556],
              [128.410004, -1.157373],
              [128.434998, -1.156389],
              [128.426117, -1.145556],
            ],
          ],
          [
            [
              [127.548889, -1.170556],
              [127.510002, -1.177779],
              [127.46833, -1.25611],
              [127.561943, -1.248329],
              [127.69278, -1.285],
              [127.695274, -1.226388],
              [127.599167, -1.209721],
              [127.548889, -1.170556],
            ],
          ],
          [
            [
              [127.408333, -1.311666],
              [127.375557, -1.319167],
              [127.434723, -1.318059],
              [127.408333, -1.311666],
            ],
          ],
          [
            [
              [127.956947, -1.473888],
              [127.89222, -1.425279],
              [127.878326, -1.43889],
              [127.835831, -1.435829],
              [127.758606, -1.351939],
              [127.641945, -1.330556],
              [127.513611, -1.416388],
              [127.503326, -1.453059],
              [127.440552, -1.40889],
              [127.414169, -1.419722],
              [127.442947, -1.434822],
              [127.402496, -1.537499],
              [127.403053, -1.652778],
              [127.498886, -1.724999],
              [127.595276, -1.738056],
              [127.719437, -1.68611],
              [127.906387, -1.68472],
              [128.023056, -1.728055],
              [128.154999, -1.685],
              [128.167496, -1.620555],
              [128.126114, -1.569719],
              [128.11116, -1.5603],
              [128.07666, -1.580559],
              [127.956947, -1.473888],
            ],
          ],
          [
            [
              [127.393333, -1.389444],
              [127.32222, -1.365278],
              [127.281113, -1.415],
              [127.33139, -1.454445],
              [127.393333, -1.389444],
            ],
          ],
          [
            [
              [127.395279, -1.483055],
              [127.378052, -1.521944],
              [127.410553, -1.500557],
              [127.395279, -1.483055],
            ],
          ],
          [
            [
              [128.642227, -1.510833],
              [128.643936, -1.498875],
              [128.630005, -1.503611],
              [128.642227, -1.510833],
            ],
          ],
          [
            [
              [128.350006, -1.60121],
              [128.311386, -1.614167],
              [128.296188, -1.64741],
              [128.35817, -1.650711],
              [128.350006, -1.60121],
            ],
          ],
          [
            [
              [127.630554, -1.820278],
              [127.582222, -1.828055],
              [127.571388, -1.86528],
              [127.635002, -1.846944],
              [127.630554, -1.820278],
            ],
          ],
          [
            [
              [128.811356, 0.565633],
              [128.828339, 0.510555],
              [128.860275, 0.490278],
              [128.811356, 0.565633],
            ],
          ],
          [
            [
              [128.957779, 0.183941],
              [128.939041, 0.192326],
              [128.978821, 0.166191],
              [128.957779, 0.183941],
            ],
          ],
          [
            [
              [129.285095, 0.05109],
              [129.364441, -0.005829],
              [129.383057, -0.07333],
              [129.460587, -0.092529],
              [129.526947, -0.205278],
              [129.574997, -0.199722],
              [129.545837, -0.128888],
              [129.39917, -0.011939],
              [129.285095, 0.05109],
            ],
          ],
          [
            [
              [129.59639, -0.001941],
              [129.587494, -0.045834],
              [129.625793, -0.071814],
              [129.625549, -0.032219],
              [129.653061, -0.071388],
              [129.653137, -0.048747],
              [129.59639, -0.001941],
            ],
          ],
          [
            [
              [129.421814, -0.081371],
              [129.410004, -0.092219],
              [129.432495, -0.118329],
              [129.421814, -0.081371],
            ],
          ],
          [
            [
              [128.348343, 0.801915],
              [128.325668, 0.79518],
              [128.32196, 0.773396],
              [128.349747, 0.78179],
              [128.348343, 0.801915],
            ],
          ],
          [
            [
              [128.645279, 0.57889],
              [128.635559, 0.564445],
              [128.669952, 0.567474],
              [128.645279, 0.57889],
            ],
          ],
          [
            [
              [128.648605, 2.2925],
              [128.690277, 2.491944],
              [128.632217, 2.534722],
              [128.567215, 2.64556],
              [128.525558, 2.576945],
              [128.460007, 2.59639],
              [128.323334, 2.471672],
              [128.282776, 2.370136],
              [128.196945, 2.281671],
              [128.247986, 2.246839],
              [128.248611, 2.110556],
              [128.292984, 2.05585],
              [128.259369, 1.980616],
              [128.300278, 2.035832],
              [128.506668, 2.059029],
              [128.573608, 2.105834],
              [128.648605, 2.2925],
            ],
          ],
          [
            [
              [128.160355, 2.288176],
              [128.188614, 2.30583],
              [128.16806, 2.416134],
              [128.124725, 2.332501],
              [128.160355, 2.288176],
            ],
          ],
          [
            [
              [127.775566, 2.294972],
              [127.757042, 2.262481],
              [127.794289, 2.217322],
              [127.825333, 2.270581],
              [127.779129, 2.27493],
              [127.775566, 2.294972],
            ],
          ],
          [
            [
              [127.765831, 2.210555],
              [127.753609, 2.205277],
              [127.771393, 2.198611],
              [127.765831, 2.210555],
            ],
          ],
          [
            [
              [127.722176, 2.19346],
              [127.769844, 2.152214],
              [127.77784, 2.160234],
              [127.758568, 2.19051],
              [127.722176, 2.19346],
            ],
          ],
          [
            [
              [127.778336, 2.126389],
              [127.766945, 2.114722],
              [127.784447, 2.103612],
              [127.778336, 2.126389],
            ],
          ],
          [
            [
              [128.003326, 1.792758],
              [127.993607, 1.80285],
              [128.019165, 1.767608],
              [128.003326, 1.792758],
            ],
          ],
          [
            [
              [128.042221, 1.755],
              [128.036667, 1.743055],
              [128.056946, 1.741945],
              [128.042221, 1.755],
            ],
          ],
          [
            [
              [127.524651, 1.720022],
              [127.494881, 1.666063],
              [127.534981, 1.68859],
              [127.524651, 1.720022],
            ],
          ],
          [
            [
              [127.542221, 1.68472],
              [127.529442, 1.672499],
              [127.547226, 1.671389],
              [127.542221, 1.68472],
            ],
          ],
          [
            [
              [128.050278, 1.582223],
              [128.05722, 1.554721],
              [128.06778, 1.565556],
              [128.050278, 1.582223],
            ],
          ],
          [
            [
              [125, -1.715],
              [124.898888, -1.709166],
              [124.870277, -1.68],
              [124.643333, -1.639167],
              [124.554726, -1.638057],
              [124.525002, -1.665001],
              [124.529167, -1.629439],
              [124.4925, -1.658334],
              [124.400276, -1.65972],
              [124.361389, -1.70528],
              [124.375557, -1.699167],
              [124.33667, -1.889445],
              [124.404099, -2.033232],
              [124.681389, -1.97611],
              [124.860558, -1.891668],
              [124.891113, -1.925561],
              [125.008888, -1.943333],
              [125.013054, -1.900834],
              [125.042778, -1.886938],
              [125.326385, -1.885555],
              [125.321106, -1.78417],
              [125.269997, -1.791388],
              [125.291389, -1.730829],
              [125.2229, -1.73585],
              [125.206108, -1.793334],
              [125.181114, -1.782499],
              [125.205276, -1.72778],
              [125.175278, -1.698055],
              [125.149002, -1.714871],
              [125.109726, -1.690001],
              [125.114166, -1.730556],
              [125.087776, -1.745555],
              [125.109444, -1.749723],
              [125.070427, -1.773576],
              [125.061111, -1.743057],
              [125.049446, -1.770832],
              [125.018333, -1.757778],
              [125.026947, -1.723611],
              [125, -1.715],
            ],
          ],
          [
            [
              [124.993889, -1.656666],
              [125, -1.670833],
              [125.022781, -1.665001],
              [124.993889, -1.656666],
            ],
          ],
          [
            [
              [124.157219, -1.735829],
              [124.143608, -1.76056],
              [124.168053, -1.752219],
              [124.162498, -1.775279],
              [124.150276, -1.772779],
              [124.162781, -1.788612],
              [124.181664, -1.7675],
              [124.157219, -1.735829],
            ],
          ],
          [
            [
              [124.293053, -1.7425],
              [124.28389, -1.760834],
              [124.31028, -1.786112],
              [124.293053, -1.7425],
            ],
          ],
          [
            [
              [125.542503, -1.75667],
              [125.507225, -1.778889],
              [125.551109, -1.779167],
              [125.542503, -1.75667],
            ],
          ],
          [
            [
              [125.958618, -1.917944],
              [126.089401, -1.879228],
              [126.244621, -1.879878],
              [126.355278, -1.815],
              [125.911942, -1.792778],
              [125.636337, -1.829687],
              [125.541748, -1.812149],
              [125.538757, -1.786326],
              [125.488411, -1.819576],
              [125.480003, -1.779167],
              [125.424721, -1.808889],
              [125.403053, -1.774753],
              [125.327225, -1.832223],
              [125.345001, -1.88806],
              [125.370018, -1.84583],
              [125.434166, -1.886389],
              [125.421837, -1.950209],
              [125.784447, -1.93333],
              [125.841667, -1.895],
              [125.917366, -1.939249],
              [125.958618, -1.917944],
            ],
          ],
          [
            [
              [126.458054, -1.800555],
              [126.349442, -1.826111],
              [126.461594, -1.841237],
              [126.486839, -1.820331],
              [126.458054, -1.800555],
            ],
          ],
          [
            [
              [125.383331, -1.878889],
              [125.374725, -1.889721],
              [125.387497, -1.890555],
              [125.383331, -1.878889],
            ],
          ],
          [
            [
              [124.339317, -2.022561],
              [124.37278, -2.039166],
              [124.366058, -1.970259],
              [124.307777, -1.98028],
              [124.30529, -2.00778],
              [124.339317, -2.022561],
            ],
          ],
          [
            [
              [125.971382, -2.003113],
              [125.907501, -1.975278],
              [125.855003, -2.092779],
              [125.879448, -2.210277],
              [125.956665, -2.320555],
              [125.976387, -2.418611],
              [126.048889, -2.477779],
              [126.072708, -2.42133],
              [125.957832, -2.158981],
              [125.99292, -2.04542],
              [125.971382, -2.003113],
            ],
          ],
          [
            [
              [124.374794, -2.006648],
              [124.368355, -2.044933],
              [124.388336, -2.073611],
              [124.406113, -2.046389],
              [124.374794, -2.006648],
            ],
          ],
          [
            [
              [127.318748, 0.90846],
              [127.302811, 0.898022],
              [127.319656, 0.881147],
              [127.331543, 0.892016],
              [127.318748, 0.90846],
            ],
          ],
          [
            [
              [127.328598, 0.867235],
              [127.293938, 0.790711],
              [127.314911, 0.756706],
              [127.368546, 0.759357],
              [127.388504, 0.8298],
              [127.328598, 0.867235],
            ],
          ],
          [
            [
              [127.408005, 0.480555],
              [127.382797, 0.448877],
              [127.420242, 0.43252],
              [127.439613, 0.450817],
              [127.408005, 0.480555],
            ],
          ],
          [
            [
              [127.405518, 0.751968],
              [127.362213, 0.678347],
              [127.402657, 0.62146],
              [127.44873, 0.655016],
              [127.460556, 0.714351],
              [127.447868, 0.745732],
              [127.405518, 0.751968],
            ],
          ],
          [
            [
              [127.395508, 0.585721],
              [127.381752, 0.558214],
              [127.411163, 0.574129],
              [127.395508, 0.585721],
            ],
          ],
        ],
      },
      properties: {
        cartodb_id: 19,
        country: 'Indonesia',
        id_1: 20,
        state: 'Maluku Utara',
        key: 'indonesia-malukuutara',
        name: 'North Maluku',
        slug: 'north-maluku',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [118.83139, -6.568889],
              [118.825279, -6.579444],
              [118.84111, -6.583334],
              [118.83139, -6.568889],
            ],
          ],
          [
            [
              [119.194168, -6.81917],
              [119.172501, -6.827776],
              [119.168892, -6.847851],
              [119.194168, -6.81917],
            ],
          ],
          [
            [
              [119.113335, -6.836667],
              [119.097504, -6.868265],
              [119.12278, -6.858055],
              [119.113335, -6.836667],
            ],
          ],
          [
            [
              [118.110786, -7.296273],
              [118.099167, -7.301666],
              [118.10778, -7.309444],
              [118.110786, -7.296273],
            ],
          ],
          [
            [
              [118.162376, -8.239049],
              [118.143242, -8.134218],
              [118.078888, -8.099441],
              [117.958786, -8.107],
              [117.925827, -8.08],
              [117.778214, -8.126952],
              [117.768501, -8.1552],
              [117.724716, -8.14944],
              [117.691467, -8.236439],
              [117.818176, -8.369231],
              [117.980728, -8.46485],
              [118.081306, -8.450858],
              [118.193626, -8.57093],
              [118.230103, -8.551503],
              [118.278961, -8.593327],
              [118.267197, -8.658638],
              [118.073486, -8.65568],
              [117.964447, -8.742223],
              [117.921707, -8.718657],
              [117.905151, -8.740912],
              [117.90152, -8.704578],
              [117.871216, -8.696301],
              [117.806396, -8.726852],
              [117.769974, -8.705002],
              [117.749237, -8.638953],
              [117.781441, -8.632309],
              [117.750801, -8.63037],
              [117.73716, -8.56337],
              [117.69046, -8.577692],
              [117.632225, -8.561111],
              [117.635101, -8.465103],
              [117.599167, -8.426939],
              [117.574738, -8.440618],
              [117.591446, -8.503538],
              [117.571686, -8.500557],
              [117.562485, -8.405011],
              [117.428116, -8.395359],
              [117.388824, -8.472795],
              [117.201927, -8.405948],
              [117.157219, -8.364723],
              [117.112221, -8.369999],
              [117.005081, -8.501874],
              [116.986801, -8.479311],
              [116.855278, -8.53889],
              [116.818611, -8.516388],
              [116.833336, -8.556945],
              [116.745552, -8.662499],
              [116.773613, -8.675],
              [116.785278, -8.72056],
              [116.763336, -8.736666],
              [116.815002, -8.813056],
              [116.731667, -8.869166],
              [116.752502, -8.893334],
              [116.723892, -8.91056],
              [116.751663, -8.923888],
              [116.716888, -8.959839],
              [116.727913, -8.997778],
              [116.874084, -9.060454],
              [116.944626, -9.05531],
              [117.01519, -9.11],
              [117.135612, -9.084421],
              [117.198891, -9.011667],
              [117.364395, -9.056256],
              [117.548424, -9.003838],
              [117.690826, -8.918329],
              [117.765831, -8.934167],
              [117.812775, -8.898888],
              [117.885002, -8.944168],
              [117.935089, -8.898952],
              [117.982071, -8.89524],
              [118.006783, -8.854315],
              [118.082031, -8.841329],
              [118.125526, -8.879131],
              [118.183929, -8.854619],
              [118.3545, -8.684317],
              [118.403473, -8.676506],
              [118.380295, -8.624661],
              [118.398407, -8.586508],
              [118.387779, -8.620556],
              [118.419998, -8.622849],
              [118.429443, -8.717223],
              [118.364723, -8.78],
              [118.395111, -8.854906],
              [118.467224, -8.889168],
              [118.720261, -8.792938],
              [118.843613, -8.846945],
              [118.959877, -8.817899],
              [118.893593, -8.761441],
              [118.727119, -8.776766],
              [118.692558, -8.747499],
              [118.760559, -8.692777],
              [118.773407, -8.72143],
              [118.810745, -8.70366],
              [118.846291, -8.73034],
              [118.908737, -8.68026],
              [118.971664, -8.749443],
              [119.035942, -8.73232],
              [119.098831, -8.752221],
              [119.104446, -8.726109],
              [119.133057, -8.753056],
              [119.172783, -8.72389],
              [119.148056, -8.711669],
              [119.176735, -8.69814],
              [119.150558, -8.637223],
              [119.193291, -8.609678],
              [119.166389, -8.556389],
              [119.150566, -8.5926],
              [119.108749, -8.5958],
              [119.129166, -8.629723],
              [119.033058, -8.636669],
              [119.036942, -8.596112],
              [119.009163, -8.574166],
              [119.015137, -8.532718],
              [119.054222, -8.510866],
              [119.035278, -8.466944],
              [119.05365, -8.463929],
              [118.991669, -8.375278],
              [119.000275, -8.309096],
              [118.816948, -8.280557],
              [118.764442, -8.309167],
              [118.701714, -8.388059],
              [118.712372, -8.498269],
              [118.651283, -8.550466],
              [118.698036, -8.39659],
              [118.647888, -8.290407],
              [118.464417, -8.244849],
              [118.311111, -8.370555],
              [118.23333, -8.331944],
              [118.162376, -8.239049],
            ],
          ],
          [
            [
              [117.747215, -8.097818],
              [117.734726, -8.108049],
              [117.751976, -8.12132],
              [117.760246, -8.11],
              [117.747215, -8.097818],
            ],
          ],
          [
            [
              [119.000832, -8.211109],
              [119.011948, -8.247222],
              [119.05278, -8.261945],
              [119.114723, -8.224722],
              [119.124168, -8.188056],
              [119.090843, -8.133609],
              [119.054443, -8.126667],
              [119.012222, -8.154169],
              [119.000832, -8.211109],
            ],
          ],
          [
            [
              [119.276108, -8.409166],
              [119.279442, -8.46861],
              [119.301323, -8.429309],
              [119.321388, -8.456668],
              [119.320557, -8.400555],
              [119.276108, -8.409166],
            ],
          ],
          [
            [
              [119.045006, -8.556749],
              [119.044128, -8.581749],
              [119.056381, -8.570991],
              [119.045006, -8.556749],
            ],
          ],
          [
            [
              [119.227783, -8.653608],
              [119.208046, -8.67306],
              [119.223053, -8.690559],
              [119.240837, -8.664443],
              [119.227783, -8.653608],
            ],
          ],
          [
            [
              [118.206108, -8.640833],
              [118.194717, -8.64806],
              [118.209717, -8.65389],
              [118.206108, -8.640833],
            ],
          ],
          [
            [
              [115.917221, -8.775277],
              [115.898331, -8.745829],
              [115.882225, -8.763332],
              [115.866112, -8.720829],
              [115.820137, -8.752365],
              [115.830826, -8.815278],
              [115.933891, -8.833609],
              [116.00444, -8.904162],
              [116.02417, -8.903889],
              [116.018326, -8.859719],
              [116.071205, -8.855831],
              [116.044281, -8.870534],
              [116.076538, -8.89513],
              [116.150223, -8.862438],
              [116.173332, -8.913612],
              [116.276657, -8.913609],
              [116.279327, -8.892776],
              [116.346321, -8.93071],
              [116.340164, -8.900105],
              [116.376587, -8.903057],
              [116.379494, -8.956343],
              [116.404999, -8.900001],
              [116.387779, -8.86167],
              [116.463982, -8.82236],
              [116.435921, -8.920408],
              [116.496666, -8.911389],
              [116.510834, -8.871388],
              [116.510002, -8.915556],
              [116.571106, -8.89278],
              [116.591942, -8.858329],
              [116.541946, -8.86804],
              [116.524437, -8.825828],
              [116.503609, -8.858609],
              [116.480553, -8.80889],
              [116.546028, -8.75286],
              [116.666389, -8.56028],
              [116.662224, -8.488056],
              [116.711388, -8.443054],
              [116.717499, -8.34778],
              [116.65139, -8.288329],
              [116.460556, -8.22694],
              [116.350563, -8.211109],
              [116.269722, -8.244722],
              [116.035553, -8.435556],
              [116.071945, -8.562499],
              [116.064163, -8.767501],
              [116.040276, -8.764723],
              [116.033607, -8.719721],
              [116.010277, -8.75167],
              [115.965553, -8.72972],
              [115.917221, -8.775277],
            ],
          ],
          [
            [
              [116.035835, -8.361945],
              [116.040558, -8.339167],
              [116.028336, -8.344445],
              [116.035835, -8.361945],
            ],
          ],
          [
            [
              [115.923889, -8.726666],
              [115.912498, -8.749167],
              [115.934723, -8.7625],
              [115.923889, -8.726666],
            ],
          ],
          [
            [
              [117.182503, -7.491939],
              [117.17778, -7.509445],
              [117.19722, -7.501389],
              [117.182503, -7.491939],
            ],
          ],
          [
            [
              [117.440002, -7.511667],
              [117.423332, -7.518329],
              [117.432503, -7.54028],
              [117.440002, -7.511667],
            ],
          ],
          [
            [
              [117.289436, -7.52472],
              [117.283058, -7.539167],
              [117.335831, -7.528611],
              [117.289436, -7.52472],
            ],
          ],
          [
            [
              [117.405556, -8.128333],
              [117.34507, -8.155225],
              [117.424164, -8.137153],
              [117.405556, -8.128333],
            ],
          ],
          [
            [
              [117.597504, -8.273056],
              [117.689827, -8.18068],
              [117.666641, -8.151072],
              [117.557953, -8.143375],
              [117.483055, -8.184913],
              [117.478889, -8.256667],
              [117.507774, -8.276944],
              [117.470558, -8.356944],
              [117.527779, -8.389445],
              [117.597504, -8.273056],
            ],
          ],
          [
            [
              [117.046646, -8.377454],
              [116.991112, -8.403334],
              [116.976387, -8.430834],
              [117.033638, -8.412938],
              [117.046646, -8.377454],
            ],
          ],
          [
            [
              [117.660408, -8.418884],
              [117.642365, -8.439071],
              [117.661247, -8.434667],
              [117.660408, -8.418884],
            ],
          ],
          [
            [
              [116.954445, -8.419723],
              [116.839447, -8.446945],
              [116.94722, -8.439166],
              [116.954445, -8.419723],
            ],
          ],
          [
            [
              [117.646103, -8.488947],
              [117.650352, -8.532905],
              [117.679802, -8.5224],
              [117.659782, -8.448677],
              [117.646103, -8.488947],
            ],
          ],
          [
            [
              [117.754547, -8.56812],
              [117.769592, -8.542969],
              [117.738113, -8.527082],
              [117.725204, -8.4731],
              [117.702339, -8.49467],
              [117.713882, -8.53538],
              [117.754547, -8.56812],
            ],
          ],
          [
            [
              [116.858055, -8.503333],
              [116.841393, -8.510278],
              [116.850281, -8.518888],
              [116.858055, -8.503333],
            ],
          ],
          [
            [
              [117.822769, -8.55579],
              [117.813683, -8.562158],
              [117.83046, -8.565412],
              [117.822769, -8.55579],
            ],
          ],
          [
            [
              [117.989632, -8.63259],
              [117.949722, -8.613611],
              [117.979156, -8.639719],
              [117.955002, -8.667777],
              [117.989723, -8.648888],
              [117.977776, -8.691389],
              [118.002975, -8.693996],
              [118.004951, -8.6332],
              [117.989632, -8.63259],
            ],
          ],
          [
            [
              [116.699448, -8.285833],
              [116.685837, -8.291389],
              [116.705002, -8.309999],
              [116.699448, -8.285833],
            ],
          ],
          [
            [
              [116.716942, -8.308889],
              [116.710556, -8.325556],
              [116.743057, -8.34528],
              [116.716942, -8.308889],
            ],
          ],
        ],
      },
      properties: {
        cartodb_id: 15,
        country: 'Indonesia',
        id_1: 22,
        state: 'Nusa Tenggara Barat',
        key: 'indonesia-nusatenggarabarat',
        name: 'West Nusa Tenggara',
        slug: 'west-nusa-tenggara',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [124.223053, -10.15944],
              [124.390831, -10.170831],
              [124.478889, -10.12278],
              [124.542221, -10.01639],
              [124.747925, -9.8836],
              [124.864029, -9.720348],
              [124.983887, -9.638055],
              [124.975021, -9.53931],
              [124.993347, -9.558313],
              [125.089447, -9.460556],
              [125.049599, -9.324715],
              [124.994522, -9.290232],
              [124.990402, -9.17868],
              [125.055939, -9.169768],
              [125.099197, -9.204998],
              [125.179344, -9.16645],
              [125.186615, -9.029656],
              [125.120598, -8.970105],
              [124.984184, -9.065965],
              [124.931931, -9.028277],
              [124.938072, -8.957118],
              [124.697578, -9.053001],
              [124.670906, -9.117499],
              [124.616463, -9.153609],
              [124.530281, -9.18611],
              [124.476044, -9.174028],
              [124.461395, -9.301518],
              [124.37989, -9.356883],
              [124.351776, -9.485137],
              [124.278358, -9.503233],
              [124.287003, -9.451237],
              [124.234985, -9.374147],
              [124.134521, -9.426572],
              [124.043266, -9.335277],
              [123.980278, -9.351388],
              [123.871674, -9.475004],
              [123.794998, -9.48944],
              [123.787323, -9.541985],
              [123.673332, -9.627498],
              [123.680832, -9.746112],
              [123.640266, -9.773609],
              [123.66861, -9.85167],
              [123.577774, -9.942778],
              [123.593414, -10.044022],
              [123.654373, -10.009974],
              [123.770836, -10.043334],
              [123.742455, -10.097119],
              [123.532578, -10.175619],
              [123.527168, -10.215787],
              [123.488258, -10.224956],
              [123.500443, -10.272918],
              [123.455368, -10.35166],
              [123.543808, -10.327729],
              [123.611748, -10.370362],
              [123.798004, -10.355956],
              [123.962929, -10.276489],
              [124.019722, -10.274999],
              [124.105278, -10.172779],
              [124.16333, -10.13944],
              [124.183609, -10.144721],
              [124.108887, -10.174443],
              [124.223053, -10.15944],
            ],
          ],
          [
            [
              [123.410706, -10.332513],
              [123.395874, -10.292703],
              [123.420563, -10.295477],
              [123.418571, -10.25922],
              [123.392616, -10.210319],
              [123.428131, -10.230505],
              [123.506882, -10.173981],
              [123.46875, -10.1616],
              [123.451668, -10.113966],
              [123.396004, -10.133797],
              [123.300362, -10.271897],
              [123.322601, -10.340239],
              [123.347923, -10.30589],
              [123.410706, -10.332513],
            ],
          ],
          [
            [
              [121.876106, -10.618059],
              [121.986946, -10.550279],
              [121.999168, -10.44264],
              [121.883331, -10.42472],
              [121.845001, -10.449719],
              [121.833893, -10.50028],
              [121.683609, -10.576111],
              [121.749733, -10.615278],
              [121.876106, -10.618059],
            ],
          ],
          [
            [
              [121.566391, -10.596389],
              [121.520836, -10.6375],
              [121.635002, -10.609168],
              [121.566391, -10.596389],
            ],
          ],
          [
            [
              [123.589043, -7.777652],
              [123.574173, -7.795],
              [123.591667, -7.806667],
              [123.601936, -7.781109],
              [123.589043, -7.777652],
            ],
          ],
          [
            [
              [123.405937, -8.371239],
              [123.335556, -8.399999],
              [123.210312, -8.51245],
              [123.22139, -8.554721],
              [123.317032, -8.52702],
              [123.393608, -8.585829],
              [123.491035, -8.515278],
              [123.546455, -8.571716],
              [123.587273, -8.530699],
              [123.553612, -8.470279],
              [123.620819, -8.405611],
              [123.672783, -8.42917],
              [123.763885, -8.290277],
              [123.924721, -8.246944],
              [123.782501, -8.169998],
              [123.699173, -8.216939],
              [123.692497, -8.256945],
              [123.651749, -8.253544],
              [123.649033, -8.217288],
              [123.604958, -8.220046],
              [123.634987, -8.291595],
              [123.599754, -8.317217],
              [123.604324, -8.289277],
              [123.574173, -8.288057],
              [123.596664, -8.3325],
              [123.551041, -8.369165],
              [123.494987, -8.360199],
              [123.561546, -8.243831],
              [123.495979, -8.24126],
              [123.455276, -8.27139],
              [123.409561, -8.251997],
              [123.34417, -8.30444],
              [123.414444, -8.292499],
              [123.470833, -8.338333],
              [123.405937, -8.371239],
            ],
          ],
          [
            [
              [119.908333, -8.474999],
              [119.877502, -8.459444],
              [119.88028, -8.427219],
              [119.855553, -8.430834],
              [119.880554, -8.477777],
              [119.876389, -8.50889],
              [119.794167, -8.582777],
              [119.810555, -8.604167],
              [119.798889, -8.765356],
              [119.833336, -8.806939],
              [119.889442, -8.813334],
              [119.887596, -8.860469],
              [119.919403, -8.865688],
              [119.910835, -8.839444],
              [119.943359, -8.820817],
              [120.136406, -8.788797],
              [120.129013, -8.769718],
              [120.276627, -8.85458],
              [120.368607, -8.8182],
              [120.59481, -8.815],
              [120.645546, -8.868059],
              [120.754433, -8.893708],
              [120.789589, -8.840449],
              [120.83828, -8.829806],
              [120.931801, -8.933978],
              [121.030296, -8.956667],
              [121.172966, -8.886508],
              [121.342224, -8.905279],
              [121.388664, -8.788884],
              [121.589241, -8.803409],
              [121.6408, -8.83962],
              [121.624458, -8.892108],
              [121.644516, -8.906111],
              [121.673607, -8.847501],
              [121.776016, -8.882419],
              [122.031555, -8.794654],
              [122.115021, -8.73013],
              [122.27861, -8.75528],
              [122.47052, -8.732801],
              [122.557655, -8.666248],
              [122.832191, -8.589669],
              [122.843491, -8.544788],
              [122.788956, -8.478787],
              [122.783997, -8.423135],
              [122.886436, -8.449609],
              [122.878746, -8.384451],
              [122.914047, -8.32895],
              [122.964539, -8.3526],
              [123.021667, -8.306391],
              [122.957222, -8.19611],
              [122.969383, -8.12948],
              [122.844017, -8.07027],
              [122.781982, -8.10853],
              [122.784721, -8.149999],
              [122.730057, -8.223259],
              [122.78746, -8.235453],
              [122.820534, -8.192438],
              [122.900543, -8.176669],
              [122.938873, -8.20003],
              [122.893166, -8.277509],
              [122.809814, -8.298548],
              [122.764252, -8.362998],
              [122.59697, -8.392843],
              [122.546944, -8.464166],
              [122.491386, -8.473055],
              [122.478889, -8.494169],
              [122.517212, -8.54725],
              [122.474472, -8.608388],
              [122.364296, -8.610601],
              [122.293068, -8.642128],
              [122.101852, -8.527221],
              [122.016434, -8.529222],
              [122.023613, -8.44],
              [121.955833, -8.472777],
              [121.958046, -8.44194],
              [121.912849, -8.495636],
              [121.842216, -8.479719],
              [121.800827, -8.51028],
              [121.681389, -8.511946],
              [121.617767, -8.471108],
              [121.611092, -8.558789],
              [121.570831, -8.530279],
              [121.580902, -8.57015],
              [121.513611, -8.615277],
              [121.476669, -8.563609],
              [121.419121, -8.59203],
              [121.365837, -8.561389],
              [121.268059, -8.46361],
              [121.023613, -8.413332],
              [121.015831, -8.369167],
              [120.990387, -8.374279],
              [120.998337, -8.395279],
              [120.963608, -8.369999],
              [120.978134, -8.347174],
              [120.94429, -8.344534],
              [120.953331, -8.371667],
              [120.940033, -8.348407],
              [120.88472, -8.368622],
              [120.808151, -8.338449],
              [120.735428, -8.349111],
              [120.60096, -8.255078],
              [120.590675, -8.297085],
              [120.53083, -8.2725],
              [120.466568, -8.295268],
              [120.427727, -8.240838],
              [120.366943, -8.294168],
              [120.261368, -8.282489],
              [120.200279, -8.305554],
              [120.16333, -8.37639],
              [120.120552, -8.356944],
              [120.109726, -8.440832],
              [120.084717, -8.43583],
              [120.086517, -8.38673],
              [120.050278, -8.405],
              [120.07534, -8.377039],
              [120.022202, -8.381716],
              [119.999748, -8.460358],
              [119.96917, -8.427777],
              [119.908333, -8.474999],
            ],
          ],
          [
            [
              [120.131744, -8.338417],
              [120.111298, -8.34576],
              [120.154411, -8.350645],
              [120.131744, -8.338417],
            ],
          ],
          [
            [
              [120.03167, -8.362779],
              [120.022301, -8.351709],
              [120.016113, -8.373055],
              [120.03167, -8.362779],
            ],
          ],
          [
            [
              [119.852776, -8.374038],
              [119.838058, -8.38861],
              [119.865555, -8.401943],
              [119.878326, -8.38083],
              [119.852776, -8.374038],
            ],
          ],
          [
            [
              [119.462502, -8.604998],
              [119.504173, -8.56861],
              [119.518333, -8.602222],
              [119.574051, -8.595882],
              [119.559166, -8.514439],
              [119.578613, -8.538334],
              [119.567497, -8.485278],
              [119.481392, -8.485278],
              [119.455002, -8.426939],
              [119.451866, -8.451198],
              [119.420006, -8.450391],
              [119.449059, -8.489398],
              [119.412224, -8.502499],
              [119.436966, -8.52581],
              [119.373337, -8.584719],
              [119.373497, -8.630873],
              [119.409187, -8.646579],
              [119.377182, -8.738364],
              [119.404442, -8.722219],
              [119.440552, -8.752778],
              [119.471947, -8.737501],
              [119.452225, -8.744723],
              [119.432983, -8.67861],
              [119.486107, -8.639999],
              [119.492775, -8.612223],
              [119.464447, -8.6275],
              [119.462502, -8.604998],
            ],
          ],
          [
            [
              [119.727219, -8.493056],
              [119.71611, -8.51861],
              [119.734436, -8.501669],
              [119.727219, -8.493056],
            ],
          ],
          [
            [
              [119.788887, -8.52083],
              [119.784721, -8.530833],
              [119.799446, -8.529723],
              [119.788887, -8.52083],
            ],
          ],
          [
            [
              [119.659996, -8.531389],
              [119.646667, -8.535555],
              [119.656387, -8.5675],
              [119.659996, -8.531389],
            ],
          ],
          [
            [
              [119.661942, -8.654169],
              [119.663055, -8.610555],
              [119.614166, -8.609167],
              [119.641876, -8.657902],
              [119.623886, -8.681944],
              [119.64917, -8.689723],
              [119.651947, -8.669168],
              [119.661392, -8.700834],
              [119.618057, -8.70722],
              [119.633018, -8.740491],
              [119.601944, -8.749443],
              [119.61869, -8.794422],
              [119.658562, -8.773119],
              [119.694168, -8.810556],
              [119.733612, -8.766945],
              [119.689888, -8.722725],
              [119.711037, -8.679588],
              [119.745277, -8.698334],
              [119.805, -8.62472],
              [119.718613, -8.62111],
              [119.714722, -8.654166],
              [119.6875, -8.638889],
              [119.708054, -8.669168],
              [119.674721, -8.687221],
              [119.661942, -8.654169],
            ],
          ],
          [
            [
              [119.598053, -8.630279],
              [119.534775, -8.668007],
              [119.559067, -8.694259],
              [119.566391, -8.658608],
              [119.602776, -8.659445],
              [119.598053, -8.630279],
            ],
          ],
          [
            [
              [119.65583, -8.784722],
              [119.630417, -8.80623],
              [119.667938, -8.816612],
              [119.65583, -8.784722],
            ],
          ],
          [
            [
              [119.786392, -8.789998],
              [119.770432, -8.817204],
              [119.790497, -8.825828],
              [119.803337, -8.800555],
              [119.786392, -8.789998],
            ],
          ],
          [
            [
              [120.289291, -8.86877],
              [120.255554, -8.901111],
              [120.273232, -8.916269],
              [120.306664, -8.901111],
              [120.289291, -8.86877],
            ],
          ],
          [
            [
              [123.073891, -10.69722],
              [123.042931, -10.72941],
              [122.80722, -10.780557],
              [122.823051, -10.922114],
              [122.847343, -10.942712],
              [122.873917, -10.915051],
              [122.949722, -10.904478],
              [122.97052, -10.915005],
              [122.949753, -10.88526],
              [122.973579, -10.867535],
              [123.071686, -10.849487],
              [123.084167, -10.86111],
              [123.133202, -10.815648],
              [123.22361, -10.82972],
              [123.253052, -10.734999],
              [123.331612, -10.67815],
              [123.399117, -10.68811],
              [123.426247, -10.657795],
              [123.416946, -10.608648],
              [123.343567, -10.586249],
              [123.44133, -10.49236],
              [123.409721, -10.492777],
              [123.405991, -10.516307],
              [123.370003, -10.544445],
              [123.399841, -10.505107],
              [123.382927, -10.488679],
              [123.425201, -10.471649],
              [123.374641, -10.431511],
              [123.368614, -10.47827],
              [123.263519, -10.521136],
              [123.212532, -10.579471],
              [123.249725, -10.603459],
              [123.277199, -10.579769],
              [123.311241, -10.59173],
              [123.260109, -10.605355],
              [123.195274, -10.593057],
              [123.179146, -10.63587],
              [123.127365, -10.64498],
              [123.073891, -10.69722],
            ],
          ],
          [
            [
              [122.775162, -10.771939],
              [122.749397, -10.78581],
              [122.785004, -10.780832],
              [122.775162, -10.771939],
            ],
          ],
          [
            [
              [122.667404, -10.79878],
              [122.639168, -10.806944],
              [122.686272, -10.833594],
              [122.667404, -10.79878],
            ],
          ],
          [
            [
              [122.737778, -10.824165],
              [122.734032, -10.83495],
              [122.749023, -10.828216],
              [122.737778, -10.824165],
            ],
          ],
          [
            [
              [122.993614, -10.905556],
              [122.977142, -10.911855],
              [122.985703, -10.924789],
              [123.003891, -10.921945],
              [122.993614, -10.905556],
            ],
          ],
          [
            [
              [122.958466, -10.915942],
              [122.933846, -10.917514],
              [122.934273, -10.937639],
              [122.955467, -10.938498],
              [122.958466, -10.915942],
            ],
          ],
          [
            [
              [122.867325, -10.965684],
              [122.840721, -10.969157],
              [122.877777, -11.009719],
              [122.885788, -10.988391],
              [122.867325, -10.965684],
            ],
          ],
          [
            [
              [122.1325, -8.107219],
              [122.105209, -8.122517],
              [122.130737, -8.122289],
              [122.1325, -8.107219],
            ],
          ],
          [
            [
              [121.708336, -8.292499],
              [121.68528, -8.304166],
              [121.66893, -8.34819],
              [121.740837, -8.344445],
              [121.708336, -8.292499],
            ],
          ],
          [
            [
              [122.317009, -8.345234],
              [122.275566, -8.367859],
              [122.307449, -8.368648],
              [122.317009, -8.345234],
            ],
          ],
          [
            [
              [122.51918, -8.429389],
              [122.514221, -8.413827],
              [122.498436, -8.421451],
              [122.501526, -8.435276],
              [122.51918, -8.429389],
            ],
          ],
          [
            [
              [122.376823, -8.428901],
              [122.334587, -8.435039],
              [122.33448, -8.464645],
              [122.389206, -8.497968],
              [122.423248, -8.449965],
              [122.376823, -8.428901],
            ],
          ],
          [
            [
              [122.452728, -8.459843],
              [122.422775, -8.472223],
              [122.455147, -8.476697],
              [122.452728, -8.459843],
            ],
          ],
          [
            [
              [119.680313, -9.828258],
              [119.784019, -9.92318],
              [119.858055, -9.920279],
              [119.905281, -9.97361],
              [119.950546, -9.975001],
              [120.020073, -10.083775],
              [120.009308, -10.126301],
              [120.046204, -10.125948],
              [120.165237, -10.24034],
              [120.350281, -10.249064],
              [120.454056, -10.317017],
              [120.535843, -10.237999],
              [120.610123, -10.253269],
              [120.685257, -10.224668],
              [120.815498, -10.12497],
              [120.84214, -10.0879],
              [120.82489, -10.103669],
              [120.847122, -10.037068],
              [120.816109, -9.977498],
              [120.677277, -9.898064],
              [120.560448, -9.697958],
              [120.504723, -9.686109],
              [120.464722, -9.614719],
              [120.316666, -9.675278],
              [120.299164, -9.64139],
              [120.251457, -9.646244],
              [120.211128, -9.491099],
              [120.183937, -9.467859],
              [120.085754, -9.470279],
              [119.934998, -9.27583],
              [119.793892, -9.399444],
              [119.686943, -9.382779],
              [119.631111, -9.345556],
              [119.542503, -9.378332],
              [119.467308, -9.357988],
              [119.390045, -9.383006],
              [119.301109, -9.35694],
              [119.218613, -9.39306],
              [119.171669, -9.375829],
              [118.999168, -9.449165],
              [118.930557, -9.537778],
              [119.027496, -9.67611],
              [119.18528, -9.75861],
              [119.214996, -9.736667],
              [119.301941, -9.743889],
              [119.395546, -9.797499],
              [119.47139, -9.740828],
              [119.59584, -9.7809],
              [119.61972, -9.763333],
              [119.620552, -9.80028],
              [119.66497, -9.778473],
              [119.680313, -9.828258],
            ],
          ],
          [
            [
              [120.210876, -10.30331],
              [120.172287, -10.317616],
              [120.209778, -10.31931],
              [120.210876, -10.30331],
            ],
          ],
          [
            [
              [125.068611, -8.352778],
              [125.140556, -8.31917],
              [125.14167, -8.237499],
              [125.094437, -8.14694],
              [124.958549, -8.14],
              [124.872002, -8.167238],
              [124.749458, -8.146186],
              [124.609306, -8.19743],
              [124.603607, -8.125831],
              [124.525162, -8.12199],
              [124.448891, -8.152221],
              [124.39959, -8.21863],
              [124.405251, -8.275989],
              [124.504456, -8.221299],
              [124.55986, -8.222332],
              [124.409187, -8.29461],
              [124.336945, -8.419878],
              [124.414719, -8.43722],
              [124.417137, -8.460399],
              [124.497147, -8.418428],
              [124.541328, -8.443187],
              [124.591942, -8.432219],
              [124.658089, -8.39094],
              [124.786919, -8.40188],
              [124.89064, -8.363907],
              [125.068611, -8.352778],
            ],
          ],
          [
            [
              [124.028053, -8.310279],
              [123.964546, -8.347554],
              [123.912529, -8.439871],
              [123.943573, -8.460891],
              [123.986664, -8.419167],
              [124.033607, -8.420277],
              [124.065002, -8.49111],
              [124.055847, -8.544478],
              [124.141548, -8.53848],
              [124.21167, -8.46139],
              [124.247223, -8.361667],
              [124.301666, -8.33],
              [124.324722, -8.188329],
              [124.251198, -8.19165],
              [124.104294, -8.360144],
              [124.110558, -8.387501],
              [124.080002, -8.30583],
              [124.028053, -8.310279],
            ],
          ],
          [
            [
              [124.086945, -8.221388],
              [124.07, -8.235555],
              [124.088608, -8.236943],
              [124.086945, -8.221388],
            ],
          ],
          [
            [
              [124.356682, -8.276479],
              [124.316109, -8.295],
              [124.328842, -8.323502],
              [124.366356, -8.319154],
              [124.356682, -8.276479],
            ],
          ],
          [
            [
              [123.949539, -8.346658],
              [123.899269, -8.370332],
              [123.934448, -8.38083],
              [123.949539, -8.346658],
            ],
          ],
          [
            [
              [123.819405, -8.365805],
              [123.809555, -8.402317],
              [123.826668, -8.415001],
              [123.837502, -8.381944],
              [123.819405, -8.365805],
            ],
          ],
          [
            [
              [123.885277, -8.424438],
              [123.872841, -8.430863],
              [123.88018, -8.443627],
              [123.894157, -8.43028],
              [123.885277, -8.424438],
            ],
          ],
          [
            [
              [124.288063, -8.471099],
              [124.270721, -8.47352],
              [124.277626, -8.491869],
              [124.288063, -8.471099],
            ],
          ],
          [
            [
              [121.529167, -8.855833],
              [121.508331, -8.886109],
              [121.516388, -8.906941],
              [121.535278, -8.888612],
              [121.529167, -8.855833],
            ],
          ],
          [
            [
              [123.089157, -8.405],
              [123.294441, -8.388332],
              [123.305, -8.296944],
              [123.33667, -8.264721],
              [123.243713, -8.22539],
              [123.147217, -8.231109],
              [123.032623, -8.307519],
              [122.998367, -8.368379],
              [123.00528, -8.412781],
              [123.089157, -8.405],
            ],
          ],
          [
            [
              [123.019264, -8.47989],
              [123.078362, -8.48912],
              [123.168236, -8.436399],
              [122.975922, -8.435932],
              [122.945343, -8.492421],
              [122.881424, -8.530117],
              [122.885353, -8.609317],
              [122.93705, -8.59526],
              [123.019264, -8.47989],
            ],
          ],
        ],
      },
      properties: {
        cartodb_id: 29,
        country: 'Indonesia',
        id_1: 23,
        state: 'Nusa Tenggara Timur',
        key: 'indonesia-nusatenggaratimur',
        name: 'East Nusa Tenggara',
        slug: 'east-nusa-tenggara',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [140.686661, -2.477779],
              [140.612717, -2.43861],
              [140.497955, -2.444],
              [140.348801, -2.395277],
              [140.371384, -2.457221],
              [140.337006, -2.464859],
              [140.253586, -2.400959],
              [140.211105, -2.409444],
              [140.153885, -2.371111],
              [140.143417, -2.322349],
              [140.143158, -2.348534],
              [140.111282, -2.319439],
              [140.055222, -2.360203],
              [139.809998, -2.37778],
              [139.751251, -2.359654],
              [139, -1.975],
              [138.863342, -1.965819],
              [138.789734, -1.920396],
              [138.748062, -1.86667],
              [138.751114, -1.84611],
              [138.652985, -1.787329],
              [138.341309, -1.71601],
              [138.071808, -1.60333],
              [137.981049, -1.52854],
              [137.979996, -1.57028],
              [138.034485, -1.609399],
              [138.128326, -1.646109],
              [138.01889, -1.626944],
              [138, -1.605278],
              [138.00296, -1.640575],
              [137.953766, -1.62561],
              [137.954361, -1.654879],
              [137.921738, -1.634652],
              [137.963028, -1.62002],
              [138.000443, -1.63421],
              [137.962784, -1.584723],
              [137.964996, -1.542778],
              [137.93486, -1.587294],
              [137.953613, -1.537987],
              [137.913956, -1.55632],
              [137.950623, -1.520478],
              [137.970001, -1.531667],
              [137.934998, -1.458611],
              [137.83667, -1.461668],
              [137.778336, -1.480001],
              [137.809723, -1.49611],
              [137.741394, -1.481111],
              [137.753052, -1.538333],
              [137.796951, -1.52694],
              [137.793549, -1.54771],
              [137.75708, -1.546461],
              [137.727219, -1.493056],
              [137.648056, -1.51972],
              [137.636108, -1.54667],
              [137.65834, -1.550349],
              [137.634995, -1.54778],
              [137.636948, -1.528888],
              [137.592438, -1.552847],
              [137.637497, -1.561667],
              [137.633606, -1.593889],
              [137.63591, -1.561457],
              [137.585831, -1.580556],
              [137.603058, -1.57833],
              [137.642227, -1.62528],
              [137.607773, -1.608057],
              [137.603119, -1.57951],
              [137.585556, -1.585833],
              [137.580826, -1.55583],
              [137.52507, -1.604449],
              [137.531113, -1.578056],
              [137.477966, -1.597864],
              [137.524994, -1.62639],
              [137.475555, -1.618611],
              [137.504166, -1.642223],
              [137.492569, -1.670555],
              [137.519165, -1.672223],
              [137.491318, -1.670689],
              [137.503326, -1.643333],
              [137.48111, -1.634722],
              [137.47319, -1.606318],
              [137.44194, -1.639445],
              [137.45639, -1.662222],
              [137.441666, -1.62889],
              [137.401947, -1.653056],
              [137.429214, -1.671332],
              [137.394791, -1.6584],
              [137.331116, -1.689789],
              [137.354446, -1.708612],
              [137.360275, -1.76],
              [137.39473, -1.76361],
              [137.402222, -1.809446],
              [137.428329, -1.807777],
              [137.475998, -1.83778],
              [137.42778, -1.809439],
              [137.365829, -1.840829],
              [137.299438, -1.82722],
              [137.226944, -1.74833],
              [137.153122, -1.771874],
              [137.205826, -1.843612],
              [137.193329, -1.859169],
              [137.21666, -1.886669],
              [137.236893, -1.87196],
              [137.258057, -1.903059],
              [137.219452, -1.938611],
              [137.182495, -1.937779],
              [137.220551, -1.960279],
              [137.196945, -1.963055],
              [137.230438, -2.000925],
              [137.266937, -1.987779],
              [137.221603, -2.009503],
              [137.217224, -1.98333],
              [137.189163, -1.990279],
              [137.206161, -2.021535],
              [137.255707, -2.01153],
              [137.223068, -2.01325],
              [137.211685, -2.029968],
              [137.265396, -2.04078],
              [137.251404, -2.073809],
              [137.275803, -2.104687],
              [137.252502, -2.08639],
              [137.250519, -2.05105],
              [137.225052, -2.066614],
              [137.257874, -2.115513],
              [137.227066, -2.069857],
              [137.21167, -2.086944],
              [137.195679, -2.066915],
              [137.195541, -2.09764],
              [137.175613, -2.078479],
              [137.158966, -2.095534],
              [137.186279, -2.12408],
              [137.121948, -2.102294],
              [137.149048, -2.139998],
              [137.057602, -2.098139],
              [137.031235, -2.144123],
              [137.027252, -2.114966],
              [137.004715, -2.128333],
              [137.030853, -2.146974],
              [137.031235, -2.168865],
              [137.062576, -2.167899],
              [137.038681, -2.183144],
              [137.035324, -2.20457],
              [137.027176, -2.147546],
              [136.942215, -2.120279],
              [136.913895, -2.182777],
              [136.805161, -2.176717],
              [136.81073, -2.234741],
              [136.793533, -2.210915],
              [136.749146, -2.26798],
              [136.706421, -2.22702],
              [136.655609, -2.277758],
              [136.640823, -2.246155],
              [136.598709, -2.27059],
              [136.581741, -2.201986],
              [136.535141, -2.222489],
              [136.518143, -2.19077],
              [136.392654, -2.218956],
              [136.33223, -2.279722],
              [136.346664, -2.370277],
              [136.295883, -2.41113],
              [136.266083, -2.562176],
              [136.185013, -2.639112],
              [136.017731, -2.691776],
              [135.990082, -2.77009],
              [135.947495, -2.77917],
              [135.950562, -2.820001],
              [135.904999, -2.86861],
              [135.965057, -2.92696],
              [135.935486, -2.916109],
              [135.910553, -2.979166],
              [135.733948, -3.06395],
              [135.785873, -3.109717],
              [135.760956, -3.099078],
              [135.611282, -3.180421],
              [135.501968, -3.357546],
              [135.337784, -3.399168],
              [135.113922, -3.381292],
              [135.070618, -3.333921],
              [134.971939, -3.314721],
              [134.945831, -3.284169],
              [134.960007, -3.225279],
              [134.916977, -3.244816],
              [134.935944, -3.271329],
              [134.861557, -3.250149],
              [134.862289, -3.158792],
              [134.831635, -3.124849],
              [134.800629, -3.131394],
              [134.813553, -3.08191],
              [134.837784, -3.095831],
              [134.81105, -3.037865],
              [134.852753, -3.00157],
              [134.83194, -2.963055],
              [134.853058, -2.886668],
              [134.828888, -2.877499],
              [134.831161, -2.91347],
              [134.804169, -2.911667],
              [134.819168, -2.938611],
              [134.777771, -2.940829],
              [134.776672, -2.985279],
              [134.740005, -2.990555],
              [134.688339, -2.965833],
              [134.661392, -2.823219],
              [134.426788, -3.006559],
              [134.425034, -3.110369],
              [134.27594, -3.23835],
              [134.205246, -3.391239],
              [135.25766, -3.75588],
              [134.87616, -4.251943],
              [134.979721, -4.324169],
              [134.998886, -4.322361],
              [134.998428, -4.305543],
              [134.989441, -4.329445],
              [135.000839, -4.335765],
              [134.928589, -4.29995],
              [135.011948, -4.350281],
              [135.015274, -4.337818],
              [135.039795, -4.375563],
              [135.078873, -4.373259],
              [135.060562, -4.385001],
              [135.09639, -4.408057],
              [135.11908, -4.397203],
              [135.199768, -4.463722],
              [135.338669, -4.44479],
              [135.35611, -4.414719],
              [135.40889, -4.428888],
              [135.349716, -4.438889],
              [135.416977, -4.429519],
              [135.718887, -4.497223],
              [135.908188, -4.483339],
              [136.01915, -4.550833],
              [136.021164, -4.538193],
              [136.051392, -4.536944],
              [136.045013, -4.520808],
              [136.059723, -4.518889],
              [136.046387, -4.496389],
              [136.073059, -4.451805],
              [136.072495, -4.4825],
              [136.047775, -4.496945],
              [136.070343, -4.529861],
              [136.056229, -4.546981],
              [136.06778, -4.557778],
              [136.046036, -4.566249],
              [136.053055, -4.5875],
              [136.089447, -4.55972],
              [136.075485, -4.592074],
              [136.113892, -4.622781],
              [136.163605, -4.552219],
              [136.162018, -4.586597],
              [136.133362, -4.62563],
              [136.1875, -4.616944],
              [136.140549, -4.631945],
              [136.209305, -4.659304],
              [136.200317, -4.633944],
              [136.232224, -4.583329],
              [136.213058, -4.614722],
              [136.231384, -4.66257],
              [136.281799, -4.669376],
              [136.285126, -4.646466],
              [136.295029, -4.67434],
              [136.350357, -4.633609],
              [136.323303, -4.665585],
              [136.421249, -4.69368],
              [136.434174, -4.728512],
              [136.458893, -4.682219],
              [136.465088, -4.73842],
              [136.511093, -4.744961],
              [136.518753, -4.72222],
              [136.500412, -4.753264],
              [136.547531, -4.7741],
              [136.543259, -4.748958],
              [136.558548, -4.765355],
              [136.544449, -4.790555],
              [136.583038, -4.762241],
              [136.579926, -4.73576],
              [136.573486, -4.77887],
              [136.610962, -4.776591],
              [136.568893, -4.797082],
              [136.632034, -4.827103],
              [136.654541, -4.808576],
              [136.645828, -4.831459],
              [136.674576, -4.79347],
              [136.679932, -4.817713],
              [136.691116, -4.795555],
              [136.689438, -4.844445],
              [136.743332, -4.872779],
              [136.768326, -4.813333],
              [136.72226, -4.803013],
              [136.766937, -4.809166],
              [136.757782, -4.78583],
              [136.783615, -4.791945],
              [136.743195, -4.735069],
              [136.750717, -4.758848],
              [136.76973, -4.755557],
              [136.775238, -4.770323],
              [136.793686, -4.759283],
              [136.777283, -4.77061],
              [136.790024, -4.801367],
              [136.823959, -4.784859],
              [136.783127, -4.814169],
              [136.787338, -4.831839],
              [136.814102, -4.81142],
              [136.845551, -4.82758],
              [136.830551, -4.815555],
              [136.853607, -4.723888],
              [136.863327, -4.749166],
              [136.834473, -4.81917],
              [136.886108, -4.783888],
              [136.855881, -4.820006],
              [136.884445, -4.820277],
              [136.872772, -4.836671],
              [136.88269, -4.860162],
              [136.912643, -4.873709],
              [136.922073, -4.831021],
              [136.925003, -4.870833],
              [136.988907, -4.860497],
              [136.984833, -4.834018],
              [136.994171, -4.858056],
              [137.009384, -4.83132],
              [136.996536, -4.859626],
              [136.959946, -4.870778],
              [136.964447, -4.884722],
              [136.924591, -4.890449],
              [136.976257, -4.939583],
              [137.026108, -4.923059],
              [137.005524, -4.893362],
              [137.016602, -4.91996],
              [137.06189, -4.918713],
              [137.031662, -4.883056],
              [137.057465, -4.867086],
              [137.051727, -4.81478],
              [137.064194, -4.858589],
              [137.100662, -4.843472],
              [137.082443, -4.826791],
              [137.091797, -4.806379],
              [137.067596, -4.802947],
              [137.092728, -4.786487],
              [137.085022, -4.829271],
              [137.117126, -4.84292],
              [137.130676, -4.821499],
              [137.126877, -4.839812],
              [137.088455, -4.859736],
              [137.10643, -4.8794],
              [137.057785, -4.938612],
              [137.107773, -4.974721],
              [137.121964, -4.898376],
              [137.156601, -4.871111],
              [137.14856, -4.83271],
              [137.164413, -4.813178],
              [137.151871, -4.775251],
              [137.166626, -4.811603],
              [137.154037, -4.860249],
              [137.171707, -4.86604],
              [137.178055, -4.840555],
              [137.158646, -4.875339],
              [137.17778, -4.885],
              [137.211868, -4.843461],
              [137.178375, -4.887749],
              [137.142685, -4.885969],
              [137.16507, -4.90713],
              [137.13237, -4.90512],
              [137.125, -4.964166],
              [137.179993, -5.008611],
              [137.223663, -4.95997],
              [137.196808, -4.931351],
              [137.198929, -4.886048],
              [137.217072, -4.841949],
              [137.241043, -4.837099],
              [137.234619, -4.815379],
              [137.243774, -4.835383],
              [137.215042, -4.851497],
              [137.223679, -4.86581],
              [137.201218, -4.887019],
              [137.216599, -4.898372],
              [137.205627, -4.934858],
              [137.228455, -4.956563],
              [137.283447, -4.895315],
              [137.262375, -4.862608],
              [137.296661, -4.91278],
              [137.32103, -4.888448],
              [137.306946, -4.916944],
              [137.275009, -4.910381],
              [137.235596, -4.98977],
              [137.285294, -5.026015],
              [137.294647, -4.952153],
              [137.349716, -4.97],
              [137.369171, -4.925279],
              [137.368652, -4.891006],
              [137.331268, -4.846252],
              [137.37056, -4.91],
              [137.4375, -4.83833],
              [137.386383, -4.900277],
              [137.424728, -4.911666],
              [137.37944, -4.90389],
              [137.364166, -4.97],
              [137.308609, -4.97],
              [137.336243, -5.005831],
              [137.305283, -4.97194],
              [137.290665, -5.007679],
              [137.336853, -5.042817],
              [137.394958, -4.95218],
              [137.437317, -4.919075],
              [137.469101, -4.924051],
              [137.460342, -4.88565],
              [137.486435, -4.933999],
              [137.45369, -4.940616],
              [137.464661, -4.995559],
              [137.417221, -5.007223],
              [137.434174, -5.051972],
              [137.414444, -5.115833],
              [137.431671, -5.118589],
              [137.455872, -5.045023],
              [137.514755, -5.005213],
              [137.490372, -4.93494],
              [137.498032, -4.863029],
              [137.512833, -4.958318],
              [137.519028, -4.916176],
              [137.534622, -4.90425],
              [137.506882, -4.963662],
              [137.519592, -5.00266],
              [137.567963, -4.952649],
              [137.532837, -4.97914],
              [137.551117, -5.007791],
              [137.453369, -5.069268],
              [137.449387, -5.099888],
              [137.497177, -5.146317],
              [137.530273, -5.123245],
              [137.50499, -5.105753],
              [137.520874, -5.075976],
              [137.496094, -5.079269],
              [137.521423, -5.074759],
              [137.513962, -5.10728],
              [137.551361, -5.116002],
              [137.543884, -5.061111],
              [137.595551, -5.008055],
              [137.570618, -4.987661],
              [137.602661, -4.954443],
              [137.57988, -4.947223],
              [137.618164, -4.93389],
              [137.58374, -4.90084],
              [137.608093, -4.904179],
              [137.615616, -4.929145],
              [137.632904, -4.922148],
              [137.615723, -4.919128],
              [137.614426, -4.885729],
              [137.596146, -4.878935],
              [137.624222, -4.898518],
              [137.677643, -4.86235],
              [137.6203, -4.908901],
              [137.636337, -4.921067],
              [137.619919, -4.95427],
              [137.637772, -4.951111],
              [137.613617, -4.982547],
              [137.628967, -5.005211],
              [137.655731, -4.989439],
              [137.633636, -5.007355],
              [137.679459, -4.99131],
              [137.657227, -5.01028],
              [137.629379, -5.009692],
              [137.625275, -5.025557],
              [137.598053, -5.020278],
              [137.613556, -5.050661],
              [137.640549, -5.02611],
              [137.614685, -5.052061],
              [137.589127, -5.039639],
              [137.595078, -5.022031],
              [137.582199, -5.027038],
              [137.552505, -5.07263],
              [137.595276, -5.059999],
              [137.569565, -5.10746],
              [137.699081, -4.997439],
              [137.665924, -5.02725],
              [137.712875, -5.01128],
              [137.710556, -5.026332],
              [137.668335, -5.028788],
              [137.652222, -5.046112],
              [137.670502, -5.067969],
              [137.681885, -5.05243],
              [137.710007, -5.05472],
              [137.725006, -5.018056],
              [137.749451, -5.010277],
              [137.700272, -5.056945],
              [137.743896, -5.077222],
              [137.756943, -5.04833],
              [137.745834, -5.077781],
              [137.728394, -5.075208],
              [137.734924, -5.086945],
              [137.788895, -5.05222],
              [137.805832, -5.06639],
              [137.804169, -5.04472],
              [137.827774, -5.04639],
              [137.803604, -5.046939],
              [137.804718, -5.068056],
              [137.788605, -5.053889],
              [137.759583, -5.088611],
              [137.73555, -5.090277],
              [137.738052, -5.108611],
              [137.719452, -5.12028],
              [137.748337, -5.138055],
              [137.758606, -5.103056],
              [137.810272, -5.070417],
              [137.83812, -5.075417],
              [137.856323, -5.053611],
              [137.837708, -5.076876],
              [137.778671, -5.09182],
              [137.796753, -5.130053],
              [137.771118, -5.122233],
              [137.74556, -5.184166],
              [137.717346, -5.157984],
              [137.714172, -5.186945],
              [137.685822, -5.20237],
              [137.680832, -5.2375],
              [137.719528, -5.276592],
              [137.735001, -5.255556],
              [137.738892, -5.2875],
              [137.767776, -5.276389],
              [137.777496, -5.202219],
              [137.772507, -5.26972],
              [137.820282, -5.228612],
              [137.802017, -5.179166],
              [137.856384, -5.145833],
              [137.835831, -5.129166],
              [137.88945, -5.0975],
              [137.910278, -5.117219],
              [137.897781, -5.108889],
              [137.915283, -5.09389],
              [137.961105, -5.093334],
              [137.967224, -5.080279],
              [137.930832, -5.040829],
              [137.968887, -5.083055],
              [137.948059, -5.105557],
              [137.914444, -5.095829],
              [137.89917, -5.109169],
              [137.90889, -5.11889],
              [137.887222, -5.100278],
              [137.843887, -5.166389],
              [137.806107, -5.178473],
              [137.825562, -5.23056],
              [137.728073, -5.325589],
              [137.740829, -5.374444],
              [137.746384, -5.35576],
              [137.832504, -5.298055],
              [137.861389, -5.223611],
              [137.902222, -5.214167],
              [137.916107, -5.167223],
              [137.952774, -5.138611],
              [138.021393, -5.139723],
              [137.960281, -5.141389],
              [137.91806, -5.168331],
              [137.912781, -5.210834],
              [137.867218, -5.221941],
              [137.838608, -5.279999],
              [137.876114, -5.303333],
              [137.91037, -5.270108],
              [137.877228, -5.308056],
              [137.909698, -5.321306],
              [137.958832, -5.278958],
              [137.939163, -5.210278],
              [137.951904, -5.197399],
              [137.941116, -5.21111],
              [137.963333, -5.231944],
              [137.960205, -5.27179],
              [137.994781, -5.28301],
              [138.060837, -5.23278],
              [138.044952, -5.18542],
              [138.0961, -5.170526],
              [138.066498, -5.137029],
              [138.102356, -5.130493],
              [138.096909, -5.098136],
              [138.120285, -5.100556],
              [138.111755, -5.11218],
              [138.095932, -5.10047],
              [138.102127, -5.13235],
              [138.067535, -5.139541],
              [138.096939, -5.152222],
              [138.096298, -5.180214],
              [138.047623, -5.189037],
              [138.087784, -5.23],
              [137.994446, -5.2925],
              [137.947418, -5.293072],
              [137.875275, -5.361939],
              [138.028885, -5.505281],
              [138.041107, -5.479722],
              [137.984024, -5.371354],
              [138.001587, -5.358379],
              [138.048355, -5.367272],
              [138.083145, -5.27842],
              [138.147339, -5.267649],
              [138.141861, -5.191596],
              [138.176849, -5.186413],
              [138.141708, -5.197303],
              [138.150391, -5.268478],
              [138.088348, -5.27899],
              [138.049789, -5.371112],
              [137.991089, -5.368555],
              [138.026642, -5.437757],
              [138.051392, -5.433055],
              [138.054428, -5.374451],
              [138.126999, -5.37387],
              [138.112885, -5.320039],
              [138.129013, -5.295087],
              [138.176834, -5.275101],
              [138.181061, -5.234888],
              [138.177704, -5.276022],
              [138.129532, -5.296389],
              [138.131561, -5.312794],
              [138.114273, -5.321293],
              [138.140244, -5.369929],
              [138.177994, -5.333399],
              [138.236465, -5.322412],
              [138.28566, -5.266903],
              [138.239044, -5.326373],
              [138.183258, -5.339423],
              [138.16185, -5.383855],
              [138.058685, -5.386909],
              [138.05278, -5.50472],
              [138.088699, -5.521059],
              [138.076859, -5.463939],
              [138.091797, -5.445975],
              [138.078049, -5.463055],
              [138.094376, -5.501411],
              [138.081116, -5.530834],
              [138.125793, -5.532678],
              [138.133392, -5.481514],
              [138.165573, -5.436957],
              [138.186066, -5.440681],
              [138.198685, -5.481849],
              [138.281662, -5.40862],
              [138.242188, -5.466599],
              [138.255905, -5.505616],
              [138.297501, -5.507778],
              [138.255829, -5.506388],
              [138.259506, -5.532753],
              [138.297165, -5.524929],
              [138.255646, -5.52691],
              [138.254181, -5.540461],
              [138.28833, -5.542779],
              [138.235001, -5.536666],
              [138.262222, -5.565001],
              [138.243607, -5.595555],
              [138.27417, -5.594721],
              [138.286194, -5.61748],
              [138.310883, -5.578286],
              [138.353058, -5.551871],
              [138.307358, -5.589237],
              [138.368439, -5.617626],
              [138.374756, -5.644679],
              [138.339447, -5.653055],
              [138.423218, -5.659669],
              [138.232498, -5.707777],
              [138.15889, -5.753889],
              [138.16333, -5.805554],
              [138.34938, -5.83769],
              [138.405792, -5.818329],
              [138.403336, -5.73527],
              [138.424393, -5.855866],
              [138.444489, -5.846298],
              [138.466385, -5.88361],
              [138.487228, -5.86228],
              [138.53923, -5.887751],
              [138.517075, -5.889771],
              [138.487595, -5.8637],
              [138.466583, -5.884939],
              [138.444199, -5.847572],
              [138.426193, -5.858562],
              [138.401398, -5.83333],
              [138.251114, -5.87778],
              [138.254166, -5.923331],
              [138.288895, -5.939166],
              [138.363617, -6.069171],
              [138.348969, -6.090442],
              [138.403061, -6.18111],
              [138.383057, -6.183332],
              [138.389999, -6.23194],
              [138.441391, -6.327219],
              [138.428848, -6.36095],
              [138.681107, -6.6075],
              [138.824997, -6.691108],
              [138.834793, -6.784596],
              [138.872955, -6.798277],
              [138.91037, -6.780899],
              [138.938889, -6.787777],
              [138.953888, -6.828889],
              [139.134613, -6.956379],
              [139.217438, -6.970949],
              [139.219482, -7.021232],
              [139.258286, -7.016988],
              [139.344269, -7.043914],
              [139.389664, -7.116319],
              [139.474976, -7.148047],
              [139.470047, -7.238251],
              [139.50708, -7.263996],
              [139.56192, -7.26636],
              [139.57373, -7.187123],
              [139.631943, -7.15506],
              [139.656052, -7.18003],
              [139.664612, -7.262061],
              [139.688766, -7.285228],
              [139.698654, -7.24724],
              [139.745209, -7.214639],
              [139.766602, -7.112126],
              [139.802246, -7.078103],
              [139.830399, -7.085875],
              [139.784821, -7.100969],
              [139.763702, -7.129942],
              [139.769852, -7.18024],
              [139.747726, -7.219747],
              [139.690643, -7.288561],
              [139.666168, -7.295719],
              [139.635056, -7.233941],
              [139.648392, -7.177779],
              [139.597656, -7.18476],
              [139.577698, -7.267271],
              [139.541382, -7.284661],
              [139.473785, -7.26068],
              [139.459442, -7.221426],
              [139.472626, -7.171249],
              [139.404419, -7.131457],
              [139.353729, -7.164835],
              [139.383499, -7.200255],
              [139.43161, -7.200046],
              [139.437164, -7.220868],
              [139.42308, -7.233811],
              [139.265533, -7.225818],
              [139.243729, -7.15057],
              [139.170609, -7.236943],
              [139.023254, -7.240216],
              [138.915115, -7.198061],
              [138.671951, -7.19833],
              [138.67334, -7.223056],
              [138.868591, -7.321462],
              [138.964325, -7.519186],
              [139.054016, -7.517074],
              [139.115234, -7.566709],
              [139.114456, -7.636712],
              [139.106308, -7.60077],
              [139.068344, -7.631122],
              [139.018082, -7.751259],
              [139.018051, -7.853889],
              [138.913086, -7.919674],
              [138.902771, -8.031388],
              [138.924164, -8.076111],
              [138.835281, -8.115],
              [138.910004, -8.267219],
              [138.949448, -8.262499],
              [138.92334, -8.279722],
              [138.953049, -8.295834],
              [138.935272, -8.313889],
              [138.976944, -8.329998],
              [139.003311, -8.296171],
              [138.995285, -8.230279],
              [138.948059, -8.18639],
              [138.956116, -8.159168],
              [138.953613, -8.193334],
              [138.988052, -8.203059],
              [139.070053, -8.151273],
              [139.068802, -8.11299],
              [139.08194, -8.1375],
              [139.223236, -8.095256],
              [139.277191, -8.176252],
              [139.343323, -8.206104],
              [139.506393, -8.180833],
              [139.71257, -8.097499],
              [139.924423, -8.107983],
              [140.060791, -8.062901],
              [140.046387, -8.016963],
              [140.066101, -7.9232],
              [140.056671, -7.993609],
              [140.078033, -8.032313],
              [140.074966, -8.065577],
              [139.956665, -8.124438],
              [139.983337, -8.187221],
              [140.169556, -8.336712],
              [140.228607, -8.355278],
              [140.314621, -8.455412],
              [140.355118, -8.463129],
              [140.500488, -8.61316],
              [140.61322, -8.809982],
              [140.892776, -9.084998],
              [141.001114, -9.118333],
              [141.011765, -7.999899],
              [140.982391, -6.891975],
              [140.951218, -6.876529],
              [140.936356, -6.89572],
              [140.936249, -6.846869],
              [140.897812, -6.856439],
              [140.902603, -6.805129],
              [140.858093, -6.780619],
              [140.885437, -6.740618],
              [140.836395, -6.713049],
              [140.834351, -6.67659],
              [140.864258, -6.671305],
              [140.8564, -6.630125],
              [140.893906, -6.623135],
              [140.931885, -6.553238],
              [140.957016, -6.341135],
              [141.000214, -6.313239],
              [141.002945, -2.604969],
              [140.915283, -2.627222],
              [140.789536, -2.595499],
              [140.761108, -2.62861],
              [140.71167, -2.596666],
              [140.736664, -2.627222],
              [140.705826, -2.628334],
              [140.686951, -2.613889],
              [140.698059, -2.588055],
              [140.717224, -2.59278],
              [140.707947, -2.538949],
              [140.747223, -2.534719],
              [140.686661, -2.477779],
            ],
          ],
          [
            [
              [137.988617, -1.571112],
              [137.980133, -1.583469],
              [138.023483, -1.616599],
              [138.024155, -1.600109],
              [137.988617, -1.571112],
            ],
          ],
          [
            [
              [137.978058, -1.581944],
              [137.975525, -1.569188],
              [137.98111, -1.59639],
              [138.012772, -1.607222],
              [137.978058, -1.581944],
            ],
          ],
          [
            [
              [138.716949, -1.57972],
              [138.710556, -1.609445],
              [138.747498, -1.625],
              [138.716949, -1.57972],
            ],
          ],
          [
            [
              [138.799728, -1.66194],
              [138.789169, -1.672499],
              [138.797577, -1.707807],
              [138.799728, -1.66194],
            ],
          ],
          [
            [
              [139.008606, -1.93222],
              [138.99884, -1.93779],
              [139.018188, -1.948651],
              [139.025116, -1.934025],
              [139.008606, -1.93222],
            ],
          ],
          [
            [
              [137.632217, -5.07833],
              [137.604721, -5.103888],
              [137.661667, -5.116667],
              [137.665344, -5.075069],
              [137.677567, -5.094309],
              [137.664169, -5.117221],
              [137.550064, -5.169653],
              [137.670837, -5.216389],
              [137.704727, -5.14889],
              [137.742249, -5.136425],
              [137.716736, -5.121875],
              [137.725281, -5.087779],
              [137.694168, -5.05472],
              [137.671387, -5.07],
              [137.648895, -5.05111],
              [137.632217, -5.07833],
            ],
          ],
          [
            [
              [137.726868, -5.07583],
              [137.715134, -5.10132],
              [137.732224, -5.111806],
              [137.726868, -5.07583],
            ],
          ],
          [
            [
              [137.574783, -5.109547],
              [137.547607, -5.142501],
              [137.62973, -5.127717],
              [137.599365, -5.096743],
              [137.574783, -5.109547],
            ],
          ],
          [
            [
              [137.775558, -5.108889],
              [137.759171, -5.104166],
              [137.752441, -5.137569],
              [137.705826, -5.153334],
              [137.705826, -5.185],
              [137.717224, -5.155],
              [137.743332, -5.182222],
              [137.771118, -5.119439],
              [137.794235, -5.12882],
              [137.775558, -5.108889],
            ],
          ],
          [
            [
              [137.76973, -5.359721],
              [137.838882, -5.375554],
              [137.892227, -5.329723],
              [137.843338, -5.285556],
              [137.780548, -5.334439],
              [137.76973, -5.359721],
            ],
          ],
          [
            [
              [138.156113, -5.462446],
              [138.133392, -5.536582],
              [138.064636, -5.556417],
              [138.045273, -5.598889],
              [138.089371, -5.745764],
              [138.134445, -5.718888],
              [138.183304, -5.72805],
              [138.213333, -5.67333],
              [138.24675, -5.666887],
              [138.239975, -5.611981],
              [138.277359, -5.60064],
              [138.243408, -5.597385],
              [138.260788, -5.565427],
              [138.231247, -5.553288],
              [138.234222, -5.536142],
              [138.254654, -5.534579],
              [138.240051, -5.447612],
              [138.201935, -5.485814],
              [138.177704, -5.439199],
              [138.156113, -5.462446],
            ],
          ],
          [
            [
              [138.372345, -5.631629],
              [138.339172, -5.595831],
              [138.286118, -5.619167],
              [138.243561, -5.611985],
              [138.250809, -5.663958],
              [138.225037, -5.68639],
              [138.3405, -5.659877],
              [138.341003, -5.647079],
              [138.372818, -5.645044],
              [138.372345, -5.631629],
            ],
          ],
          [
            [
              [138.219986, -5.699447],
              [138.219543, -5.67475],
              [138.204453, -5.712554],
              [138.219986, -5.699447],
            ],
          ],
          [
            [
              [135.823059, -1.064444],
              [135.802216, -1.09861],
              [135.873337, -1.17333],
              [136, -1.1425],
              [136.139725, -1.20047],
              [136.315704, -1.13999],
              [136.387589, -1.076979],
              [136.323883, -1.073889],
              [136.256104, -1.016666],
              [136.170898, -1.042747],
              [136.091095, -0.916307],
              [136.039993, -0.89528],
              [136.051392, -0.856669],
              [135.924622, -0.790082],
              [135.854996, -0.700555],
              [135.80545, -0.685597],
              [135.748367, -0.739628],
              [135.717468, -0.70734],
              [135.68512, -0.71941],
              [135.64328, -0.662539],
              [135.595291, -0.70156],
              [135.589767, -0.663129],
              [135.533615, -0.673333],
              [135.363052, -0.63056],
              [135.393799, -0.723137],
              [135.489319, -0.805698],
              [135.483185, -0.81471],
              [135.50972, -0.827266],
              [135.541382, -0.875561],
              [135.542496, -0.839051],
              [135.467422, -0.74415],
              [135.642212, -0.886614],
              [135.729584, -0.77534],
              [135.708328, -0.802501],
              [135.740829, -0.810277],
              [135.773636, -0.875779],
              [135.823059, -1.064444],
            ],
          ],
          [
            [
              [134.865616, -1.132207],
              [134.952164, -1.128141],
              [134.98584, -1.036112],
              [134.972229, -1.01083],
              [134.925003, -1.074723],
              [134.946854, -0.99929],
              [134.941528, -0.965931],
              [134.843536, -0.93096],
              [134.791916, -0.98111],
              [134.810608, -0.980829],
              [134.794449, -1.0325],
              [134.865616, -1.132207],
            ],
          ],
          [
            [
              [136.618332, -1.144722],
              [136.597931, -1.140549],
              [136.600555, -1.173061],
              [136.618332, -1.144722],
            ],
          ],
          [
            [
              [136.597504, -1.189167],
              [136.575912, -1.198749],
              [136.589996, -1.2275],
              [136.615051, -1.218769],
              [136.597504, -1.189167],
            ],
          ],
          [
            [
              [136.448471, -1.240008],
              [136.442581, -1.1992],
              [136.429825, -1.213009],
              [136.448471, -1.240008],
            ],
          ],
          [
            [
              [136.323914, -1.217178],
              [136.294739, -1.240503],
              [136.344635, -1.236434],
              [136.323914, -1.217178],
            ],
          ],
          [
            [
              [136.521667, -1.226666],
              [136.500702, -1.239311],
              [136.539108, -1.239675],
              [136.521667, -1.226666],
            ],
          ],
          [
            [
              [136.220123, -1.22892],
              [136.188354, -1.2483],
              [136.206329, -1.256306],
              [136.220123, -1.22892],
            ],
          ],
          [
            [
              [136.604172, -1.22861],
              [136.59375, -1.244999],
              [136.60878, -1.25436],
              [136.616837, -1.236922],
              [136.604172, -1.22861],
            ],
          ],
          [
            [
              [136.650345, -1.258679],
              [136.619873, -1.24419],
              [136.588333, -1.275829],
              [136.600372, -1.320721],
              [136.662201, -1.34517],
              [136.650345, -1.258679],
            ],
          ],
          [
            [
              [138.736664, -6.651109],
              [138.671951, -6.640279],
              [138.672775, -6.724167],
              [138.752777, -6.75083],
              [138.835281, -6.826391],
              [138.944717, -6.82611],
              [138.927505, -6.787777],
              [138.875, -6.803611],
              [138.831116, -6.789719],
              [138.821671, -6.706112],
              [138.736664, -6.651109],
            ],
          ],
          [
            [
              [138.674728, -6.753055],
              [138.635498, -6.729222],
              [138.610275, -6.755277],
              [138.675003, -6.834723],
              [138.700562, -6.809445],
              [138.696671, -6.831667],
              [138.66861, -6.838609],
              [138.679169, -6.86917],
              [138.728333, -6.883611],
              [138.798889, -6.834999],
              [138.736115, -6.759439],
              [138.674728, -6.753055],
            ],
          ],
          [
            [
              [139.348892, -7.191886],
              [139.338058, -7.165287],
              [139.380539, -7.112805],
              [139.344315, -7.047271],
              [139.219162, -7.025279],
              [139.224091, -6.99824],
              [139.187759, -6.97306],
              [139.128784, -6.966357],
              [138.934448, -6.842219],
              [138.798828, -6.850365],
              [138.717224, -6.90222],
              [138.626663, -6.87611],
              [138.565552, -6.918889],
              [138.613663, -7.010989],
              [138.855499, -7.157715],
              [138.935654, -7.159518],
              [139.06456, -7.211061],
              [139.159592, -7.202781],
              [139.179718, -7.2135],
              [139.201248, -7.156321],
              [139.238541, -7.139749],
              [139.261368, -7.15547],
              [139.273407, -7.21773],
              [139.429672, -7.217217],
              [139.348892, -7.191886],
            ],
          ],
          [
            [
              [139.778641, -7.11203],
              [139.782822, -7.101749],
              [139.765869, -7.121571],
              [139.778641, -7.11203],
            ],
          ],
          [
            [
              [138.765839, -7.16701],
              [138.759338, -7.144841],
              [138.713959, -7.116509],
              [138.716339, -7.156767],
              [138.765839, -7.16701],
            ],
          ],
          [
            [
              [138.791992, -7.16389],
              [138.806412, -7.159609],
              [138.781143, -7.14949],
              [138.791992, -7.16389],
            ],
          ],
          [
            [
              [138.992355, -7.200771],
              [138.960587, -7.196493],
              [139.029861, -7.223701],
              [138.992355, -7.200771],
            ],
          ],
          [
            [
              [139.651947, -7.205833],
              [139.639862, -7.223539],
              [139.655609, -7.286751],
              [139.676086, -7.288565],
              [139.651947, -7.205833],
            ],
          ],
          [
            [
              [139.160416, -7.208007],
              [139.144318, -7.211838],
              [139.17189, -7.215815],
              [139.160416, -7.208007],
            ],
          ],
          [
            [
              [135.239502, -1.483889],
              [135.221893, -1.50746],
              [135.215729, -1.474511],
              [135.154831, -1.489979],
              [135.117783, -1.4625],
              [135.092072, -1.501065],
              [135.247437, -1.529691],
              [135.325317, -1.509429],
              [135.337967, -1.482462],
              [135.262192, -1.478369],
              [135.253326, -1.52139],
              [135.239502, -1.483889],
            ],
          ],
          [
            [
              [136.18689, -1.870264],
              [136.229462, -1.909598],
              [136.251984, -1.887713],
              [136.259796, -1.913474],
              [136.275558, -1.883385],
              [136.341843, -1.860849],
              [136.536072, -1.899107],
              [136.503586, -1.849189],
              [136.630951, -1.872919],
              [136.718781, -1.816415],
              [136.848068, -1.82756],
              [136.903732, -1.793248],
              [136.789688, -1.73943],
              [136.445633, -1.697759],
              [136.370667, -1.717186],
              [136.20311, -1.64816],
              [135.89917, -1.63472],
              [135.85083, -1.66194],
              [135.846115, -1.63722],
              [135.785553, -1.62611],
              [135.410278, -1.601944],
              [135.465561, -1.63611],
              [135.474167, -1.680834],
              [135.553925, -1.66302],
              [135.672501, -1.706944],
              [135.732224, -1.68861],
              [135.751389, -1.721111],
              [135.788895, -1.71944],
              [135.762497, -1.748889],
              [135.821945, -1.73528],
              [135.829163, -1.76528],
              [135.845001, -1.746667],
              [135.864716, -1.767778],
              [135.867218, -1.740278],
              [135.899445, -1.788056],
              [135.911942, -1.754719],
              [135.978058, -1.816944],
              [136.18689, -1.870264],
            ],
          ],
          [
            [
              [135.789169, -1.759168],
              [135.765274, -1.772223],
              [135.792496, -1.771389],
              [135.789169, -1.759168],
            ],
          ],
          [
            [
              [135.813263, -1.784792],
              [135.794281, -1.80024],
              [135.804428, -1.817163],
              [135.824982, -1.803397],
              [135.813263, -1.784792],
            ],
          ],
          [
            [
              [137.02417, -1.830833],
              [136.950394, -1.83829],
              [137.054443, -1.869444],
              [137.02417, -1.830833],
            ],
          ],
          [
            [
              [136.363892, -1.886389],
              [136.358612, -1.903059],
              [136.372772, -1.898056],
              [136.363892, -1.886389],
            ],
          ],
          [
            [
              [136.28215, -1.88671],
              [136.252518, -1.936765],
              [136.299057, -1.934199],
              [136.316437, -1.904517],
              [136.28215, -1.88671],
            ],
          ],
          [
            [
              [136.33139, -1.905832],
              [136.330551, -1.935001],
              [136.36084, -1.962777],
              [136.357498, -1.905279],
              [136.33139, -1.905832],
            ],
          ],
          [
            [
              [139.641342, -7.166201],
              [139.639542, -7.180967],
              [139.629395, -7.166479],
              [139.584839, -7.185022],
              [139.641342, -7.166201],
            ],
          ],
          [
            [
              [139.582672, -7.199419],
              [139.589844, -7.19128],
              [139.566711, -7.226402],
              [139.574646, -7.245232],
              [139.582672, -7.199419],
            ],
          ],
          [
            [
              [138.238281, -7.461789],
              [138.049286, -7.599432],
              [137.968765, -7.734963],
              [137.906097, -7.787568],
              [137.79834, -8],
              [137.80986, -8.041252],
              [137.705826, -8.172501],
              [137.63945, -8.416108],
              [137.656387, -8.44694],
              [137.870834, -8.373609],
              [138.300827, -8.391945],
              [138.33194, -8.300556],
              [138.323883, -8.370279],
              [138.340271, -8.379444],
              [138.468887, -8.350834],
              [138.488052, -8.321387],
              [138.615005, -8.249722],
              [138.678604, -8.150555],
              [138.794449, -8.13972],
              [138.827499, -8.079165],
              [138.913895, -8.075277],
              [138.888885, -8.03513],
              [138.896149, -7.899531],
              [138.993958, -7.849852],
              [138.994293, -7.74785],
              [139.087479, -7.572639],
              [138.912949, -7.53501],
              [138.865005, -7.436666],
              [138.749786, -7.370584],
              [138.418365, -7.40394],
              [138.238281, -7.461789],
            ],
          ],
          [
            [
              [140.057495, -8],
              [140.05365, -8.022773],
              [140.072754, -8.043889],
              [140.057495, -8],
            ],
          ],
          [
            [
              [138.892502, -8.388057],
              [138.916107, -8.300055],
              [138.846115, -8.263055],
              [138.815002, -8.137778],
              [138.745285, -8.16528],
              [138.679443, -8.16972],
              [138.662506, -8.235833],
              [138.554031, -8.317221],
              [138.59639, -8.363333],
              [138.892502, -8.388057],
            ],
          ],
          [
            [
              [138.853058, -8.206389],
              [138.848343, -8.254039],
              [138.890274, -8.267221],
              [138.88681, -8.238333],
              [138.853058, -8.206389],
            ],
          ],
          [
            [
              [138.846939, -8.25472],
              [138.880829, -8.286945],
              [138.900833, -8.282498],
              [138.846939, -8.25472],
            ],
          ],
          [
            [
              [138.9375, -8.320277],
              [138.95166, -8.341667],
              [138.98111, -8.335279],
              [138.9375, -8.320277],
            ],
          ],
          [
            [
              [138.476105, -8.359444],
              [138.499451, -8.320829],
              [138.47139, -8.352778],
              [138.303329, -8.394999],
              [138.26445, -8.390828],
              [138.261673, -8.415559],
              [138.34639, -8.41944],
              [138.476105, -8.359444],
            ],
          ],
          [
            [
              [138.925827, -8.32361],
              [138.926117, -8.361945],
              [138.970276, -8.365555],
              [138.925827, -8.32361],
            ],
          ],
          [
            [
              [136.0336, -4.543684],
              [135.986526, -4.542084],
              [136.038391, -4.578921],
              [136.046677, -4.557589],
              [136.06691, -4.554398],
              [136.055054, -4.547481],
              [136.069244, -4.528965],
              [136.047501, -4.520831],
              [136.052338, -4.53639],
              [136.0336, -4.543684],
            ],
          ],
          [
            [
              [136.212494, -4.625279],
              [136.211395, -4.61472],
              [136.21167, -4.653333],
              [136.212494, -4.625279],
            ],
          ],
          [
            [
              [136.783844, -4.800275],
              [136.777893, -4.822709],
              [136.792496, -4.807291],
              [136.783844, -4.800275],
            ],
          ],
          [
            [
              [136.682083, -4.825362],
              [136.673447, -4.810445],
              [136.651459, -4.8268],
              [136.680832, -4.849443],
              [136.682083, -4.825362],
            ],
          ],
          [
            [
              [136.787216, -4.836388],
              [136.767227, -4.855556],
              [136.777771, -4.869718],
              [136.794312, -4.849026],
              [136.787216, -4.836388],
            ],
          ],
          [
            [
              [136.850891, -4.83077],
              [136.808517, -4.833339],
              [136.789032, -4.88345],
              [136.836945, -4.870831],
              [136.794998, -4.888334],
              [136.831451, -4.914025],
              [136.905563, -4.874452],
              [136.850891, -4.83077],
            ],
          ],
          [
            [
              [137.069458, -4.862466],
              [137.055145, -4.849776],
              [137.05806, -4.893611],
              [137.079391, -4.883259],
              [137.069458, -4.862466],
            ],
          ],
          [
            [
              [137.410934, -4.958318],
              [137.337982, -5.052601],
              [137.405579, -5.109431],
              [137.414169, -5.004723],
              [137.448639, -4.99531],
              [137.45314, -4.938498],
              [137.476303, -4.92868],
              [137.436829, -4.921295],
              [137.410934, -4.958318],
            ],
          ],
          [
            [
              [137.616577, -4.958748],
              [137.575302, -4.987336],
              [137.618896, -5.02194],
              [137.616577, -4.958748],
            ],
          ],
          [
            [
              [137.50972, -5.171941],
              [137.496109, -5.185281],
              [137.507141, -5.198541],
              [137.50972, -5.171941],
            ],
          ],
          [
            [
              [134.817627, -2.681377],
              [134.804443, -2.711944],
              [134.863327, -2.7075],
              [134.817627, -2.681377],
            ],
          ],
          [
            [
              [135.730316, -2.91161],
              [135.672134, -2.94229],
              [135.718063, -2.929645],
              [135.730316, -2.91161],
            ],
          ],
          [
            [
              [135.769165, -2.933611],
              [135.729828, -2.941041],
              [135.708496, -2.972639],
              [135.806946, -2.94306],
              [135.769165, -2.933611],
            ],
          ],
          [
            [
              [135.611801, -3.06994],
              [135.619553, -3.097629],
              [135.625916, -3.077431],
              [135.611801, -3.06994],
            ],
          ],
          [
            [
              [135.268097, -0.391721],
              [135.267563, -0.410619],
              [135.278336, -0.406945],
              [135.268097, -0.391721],
            ],
          ],
          [
            [
              [135.491287, -0.93065],
              [135.4935, -0.964363],
              [135.509216, -0.967496],
              [135.491287, -0.93065],
            ],
          ],
          [
            [
              [137.361664, -1.763056],
              [137.343338, -1.696667],
              [137.241943, -1.74111],
              [137.271118, -1.785278],
              [137.320007, -1.798611],
              [137.360138, -1.839236],
              [137.401947, -1.78667],
              [137.361664, -1.763056],
            ],
          ],
          [
            [
              [137.123337, -1.888055],
              [137.160828, -1.932362],
              [137.138336, -1.8475],
              [137.162781, -1.796666],
              [137.140274, -1.772501],
              [137.091949, -1.826389],
              [137.123337, -1.888055],
            ],
          ],
          [
            [
              [137.274719, -1.79111],
              [137.299591, -1.825001],
              [137.323792, -1.822457],
              [137.311951, -1.800001],
              [137.274719, -1.79111],
            ],
          ],
          [
            [
              [137.165833, -1.86778],
              [137.213058, -1.890555],
              [137.17775, -1.806419],
              [137.142105, -1.846445],
              [137.165833, -1.86778],
            ],
          ],
          [
            [
              [137.087494, -1.85566],
              [137.093933, -1.895802],
              [137.111938, -1.87611],
              [137.087494, -1.85566],
            ],
          ],
          [
            [
              [137.180557, -1.878057],
              [137.174728, -1.940279],
              [137.256104, -1.90694],
              [137.231674, -1.871939],
              [137.212296, -1.892709],
              [137.180557, -1.878057],
            ],
          ],
          [
            [
              [137.199997, -1.972219],
              [137.197083, -1.985486],
              [137.213333, -1.982222],
              [137.199997, -1.972219],
            ],
          ],
          [
            [
              [136.269501, -2.222935],
              [136.257401, -2.215674],
              [136.248444, -2.228918],
              [136.269501, -2.222935],
            ],
          ],
        ],
      },
      properties: {
        cartodb_id: 1,
        country: 'Indonesia',
        id_1: 24,
        state: 'Papua',
        key: 'indonesia-papua',
        name: 'Papua',
        slug: 'papua',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [101.914047, -0.920961],
              [102.007767, -0.878599],
              [102.116623, -0.921171],
              [102.165001, -0.89873],
              [102.20359, -0.94852],
              [102.306816, -0.91736],
              [102.326332, -0.963339],
              [102.38623, -0.99338],
              [102.382446, -1.02669],
              [102.44252, -1.02545],
              [102.48381, -1.080979],
              [102.616806, -1.094629],
              [102.63903, -1.121101],
              [102.779449, -1.026619],
              [102.789528, -0.976889],
              [102.913399, -0.886409],
              [103.007942, -0.75602],
              [103.126038, -0.746429],
              [103.360077, -0.80318],
              [103.485001, -0.771112],
              [103.459999, -0.735277],
              [103.412331, -0.731389],
              [103.435143, -0.710556],
              [103.362617, -0.696942],
              [103.421112, -0.669167],
              [103.373337, -0.651111],
              [103.430557, -0.61583],
              [103.404716, -0.60417],
              [103.436386, -0.541945],
              [103.400467, -0.517549],
              [103.553055, -0.496112],
              [103.604164, -0.426389],
              [103.55278, -0.385277],
              [103.604721, -0.395834],
              [103.786392, -0.340556],
              [103.709442, -0.255829],
              [103.547226, -0.215831],
              [103.442245, -0.352848],
              [103.521393, -0.21111],
              [103.424141, -0.207049],
              [103.501549, -0.147554],
              [103.454102, -0.11187],
              [103.488052, -0.089149],
              [103.457008, -0.044549],
              [103.504738, -0.07983],
              [103.533882, -0.079921],
              [103.583702, -0.06066],
              [103.59581, -0.037978],
              [103.567307, -0.025967],
              [103.614273, -0.006715],
              [103.811668, -0.010834],
              [103.753052, 0.229166],
              [103.712776, 0.299446],
              [103.651665, 0.337222],
              [103.614403, 0.345418],
              [103.6007, 0.294215],
              [103.585732, 0.393189],
              [103.414749, 0.521301],
              [103.32621, 0.545462],
              [103.195908, 0.475206],
              [103.142067, 0.39348],
              [102.859177, 0.25005],
              [102.69117, 0.248486],
              [102.598793, 0.201912],
              [102.583611, 0.154167],
              [102.527206, 0.168425],
              [102.548119, 0.171994],
              [102.513992, 0.19311],
              [102.484947, 0.239691],
              [102.431664, 0.243611],
              [102.405815, 0.208622],
              [102.430275, 0.26111],
              [102.494911, 0.24997],
              [102.521767, 0.203079],
              [102.573799, 0.169348],
              [102.612465, 0.230497],
              [102.899178, 0.33559],
              [103.10733, 0.455001],
              [103.104446, 0.478611],
              [102.894142, 0.728491],
              [102.674683, 0.755719],
              [102.570236, 0.738701],
              [102.407532, 0.811556],
              [102.229172, 0.995434],
              [102.191437, 1.093891],
              [102.209427, 1.187251],
              [102.180069, 1.230281],
              [102.166817, 1.133411],
              [102.122726, 1.124511],
              [102.152969, 1.132561],
              [102.150597, 1.166825],
              [102.166061, 1.182529],
              [102.149017, 1.378252],
              [102.006752, 1.45814],
              [101.751945, 1.664723],
              [101.561386, 1.637221],
              [101.409721, 1.698055],
              [101.356941, 1.779443],
              [101.305, 2.041667],
              [101.078056, 2.214441],
              [101.053665, 2.28401],
              [100.789444, 2.286945],
              [100.760902, 2.252232],
              [100.795021, 2.112496],
              [100.773567, 2.081344],
              [100.670403, 2.124381],
              [100.645546, 2.110561],
              [100.43, 2.272778],
              [100.361389, 2.393055],
              [100.3293, 2.529452],
              [100.307129, 2.357482],
              [100.361328, 2.201904],
              [100.285522, 1.813293],
              [100.421158, 1.767701],
              [100.411362, 1.69775],
              [100.455322, 1.629089],
              [100.415894, 1.537212],
              [100.434021, 1.511289],
              [100.381348, 1.450509],
              [100.323486, 1.44348],
              [100.331718, 1.479493],
              [100.291878, 1.47756],
              [100.283859, 1.46392],
              [100.337547, 1.431191],
              [100.315857, 1.416461],
              [100.077881, 1.387211],
              [100.053658, 1.361081],
              [100.094643, 1.327261],
              [100.130478, 1.174599],
              [100.183029, 1.12499],
              [100.102188, 1.09181],
              [100.161377, 0.999161],
              [100.14064, 0.951121],
              [100.081863, 0.938071],
              [100.13134, 0.902851],
              [100.130508, 0.80901],
              [100.25573, 0.709401],
              [100.206459, 0.584121],
              [100.280533, 0.41644],
              [100.281677, 0.33296],
              [100.376022, 0.35071],
              [100.451752, 0.229109],
              [100.605743, 0.183469],
              [100.668938, 0.18083],
              [100.74514, 0.250221],
              [100.812531, 0.268481],
              [100.842186, 0.189851],
              [100.824219, 0.118061],
              [100.845413, 0.01544],
              [100.809166, -0.041148],
              [100.776741, -0.01239],
              [100.733833, -0.04317],
              [100.781006, -0.230419],
              [100.838783, -0.2906],
              [100.844856, -0.337659],
              [100.907509, -0.371669],
              [100.915039, -0.32568],
              [100.996368, -0.31436],
              [101.036423, -0.34944],
              [101.024826, -0.398439],
              [101.068939, -0.50064],
              [101.162247, -0.53627],
              [101.253769, -0.62543],
              [101.359833, -0.65925],
              [101.355362, -0.68732],
              [101.407646, -0.720709],
              [101.395836, -0.738651],
              [101.595917, -0.851199],
              [101.650337, -0.93408],
              [101.845276, -0.982571],
              [101.914047, -0.920961],
            ],
          ],
          [
            [
              [103.208931, 0.7028],
              [103.148483, 0.66511],
              [103.138641, 0.552931],
              [103.169434, 0.497803],
              [103.30043, 0.550661],
              [103.281151, 0.653311],
              [103.208931, 0.7028],
            ],
          ],
          [
            [
              [103.109169, 0.666945],
              [103.093102, 0.652121],
              [103.103752, 0.630071],
              [103.109169, 0.666945],
            ],
          ],
          [
            [
              [103.067978, 0.563381],
              [103.083977, 0.591011],
              [103.035622, 0.660158],
              [103.028374, 0.636104],
              [103.067978, 0.563381],
            ],
          ],
          [
            [
              [102.966736, 0.35389],
              [102.934753, 0.340978],
              [102.945236, 0.312279],
              [102.986626, 0.335219],
              [102.966736, 0.35389],
            ],
          ],
          [
            [
              [102.908363, 0.30936],
              [102.819542, 0.26782],
              [102.853996, 0.26006],
              [102.937454, 0.316539],
              [102.908363, 0.30936],
            ],
          ],
          [
            [
              [101.415833, 1.83972],
              [101.454445, 1.725555],
              [101.61528, 1.688055],
              [101.732224, 1.763888],
              [101.777779, 1.928332],
              [101.728333, 1.940555],
              [101.784164, 1.936389],
              [101.777779, 1.986669],
              [101.708885, 2.091945],
              [101.655724, 2.124963],
              [101.612999, 2.101656],
              [101.626564, 2.096252],
              [101.621925, 2.064369],
              [101.620003, 2.106112],
              [101.586113, 2.08611],
              [101.592461, 2.056639],
              [101.580345, 2.073965],
              [101.516388, 2.0275],
              [101.484169, 2.062781],
              [101.446945, 2.057222],
              [101.398056, 2.0075],
              [101.383057, 1.91056],
              [101.415833, 1.83972],
            ],
          ],
          [
            [
              [101.402496, 1.827501],
              [101.394165, 1.789166],
              [101.406113, 1.757219],
              [101.402496, 1.827501],
            ],
          ],
          [
            [
              [102.03833, 1.609171],
              [101.99855, 1.584105],
              [102.084999, 1.481667],
              [102.181946, 1.43778],
              [102.294716, 1.428889],
              [102.508278, 1.254549],
              [102.495552, 1.432219],
              [102.47139, 1.4425],
              [102.497498, 1.469171],
              [102.462502, 1.5175],
              [102.03833, 1.609171],
            ],
          ],
          [
            [
              [102.44252, 1.08239],
              [102.483612, 1.195],
              [102.442146, 1.242096],
              [102.484642, 1.198129],
              [102.484993, 1.238279],
              [102.290672, 1.41322],
              [102.213898, 1.41431],
              [102.229706, 1.352399],
              [102.205338, 1.277218],
              [102.245789, 1.194704],
              [102.236137, 1.101478],
              [102.277443, 1.003204],
              [102.386307, 0.91344],
              [102.44014, 0.94943],
              [102.478317, 0.941141],
              [102.44252, 1.08239],
            ],
          ],
          [
            [
              [102.657677, 1.03797],
              [102.624474, 1.011149],
              [102.73085, 1.039711],
              [102.778641, 1.020097],
              [102.81189, 1.036981],
              [102.811562, 1.080421],
              [102.854736, 1.085342],
              [102.880127, 1.042189],
              [102.821716, 1.04924],
              [102.790306, 1.023331],
              [102.806084, 0.992549],
              [102.88929, 0.964007],
              [103.071854, 0.831692],
              [103.153206, 0.847245],
              [103.154999, 0.894719],
              [103.003326, 1.072781],
              [102.874504, 1.134391],
              [102.73597, 1.1547],
              [102.680397, 1.108101],
              [102.657677, 1.03797],
            ],
          ],
          [
            [
              [102.536591, 1.127383],
              [102.48484, 1.12999],
              [102.45977, 1.076551],
              [102.500656, 0.945325],
              [102.6082, 1.005649],
              [102.614899, 1.047572],
              [102.536591, 1.127383],
            ],
          ],
          [
            [
              [102.865929, 1.059505],
              [102.842125, 1.071836],
              [102.823753, 1.053876],
              [102.865929, 1.059505],
            ],
          ],
          [
            [
              [102.905869, 0.778383],
              [102.994003, 0.689499],
              [103.048889, 0.718611],
              [103.046654, 0.82447],
              [102.77578, 1.005175],
              [102.69558, 0.937002],
              [102.746651, 0.908059],
              [102.78083, 0.926944],
              [102.782051, 0.904975],
              [102.697144, 0.927527],
              [102.684723, 0.957781],
              [102.646179, 0.948769],
              [102.626183, 0.903375],
              [102.646317, 0.95067],
              [102.743286, 0.995879],
              [102.737106, 1.02234],
              [102.638046, 0.97948],
              [102.589317, 1.001665],
              [102.48465, 0.929567],
              [102.440979, 0.93514],
              [102.408745, 0.887096],
              [102.43708, 0.82588],
              [102.56028, 0.76921],
              [102.691811, 0.792222],
              [102.905869, 0.778383],
            ],
          ],
          [
            [
              [103.092308, 0.820251],
              [103.075043, 0.820691],
              [103.082703, 0.795887],
              [103.109169, 0.806708],
              [103.092308, 0.820251],
            ],
          ],
          [
            [
              [103.103416, 0.798351],
              [103.080353, 0.77615],
              [103.087738, 0.717289],
              [103.114723, 0.736939],
              [103.103416, 0.798351],
            ],
          ],
          [
            [
              [103.546112, 0.448891],
              [103.558212, 0.423656],
              [103.590279, 0.408331],
              [103.546112, 0.448891],
            ],
          ],
          [
            [
              [103.628563, -0.016778],
              [103.599472, -0.020901],
              [103.64669, -0.050217],
              [103.628563, -0.016778],
            ],
          ],
          [
            [
              [103.666656, -0.05315],
              [103.680496, -0.038186],
              [103.635635, -0.016538],
              [103.666656, -0.05315],
            ],
          ],
          [
            [
              [103.479721, -0.053889],
              [103.463608, -0.045556],
              [103.490547, -0.085829],
              [103.528336, -0.082777],
              [103.501663, -0.081667],
              [103.479721, -0.053889],
            ],
          ],
          [
            [
              [103.581146, -0.077897],
              [103.473335, -0.108055],
              [103.542999, -0.121347],
              [103.590836, -0.097778],
              [103.581146, -0.077897],
            ],
          ],
          [
            [
              [103.557281, -0.176062],
              [103.477783, -0.20167],
              [103.566826, -0.195259],
              [103.557281, -0.176062],
            ],
          ],
          [
            [
              [100.735832, 2.149445],
              [100.785553, 2.121667],
              [100.739441, 2.261945],
              [100.710281, 2.21722],
              [100.735832, 2.149445],
            ],
          ],
          [
            [
              [100.638336, 2.219241],
              [100.632797, 2.195269],
              [100.684448, 2.161741],
              [100.67028, 2.196739],
              [100.638336, 2.219241],
            ],
          ],
        ],
      },
      properties: {
        cartodb_id: 12,
        country: 'Indonesia',
        id_1: 25,
        state: 'Riau',
        key: 'indonesia-riau',
        name: 'Riau',
        slug: 'riau',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [119.591652, -4.55424],
              [119.481392, -4.750834],
              [119.506111, -4.868611],
              [119.56147, -4.891851],
              [119.513885, -4.886388],
              [119.525558, -4.923611],
              [119.447716, -5.086544],
              [119.450279, -5.0975],
              [119.471939, -5.077922],
              [119.450279, -5.108611],
              [119.498611, -5.116945],
              [119.409447, -5.112136],
              [119.353889, -5.325833],
              [119.362976, -5.41842],
              [119.396111, -5.432501],
              [119.430275, -5.487501],
              [119.438889, -5.596667],
              [119.487984, -5.604735],
              [119.460327, -5.576923],
              [119.514442, -5.53444],
              [119.512871, -5.56993],
              [119.579445, -5.603612],
              [119.561386, -5.618332],
              [119.552902, -5.595309],
              [119.558998, -5.650539],
              [119.611389, -5.6125],
              [119.656113, -5.640278],
              [119.646667, -5.682223],
              [119.785614, -5.701977],
              [119.849953, -5.664043],
              [119.840088, -5.635554],
              [119.935448, -5.544427],
              [120.13028, -5.589722],
              [120.314857, -5.507519],
              [120.472504, -5.623055],
              [120.404861, -5.341689],
              [120.35833, -5.331111],
              [120.271667, -5.14394],
              [120.300552, -5.055555],
              [120.303391, -4.87538],
              [120.383614, -4.838332],
              [120.400108, -4.710047],
              [120.458572, -4.658292],
              [120.397186, -4.600131],
              [120.377472, -4.512521],
              [120.402496, -4.480834],
              [120.348145, -4.425264],
              [120.393501, -4.332041],
              [120.365067, -4.298959],
              [120.380867, -4.163501],
              [120.330696, -4.104164],
              [120.361115, -4.02389],
              [120.33667, -3.9375],
              [120.358917, -3.849969],
              [120.442497, -3.739167],
              [120.413612, -3.667221],
              [120.418892, -3.573057],
              [120.379723, -3.503611],
              [120.421249, -3.264168],
              [120.389168, -3.250832],
              [120.382217, -3.202221],
              [120.255547, -3.16028],
              [120.254723, -3.05167],
              [120.189453, -2.967989],
              [120.196831, -2.951605],
              [120.256111, -2.945834],
              [120.353615, -2.860277],
              [120.453331, -2.824721],
              [120.569725, -2.711666],
              [120.564163, -2.670555],
              [120.582497, -2.669719],
              [120.568611, -2.691945],
              [120.632774, -2.683056],
              [120.666107, -2.639441],
              [120.799156, -2.60833],
              [120.793892, -2.62389],
              [120.857224, -2.658057],
              [120.945709, -2.64595],
              [120.970894, -2.670767],
              [120.98764, -2.639878],
              [120.976669, -2.666945],
              [121.006386, -2.679166],
              [121.010429, -2.614999],
              [121.026947, -2.65389],
              [121.041389, -2.624166],
              [121.031387, -2.659723],
              [121.054573, -2.651976],
              [121.072212, -2.61561],
              [121.056114, -2.580555],
              [121.073402, -2.616626],
              [121.054787, -2.639081],
              [121.073677, -2.642818],
              [121.036942, -2.69417],
              [121.058823, -2.672917],
              [121.046944, -2.701666],
              [121.09816, -2.71633],
              [120.972656, -2.81988],
              [120.985046, -2.83115],
              [121.101349, -2.77383],
              [121.172737, -2.96713],
              [121.215973, -2.974619],
              [121.269417, -3.06436],
              [121.305733, -3.069639],
              [121.367706, -3.01218],
              [121.497948, -2.98648],
              [121.57132, -2.912889],
              [121.656937, -2.878121],
              [121.742439, -2.735281],
              [121.800636, -2.709759],
              [121.71553, -2.558119],
              [121.504707, -2.490139],
              [121.328552, -2.372219],
              [121.164711, -2.33201],
              [120.975296, -2.2411],
              [120.706909, -2.212381],
              [120.581871, -2.121749],
              [120.399567, -1.884479],
              [120.336891, -2.053441],
              [120.122032, -2.007521],
              [120.093803, -1.96225],
              [119.99604, -1.963381],
              [119.880997, -2.047519],
              [119.733871, -2.07421],
              [119.629776, -2.163379],
              [119.596573, -2.294341],
              [119.690308, -2.33612],
              [119.715866, -2.39282],
              [119.689026, -2.437739],
              [119.720917, -2.500069],
              [119.700089, -2.553469],
              [119.751106, -2.604909],
              [119.76104, -2.68366],
              [119.727882, -2.72763],
              [119.643578, -2.75641],
              [119.493317, -2.74063],
              [119.5252, -2.793351],
              [119.534187, -2.94152],
              [119.629059, -3.121519],
              [119.545479, -3.13092],
              [119.484596, -3.18489],
              [119.420471, -3.176799],
              [119.389656, -3.21247],
              [119.43866, -3.266739],
              [119.435226, -3.345711],
              [119.516594, -3.571808],
              [119.46389, -3.639999],
              [119.451942, -3.713609],
              [119.570557, -3.948335],
              [119.586563, -4.039411],
              [119.613777, -4.00442],
              [119.583885, -4.022222],
              [119.583611, -3.976388],
              [119.641388, -3.961668],
              [119.605278, -4.083888],
              [119.638023, -4.173174],
              [119.612373, -4.203055],
              [119.624649, -4.236449],
              [119.594444, -4.240834],
              [119.639999, -4.302221],
              [119.59671, -4.416243],
              [119.591652, -4.55424],
            ],
          ],
          [
            [
              [120.431389, -5.63944],
              [120.419998, -5.649723],
              [120.429443, -5.661112],
              [120.445831, -5.646111],
              [120.431389, -5.63944],
            ],
          ],
          [
            [
              [118.154724, -5.128056],
              [118.147217, -5.114721],
              [118.138641, -5.126358],
              [118.154724, -5.128056],
            ],
          ],
          [
            [
              [117.661667, -5.175556],
              [117.656113, -5.222223],
              [117.681938, -5.196813],
              [117.661667, -5.175556],
            ],
          ],
          [
            [
              [117.929443, -5.351667],
              [117.926392, -5.389443],
              [117.947777, -5.408611],
              [117.951668, -5.383333],
              [117.929443, -5.351667],
            ],
          ],
          [
            [
              [119.312775, -5.511667],
              [119.27906, -5.4938],
              [119.279167, -5.471939],
              [119.276108, -5.501112],
              [119.248337, -5.494999],
              [119.257767, -5.52556],
              [119.29583, -5.53139],
              [119.291809, -5.512451],
              [119.310837, -5.519719],
              [119.320763, -5.496492],
              [119.299721, -5.493611],
              [119.301949, -5.456808],
              [119.26667, -5.45472],
              [119.297501, -5.46778],
              [119.298058, -5.498612],
              [119.31591, -5.487446],
              [119.312775, -5.511667],
            ],
          ],
          [
            [
              [120.489166, -5.738329],
              [120.474442, -5.750835],
              [120.492775, -5.751945],
              [120.489166, -5.738329],
            ],
          ],
          [
            [
              [120.458115, -6.38956],
              [120.483971, -6.49799],
              [120.509979, -6.443737],
              [120.495674, -6.391684],
              [120.53833, -6.327239],
              [120.524467, -6.222331],
              [120.568466, -6.055651],
              [120.493111, -5.7675],
              [120.461388, -5.792778],
              [120.445137, -5.956089],
              [120.466187, -6.093651],
              [120.426506, -6.179759],
              [120.445557, -6.16514],
              [120.443039, -6.2589],
              [120.480293, -6.281098],
              [120.458115, -6.38956],
            ],
          ],
          [
            [
              [120.429733, -6.10583],
              [120.392365, -6.178013],
              [120.414795, -6.20879],
              [120.429733, -6.10583],
            ],
          ],
          [
            [
              [120.992241, -6.504577],
              [121.00132, -6.493719],
              [120.979668, -6.504577],
              [120.992241, -6.504577],
            ],
          ],
          [
            [
              [120.438942, -6.59793],
              [120.420059, -6.596825],
              [120.426071, -6.641673],
              [120.440979, -6.641493],
              [120.438942, -6.59793],
            ],
          ],
          [
            [
              [120.800095, -6.788329],
              [120.778847, -6.799716],
              [120.795654, -6.866649],
              [120.81385, -6.832688],
              [120.800095, -6.788329],
            ],
          ],
          [
            [
              [120.629715, -7.000628],
              [120.603889, -7.072223],
              [120.63475, -7.099226],
              [120.631943, -7.133331],
              [120.767731, -7.134739],
              [120.785904, -7.059813],
              [120.736809, -7.075277],
              [120.629715, -7.000628],
            ],
          ],
          [
            [
              [120.560486, -7.07302],
              [120.563332, -7.093056],
              [120.611656, -7.08917],
              [120.560486, -7.07302],
            ],
          ],
          [
            [
              [121.751389, -7.222219],
              [121.738335, -7.229166],
              [121.747498, -7.238333],
              [121.751389, -7.222219],
            ],
          ],
          [
            [
              [120.906303, -7.277767],
              [120.79528, -7.270555],
              [121, -7.33333],
              [121.063889, -7.300556],
              [121.003609, -7.281388],
              [120.906303, -7.277767],
            ],
          ],
          [
            [
              [121.766113, -7.261667],
              [121.730278, -7.277778],
              [121.727501, -7.294445],
              [121.772781, -7.286945],
              [121.766113, -7.261667],
            ],
          ],
          [
            [
              [121.096947, -7.302219],
              [121.059723, -7.372499],
              [121.099167, -7.398055],
              [121.168335, -7.354165],
              [121.096947, -7.302219],
            ],
          ],
          [
            [
              [121.819725, -7.330278],
              [121.756386, -7.347777],
              [121.753891, -7.423055],
              [121.823608, -7.39694],
              [121.840279, -7.350555],
              [121.819725, -7.330278],
            ],
          ],
          [
            [
              [121.797005, -7.472008],
              [121.722778, -7.491661],
              [121.797501, -7.488609],
              [121.797005, -7.472008],
            ],
          ],
          [
            [
              [120.423058, -5.04861],
              [120.412498, -5.051388],
              [120.427635, -5.058155],
              [120.423058, -5.04861],
            ],
          ],
        ],
      },
      properties: {
        cartodb_id: 18,
        country: 'Indonesia',
        id_1: 27,
        state: 'Sulawesi Selatan',
        key: 'indonesia-sulawesiselatan',
        name: 'South Sulawesi',
        slug: 'south-sulawesi',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [102.565277, -4.179722],
              [102.795746, -4.355028],
              [102.898262, -4.488273],
              [103.037216, -4.53472],
              [103.252197, -4.661098],
              [103.321945, -4.80216],
              [103.383888, -4.801666],
              [103.418739, -4.868292],
              [103.498924, -4.87115],
              [103.532867, -4.916354],
              [103.595673, -4.922547],
              [103.720436, -4.800879],
              [103.778877, -4.795049],
              [103.763008, -4.74064],
              [103.68206, -4.72799],
              [103.706207, -4.698469],
              [103.685951, -4.633819],
              [103.598297, -4.59165],
              [103.636147, -4.554519],
              [103.618637, -4.53271],
              [103.495514, -4.577103],
              [103.407379, -4.515133],
              [103.476334, -4.354233],
              [103.463722, -4.32299],
              [103.365379, -4.315221],
              [103.304741, -4.259409],
              [103.214432, -4.240679],
              [103.072746, -4.253483],
              [103.0755, -4.199775],
              [102.930901, -4.093738],
              [102.926773, -4.053802],
              [102.983231, -4.046916],
              [103.001137, -4.014615],
              [102.956093, -4.01295],
              [102.907036, -3.966],
              [102.821777, -3.969891],
              [102.619728, -3.80072],
              [102.733803, -3.74177],
              [102.745926, -3.669619],
              [102.806503, -3.6169],
              [102.814087, -3.52589],
              [102.901787, -3.61497],
              [102.956337, -3.5676],
              [102.994019, -3.51892],
              [102.996826, -3.368589],
              [102.901367, -3.380369],
              [102.897827, -3.332799],
              [102.812683, -3.33645],
              [102.789978, -3.285489],
              [102.633102, -3.350159],
              [102.585991, -3.32866],
              [102.491783, -3.24482],
              [102.492142, -3.132361],
              [102.462326, -3.07265],
              [102.389923, -3.04432],
              [102.322517, -3.06805],
              [102.25296, -3.033271],
              [102.217949, -2.929399],
              [102.132637, -2.87591],
              [102.060333, -2.76255],
              [101.989738, -2.723149],
              [101.861443, -2.724409],
              [101.670387, -2.52867],
              [101.548027, -2.56912],
              [101.557388, -2.44903],
              [101.292519, -2.277211],
              [101.244637, -2.34494],
              [101.03228, -2.47806],
              [101.142502, -2.629166],
              [101.331108, -2.749445],
              [101.405327, -2.92918],
              [101.49472, -3.021109],
              [101.520279, -3.115832],
              [101.620003, -3.244722],
              [101.90667, -3.435415],
              [101.924164, -3.477221],
              [102.048141, -3.528771],
              [102.216667, -3.6533],
              [102.263885, -3.767499],
              [102.24778, -3.793611],
              [102.308052, -3.864444],
              [102.311699, -3.8933],
              [102.294167, -3.84444],
              [102.303886, -3.885],
              [102.28167, -3.901945],
              [102.306656, -3.89111],
              [102.301666, -3.916666],
              [102.264725, -3.914444],
              [102.279999, -3.963334],
              [102.565277, -4.179722],
            ],
          ],
          [
            [
              [102.33075, -5.364571],
              [102.153282, -5.281296],
              [102.085999, -5.340337],
              [102.202957, -5.416611],
              [102.271477, -5.51512],
              [102.280334, -5.477056],
              [102.357254, -5.506049],
              [102.374695, -5.489062],
              [102.339195, -5.45629],
              [102.367516, -5.456885],
              [102.387726, -5.397493],
              [102.33075, -5.364571],
            ],
          ],
        ],
      },
      properties: {
        cartodb_id: 24,
        country: 'Indonesia',
        id_1: 5,
        state: 'Bengkulu',
        key: 'indonesia-bengkulu',
        name: 'Bengkulu',
        slug: 'bengkulu',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [121.63028, -0.123612],
              [121.563606, -0.160001],
              [121.594063, -0.211265],
              [121.652496, -0.191669],
              [121.654167, -0.136945],
              [121.63028, -0.123612],
            ],
          ],
          [
            [
              [122.280556, -0.26],
              [122.342499, -0.28722],
              [122.396797, -0.390419],
              [122.368401, -0.329851],
              [122.379639, -0.273379],
              [122.230103, -0.21143],
              [122.250671, -0.259829],
              [122.280556, -0.26],
            ],
          ],
          [
            [
              [122.15361, -0.23972],
              [122.179634, -0.279965],
              [122.245209, -0.291999],
              [122.232407, -0.257856],
              [122.185555, -0.250555],
              [122.209023, -0.210058],
              [122.15361, -0.23972],
            ],
          ],
          [
            [
              [122.087776, -0.241111],
              [122.029068, -0.275141],
              [122.083878, -0.263861],
              [122.087776, -0.241111],
            ],
          ],
          [
            [
              [122.153053, -0.390556],
              [122.090553, -0.271667],
              [122.066109, -0.427223],
              [122.097221, -0.4475],
              [122.093887, -0.401389],
              [122.125832, -0.42583],
              [122.153053, -0.390556],
            ],
          ],
          [
            [
              [121.875809, -0.412894],
              [121.923592, -0.449114],
              [122.009163, -0.402221],
              [122.044952, -0.425659],
              [122.060555, -0.350001],
              [122.040283, -0.33861],
              [122.011948, -0.371945],
              [121.980003, -0.354999],
              [122.001953, -0.339439],
              [121.941391, -0.323609],
              [121.924911, -0.33495],
              [121.966911, -0.358869],
              [121.928329, -0.370894],
              [121.899719, -0.334029],
              [121.858696, -0.378089],
              [121.875809, -0.412894],
            ],
          ],
          [
            [
              [121.816673, -0.408889],
              [121.827225, -0.396944],
              [121.815971, -0.388958],
              [121.816673, -0.408889],
            ],
          ],
          [
            [
              [121.919243, -0.484256],
              [121.916237, -0.440645],
              [121.866844, -0.40909],
              [121.792747, -0.443341],
              [121.765938, -0.414289],
              [121.768845, -0.458998],
              [121.738083, -0.448631],
              [121.69323, -0.471164],
              [121.654732, -0.567829],
              [121.699448, -0.563333],
              [121.737381, -0.49585],
              [121.829445, -0.532501],
              [121.862427, -0.518491],
              [121.835678, -0.496353],
              [121.862595, -0.496732],
              [121.885452, -0.53986],
              [121.919243, -0.484256],
            ],
          ],
          [
            [
              [121.873886, -0.526944],
              [121.871109, -0.513609],
              [121.860558, -0.525278],
              [121.873886, -0.526944],
            ],
          ],
          [
            [
              [121.771751, -0.738167],
              [121.756477, -0.741591],
              [121.768303, -0.755961],
              [121.771751, -0.738167],
            ],
          ],
          [
            [
              [121.059631, -1.440558],
              [120.98111, -1.39944],
              [120.861946, -1.41278],
              [120.84417, -1.35917],
              [120.805832, -1.351667],
              [120.677223, -1.41583],
              [120.637779, -1.30944],
              [120.571823, -1.256892],
              [120.586388, -1.11278],
              [120.50988, -0.99798],
              [120.460953, -0.993219],
              [120.40667, -0.922777],
              [120.310837, -0.965833],
              [120.109726, -0.73639],
              [120.058891, -0.630556],
              [120.049164, -0.493334],
              [120.072777, -0.442222],
              [120.004997, -0.230278],
              [120.041115, -0.136667],
              [120.035004, -0.065556],
              [120.100273, -0.01611],
              [120.135414, 0.192217],
              [120.305862, 0.419811],
              [120.506943, 0.513288],
              [120.687729, 0.512454],
              [120.768044, 0.456489],
              [120.81028, 0.475001],
              [120.897781, 0.395561],
              [121.027222, 0.439722],
              [121.078613, 0.397781],
              [121.146309, 0.410084],
              [121.176193, 0.45896],
              [121.233887, 0.458889],
              [121.273026, 0.494922],
              [121.340248, 0.473695],
              [121.345757, 0.568969],
              [121.247589, 0.58831],
              [121.162033, 0.688141],
              [121.223572, 0.70874],
              [121.309227, 0.83295],
              [121.392548, 0.86799],
              [121.524643, 0.850791],
              [121.831306, 0.976859],
              [122.012619, 0.93681],
              [122.214516, 1.019796],
              [122.187233, 1.05639],
              [122.163887, 1.03222],
              [122.064796, 1.07372],
              [121.983841, 1.02226],
              [121.927277, 1.10275],
              [121.740837, 1.063056],
              [121.710846, 1.080731],
              [121.708618, 1.039171],
              [121.684654, 1.071348],
              [121.651329, 1.040456],
              [121.556587, 1.062263],
              [121.420311, 1.186234],
              [121.434395, 1.223626],
              [121.413887, 1.237777],
              [121.470055, 1.265072],
              [121.458389, 1.309604],
              [121.398613, 1.260281],
              [121.254738, 1.230313],
              [121.101929, 1.315828],
              [121.061241, 1.302603],
              [120.945229, 1.350002],
              [120.895081, 1.304166],
              [120.902298, 1.35618],
              [120.868614, 1.318055],
              [120.807503, 1.325],
              [120.826942, 1.181667],
              [120.788971, 1.16428],
              [120.777107, 1.110271],
              [120.809654, 1.033263],
              [120.728333, 0.963611],
              [120.696663, 0.986945],
              [120.686668, 0.952778],
              [120.635612, 0.938661],
              [120.603333, 0.871666],
              [120.609436, 0.808611],
              [120.573891, 0.770557],
              [120.59417, 0.750277],
              [120.548058, 0.755555],
              [120.535446, 0.790558],
              [120.397499, 0.791944],
              [120.37278, 0.8675],
              [120.363609, 0.828891],
              [120.326393, 0.855559],
              [120.354156, 0.98389],
              [120.282219, 0.986391],
              [120.247223, 0.957222],
              [120.237778, 0.79972],
              [120.03791, 0.717909],
              [120.015266, 0.660281],
              [120.046669, 0.513889],
              [120, 0.483333],
              [119.900719, 0.478841],
              [119.876106, 0.43972],
              [119.888611, 0.39333],
              [119.848335, 0.350277],
              [119.903053, 0.22139],
              [119.807503, 0.236111],
              [119.770279, 0.213055],
              [119.877502, 0.082221],
              [119.882919, -0.019859],
              [119.839088, -0.101876],
              [119.775879, -0.113744],
              [119.732216, -0.02181],
              [119.665276, 0.01861],
              [119.664398, -0.019123],
              [119.604813, -0.008345],
              [119.703728, -0.138974],
              [119.809647, -0.135685],
              [119.818008, -0.194411],
              [119.750648, -0.36665],
              [119.758331, -0.490277],
              [119.823082, -0.688596],
              [119.861267, -0.71585],
              [119.879158, -0.860851],
              [119.837463, -0.879073],
              [119.727783, -0.645302],
              [119.660736, -0.717206],
              [119.681152, -0.76804],
              [119.551865, -0.861692],
              [119.550926, -1.008439],
              [119.584396, -1.118209],
              [119.610977, -1.12573],
              [119.580353, -1.26124],
              [119.474258, -1.287229],
              [119.467911, -1.306169],
              [119.508636, -1.31016],
              [119.422493, -1.353379],
              [119.451843, -1.39671],
              [119.501312, -1.390499],
              [119.529373, -1.427779],
              [119.591492, -1.392539],
              [119.66967, -1.426489],
              [119.696411, -1.456209],
              [119.659477, -1.49923],
              [119.67141, -1.545301],
              [119.777779, -1.692499],
              [119.756668, -1.755519],
              [119.906616, -1.799299],
              [119.902443, -1.850479],
              [119.860428, -1.87573],
              [119.873543, -1.948739],
              [119.814293, -2.042451],
              [119.880997, -2.047519],
              [119.99604, -1.963381],
              [120.093803, -1.96225],
              [120.122032, -2.007521],
              [120.336891, -2.053441],
              [120.399567, -1.884479],
              [120.581871, -2.121749],
              [120.706909, -2.212381],
              [120.944107, -2.230541],
              [121.164711, -2.33201],
              [121.328552, -2.372219],
              [121.504707, -2.490139],
              [121.705276, -2.54735],
              [121.800972, -2.70872],
              [121.734436, -2.746369],
              [121.672211, -2.839989],
              [121.673203, -2.891356],
              [121.912407, -2.984482],
              [121.974594, -2.955989],
              [122.107826, -2.964571],
              [122.122467, -3.011799],
              [122.193848, -3.03235],
              [122.192093, -3.095129],
              [122.273773, -3.13598],
              [122.295219, -3.196719],
              [122.338966, -3.19294],
              [122.332512, -3.23414],
              [122.368057, -3.22583],
              [122.358887, -3.255279],
              [122.400558, -3.239722],
              [122.426109, -3.280211],
              [122.424156, -3.203889],
              [122.488052, -3.181109],
              [122.471657, -3.15007],
              [122.446388, -3.16278],
              [122.424438, -3.126838],
              [122.373611, -3.141388],
              [122.250832, -3.023612],
              [122.314613, -2.91061],
              [122.252327, -2.8781],
              [122.208611, -2.899722],
              [122.184807, -2.822811],
              [122.13826, -2.787632],
              [122.085999, -2.78858],
              [122.0103, -2.718479],
              [122.035492, -2.658266],
              [121.82505, -2.294234],
              [121.677422, -2.17253],
              [121.552353, -2.16887],
              [121.534668, -2.046717],
              [121.53006, -2.066311],
              [121.492004, -2.020427],
              [121.468597, -2.031878],
              [121.487221, -2.016701],
              [121.421539, -1.957756],
              [121.408699, -1.905554],
              [121.378593, -1.917162],
              [121.380714, -1.975927],
              [121.342926, -2.004282],
              [121.350769, -1.949929],
              [121.314476, -1.925793],
              [121.349892, -1.897329],
              [121.333511, -1.87653],
              [121.362457, -1.874661],
              [121.344757, -1.84304],
              [121.294998, -1.855555],
              [121.299187, -1.797861],
              [121.321831, -1.761028],
              [121.372963, -1.808491],
              [121.444984, -1.804839],
              [121.468033, -1.83471],
              [121.448807, -1.866868],
              [121.522652, -1.898127],
              [121.555, -1.956666],
              [121.676109, -1.946668],
              [121.74279, -1.888169],
              [121.80648, -1.731169],
              [121.84906, -1.694219],
              [121.857803, -1.708774],
              [121.885895, -1.70636],
              [121.863503, -1.705749],
              [121.862595, -1.671189],
              [121.90377, -1.701275],
              [122.030624, -1.611712],
              [122.218735, -1.60237],
              [122.390862, -1.494407],
              [122.560989, -1.310385],
              [122.559761, -1.267435],
              [122.631874, -1.235523],
              [122.667076, -1.139341],
              [122.714417, -1.135744],
              [122.797203, -1.00618],
              [122.803055, -0.941667],
              [123.006111, -0.89479],
              [123.041115, -0.910001],
              [123.041855, -0.872226],
              [123.043335, -0.901112],
              [123.075836, -0.898334],
              [123.061714, -0.869124],
              [123.099998, -0.835831],
              [123.150558, -0.88472],
              [123.141113, -0.951668],
              [123.252502, -1.008888],
              [123.265266, -1.04333],
              [123.320801, -1.050521],
              [123.374725, -1.008612],
              [123.391945, -0.891111],
              [123.443886, -0.838889],
              [123.458611, -0.76861],
              [123.426392, -0.744171],
              [123.443886, -0.710834],
              [123.421669, -0.718888],
              [123.381668, -0.671389],
              [123.411667, -0.65556],
              [123.394997, -0.641667],
              [123.259453, -0.578329],
              [123.05722, -0.563889],
              [122.954552, -0.64398],
              [122.953552, -0.618071],
              [122.896713, -0.615379],
              [122.88517, -0.63771],
              [122.808022, -0.634225],
              [122.778069, -0.671074],
              [122.723946, -0.662794],
              [122.96032, -0.754709],
              [122.791077, -0.775326],
              [122.726448, -0.81277],
              [122.615952, -0.761661],
              [122.580261, -0.784117],
              [122.460411, -0.758828],
              [122.41613, -0.784128],
              [122.202843, -0.788919],
              [122.161461, -0.81659],
              [122.149643, -0.90727],
              [122.110054, -0.955481],
              [122.006058, -0.937551],
              [121.946922, -0.996371],
              [121.711327, -0.949421],
              [121.656693, -0.911348],
              [121.665558, -0.82028],
              [121.63195, -0.806359],
              [121.595703, -0.824581],
              [121.586487, -0.86736],
              [121.476852, -0.903359],
              [121.448303, -0.974409],
              [121.221916, -1.20284],
              [121.162781, -1.367539],
              [121.112129, -1.429509],
              [121.059631, -1.440558],
            ],
          ],
          [
            [
              [120.389717, 1.077501],
              [120.366112, 1.030278],
              [120.385826, 1.006939],
              [120.402428, 1.054931],
              [120.389717, 1.077501],
            ],
          ],
          [
            [
              [120.614998, 1.068332],
              [120.598335, 1.055278],
              [120.675003, 1.012779],
              [120.664444, 1.04111],
              [120.614998, 1.068332],
            ],
          ],
          [
            [
              [120.727501, 1.00278],
              [120.699165, 0.992777],
              [120.724998, 0.98611],
              [120.727501, 1.00278],
            ],
          ],
          [
            [
              [123.298889, -1.14583],
              [123.279167, -1.157501],
              [123.280556, -1.178888],
              [123.298889, -1.14583],
            ],
          ],
          [
            [
              [123.126389, -1.315279],
              [123.164948, -1.303117],
              [123.184448, -1.513611],
              [123.107498, -1.566389],
              [123.098892, -1.600829],
              [123.161942, -1.640559],
              [123.228333, -1.59861],
              [123.242661, -1.64894],
              [123.256813, -1.444348],
              [123.324722, -1.4175],
              [123.354721, -1.524722],
              [123.370834, -1.498056],
              [123.412498, -1.53083],
              [123.440277, -1.504999],
              [123.458611, -1.51889],
              [123.473053, -1.462778],
              [123.510002, -1.458889],
              [123.539169, -1.396111],
              [123.559723, -1.281666],
              [123.442497, -1.23361],
              [123.428055, -1.269167],
              [123.411942, -1.224166],
              [123.374443, -1.221109],
              [123.242226, -1.396945],
              [123.184166, -1.318889],
              [123.189163, -1.264445],
              [123.21833, -1.270277],
              [123.248611, -1.229166],
              [123.190834, -1.150557],
              [123.163612, -1.15111],
              [123.146667, -1.209721],
              [123.106667, -1.160277],
              [122.971893, -1.21494],
              [122.906113, -1.181111],
              [122.825554, -1.285609],
              [122.792503, -1.384166],
              [122.789406, -1.479839],
              [122.825798, -1.49294],
              [122.850296, -1.579009],
              [122.907593, -1.599332],
              [122.99852, -1.515907],
              [123.11972, -1.298329],
              [123.126389, -1.315279],
            ],
          ],
          [
            [
              [123.313866, -1.173792],
              [123.275002, -1.214445],
              [123.299446, -1.283888],
              [123.326668, -1.20111],
              [123.313866, -1.173792],
            ],
          ],
          [
            [
              [123.557922, -1.498469],
              [123.564301, -1.50682],
              [123.565666, -1.474331],
              [123.551262, -1.483217],
              [123.557922, -1.498469],
            ],
          ],
          [
            [
              [123.525833, -1.480277],
              [123.478607, -1.5475],
              [123.499168, -1.588889],
              [123.474442, -1.680556],
              [123.489723, -1.714791],
              [123.568611, -1.72806],
              [123.613609, -1.696667],
              [123.623055, -1.624168],
              [123.597778, -1.591941],
              [123.559723, -1.59889],
              [123.563057, -1.543612],
              [123.525833, -1.480277],
            ],
          ],
          [
            [
              [123.269997, -1.785834],
              [123.308334, -1.787222],
              [123.338333, -1.735829],
              [123.385002, -1.721667],
              [123.38028, -1.673609],
              [123.308609, -1.721945],
              [123.269997, -1.785834],
            ],
          ],
          [
            [
              [123.144157, -1.831941],
              [123.144722, -1.798889],
              [123.095833, -1.760556],
              [123.051392, -1.920278],
              [123.132767, -1.87],
              [123.144157, -1.831941],
            ],
          ],
          [
            [
              [124.001106, -1.811289],
              [123.987473, -1.823093],
              [124.030388, -1.894578],
              [124.032349, -1.860193],
              [124.001106, -1.811289],
            ],
          ],
          [
            [
              [123.690117, -1.8611],
              [123.694168, -1.908055],
              [123.703865, -1.890697],
              [123.690117, -1.8611],
            ],
          ],
          [
            [
              [123.765266, -1.864999],
              [123.75528, -1.877222],
              [123.769447, -1.885833],
              [123.765266, -1.864999],
            ],
          ],
          [
            [
              [123.819992, -1.914938],
              [123.782776, -1.879723],
              [123.770554, -1.942777],
              [123.824722, -2.026668],
              [123.86409, -1.982781],
              [123.819992, -1.914938],
            ],
          ],
          [
            [
              [123.667015, -1.88723],
              [123.680901, -1.929709],
              [123.688667, -1.914489],
              [123.667015, -1.88723],
            ],
          ],
          [
            [
              [123.647499, -1.895556],
              [123.643059, -1.953334],
              [123.660004, -1.966667],
              [123.647499, -1.895556],
            ],
          ],
          [
            [
              [123.729446, -1.898888],
              [123.698891, -1.934167],
              [123.705276, -1.963055],
              [123.739441, -1.953334],
              [123.729446, -1.898888],
            ],
          ],
          [
            [
              [123.479721, -1.93583],
              [123.46917, -1.953061],
              [123.487221, -1.949166],
              [123.479721, -1.93583],
            ],
          ],
          [
            [
              [123.486389, -1.979166],
              [123.477219, -1.999999],
              [123.492172, -2.003341],
              [123.486389, -1.979166],
            ],
          ],
          [
            [
              [123.765556, -2.004166],
              [123.762497, -2.060556],
              [123.784721, -2.028056],
              [123.765556, -2.004166],
            ],
          ],
          [
            [
              [123.863052, -2.00889],
              [123.844437, -2.06444],
              [123.889442, -2.08778],
              [123.896942, -2.074441],
              [123.863052, -2.00889],
            ],
          ],
          [
            [
              [123.559166, -2.094167],
              [123.542778, -2.100833],
              [123.560837, -2.106946],
              [123.559166, -2.094167],
            ],
          ],
          [
            [
              [123.445, -2.120281],
              [123.437767, -2.130279],
              [123.449997, -2.134168],
              [123.445, -2.120281],
            ],
          ],
          [
            [
              [123.643333, -2.124167],
              [123.631386, -2.126667],
              [123.640182, -2.136928],
              [123.643333, -2.124167],
            ],
          ],
          [
            [
              [123.663887, -2.152499],
              [123.677223, -2.177501],
              [123.680267, -2.16139],
              [123.663887, -2.152499],
            ],
          ],
          [
            [
              [122.598053, -0.47722],
              [122.525833, -0.504169],
              [122.616386, -0.501671],
              [122.598053, -0.47722],
            ],
          ],
          [
            [
              [119.859016, 0.532373],
              [119.850967, 0.50565],
              [119.877937, 0.52023],
              [119.859016, 0.532373],
            ],
          ],
          [
            [
              [121.405281, -1.836668],
              [121.383057, -1.836389],
              [121.371666, -1.858333],
              [121.408058, -1.857223],
              [121.405281, -1.836668],
            ],
          ],
          [
            [
              [121.378609, -1.9025],
              [121.364441, -1.904723],
              [121.364723, -1.9225],
              [121.378609, -1.9025],
            ],
          ],
          [
            [
              [122.326942, -3],
              [122.342308, -3.026882],
              [122.361061, -3.015653],
              [122.326942, -3],
            ],
          ],
          [
            [
              [122.410278, -3.016109],
              [122.388611, -3.037501],
              [122.421112, -3.060559],
              [122.401947, -3.02556],
              [122.410278, -3.016109],
            ],
          ],
          [
            [
              [122.386101, -3.030306],
              [122.358116, -3.040142],
              [122.385544, -3.045412],
              [122.386101, -3.030306],
            ],
          ],
          [
            [
              [122.49028, -3.057778],
              [122.488892, -3.075834],
              [122.460556, -3.061388],
              [122.490555, -3.085556],
              [122.520279, -3.0875],
              [122.513336, -3.063054],
              [122.49028, -3.057778],
            ],
          ],
          [
            [
              [122.403893, -3.311945],
              [122.392052, -3.332052],
              [122.406944, -3.329444],
              [122.403893, -3.311945],
            ],
          ],
          [
            [
              [123.099243, -3.50646],
              [123.056923, -3.513197],
              [123.05722, -3.524723],
              [123.099243, -3.50646],
            ],
          ],
          [
            [
              [123.152443, -3.556531],
              [123.043274, -3.59428],
              [123.105469, -3.63932],
              [123.124916, -3.610731],
              [123.176491, -3.62033],
              [123.152443, -3.556531],
            ],
          ],
        ],
      },
      properties: {
        cartodb_id: 26,
        country: 'Indonesia',
        id_1: 28,
        state: 'Sulawesi Tengah',
        key: 'indonesia-sulawesitengah',
        name: 'Central Sulawesi',
        slug: 'central-sulawesi',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [125.2416, 1.500429],
              [125.143417, 1.606881],
              [125.175003, 1.685561],
              [125.110558, 1.664721],
              [125.031937, 1.68139],
              [125.013336, 1.693611],
              [125.025726, 1.73007],
              [124.981697, 1.750473],
              [124.96611, 1.676939],
              [124.940834, 1.694445],
              [124.867233, 1.600301],
              [124.806122, 1.576448],
              [124.84478, 1.518434],
              [124.827957, 1.4648],
              [124.75174, 1.453657],
              [124.67421, 1.394459],
              [124.611389, 1.416897],
              [124.531967, 1.353081],
              [124.515297, 1.300141],
              [124.61187, 1.253193],
              [124.578621, 1.189692],
              [124.528069, 1.215439],
              [124.51722, 1.18889],
              [124.416527, 1.19583],
              [124.353546, 1.1652],
              [124.298271, 1.010064],
              [124.201317, 0.98939],
              [124.144867, 0.93253],
              [124.090286, 0.92221],
              [124.095116, 0.898594],
              [124.077827, 0.923443],
              [123.929642, 0.858205],
              [123.956947, 0.837825],
              [123.792053, 0.855771],
              [123.7873, 0.830619],
              [123.596298, 0.904811],
              [123.529068, 0.886389],
              [123.260063, 0.91517],
              [123.24736, 0.959422],
              [123.167397, 0.952046],
              [123.197731, 0.920287],
              [123.177948, 0.902019],
              [123.116547, 0.91267],
              [123.235992, 0.832371],
              [123.26181, 0.730521],
              [123.351852, 0.66312],
              [123.490692, 0.633031],
              [123.524139, 0.559139],
              [123.515518, 0.434459],
              [123.465042, 0.327178],
              [123.48777, 0.308359],
              [123.557198, 0.322481],
              [123.677368, 0.295509],
              [123.992607, 0.374967],
              [124.218864, 0.383158],
              [124.287704, 0.406686],
              [124.272675, 0.428531],
              [124.313873, 0.410321],
              [124.343704, 0.44998],
              [124.453255, 0.443653],
              [124.448433, 0.46489],
              [124.488609, 0.486661],
              [124.512642, 0.472578],
              [124.493378, 0.5475],
              [124.538139, 0.587729],
              [124.568535, 0.579291],
              [124.555634, 0.65183],
              [124.630623, 0.71322],
              [124.625031, 0.766276],
              [124.669846, 0.826359],
              [124.736618, 0.856961],
              [124.708946, 0.849426],
              [124.717171, 0.895398],
              [124.761063, 0.887378],
              [124.78997, 0.945241],
              [124.887161, 0.972431],
              [125.023537, 1.14193],
              [125.066551, 1.359134],
              [125.128609, 1.429721],
              [125.20739, 1.444148],
              [125.2416, 1.500429],
            ],
          ],
          [
            [
              [125.177498, 1.398333],
              [125.260277, 1.413334],
              [125.293053, 1.55111],
              [125.240379, 1.44962],
              [125.186386, 1.432222],
              [125.177498, 1.398333],
            ],
          ],
          [
            [
              [125.604515, 3.792727],
              [125.58979, 3.7693],
              [125.635559, 3.784166],
              [125.604515, 3.792727],
            ],
          ],
          [
            [
              [125.572342, 3.770489],
              [125.557869, 3.744824],
              [125.585831, 3.750547],
              [125.572342, 3.770489],
            ],
          ],
          [
            [
              [125.571953, 3.58722],
              [125.575279, 3.605831],
              [125.594444, 3.61139],
              [125.507973, 3.722915],
              [125.403397, 3.719081],
              [125.413612, 3.644029],
              [125.503891, 3.606389],
              [125.481941, 3.59361],
              [125.511673, 3.56673],
              [125.484596, 3.467859],
              [125.527473, 3.407455],
              [125.569443, 3.436389],
              [125.611946, 3.338333],
              [125.620239, 3.388034],
              [125.641083, 3.370068],
              [125.676041, 3.426103],
              [125.671669, 3.493333],
              [125.660553, 3.52667],
              [125.640274, 3.513056],
              [125.660553, 3.488332],
              [125.641541, 3.491457],
              [125.584999, 3.583056],
              [125.559723, 3.578889],
              [125.571953, 3.58722],
            ],
          ],
          [
            [
              [125.709167, 3.476112],
              [125.690277, 3.463889],
              [125.703133, 3.451451],
              [125.709167, 3.476112],
            ],
          ],
          [
            [
              [125.576508, 3.402161],
              [125.548836, 3.410028],
              [125.560112, 3.39239],
              [125.576508, 3.402161],
            ],
          ],
          [
            [
              [125.459999, 3.255833],
              [125.447777, 3.251391],
              [125.455559, 3.231389],
              [125.473236, 3.245468],
              [125.459999, 3.255833],
            ],
          ],
          [
            [
              [125.513611, 3.19222],
              [125.501358, 3.164096],
              [125.53331, 3.15948],
              [125.539719, 3.180278],
              [125.513611, 3.19222],
            ],
          ],
          [
            [
              [125.385826, 2.645],
              [125.431282, 2.648847],
              [125.393066, 2.6886],
              [125.456787, 2.76971],
              [125.406662, 2.812019],
              [125.359169, 2.752219],
              [125.385826, 2.645],
            ],
          ],
          [
            [
              [125.178612, 2.735001],
              [125.159447, 2.736391],
              [125.168327, 2.724719],
              [125.178612, 2.735001],
            ],
          ],
          [
            [
              [125.462502, 2.67417],
              [125.442497, 2.638055],
              [125.46611, 2.641109],
              [125.462502, 2.67417],
            ],
          ],
          [
            [
              [125.39312, 2.383565],
              [125.380363, 2.340165],
              [125.442673, 2.316832],
              [125.456436, 2.36847],
              [125.39312, 2.383565],
            ],
          ],
          [
            [
              [125.378578, 2.327065],
              [125.34404, 2.30478],
              [125.364731, 2.287232],
              [125.385841, 2.30408],
              [125.378578, 2.327065],
            ],
          ],
          [
            [
              [125.409721, 2.135],
              [125.390266, 2.114171],
              [125.353607, 2.126389],
              [125.363892, 2.105556],
              [125.341774, 2.104674],
              [125.342926, 2.071811],
              [125.409584, 2.103249],
              [125.409721, 2.135],
            ],
          ],
          [
            [
              [124.702736, 1.649259],
              [124.684196, 1.641756],
              [124.687775, 1.61889],
              [124.71637, 1.627692],
              [124.702736, 1.649259],
            ],
          ],
          [
            [
              [124.755211, 1.628457],
              [124.736107, 1.61747],
              [124.781952, 1.595765],
              [124.776031, 1.62729],
              [124.755211, 1.628457],
            ],
          ],
          [
            [
              [125.09861, 1.88806],
              [125.065277, 1.85806],
              [125.051109, 1.804445],
              [125.087502, 1.83861],
              [125.09861, 1.88806],
            ],
          ],
          [
            [
              [125.136391, 1.847781],
              [125.112778, 1.811944],
              [125.150833, 1.734722],
              [125.148331, 1.756944],
              [125.188889, 1.80056],
              [125.136391, 1.847781],
            ],
          ],
          [
            [
              [124.761665, 1.749445],
              [124.731407, 1.71947],
              [124.770241, 1.692125],
              [124.781067, 1.700246],
              [124.761665, 1.749445],
            ],
          ],
          [
            [
              [124.744431, 1.74153],
              [124.73793, 1.752401],
              [124.745369, 1.728361],
              [124.744431, 1.74153],
            ],
          ],
          [
            [
              [125.027527, 1.705071],
              [125.017517, 1.693978],
              [125.030006, 1.687321],
              [125.027527, 1.705071],
            ],
          ],
          [
            [
              [127.132813, 4.73138],
              [127.147087, 4.75217],
              [127.133041, 4.77877],
              [127.110283, 4.747851],
              [127.132813, 4.73138],
            ],
          ],
          [
            [
              [127.067627, 4.741672],
              [127.055016, 4.728786],
              [127.08419, 4.718348],
              [127.093437, 4.733825],
              [127.067627, 4.741672],
            ],
          ],
          [
            [
              [126.857811, 4.358281],
              [126.855553, 4.489445],
              [126.807396, 4.534061],
              [126.739723, 4.545523],
              [126.680038, 4.335811],
              [126.706886, 4.322842],
              [126.716156, 4.26426],
              [126.780624, 4.247708],
              [126.790749, 4.21367],
              [126.668053, 4.044166],
              [126.68206, 3.992621],
              [126.761665, 3.994166],
              [126.808052, 4.031945],
              [126.796791, 4.148735],
              [126.870552, 4.217223],
              [126.861313, 4.249919],
              [126.911942, 4.26972],
              [126.857811, 4.358281],
            ],
          ],
          [
            [
              [126.643059, 3.999445],
              [126.608345, 4.042509],
              [126.695557, 3.80972],
              [126.685555, 3.868055],
              [126.721458, 3.914403],
              [126.643059, 3.999445],
            ],
          ],
          [
            [
              [126.761223, 3.85528],
              [126.751389, 3.785001],
              [126.823334, 3.728054],
              [126.851669, 3.75361],
              [126.838608, 3.786667],
              [126.761223, 3.85528],
            ],
          ],
        ],
      },
      properties: {
        cartodb_id: 27,
        country: 'Indonesia',
        id_1: 30,
        state: 'Sulawesi Utara',
        key: 'indonesia-sulawesiutara',
        name: 'North Sulawesi',
        slug: 'north-sulawesi',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [123.58535, -5.245944],
              [123.523056, -5.255278],
              [123.528023, -5.3118],
              [123.581665, -5.383055],
              [123.617226, -5.384723],
              [123.644722, -5.294445],
              [123.58535, -5.245944],
            ],
          ],
          [
            [
              [124.32972, -5.320557],
              [124.331665, -5.341388],
              [124.351387, -5.34944],
              [124.32972, -5.320557],
            ],
          ],
          [
            [
              [123.470558, -5.32583],
              [123.478867, -5.359409],
              [123.541115, -5.378055],
              [123.470558, -5.32583],
            ],
          ],
          [
            [
              [123.611946, -5.401666],
              [123.584999, -5.411945],
              [123.643471, -5.427505],
              [123.611946, -5.401666],
            ],
          ],
          [
            [
              [123.773392, -5.456541],
              [123.761185, -5.47624],
              [123.786453, -5.47742],
              [123.773392, -5.456541],
            ],
          ],
          [
            [
              [123.771942, -5.571111],
              [123.817978, -5.601809],
              [123.807503, -5.578334],
              [123.827919, -5.56581],
              [123.690277, -5.469439],
              [123.771942, -5.571111],
            ],
          ],
          [
            [
              [123.866112, -5.531666],
              [123.846809, -5.555417],
              [123.8675, -5.552778],
              [123.866112, -5.531666],
            ],
          ],
          [
            [
              [123.841942, -5.550829],
              [123.831947, -5.585834],
              [123.882133, -5.583104],
              [123.841942, -5.550829],
            ],
          ],
          [
            [
              [124.002914, -5.766198],
              [123.98381, -5.735339],
              [123.893333, -5.729167],
              [123.923889, -5.775556],
              [124.002914, -5.766198],
            ],
          ],
          [
            [
              [123.918335, -5.776666],
              [123.893608, -5.76306],
              [123.895279, -5.7775],
              [123.918335, -5.776666],
            ],
          ],
          [
            [
              [123.902779, -5.80111],
              [123.922783, -5.82361],
              [123.945557, -5.818612],
              [123.940002, -5.804444],
              [123.902779, -5.80111],
            ],
          ],
          [
            [
              [123.991112, -5.8775],
              [123.981186, -5.962249],
              [124.046959, -5.984585],
              [124.044998, -6.034999],
              [124.072502, -6.024723],
              [124.041664, -5.908056],
              [123.991112, -5.8775],
            ],
          ],
          [
            [
              [122.572472, -5.533578],
              [122.636391, -5.695278],
              [122.71389, -5.61972],
              [122.724167, -5.691666],
              [122.751328, -5.63938],
              [122.753746, -5.694861],
              [122.801109, -5.697779],
              [122.894165, -5.565555],
              [122.896385, -5.516389],
              [122.84417, -5.523055],
              [122.87278, -5.455],
              [122.974998, -5.394439],
              [123.015556, -5.44417],
              [123.13028, -5.39972],
              [123.220276, -5.296111],
              [123.182503, -5.246667],
              [123.092346, -5.215655],
              [123.0877, -5.175118],
              [123.059998, -5.191945],
              [123.068268, -5.161874],
              [123.033417, -5.136088],
              [122.954437, -5.217221],
              [122.919998, -5.179166],
              [122.97361, -5.115833],
              [122.980553, -5.058889],
              [122.951942, -5.019722],
              [123.009445, -4.908056],
              [122.994186, -4.826953],
              [123.040001, -4.80139],
              [123.021927, -4.764509],
              [123.031006, -4.720417],
              [123.055557, -4.769999],
              [123.079796, -4.750021],
              [123.077499, -4.791111],
              [123.112373, -4.79608],
              [123.126709, -4.772659],
              [123.105553, -4.787501],
              [123.112579, -4.690758],
              [123.152328, -4.735758],
              [123.132767, -4.756669],
              [123.175827, -4.77139],
              [123.175583, -4.840299],
              [123.213608, -4.858615],
              [123.211388, -4.695556],
              [123.199997, -4.656109],
              [123.199928, -4.684926],
              [123.17778, -4.669166],
              [123.208893, -4.61306],
              [123.139168, -4.483061],
              [123.045593, -4.4241],
              [123.079483, -4.366709],
              [122.949165, -4.425556],
              [122.8675, -4.525555],
              [122.828613, -4.67694],
              [122.856201, -4.784682],
              [122.805611, -4.86754],
              [122.831665, -4.921945],
              [122.812286, -4.981891],
              [122.736946, -5.051944],
              [122.802223, -5.026389],
              [122.757225, -5.109723],
              [122.786667, -5.086941],
              [122.790001, -5.10611],
              [122.736664, -5.175],
              [122.755554, -5.22889],
              [122.784027, -5.18444],
              [122.811943, -5.219445],
              [122.768059, -5.254719],
              [122.732216, -5.24194],
              [122.686668, -5.32],
              [122.652496, -5.326111],
              [122.618614, -5.405001],
              [122.63903, -5.410277],
              [122.649445, -5.437221],
              [122.56472, -5.481388],
              [122.572472, -5.533578],
            ],
          ],
          [
            [
              [121.602219, -4.788609],
              [121.626953, -4.77722],
              [121.698608, -4.855834],
              [121.813889, -4.83444],
              [121.901657, -4.8825],
              [122.023132, -4.893043],
              [122.026108, -4.870555],
              [122.120003, -4.818333],
              [122.044441, -4.766111],
              [122.019051, -4.648006],
              [122.044167, -4.64333],
              [122.105728, -4.506768],
              [122.106667, -4.531666],
              [122.144447, -4.534444],
              [122.209007, -4.47208],
              [122.263336, -4.493055],
              [122.419167, -4.43222],
              [122.504997, -4.45583],
              [122.514656, -4.413399],
              [122.526108, -4.436945],
              [122.589447, -4.39639],
              [122.662781, -4.455],
              [122.671112, -4.508056],
              [122.695831, -4.501943],
              [122.692497, -4.469167],
              [122.743614, -4.51],
              [122.757225, -4.427222],
              [122.702774, -4.405],
              [122.660141, -4.338559],
              [122.693329, -4.328759],
              [122.832169, -4.449088],
              [122.848053, -4.420624],
              [122.883057, -4.413889],
              [122.90667, -4.323888],
              [122.879585, -4.295347],
              [122.906387, -4.232778],
              [122.888336, -4.126666],
              [122.841476, -4.060078],
              [122.767906, -4.05722],
              [122.835281, -4.153334],
              [122.816277, -4.13619],
              [122.764832, -4.158993],
              [122.714722, -4.13124],
              [122.695831, -4.137222],
              [122.711945, -4.164444],
              [122.677223, -4.1575],
              [122.650902, -4.083649],
              [122.658607, -4.008331],
              [122.530563, -3.97083],
              [122.59861, -3.979167],
              [122.666664, -3.897501],
              [122.525414, -3.894376],
              [122.480591, -3.821425],
              [122.450836, -3.83861],
              [122.473335, -3.816389],
              [122.443047, -3.749439],
              [122.392502, -3.725971],
              [122.341942, -3.744445],
              [122.196663, -3.609167],
              [122.200546, -3.56472],
              [122.266945, -3.55889],
              [122.269447, -3.537778],
              [122.239586, -3.542778],
              [122.273888, -3.516666],
              [122.282219, -3.548333],
              [122.284157, -3.490281],
              [122.247223, -3.499723],
              [122.267502, -3.487778],
              [122.245071, -3.46614],
              [122.287498, -3.492223],
              [122.302086, -3.468057],
              [122.238892, -3.391389],
              [122.267502, -3.399168],
              [122.276459, -3.364789],
              [122.328331, -3.373609],
              [122.315002, -3.424439],
              [122.357216, -3.417218],
              [122.359726, -3.464722],
              [122.390274, -3.408056],
              [122.371727, -3.37979],
              [122.29361, -3.360001],
              [122.276665, -3.313333],
              [122.339508, -3.23437],
              [122.338966, -3.19294],
              [122.295219, -3.196719],
              [122.273773, -3.13598],
              [122.192093, -3.095129],
              [122.193848, -3.03235],
              [122.122467, -3.011799],
              [122.11097, -2.966699],
              [121.974594, -2.955989],
              [121.912407, -2.984482],
              [121.735283, -2.931528],
              [121.658653, -2.875694],
              [121.57132, -2.912889],
              [121.497948, -2.98648],
              [121.374077, -3.009281],
              [121.284416, -3.07066],
              [121.215973, -2.974619],
              [121.172737, -2.96713],
              [121.108963, -2.77992],
              [121.084351, -2.77362],
              [120.986969, -2.83322],
              [120.997116, -2.871801],
              [120.995033, -2.835569],
              [121.026993, -2.847959],
              [121.036079, -2.81964],
              [121.051376, -2.869029],
              [121.079529, -2.865301],
              [121.085846, -2.968751],
              [121.061478, -2.95367],
              [121.090607, -3.0138],
              [121.043335, -3.036389],
              [121.069496, -3.204167],
              [120.973061, -3.262689],
              [120.956947, -3.329166],
              [120.866386, -3.428329],
              [120.88028, -3.529445],
              [121.168892, -3.825555],
              [121.247116, -3.830658],
              [121.252502, -3.920832],
              [121.290863, -3.8785],
              [121.349998, -3.991112],
              [121.608681, -4.065279],
              [121.616951, -4.151066],
              [121.580833, -4.243056],
              [121.550278, -4.266944],
              [121.540359, -4.254402],
              [121.560837, -4.244166],
              [121.527115, -4.243546],
              [121.525002, -4.402778],
              [121.46611, -4.613334],
              [121.466393, -4.683889],
              [121.506668, -4.736389],
              [121.602219, -4.788609],
            ],
          ],
          [
            [
              [121.904343, -5.4062],
              [121.951065, -5.494922],
              [122.049446, -5.461111],
              [122.035004, -5.241737],
              [122.074722, -5.23139],
              [122.034447, -5.148056],
              [121.991112, -5.157222],
              [121.970276, -5.064719],
              [121.939941, -5.072331],
              [121.934967, -5.110559],
              [121.891388, -5.055555],
              [121.850189, -5.094099],
              [121.868057, -5.11889],
              [121.839737, -5.111539],
              [121.882233, -5.154111],
              [121.828636, -5.16194],
              [121.838257, -5.191651],
              [121.793129, -5.265069],
              [121.904343, -5.4062],
            ],
          ],
          [
            [
              [121.836235, -5.151408],
              [121.837524, -5.119648],
              [121.823082, -5.151194],
              [121.836235, -5.151408],
            ],
          ],
          [
            [
              [122.063278, -5.308409],
              [122.075592, -5.28508],
              [122.057327, -5.296421],
              [122.063278, -5.308409],
            ],
          ],
          [
            [
              [122.641113, -5.353889],
              [122.640556, -5.265],
              [122.578056, -5.249999],
              [122.627502, -5.201109],
              [122.589867, -5.197082],
              [122.589165, -5.159723],
              [122.62722, -5.07167],
              [122.721527, -4.96472],
              [122.760834, -4.971666],
              [122.769997, -4.901945],
              [122.725281, -4.846938],
              [122.741112, -4.64861],
              [122.670891, -4.610191],
              [122.59584, -4.63505],
              [122.588608, -4.665556],
              [122.476448, -4.740747],
              [122.374443, -4.740277],
              [122.321106, -4.82528],
              [122.377914, -4.958115],
              [122.381668, -5.067219],
              [122.41111, -5.09056],
              [122.39389, -5.071388],
              [122.318886, -5.116939],
              [122.315407, -5.238189],
              [122.265556, -5.325689],
              [122.268326, -5.383889],
              [122.327217, -5.397218],
              [122.376663, -5.379723],
              [122.38028, -5.322779],
              [122.445831, -5.314439],
              [122.3825, -5.336112],
              [122.40889, -5.400557],
              [122.464996, -5.403332],
              [122.46833, -5.361939],
              [122.498337, -5.346388],
              [122.478615, -5.300833],
              [122.539024, -5.261527],
              [122.512497, -5.405833],
              [122.52861, -5.441946],
              [122.566246, -5.411945],
              [122.593887, -5.435559],
              [122.595833, -5.362499],
              [122.641113, -5.353889],
            ],
          ],
          [
            [
              [122.702499, -5.265556],
              [122.713058, -5.238056],
              [122.681664, -5.295001],
              [122.702499, -5.265556],
            ],
          ],
          [
            [
              [122.074448, -5.4725],
              [122.070557, -5.487223],
              [122.086388, -5.493889],
              [122.087776, -5.474722],
              [122.074448, -5.4725],
            ],
          ],
          [
            [
              [122.049446, -5.489441],
              [121.993332, -5.523612],
              [122.029724, -5.518889],
              [122.049446, -5.489441],
            ],
          ],
          [
            [
              [122.520279, -5.499439],
              [122.470993, -5.53075],
              [122.486946, -5.562779],
              [122.508331, -5.559444],
              [122.520279, -5.499439],
            ],
          ],
          [
            [
              [122.512962, -5.62442],
              [122.457733, -5.694027],
              [122.543892, -5.686666],
              [122.554726, -5.646389],
              [122.512962, -5.62442],
            ],
          ],
          [
            [
              [122.688332, -6.177778],
              [122.666389, -6.184999],
              [122.687767, -6.2075],
              [122.726669, -6.205834],
              [122.697777, -6.198056],
              [122.688332, -6.177778],
            ],
          ],
          [
            [
              [122.449997, -3.48889],
              [122.467224, -3.466667],
              [122.413055, -3.397777],
              [122.409096, -3.47757],
              [122.433891, -3.506668],
              [122.449997, -3.48889],
            ],
          ],
          [
            [
              [122.300278, -3.534719],
              [122.283607, -3.59278],
              [122.368332, -3.576667],
              [122.333893, -3.56278],
              [122.34639, -3.54194],
              [122.300278, -3.534719],
            ],
          ],
          [
            [
              [123.244743, -4.129054],
              [123.254059, -4.065332],
              [123.187553, -4.009288],
              [123.08783, -4.021834],
              [123.012779, -3.977501],
              [122.971352, -4.057152],
              [122.936668, -4.068889],
              [122.989166, -4.199445],
              [123.111107, -4.259972],
              [123.244743, -4.129054],
            ],
          ],
          [
            [
              [121.368332, -4.084445],
              [121.355003, -4.090279],
              [121.377777, -4.100834],
              [121.368332, -4.084445],
            ],
          ],
          [
            [
              [121.442223, -4.166667],
              [121.429169, -4.130278],
              [121.457779, -4.137779],
              [121.470833, -4.110832],
              [121.42083, -4.115279],
              [121.412216, -4.084718],
              [121.404999, -4.113611],
              [121.383057, -4.10528],
              [121.393608, -4.165279],
              [121.442223, -4.166667],
            ],
          ],
          [
            [
              [121.491943, -4.193611],
              [121.468063, -4.208329],
              [121.472221, -4.226669],
              [121.503891, -4.200833],
              [121.491943, -4.193611],
            ],
          ],
          [
            [
              [122.709045, -4.525079],
              [122.693611, -4.55639],
              [122.743057, -4.575833],
              [122.742775, -4.531112],
              [122.709045, -4.525079],
            ],
          ],
          [
            [
              [122.355003, -4.66139],
              [122.363892, -4.640834],
              [122.349716, -4.635829],
              [122.340492, -4.661339],
              [122.355003, -4.66139],
            ],
          ],
          [
            [
              [122.375557, -4.663609],
              [122.324165, -4.725556],
              [122.365067, -4.695328],
              [122.375557, -4.663609],
            ],
          ],
          [
            [
              [122.308609, -4.684999],
              [122.291389, -4.704723],
              [122.301941, -4.716943],
              [122.318336, -4.6925],
              [122.308609, -4.684999],
            ],
          ],
          [
            [
              [123.128052, -4.727222],
              [123.121948, -4.741109],
              [123.134842, -4.747534],
              [123.128052, -4.727222],
            ],
          ],
          [
            [
              [123.140831, -4.763611],
              [123.136391, -4.77389],
              [123.156944, -4.774166],
              [123.140831, -4.763611],
            ],
          ],
          [
            [
              [123.061768, -4.842437],
              [123.113846, -4.847681],
              [123.117638, -4.796668],
              [123.080559, -4.793889],
              [123.046257, -4.812489],
              [123.033707, -4.833359],
              [123.061768, -4.842437],
            ],
          ],
        ],
      },
      properties: {
        cartodb_id: 28,
        country: 'Indonesia',
        id_1: 29,
        state: 'Sulawesi Tenggara',
        key: 'indonesia-sulawesitenggara',
        name: 'Southeast Sulawesi',
        slug: 'southeast-sulawesi',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [115.196671, -8.735269],
              [115.223053, -8.725829],
              [115.239723, -8.743611],
              [115.22583, -8.722219],
              [115.263611, -8.707222],
              [115.263885, -8.660833],
              [115.361107, -8.578059],
              [115.446991, -8.569936],
              [115.529167, -8.501389],
              [115.611946, -8.505555],
              [115.712502, -8.400555],
              [115.698891, -8.35944],
              [115.453056, -8.161668],
              [115.185837, -8.06111],
              [115.116829, -8.081529],
              [115, -8.176389],
              [114.867851, -8.196101],
              [114.617737, -8.122888],
              [114.522293, -8.154372],
              [114.493378, -8.095751],
              [114.436111, -8.096667],
              [114.465553, -8.165834],
              [114.443184, -8.180405],
              [114.433037, -8.16595],
              [114.448479, -8.21972],
              [114.581543, -8.398146],
              [114.73317, -8.395719],
              [114.899849, -8.457807],
              [115.142708, -8.669931],
              [115.168053, -8.771945],
              [115.088058, -8.837639],
              [115.217781, -8.832499],
              [115.240837, -8.80306],
              [115.221786, -8.753236],
              [115.217781, -8.786944],
              [115.18222, -8.762222],
              [115.196671, -8.735269],
            ],
          ],
          [
            [
              [115.45417, -8.663055],
              [115.43, -8.692499],
              [115.470001, -8.67861],
              [115.45417, -8.663055],
            ],
          ],
          [
            [
              [115.500275, -8.67139],
              [115.451393, -8.748329],
              [115.595558, -8.81722],
              [115.624855, -8.753347],
              [115.572777, -8.676939],
              [115.500275, -8.67139],
            ],
          ],
          [
            [
              [114.518158, -8.092539],
              [114.501976, -8.095299],
              [114.528946, -8.094967],
              [114.518158, -8.092539],
            ],
          ],
        ],
      },
      properties: {
        cartodb_id: 31,
        country: 'Indonesia',
        id_1: 2,
        state: 'Bali',
        key: 'indonesia-bali',
        name: 'Bali',
        slug: 'bali',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [106.273537, -5.807501],
              [106.25489, -5.81084],
              [106.294693, -5.81311],
              [106.273537, -5.807501],
            ],
          ],
          [
            [
              [106.044548, -5.880798],
              [105.991943, -5.922499],
              [106.0028, -5.946576],
              [105.983772, -5.998896],
              [105.881821, -6.073608],
              [105.824997, -6.238611],
              [105.841393, -6.304722],
              [105.797775, -6.49139],
              [105.713333, -6.53222],
              [105.673889, -6.508611],
              [105.677223, -6.475556],
              [105.650558, -6.480279],
              [105.611115, -6.632778],
              [105.506668, -6.729719],
              [105.46833, -6.827222],
              [105.389725, -6.741112],
              [105.369446, -6.646389],
              [105.262222, -6.761668],
              [105.210281, -6.747781],
              [105.237778, -6.838889],
              [105.266113, -6.8475],
              [105.326385, -6.802501],
              [105.414444, -6.854166],
              [105.440552, -6.836389],
              [105.538124, -6.874444],
              [105.671387, -6.835831],
              [105.882225, -6.846666],
              [105.913887, -6.816666],
              [105.993057, -6.814168],
              [106.231392, -6.9275],
              [106.310211, -6.995833],
              [106.395279, -6.982222],
              [106.391403, -6.90002],
              [106.438408, -6.81564],
              [106.511497, -6.765959],
              [106.429398, -6.69578],
              [106.403702, -6.455621],
              [106.458298, -6.421798],
              [106.428001, -6.348768],
              [106.472282, -6.31181],
              [106.502007, -6.356301],
              [106.531693, -6.35331],
              [106.519547, -6.32599],
              [106.595253, -6.363669],
              [106.767403, -6.36123],
              [106.746773, -6.241099],
              [106.685532, -6.1695],
              [106.685646, -6.0991],
              [106.724472, -6.089202],
              [106.716316, -6.044968],
              [106.635834, -6.004167],
              [106.578331, -6.031109],
              [106.534996, -6.010829],
              [106.477501, -6.045832],
              [106.404167, -6.018852],
              [106.387497, -5.975555],
              [106.355797, -5.976989],
              [106.354317, -5.962279],
              [106.332497, -5.986112],
              [106.263611, -5.953329],
              [106.223892, -6.013334],
              [106.159721, -6.025555],
              [106.097778, -5.981112],
              [106.114998, -5.933888],
              [106.082779, -5.889169],
              [106.044548, -5.880798],
            ],
          ],
          [
            [
              [106.153526, -5.920038],
              [106.138893, -5.924769],
              [106.153641, -5.944969],
              [106.169296, -5.935319],
              [106.153526, -5.920038],
            ],
          ],
          [
            [
              [105.860558, -5.943873],
              [105.82917, -5.950555],
              [105.851585, -5.980519],
              [105.866089, -5.968241],
              [105.860558, -5.943873],
            ],
          ],
          [
            [
              [105.250557, -6.620555],
              [105.262222, -6.521666],
              [105.184723, -6.538889],
              [105.099998, -6.613057],
              [105.127777, -6.623333],
              [105.178047, -6.579439],
              [105.183334, -6.681112],
              [105.250557, -6.620555],
            ],
          ],
          [
            [
              [105.249168, -6.725833],
              [105.253609, -6.752499],
              [105.268333, -6.7375],
              [105.249168, -6.725833],
            ],
          ],
          [
            [
              [105.548607, -6.994718],
              [105.521111, -7.009166],
              [105.57, -7.0125],
              [105.548607, -6.994718],
            ],
          ],
        ],
      },
      properties: {
        cartodb_id: 30,
        country: 'Indonesia',
        id_1: 4,
        state: 'Banten',
        key: 'indonesia-banten',
        name: 'Banten',
        slug: 'banten',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [122.406387, 0.518612],
              [122.511406, 0.512511],
              [122.529442, 0.487777],
              [122.552574, 0.514113],
              [122.577225, 0.493056],
              [122.601631, 0.509621],
              [122.643333, 0.47611],
              [122.648056, 0.52],
              [122.693054, 0.473056],
              [123.046585, 0.489838],
              [123.062668, 0.51829],
              [123.227623, 0.348821],
              [123.345367, 0.305911],
              [123.46524, 0.327381],
              [123.515518, 0.434459],
              [123.524117, 0.56868],
              [123.48703, 0.636361],
              [123.351852, 0.66312],
              [123.26181, 0.730521],
              [123.235992, 0.832371],
              [123.128777, 0.88741],
              [123.112221, 0.926944],
              [123.088257, 0.90635],
              [123.083649, 0.930269],
              [122.945274, 0.964723],
              [122.946106, 0.937501],
              [122.976112, 0.938889],
              [122.950554, 0.894444],
              [122.848221, 0.801243],
              [122.771942, 0.879445],
              [122.713196, 0.867839],
              [122.722504, 0.886667],
              [122.624168, 0.964171],
              [122.612503, 0.944167],
              [122.561111, 0.960001],
              [122.452225, 1.031944],
              [122.434723, 0.993891],
              [122.393333, 1.01361],
              [122.366669, 0.99396],
              [122.334167, 1.040832],
              [122.313889, 1.00528],
              [122.23111, 0.992221],
              [122.221657, 1.02528],
              [122.012619, 0.93681],
              [121.831306, 0.976859],
              [121.524643, 0.850791],
              [121.361588, 0.861781],
              [121.271667, 0.80365],
              [121.223572, 0.70874],
              [121.162033, 0.688141],
              [121.247589, 0.58831],
              [121.345757, 0.568969],
              [121.341324, 0.473111],
              [121.409721, 0.470834],
              [121.439583, 0.500511],
              [121.443054, 0.465834],
              [121.493614, 0.478611],
              [121.532333, 0.554941],
              [121.601715, 0.527054],
              [121.607498, 0.549444],
              [121.620117, 0.522347],
              [121.634796, 0.544391],
              [121.72139, 0.52167],
              [121.804443, 0.409722],
              [122.11528, 0.49778],
              [122.152779, 0.461111],
              [122.406387, 0.518612],
            ],
          ],
          [
            [
              [122.663055, 1.003612],
              [122.653168, 0.995022],
              [122.66452, 0.986594],
              [122.663055, 1.003612],
            ],
          ],
          [
            [
              [122.77523, 0.9114],
              [122.755829, 0.906215],
              [122.807617, 0.873571],
              [122.812897, 0.89162],
              [122.77523, 0.9114],
            ],
          ],
          [
            [
              [122.857498, 0.899722],
              [122.881386, 0.887501],
              [122.894447, 0.853054],
              [122.891937, 0.889441],
              [122.857498, 0.899722],
            ],
          ],
          [
            [
              [121.704926, 0.501318],
              [121.691109, 0.512779],
              [121.680275, 0.499722],
              [121.706665, 0.484445],
              [121.704926, 0.501318],
            ],
          ],
        ],
      },
      properties: {
        cartodb_id: 32,
        country: 'Indonesia',
        id_1: 6,
        state: 'Gorontalo',
        key: 'indonesia-gorontalo',
        name: 'Gorontalo',
        slug: 'gorontalo',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [106.718681, -6.223279],
              [106.775948, -6.316749],
              [106.807617, -6.316019],
              [106.794601, -6.361689],
              [106.846298, -6.34686],
              [106.909447, -6.375459],
              [106.907608, -6.267298],
              [106.945259, -6.255381],
              [106.970306, -6.19889],
              [106.972839, -6.09111],
              [106.878326, -6.092219],
              [106.826393, -6.1225],
              [106.803612, -6.094439],
              [106.685646, -6.0991],
              [106.685532, -6.1695],
              [106.72036, -6.188219],
              [106.718681, -6.223279],
            ],
          ],
        ],
      },
      properties: {
        cartodb_id: 25,
        country: 'Indonesia',
        id_1: 8,
        state: 'Jakarta Raya',
        key: 'indonesia-jakartaraya',
        name: 'Jakarta Special Capital Region',
        slug: 'jakarta-special-capital-region',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [102.086479, -2.768239],
              [102.12429, -2.72675],
              [102.227676, -2.69231],
              [102.255836, -2.622689],
              [102.461586, -2.703259],
              [102.59153, -2.683029],
              [102.623001, -2.624221],
              [102.792999, -2.538899],
              [102.877457, -2.417719],
              [102.84024, -2.371389],
              [102.873749, -2.310689],
              [103.021713, -2.34951],
              [103.054733, -2.31834],
              [103.122238, -2.357391],
              [103.205429, -2.321289],
              [103.200676, -2.270171],
              [103.16069, -2.23375],
              [103.223457, -2.13716],
              [103.272728, -2.14813],
              [103.30162, -2.236829],
              [103.44912, -2.35815],
              [103.453247, -2.17754],
              [103.557846, -2.140118],
              [103.517593, -2.102369],
              [103.536926, -1.903749],
              [103.620796, -1.83458],
              [103.821846, -1.796279],
              [103.87841, -1.743041],
              [104.093376, -1.786201],
              [104.228828, -1.740689],
              [104.345032, -1.738209],
              [104.373711, -1.63998],
              [104.427803, -1.631249],
              [104.489548, -1.660766],
              [104.438614, -1.508333],
              [104.457497, -1.335556],
              [104.392853, -1.216809],
              [104.405556, -1.124166],
              [104.369446, -1.023055],
              [104.205765, -1.072362],
              [104.125137, -1.049306],
              [104.128609, -1.08917],
              [104.11528, -1.063888],
              [104.130142, -1.04361],
              [104.105003, -1.030829],
              [103.910843, -0.98111],
              [103.835556, -1.010278],
              [103.808609, -1.06028],
              [103.803886, -0.99444],
              [103.747116, -1.002679],
              [103.723335, -0.956388],
              [103.671066, -0.95497],
              [103.548492, -0.857355],
              [103.547501, -0.809999],
              [103.475807, -0.807898],
              [103.485001, -0.771112],
              [103.360077, -0.80318],
              [103.118103, -0.746249],
              [103.007942, -0.75602],
              [102.913399, -0.886409],
              [102.789528, -0.976889],
              [102.779449, -1.026619],
              [102.644753, -1.12041],
              [102.616806, -1.094629],
              [102.48381, -1.080979],
              [102.44252, -1.02545],
              [102.382446, -1.02669],
              [102.38623, -0.99338],
              [102.326332, -0.963339],
              [102.306816, -0.91736],
              [102.20359, -0.94852],
              [102.165001, -0.89873],
              [102.116623, -0.921171],
              [101.983551, -0.880411],
              [101.962486, -0.91156],
              [101.921127, -0.91122],
              [101.907013, -0.950829],
              [101.821533, -0.97719],
              [101.825218, -1.094041],
              [101.886116, -1.09967],
              [101.814079, -1.21502],
              [101.721786, -1.233971],
              [101.687271, -1.27825],
              [101.716766, -1.35368],
              [101.688713, -1.399329],
              [101.719383, -1.42338],
              [101.692589, -1.48406],
              [101.562431, -1.576379],
              [101.491913, -1.677329],
              [101.221451, -1.714809],
              [101.18763, -1.67718],
              [101.161957, -1.697939],
              [101.125816, -1.67909],
              [101.132439, -1.868831],
              [101.280983, -2.103459],
              [101.294159, -2.280201],
              [101.557388, -2.44903],
              [101.548027, -2.56912],
              [101.670387, -2.52867],
              [101.867943, -2.728179],
              [101.989738, -2.723149],
              [102.086479, -2.768239],
            ],
          ],
          [
            [
              [104.244446, -1.030555],
              [104.209999, -1.035556],
              [104.208611, -1.061666],
              [104.254448, -1.036112],
              [104.244446, -1.030555],
            ],
          ],
          [
            [
              [104.207497, -1.035],
              [104.192497, -1.038056],
              [104.203331, -1.056946],
              [104.207497, -1.035],
            ],
          ],
        ],
      },
      properties: {
        cartodb_id: 34,
        country: 'Indonesia',
        id_1: 9,
        state: 'Jambi',
        key: 'indonesia-jambi',
        name: 'Jambi',
        slug: 'jambi',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [108.56916, -6.70834],
              [108.538109, -6.483586],
              [108.384048, -6.355161],
              [108.349319, -6.239657],
              [108.336662, -6.27466],
              [108.183754, -6.227846],
              [108.20977, -6.273844],
              [108.128067, -6.33466],
              [107.890266, -6.237501],
              [107.8909, -6.18976],
              [107.855621, -6.211535],
              [107.820557, -6.18333],
              [107.666542, -6.244673],
              [107.623055, -6.189167],
              [107.466347, -6.14317],
              [107.339996, -5.968889],
              [107.164719, -5.98778],
              [107.09639, -5.93583],
              [107.007774, -5.918055],
              [106.989723, -5.942777],
              [107.016113, -5.973889],
              [106.987778, -6.00778],
              [106.993057, -6.028611],
              [107.015556, -6.033612],
              [106.986115, -6.046944],
              [107.01722, -6.078333],
              [106.972839, -6.09111],
              [106.970306, -6.19889],
              [106.945259, -6.255381],
              [106.907608, -6.267298],
              [106.909447, -6.375459],
              [106.846298, -6.34686],
              [106.794601, -6.361689],
              [106.805023, -6.31454],
              [106.775948, -6.316749],
              [106.761696, -6.36268],
              [106.595253, -6.363669],
              [106.519547, -6.32599],
              [106.531693, -6.35331],
              [106.502007, -6.356301],
              [106.472282, -6.31181],
              [106.428001, -6.348768],
              [106.458298, -6.421798],
              [106.403702, -6.455621],
              [106.429398, -6.69578],
              [106.511497, -6.765959],
              [106.434303, -6.820608],
              [106.391403, -6.90002],
              [106.390396, -6.96854],
              [106.511948, -6.965001],
              [106.551666, -7.024721],
              [106.456551, -7.12988],
              [106.4627, -7.181409],
              [106.400558, -7.186389],
              [106.373138, -7.308633],
              [106.405556, -7.382778],
              [106.443886, -7.36083],
              [106.560226, -7.416988],
              [106.788452, -7.43691],
              [106.790276, -7.410278],
              [106.796669, -7.436666],
              [107.399536, -7.493769],
              [107.601654, -7.571306],
              [107.682068, -7.661919],
              [107.812683, -7.68903],
              [107.846878, -7.737311],
              [107.940552, -7.731939],
              [108.300041, -7.814594],
              [108.445457, -7.820151],
              [108.498558, -7.793009],
              [108.48922, -7.717109],
              [108.517296, -7.69865],
              [108.640854, -7.687573],
              [108.670181, -7.731625],
              [108.67363, -7.682003],
              [108.776482, -7.700318],
              [108.800354, -7.673753],
              [108.733803, -7.61757],
              [108.753296, -7.56022],
              [108.682167, -7.36908],
              [108.6614, -7.343176],
              [108.560173, -7.335101],
              [108.555428, -7.2864],
              [108.584663, -7.229239],
              [108.567177, -7.16577],
              [108.625366, -7.13698],
              [108.696846, -7.15117],
              [108.777687, -7.10483],
              [108.798851, -7.005829],
              [108.761108, -6.983061],
              [108.754517, -6.914639],
              [108.825981, -6.826869],
              [108.830612, -6.744647],
              [108.801231, -6.744212],
              [108.805817, -6.784571],
              [108.769806, -6.808595],
              [108.709129, -6.809689],
              [108.67437, -6.758971],
              [108.602501, -6.763056],
              [108.56916, -6.70834],
            ],
          ],
        ],
      },
      properties: {
        cartodb_id: 22,
        country: 'Indonesia',
        id_1: 10,
        state: 'Jawa Barat',
        key: 'indonesia-jawabarat',
        name: 'West Java',
        slug: 'west-java',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [111.692879, -6.754612],
              [111.56237, -6.639479],
              [111.492432, -6.62007],
              [111.460419, -6.664791],
              [111.356941, -6.704445],
              [111.149437, -6.65639],
              [111.063332, -6.52],
              [111.047501, -6.427779],
              [110.980278, -6.402777],
              [110.728615, -6.433055],
              [110.663612, -6.502222],
              [110.681946, -6.525834],
              [110.64389, -6.555556],
              [110.661507, -6.57412],
              [110.619446, -6.70667],
              [110.54528, -6.725001],
              [110.543053, -6.764168],
              [110.576942, -6.772222],
              [110.520264, -6.834295],
              [110.487503, -6.945833],
              [110.446953, -6.956671],
              [110.315002, -6.940001],
              [110.172775, -6.843056],
              [110.000832, -6.92139],
              [109.882095, -6.916781],
              [109.573631, -6.83648],
              [109.520752, -6.777239],
              [109.43148, -6.844464],
              [109.275002, -6.873329],
              [109.101387, -6.83917],
              [109.068611, -6.757219],
              [108.94838, -6.828549],
              [108.852592, -6.795217],
              [108.832779, -6.74222],
              [108.825981, -6.826869],
              [108.754517, -6.914639],
              [108.761108, -6.983061],
              [108.798851, -7.005829],
              [108.777687, -7.10483],
              [108.696846, -7.15117],
              [108.625366, -7.13698],
              [108.567177, -7.16577],
              [108.584778, -7.227799],
              [108.557861, -7.330791],
              [108.6614, -7.343176],
              [108.682167, -7.36908],
              [108.753296, -7.56022],
              [108.733803, -7.61757],
              [108.788109, -7.668969],
              [108.82177, -7.62087],
              [108.81028, -7.670464],
              [108.859291, -7.659353],
              [108.871109, -7.691778],
              [108.938461, -7.7197],
              [108.963715, -7.713827],
              [108.953247, -7.66612],
              [108.985954, -7.729906],
              [108.993011, -7.665037],
              [109.010559, -7.67972],
              [109.009018, -7.661509],
              [109.027199, -7.654788],
              [108.99176, -7.699186],
              [109.016891, -7.75264],
              [109.050835, -7.700277],
              [109.167961, -7.691113],
              [109.174065, -7.670598],
              [109.179779, -7.692531],
              [109.388222, -7.71049],
              [109.386391, -7.754999],
              [109.41111, -7.779443],
              [109.470383, -7.758562],
              [109.643608, -7.780899],
              [110.008614, -7.890277],
              [110.042946, -7.892039],
              [110.06321, -7.81391],
              [110.13121, -7.745999],
              [110.128471, -7.656851],
              [110.262253, -7.646868],
              [110.281677, -7.697289],
              [110.447891, -7.543099],
              [110.494019, -7.767861],
              [110.577057, -7.805489],
              [110.67289, -7.787689],
              [110.786407, -7.819219],
              [110.755783, -8.019719],
              [110.786987, -8.151238],
              [110.828529, -8.137669],
              [110.837074, -8.206528],
              [110.900276, -8.212499],
              [110.911171, -8.121149],
              [110.956131, -8.063708],
              [111.026451, -8.083409],
              [111.077927, -8.04819],
              [111.111771, -8.06415],
              [111.14222, -7.93493],
              [111.183167, -7.916109],
              [111.248383, -7.944731],
              [111.314003, -7.850789],
              [111.284523, -7.745249],
              [111.230743, -7.74767],
              [111.179886, -7.70701],
              [111.193329, -7.608829],
              [111.154701, -7.571369],
              [111.116676, -7.418],
              [111.148041, -7.261489],
              [111.20414, -7.24505],
              [111.459091, -7.37659],
              [111.45295, -7.32056],
              [111.420197, -7.30742],
              [111.448273, -7.25188],
              [111.541611, -7.22794],
              [111.604263, -7.140271],
              [111.621803, -6.98183],
              [111.566597, -6.94502],
              [111.609039, -6.903619],
              [111.619156, -6.826409],
              [111.655281, -6.826329],
              [111.692879, -6.754612],
            ],
          ],
          [
            [
              [110.25222, -5.730833],
              [110.232132, -5.737534],
              [110.245834, -5.763055],
              [110.25222, -5.730833],
            ],
          ],
          [
            [
              [110.48333, -5.769166],
              [110.450279, -5.807223],
              [110.47583, -5.81889],
              [110.410278, -5.840277],
              [110.448608, -5.890001],
              [110.495552, -5.818055],
              [110.48333, -5.769166],
            ],
          ],
          [
            [
              [110.189018, -5.809078],
              [110.18177, -5.818719],
              [110.197777, -5.816111],
              [110.189018, -5.809078],
            ],
          ],
          [
            [
              [108.852501, -7.665555],
              [108.828888, -7.682222],
              [108.863579, -7.673224],
              [108.852501, -7.665555],
            ],
          ],
          [
            [
              [108.786217, -7.736748],
              [109.050484, -7.774892],
              [108.962692, -7.718148],
              [108.895248, -7.70941],
              [108.864471, -7.675029],
              [108.796967, -7.679745],
              [108.786217, -7.736748],
            ],
          ],
        ],
      },
      properties: {
        cartodb_id: 23,
        country: 'Indonesia',
        id_1: 11,
        state: 'Jawa Tengah',
        key: 'indonesia-jawatengah',
        name: 'Central Java',
        slug: 'central-java',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [115.756302, -1.303249],
              [115.770241, -1.229909],
              [115.845596, -1.1546],
              [115.798439, -1.112051],
              [115.755341, -1.134438],
              [115.743332, -1.09121],
              [115.673073, -1.057989],
              [115.659317, -1.000371],
              [115.500587, -0.84737],
              [115.389877, -0.815649],
              [115.353851, -0.583179],
              [115.24469, -0.448159],
              [115.260071, -0.392689],
              [115.227531, -0.29702],
              [115.252914, -0.290981],
              [115.238037, -0.23745],
              [115.269867, -0.14639],
              [115.321472, -0.09469],
              [115.300827, -0.031909],
              [115.192688, -0.07421],
              [115.143883, -0.136539],
              [115.043007, -0.19086],
              [114.934532, -0.18761],
              [114.89502, 0.04392],
              [114.788261, 0.096721],
              [114.800873, 0.17588],
              [114.907867, 0.31864],
              [115.017662, 0.330079],
              [115.047897, 0.300551],
              [115.131706, 0.38353],
              [115.135262, 0.42981],
              [115.007538, 0.618461],
              [114.979729, 0.744481],
              [114.909042, 0.793241],
              [114.865097, 0.739],
              [114.876717, 0.699379],
              [114.823257, 0.751761],
              [114.714417, 0.705221],
              [114.699547, 0.72942],
              [114.615219, 0.642469],
              [114.538437, 0.63139],
              [114.518898, 0.5662],
              [114.458847, 0.57097],
              [114.411667, 0.496481],
              [114.315567, 0.544621],
              [114.196503, 0.542461],
              [114.148277, 0.588],
              [114.172516, 0.62484],
              [114.154976, 0.668239],
              [114.108139, 0.702549],
              [114.005112, 0.654619],
              [113.889, 0.64114],
              [113.843216, 0.66563],
              [113.774666, 0.56566],
              [113.672935, 0.50568],
              [113.658348, 0.443241],
              [113.453087, 0.274881],
              [113.343407, 0.279291],
              [113.270958, 0.24151],
              [113.419342, 0.125381],
              [113.410477, 0.04983],
              [113.440208, 0.014371],
              [113.41732, -0.045511],
              [113.441429, -0.06608],
              [113.397453, -0.124708],
              [113.409073, -0.161861],
              [113.355873, -0.219359],
              [113.28756, -0.24261],
              [113.207962, -0.338049],
              [113.266602, -0.42323],
              [113.21804, -0.503209],
              [113.146019, -0.44562],
              [113.062393, -0.499649],
              [112.992569, -0.487565],
              [112.846123, -0.5719],
              [112.754082, -0.568529],
              [112.743896, -0.62011],
              [112.699158, -0.622159],
              [112.691406, -0.651499],
              [112.488258, -0.69251],
              [112.475708, -0.731581],
              [112.433113, -0.672569],
              [112.3022, -0.732871],
              [112.243538, -0.63516],
              [112.197029, -0.615789],
              [112.147186, -0.68017],
              [112.157761, -0.72885],
              [112.022758, -0.703299],
              [112.0289, -0.79598],
              [111.954117, -0.818651],
              [111.908051, -0.77366],
              [111.918953, -0.871431],
              [111.872597, -0.863976],
              [111.853928, -0.904665],
              [111.730446, -0.92433],
              [111.624687, -0.984569],
              [111.623146, -1.0463],
              [111.553772, -1.110259],
              [111.573433, -1.19982],
              [111.408928, -1.240509],
              [111.278343, -1.424281],
              [111.219727, -1.407579],
              [111.206253, -1.440369],
              [111.184662, -1.4311],
              [111.19136, -1.513311],
              [111.146736, -1.47199],
              [111.106239, -1.491641],
              [111.118416, -1.55175],
              [111.077171, -1.561929],
              [111.058273, -1.535279],
              [111.013313, -1.56207],
              [110.983383, -1.536631],
              [110.857643, -1.629531],
              [110.859451, -1.675359],
              [110.913467, -1.717321],
              [110.976723, -1.666849],
              [111.004929, -1.698119],
              [110.973938, -1.95433],
              [111.004158, -1.990881],
              [111.084633, -1.99388],
              [111.080872, -2.04857],
              [111.037148, -2.07326],
              [111.078987, -2.15783],
              [111.031776, -2.243429],
              [111.10318, -2.424131],
              [111.136292, -2.6113],
              [111.118568, -2.65266],
              [111.172676, -2.690279],
              [111.139252, -2.74909],
              [111.011696, -2.794629],
              [110.964142, -2.858801],
              [110.778679, -2.923384],
              [110.733887, -2.988677],
              [110.825974, -2.984205],
              [110.983887, -3.065833],
              [111.135559, -3.017779],
              [111.343933, -2.900429],
              [111.386108, -2.91972],
              [111.41333, -2.90194],
              [111.490547, -2.94603],
              [111.547539, -3.023295],
              [111.690552, -2.941112],
              [111.706947, -2.905559],
              [111.687233, -2.92],
              [111.669006, -2.89577],
              [111.71833, -2.846388],
              [111.703613, -2.79583],
              [111.739998, -2.825833],
              [111.726387, -2.86639],
              [111.824173, -3.046389],
              [111.797226, -3.196388],
              [111.818611, -3.38944],
              [111.766945, -3.505277],
              [111.852501, -3.5425],
              [111.906113, -3.539166],
              [112.082779, -3.40361],
              [112.260834, -3.312777],
              [112.407051, -3.345531],
              [112.5625, -3.44556],
              [112.795334, -3.307166],
              [112.787086, -3.288461],
              [112.795395, -3.307107],
              [112.917503, -3.241939],
              [113.03894, -3.13371],
              [112.995911, -3.156337],
              [112.942352, -3.118757],
              [113.032921, -2.97384],
              [113.184937, -3.084519],
              [113.343193, -3.28094],
              [113.428505, -3.235676],
              [113.449898, -3.189711],
              [113.599419, -3.166081],
              [113.633751, -3.132089],
              [113.60218, -3.170981],
              [113.630257, -3.333018],
              [113.580215, -3.443319],
              [113.628746, -3.465452],
              [113.795555, -3.459125],
              [113.967461, -3.363925],
              [114.07576, -3.343849],
              [114.174423, -3.393566],
              [114.223862, -3.375287],
              [114.351067, -3.461931],
              [114.397667, -3.256829],
              [114.478317, -3.12685],
              [114.49762, -3.000579],
              [114.59111, -2.820949],
              [114.664398, -2.801799],
              [114.680443, -2.74145],
              [114.760803, -2.72184],
              [114.797592, -2.592399],
              [114.865448, -2.520449],
              [114.875748, -2.46402],
              [115.248199, -2.209019],
              [115.291229, -2.22627],
              [115.326027, -2.10331],
              [115.278229, -2.001201],
              [115.351013, -1.93042],
              [115.31028, -1.806879],
              [115.400528, -1.43489],
              [115.524307, -1.429798],
              [115.689468, -1.36627],
              [115.756302, -1.303249],
            ],
          ],
          [
            [
              [113.396393, -3.24972],
              [113.364998, -3.275278],
              [113.366386, -3.302779],
              [113.40583, -3.270556],
              [113.396393, -3.24972],
            ],
          ],
        ],
      },
      properties: {
        cartodb_id: 33,
        country: 'Indonesia',
        id_1: 15,
        state: 'Kalimantan Tengah',
        key: 'indonesia-kalimantantengah',
        name: 'Central Kalimantan',
        slug: 'central-kalimantan',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [119.16658, -3.487701],
              [119.236168, -3.479168],
              [119.304726, -3.425277],
              [119.373055, -3.463334],
              [119.427223, -3.45972],
              [119.476357, -3.504407],
              [119.490921, -3.48397],
              [119.396072, -3.19317],
              [119.484596, -3.18489],
              [119.539963, -3.133521],
              [119.62973, -3.113259],
              [119.534187, -2.94152],
              [119.5252, -2.793351],
              [119.493317, -2.74063],
              [119.643578, -2.75641],
              [119.73996, -2.71976],
              [119.761757, -2.677659],
              [119.751106, -2.604909],
              [119.700089, -2.553469],
              [119.720917, -2.500069],
              [119.689026, -2.437739],
              [119.715866, -2.39282],
              [119.690308, -2.33612],
              [119.596573, -2.294341],
              [119.61335, -2.204939],
              [119.727409, -2.07925],
              [119.817101, -2.044529],
              [119.873543, -1.948739],
              [119.860428, -1.87573],
              [119.909172, -1.834719],
              [119.867416, -1.76982],
              [119.756668, -1.755519],
              [119.777779, -1.692499],
              [119.659027, -1.50289],
              [119.695396, -1.450899],
              [119.623283, -1.395169],
              [119.529373, -1.427779],
              [119.423126, -1.356679],
              [119.508636, -1.31016],
              [119.467911, -1.306169],
              [119.474258, -1.287229],
              [119.581657, -1.25812],
              [119.610977, -1.12573],
              [119.584396, -1.118209],
              [119.550926, -1.008439],
              [119.553627, -0.860949],
              [119.464722, -1.06861],
              [119.376663, -1.165556],
              [119.339722, -1.17],
              [119.30278, -1.273612],
              [119.332779, -1.349445],
              [119.291656, -1.390281],
              [119.28833, -1.450555],
              [119.325836, -1.589167],
              [119.28167, -1.68972],
              [119.311111, -1.798611],
              [119.363609, -1.853891],
              [119.330559, -1.962223],
              [119.288063, -1.9525],
              [119.211937, -1.99778],
              [119.187775, -2.151111],
              [119.144722, -2.200281],
              [119.121391, -2.319721],
              [119.144447, -2.470001],
              [119.044724, -2.506667],
              [119.030479, -2.58205],
              [118.91111, -2.666111],
              [118.868889, -2.679672],
              [118.818298, -2.61948],
              [118.756668, -2.785],
              [118.769447, -2.867222],
              [118.84639, -2.849721],
              [118.886108, -2.890834],
              [118.838623, -3.081085],
              [118.796806, -3.070608],
              [118.775139, -3.10422],
              [118.828476, -3.168945],
              [118.85833, -3.30111],
              [118.845711, -3.379756],
              [118.906113, -3.525279],
              [118.936943, -3.570281],
              [118.967438, -3.545067],
              [118.996391, -3.564717],
              [119.021446, -3.512368],
              [119.108658, -3.516343],
              [119.16658, -3.487701],
            ],
          ],
          [
            [
              [118.891388, -2.612777],
              [118.877777, -2.62639],
              [118.888885, -2.653612],
              [118.891388, -2.612777],
            ],
          ],
        ],
      },
      properties: {
        cartodb_id: 17,
        country: 'Indonesia',
        id_1: 26,
        state: 'Sulawesi Barat',
        key: 'indonesia-sulawesibarat',
        name: 'West Sulawesi',
        slug: 'west-sulawesi',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [104.929726, -2.354166],
              [104.922775, -2.408058],
              [104.903343, -2.365739],
              [104.917778, -2.284723],
              [104.899719, -2.279166],
              [104.846657, -2.286111],
              [104.837502, -2.340279],
              [104.775085, -2.390557],
              [104.699379, -2.35201],
              [104.640556, -2.379166],
              [104.694725, -2.343055],
              [104.743057, -2.35944],
              [104.786835, -2.282357],
              [104.902359, -2.194389],
              [104.890831, -2.086666],
              [104.82917, -2.00389],
              [104.716942, -1.994167],
              [104.683609, -2.0534],
              [104.687775, -1.993333],
              [104.654167, -1.96333],
              [104.633331, -2.019167],
              [104.629173, -1.982781],
              [104.610809, -2.005902],
              [104.581665, -2.006388],
              [104.602783, -2.04222],
              [104.576385, -2.016667],
              [104.568886, -2.03472],
              [104.570473, -2.001693],
              [104.550278, -2.029722],
              [104.566109, -1.999719],
              [104.486053, -2.030563],
              [104.559723, -1.999168],
              [104.518608, -1.977778],
              [104.455002, -2.016945],
              [104.511665, -1.991111],
              [104.504723, -1.968609],
              [104.411392, -2.031944],
              [104.427498, -2.00361],
              [104.501389, -1.965555],
              [104.493614, -1.922778],
              [104.471107, -1.912779],
              [104.471107, -1.97056],
              [104.418892, -1.974165],
              [104.400002, -1.97139],
              [104.469437, -1.968609],
              [104.464447, -1.936669],
              [104.385002, -1.95056],
              [104.462219, -1.935001],
              [104.464447, -1.909167],
              [104.397499, -1.92528],
              [104.35083, -1.913333],
              [104.454445, -1.917777],
              [104.479156, -1.892219],
              [104.471107, -1.864439],
              [104.441673, -1.885279],
              [104.472221, -1.858611],
              [104.455002, -1.846666],
              [104.479156, -1.851669],
              [104.504173, -1.805279],
              [104.483612, -1.786939],
              [104.429443, -1.804999],
              [104.484444, -1.785556],
              [104.511665, -1.807221],
              [104.54361, -1.774167],
              [104.489548, -1.660766],
              [104.412682, -1.6299],
              [104.37085, -1.642041],
              [104.345032, -1.738209],
              [104.228828, -1.740689],
              [104.093376, -1.786201],
              [103.87841, -1.743041],
              [103.821846, -1.796279],
              [103.719849, -1.80127],
              [103.585777, -1.85764],
              [103.536926, -1.903749],
              [103.52124, -2.030089],
              [103.518036, -2.10473],
              [103.557846, -2.140118],
              [103.453247, -2.17754],
              [103.44912, -2.35815],
              [103.30162, -2.236829],
              [103.272728, -2.14813],
              [103.223457, -2.13716],
              [103.16069, -2.23375],
              [103.200676, -2.270171],
              [103.205429, -2.321289],
              [103.122238, -2.357391],
              [103.054733, -2.31834],
              [103.021713, -2.34951],
              [102.873749, -2.310689],
              [102.84024, -2.371389],
              [102.877457, -2.417719],
              [102.792999, -2.538899],
              [102.623001, -2.624221],
              [102.59153, -2.683029],
              [102.461586, -2.703259],
              [102.255836, -2.622689],
              [102.227676, -2.69231],
              [102.063423, -2.770429],
              [102.132637, -2.87591],
              [102.217949, -2.929399],
              [102.25296, -3.033271],
              [102.322517, -3.06805],
              [102.389923, -3.04432],
              [102.462326, -3.07265],
              [102.492142, -3.132361],
              [102.491783, -3.24482],
              [102.585991, -3.32866],
              [102.633102, -3.350159],
              [102.789978, -3.285489],
              [102.812683, -3.33645],
              [102.897827, -3.332799],
              [102.901367, -3.380369],
              [102.996826, -3.368589],
              [102.994019, -3.51892],
              [102.956337, -3.5676],
              [102.901787, -3.61497],
              [102.814087, -3.52589],
              [102.806503, -3.6169],
              [102.745926, -3.669619],
              [102.733803, -3.74177],
              [102.619728, -3.80072],
              [102.821777, -3.969891],
              [102.907036, -3.966],
              [102.956093, -4.01295],
              [103.001137, -4.014615],
              [102.983231, -4.046916],
              [102.926773, -4.053802],
              [102.930901, -4.093738],
              [103.0755, -4.199775],
              [103.072746, -4.253483],
              [103.214432, -4.240679],
              [103.304741, -4.259409],
              [103.365379, -4.315221],
              [103.463722, -4.32299],
              [103.476334, -4.354233],
              [103.407379, -4.515133],
              [103.495514, -4.577103],
              [103.618637, -4.53271],
              [103.636147, -4.554519],
              [103.598297, -4.59165],
              [103.685951, -4.633819],
              [103.706207, -4.698469],
              [103.68206, -4.72799],
              [103.763008, -4.74064],
              [103.778877, -4.795049],
              [103.824249, -4.796399],
              [103.86615, -4.845619],
              [103.844406, -4.91568],
              [103.912117, -4.95982],
              [103.934792, -4.884859],
              [103.962349, -4.90974],
              [104.059723, -4.88585],
              [104.112068, -4.92016],
              [104.152519, -4.915281],
              [104.194489, -4.861441],
              [104.334213, -4.87058],
              [104.367622, -4.797659],
              [104.288582, -4.680341],
              [104.342293, -4.6013],
              [104.323013, -4.45639],
              [104.369888, -4.35331],
              [104.621758, -4.216279],
              [104.643257, -4.24593],
              [104.790436, -4.26234],
              [105.012337, -4.172759],
              [105.119156, -4.046499],
              [105.104767, -4.01881],
              [105.14061, -3.936611],
              [105.277618, -3.877269],
              [105.322647, -3.729401],
              [105.356903, -3.80017],
              [105.411293, -3.7784],
              [105.430122, -3.82491],
              [105.523346, -3.839611],
              [105.521347, -3.892521],
              [105.545303, -3.88211],
              [105.561096, -3.92872],
              [105.607468, -3.939779],
              [105.587097, -3.95424],
              [105.62825, -4.004789],
              [105.603981, -4.051101],
              [105.661049, -4.047099],
              [105.649826, -4.083841],
              [105.706932, -4.09951],
              [105.700241, -4.135269],
              [105.744843, -4.14274],
              [105.777298, -4.112359],
              [105.846367, -4.156151],
              [105.866867, -4.021901],
              [105.961388, -3.826009],
              [105.832092, -3.712061],
              [105.823166, -3.59376],
              [105.920128, -3.3475],
              [105.940002, -3.356388],
              [106.096107, -3.239444],
              [106.049164, -3.122223],
              [106.046112, -2.989719],
              [105.958336, -2.942778],
              [105.878609, -2.964092],
              [105.810364, -2.913242],
              [105.782654, -2.850842],
              [105.779739, -2.682799],
              [105.678757, -2.678061],
              [105.610268, -2.595645],
              [105.622498, -2.391667],
              [105.525116, -2.424062],
              [105.492317, -2.451549],
              [105.568901, -2.4092],
              [105.297241, -2.372898],
              [105.210587, -2.326039],
              [105.108627, -2.387293],
              [105.065666, -2.361849],
              [105.034866, -2.395327],
              [105.041557, -2.358419],
              [105.007141, -2.333072],
              [104.929726, -2.354166],
            ],
          ],
          [
            [
              [104.501663, -1.95028],
              [104.600548, -2.00162],
              [104.554726, -1.941667],
              [104.616112, -1.985557],
              [104.568054, -1.899444],
              [104.604164, -1.963333],
              [104.644447, -1.923334],
              [104.548607, -1.853611],
              [104.520279, -1.921666],
              [104.535843, -1.940279],
              [104.513893, -1.924439],
              [104.501663, -1.95028],
            ],
          ],
          [
            [
              [104.921112, -2.355278],
              [104.911392, -2.369999],
              [104.925835, -2.400277],
              [104.921112, -2.355278],
            ],
          ],
        ],
      },
      properties: {
        cartodb_id: 21,
        country: 'Indonesia',
        id_1: 32,
        state: 'Sumatera Selatan',
        key: 'indonesia-sumateraselatan',
        name: 'South Sulawesi',
        slug: 'south-sulawesi',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [117.70565965357025, 2.8393553992323177],
              [117.69280678300674, 2.8518314610531093],
              [117.68042758536478, 2.8499858273841028],
              [117.65756240058442, 2.835162927127442],
              [117.64136507453861, 2.815179237631753],
              [117.65587632013614, 2.798534608585385],
              [117.67753606084057, 2.7964958248225003],
              [117.700391845907, 2.8056672657317563],
              [117.7296656553458, 2.8071669356056645],
              [117.72804155167637, 2.828907575724088],
              [117.70565965357025, 2.8393553992323177],
            ],
          ],
          [
            [
              [117.58087911987559, 2.9032799629999317],
              [117.5463563237854, 2.9079924797691774],
              [117.54576863661339, 2.8915278266479163],
              [117.57093797980394, 2.883581485397258],
              [117.58535024691639, 2.8886583247219733],
              [117.5941542356295, 2.8600302950621312],
              [117.62129859615334, 2.8557992141624595],
              [117.63526351799703, 2.8504350963027036],
              [117.64881111394186, 2.8605581368485673],
              [117.6669545036475, 2.8550556322117018],
              [117.68494917786234, 2.8632350300726443],
              [117.70490611522507, 2.8612674888036054],
              [117.71725567391024, 2.8716797881917273],
              [117.71871188764351, 2.892931984023903],
              [117.71258517616502, 2.895560063845892],
              [117.65342705251658, 2.892610541143597],
              [117.60543050831131, 2.904216911781077],
              [117.58087911987559, 2.9032799629999317],
            ],
          ],
          [
            [
              [117.68736278556412, 2.935048103287386],
              [117.66252154023698, 2.9276603246643162],
              [117.63944265675332, 2.9266605807212613],
              [117.60560624663106, 2.91738862618422],
              [117.61518074479557, 2.9074955216011062],
              [117.64276116499582, 2.9018621961834583],
              [117.71057515991572, 2.9042087333464224],
              [117.71398029625368, 2.9157081224319654],
              [117.70368217569194, 2.9317512893905473],
              [117.68736278556412, 2.935048103287386],
            ],
          ],
          [
            [
              [117.46262382862687, 2.9531572567020703],
              [117.44834895813801, 2.9466657091551838],
              [117.45584110668176, 2.935462729421488],
              [117.46677385452983, 2.9394206844170867],
              [117.50700358554707, 2.915610181764521],
              [117.54050473020925, 2.912328670731597],
              [117.56591820875838, 2.9204400105980426],
              [117.57326521211985, 2.9451516438333556],
              [117.55902098602974, 2.9503516857816976],
              [117.52484885539047, 2.937549247399886],
              [117.50501987806501, 2.938987812837354],
              [117.48027814272223, 2.9544144495672526],
              [117.46262382862687, 2.9531572567020703],
            ],
          ],
          [
            [
              [117.66728803071805, 2.987271695832135],
              [117.65562912528401, 2.9875690332843305],
              [117.63557151511338, 2.978665982449172],
              [117.61391858227682, 2.9821977954919134],
              [117.59026697993727, 2.974033145613248],
              [117.56365178680062, 2.9539498993684106],
              [117.57456188702156, 2.945901840297722],
              [117.58271797625366, 2.928301936085518],
              [117.6169662066252, 2.9260218804055285],
              [117.643478389616, 2.9339045154810606],
              [117.6658857888981, 2.9365509954321283],
              [117.693154919564, 2.9457601638005713],
              [117.69049326265406, 2.9585540325426223],
              [117.66735116672305, 2.963963838660277],
              [117.66728803071805, 2.987271695832135],
            ],
          ],
          [
            [
              [117.54684432290719, 2.957495583554362],
              [117.55992902880166, 2.9645182626077826],
              [117.56359849477474, 2.9753271198507605],
              [117.55593877375543, 2.986811211468307],
              [117.53659053532499, 2.9580795996938036],
              [117.53134146323805, 2.9563270800307464],
              [117.53018180274807, 2.969309627437383],
              [117.50468459911497, 2.969909658703841],
              [117.503425460117, 2.961370128228907],
              [117.52100352968921, 2.94050027826529],
              [117.54684432290719, 2.957495583554362],
            ],
          ],
          [
            [
              [117.47901174439653, 2.9586126953196867],
              [117.49901555712233, 2.9610283588717152],
              [117.50476840513784, 2.9711671123723704],
              [117.53009771973404, 2.9710737735358634],
              [117.53408829403963, 2.9577460861130476],
              [117.5475160796035, 2.978601098162187],
              [117.54802717421478, 2.993093583002519],
              [117.5572737957491, 2.9899044836050734],
              [117.5588610209195, 3.0056538636349615],
              [117.54236618834898, 3.0117777620341144],
              [117.52386458967908, 3.006512635249976],
              [117.48085808313408, 2.981988694123004],
              [117.47901174439653, 2.9586126953196867],
            ],
          ],
          [
            [
              [117.49327839238401, 2.9998233285837728],
              [117.48837295215003, 3.0161639400138824],
              [117.4560014441114, 2.976933127361974],
              [117.47734858137198, 2.9687457821921157],
              [117.48160527766152, 2.986593160046368],
              [117.49327839238401, 2.9998233285837728],
            ],
          ],
          [
            [
              [117.51065813716332, 3.0142809943724274],
              [117.49459843526961, 3.028582268057278],
              [117.48759463028807, 3.018851171844176],
              [117.49594094951522, 3.0049877960499884],
              [117.51065813716332, 3.0142809943724274],
            ],
          ],
          [
            [
              [117.62281549793693, 3.0590440036778546],
              [117.5942227837545, 3.0564044727882447],
              [117.57430254592578, 3.046111279611921],
              [117.56363679060541, 3.0487133168661558],
              [117.54821037870613, 3.0344004832957125],
              [117.52829744427174, 3.0388433104704404],
              [117.51979090992256, 3.015814589371871],
              [117.55361963623727, 3.0162953957159857],
              [117.56503293951857, 3.005650672840318],
              [117.5714338875639, 2.983112207262593],
              [117.5776040398946, 2.983527078012287],
              [117.60352641601139, 3.0104177730618176],
              [117.61891437110137, 3.017807544582581],
              [117.62743009382382, 3.051310114670116],
              [117.62281549793693, 3.0590440036778546],
            ],
          ],
          [
            [
              [117.5165557526459, 3.0582089274001305],
              [117.51130697193923, 3.059297056713831],
              [117.50714109008925, 3.064410428298686],
              [117.50054910086033, 3.041136358656047],
              [117.49479664315061, 3.031576640849323],
              [117.50811767289235, 3.0179099153103834],
              [117.5178757838404, 3.0215963433965953],
              [117.51996631998975, 3.0392725658762743],
              [117.52872443518459, 3.0551813885103343],
              [117.5165557526459, 3.0582089274001305],
            ],
          ],
          [
            [
              [117.53000630454733, 3.0679094133887475],
              [117.48875516401972, 3.064047806361202],
              [117.46476003594694, 3.0543935671094573],
              [117.44619272831187, 3.053827917824208],
              [117.41939325869203, 3.03002048815506],
              [117.41400919656724, 3.0189632444582344],
              [117.39845478775828, 3.021040103717837],
              [117.37838365178413, 3.0036779982410735],
              [117.37704462510942, 2.9868965634257165],
              [117.36036673553645, 2.9629188795027517],
              [117.37379431605495, 2.961575470431683],
              [117.38188949960079, 2.9563978872527628],
              [117.40360258629039, 2.9575934010147193],
              [117.40894307626206, 2.9650550787289376],
              [117.42037182360082, 2.9683528674908644],
              [117.45103474565701, 2.96566288013787],
              [117.45551279657832, 2.9757662947787367],
              [117.451042212728, 2.989555445027804],
              [117.45952590969307, 3.0049585635869107],
              [117.47819505475809, 3.0052852432194754],
              [117.48745734518036, 3.031326676085257],
              [117.4987184022325, 3.041209583255693],
              [117.50264004170458, 3.0649101302983013],
              [117.50897956425558, 3.0652418515302884],
              [117.51280977774911, 3.0603819421729668],
              [117.52622232789815, 3.0602125746506204],
              [117.52980810565964, 3.056022191670877],
              [117.52513113909083, 3.045050881792804],
              [117.55313106874394, 3.039925461990549],
              [117.54922493987908, 3.06421769369166],
              [117.53000630454733, 3.0679094133887475],
            ],
          ],
          [
            [
              [117.56993845439627, 3.0511254289954195],
              [117.58869918282107, 3.060686556039798],
              [117.61930894055524, 3.066914801876635],
              [117.62001617730482, 3.0761155085239693],
              [117.60220352137549, 3.0831144733543283],
              [117.57017529265568, 3.0788531500529075],
              [117.55140667627711, 3.0719242990671773],
              [117.56993845439627, 3.0511254289954195],
            ],
          ],
          [
            [
              [117.4935149707394, 3.082647280047979],
              [117.46819879793622, 3.081799366847349],
              [117.44900686077801, 3.0678385162345876],
              [117.45704174478078, 3.057557232780141],
              [117.48810928171804, 3.0736558033726737],
              [117.4935149707394, 3.082647280047979],
            ],
          ],
          [
            [
              [117.52895223975418, 3.09818205575732],
              [117.5054705058592, 3.092817857857881],
              [117.49991699976977, 3.0797989994243267],
              [117.51975224357125, 3.0747718521392926],
              [117.53712466517118, 3.0791960705422525],
              [117.55106366225823, 3.076465886191727],
              [117.5673753263103, 3.081867171233],
              [117.56176124278716, 3.0935215377887744],
              [117.52895223975418, 3.09818205575732],
            ],
          ],
          [
            [
              [117.38847333917329, 3.090216637188121],
              [117.37876123214517, 3.096806271092589],
              [117.37663246221837, 3.110078764423463],
              [117.36254894587717, 3.1082291125835013],
              [117.37156699302238, 3.0801173711198544],
              [117.36505880891696, 3.0672368940674346],
              [117.35534689074643, 3.0675296755541126],
              [117.3561172095416, 3.0480062491890862],
              [117.37078099634596, 3.048390292078352],
              [117.39302035280707, 3.0610208088633044],
              [117.39584369253248, 3.077322165785347],
              [117.39982585637824, 3.084648605248958],
              [117.38847333917329, 3.090216637188121],
            ],
          ],
          [
            [
              [117.58706511106607, 3.1112282400912363],
              [117.56203828254081, 3.108656863423562],
              [117.56805637570119, 3.0972123149963977],
              [117.5832982620052, 3.102661345045078],
              [117.58706511106607, 3.1112282400912363],
            ],
          ],
          [
            [
              [117.45888536037114, 3.112244418245382],
              [117.44422169857262, 3.120646128516057],
              [117.43139635805551, 3.1092344458130583],
              [117.40138980079871, 3.1078259851811367],
              [117.38973228032057, 3.0951919403557895],
              [117.40266398005872, 3.090392265790456],
              [117.40711205207958, 3.077227438395596],
              [117.39642313292131, 3.0730337495079993],
              [117.39515678315911, 3.0600972410948657],
              [117.38486453517021, 3.055704230066226],
              [117.38291915499508, 3.0417999135352147],
              [117.39116644267722, 3.031248317256086],
              [117.40180538471077, 3.042404243561066],
              [117.42388077497787, 3.0365053303567606],
              [117.43860746036603, 3.0585297308624604],
              [117.44887746452343, 3.0828990515500436],
              [117.47748972007457, 3.0958731336382357],
              [117.47613847521052, 3.1050100136013157],
              [117.45888536037114, 3.112244418245382],
            ],
          ],
          [
            [
              [117.59703161831465, 3.1258684682552484],
              [117.58219261569673, 3.119515406410926],
              [117.60082511428766, 3.1063375820138504],
              [117.61604190500998, 3.1092010512875277],
              [117.61126244720424, 3.125295051525484],
              [117.59703161831465, 3.1258684682552484],
            ],
          ],
          [
            [
              [117.30586219158693, 3.230271816790946],
              [117.27042408080672, 3.2298114691240585],
              [117.2781216992571, 3.2098428315814544],
              [117.28926822584799, 3.2070170897669072],
              [117.30586219158693, 3.230271816790946],
            ],
          ],
          [
            [
              [117.45569611978021, 3.2780816923162774],
              [117.47087123412018, 3.2933635786662876],
              [117.45831317031661, 3.3026694898051687],
              [117.45139291954763, 3.2853481775763953],
              [117.43833937516206, 3.2772300334393663],
              [117.39920039498236, 3.2748490684298304],
              [117.40810192776178, 3.2499315596937777],
              [117.42290904671518, 3.248299117510328],
              [117.45569611978021, 3.2780816923162774],
            ],
          ],
          [
            [
              [117.38615408474323, 3.2975164563194426],
              [117.36843455541043, 3.2945426546198746],
              [117.36287711208911, 3.2787802164339155],
              [117.35625472127845, 3.2667412566037797],
              [117.36602042340382, 3.2544973637623684],
              [117.37927218556297, 3.2712109735076638],
              [117.37542746510553, 3.2799516553446892],
              [117.38615408474323, 3.2975164563194426],
            ],
          ],
          [
            [
              [117.44676098473167, 3.3409000081289264],
              [117.43432756066841, 3.336617658678051],
              [117.4143189716433, 3.321464214401999],
              [117.39629349474899, 3.3130186648795075],
              [117.3835220568975, 3.2865527475113367],
              [117.39607981133429, 3.276270658264309],
              [117.43610373519925, 3.2785518416941954],
              [117.44924917321089, 3.284571126456342],
              [117.45744341168415, 3.305112509834487],
              [117.47360209116368, 3.2971799866671176],
              [117.46985398217555, 3.2751350015790877],
              [117.5166776548499, 3.2758979971948747],
              [117.52257289432373, 3.3006925164465315],
              [117.50451331603676, 3.322016429614564],
              [117.47370362102538, 3.3295706017019597],
              [117.44676098473167, 3.3409000081289264],
            ],
          ],
          [
            [
              [117.48858721292959, 3.4078995680106914],
              [117.45487737429141, 3.406446479421561],
              [117.44928904375445, 3.4020922561527414],
              [117.45112619052134, 3.353606174085712],
              [117.4553246647016, 3.3473975235768876],
              [117.49652622200756, 3.341256729614429],
              [117.50508342326145, 3.355352799592083],
              [117.5022946381871, 3.388189133022138],
              [117.49770724319478, 3.405363942092322],
              [117.48858721292959, 3.4078995680106914],
            ],
          ],
          [
            [
              [117.4172208626527, 3.4211427371476475],
              [117.37246779265456, 3.4404725322816034],
              [117.36669969123784, 3.4258754967568166],
              [117.3718916213013, 3.414138402465369],
              [117.39693432555873, 3.4074846783752832],
              [117.41104041578262, 3.3968026866946843],
              [117.42173819596098, 3.370768793744901],
              [117.41628336798306, 3.345985291188299],
              [117.43428482128752, 3.347817155337509],
              [117.43694775973142, 3.3726909237472],
              [117.44426654776305, 3.3986992813468078],
              [117.43811320376142, 3.417575697660368],
              [117.4172208626527, 3.4211427371476475],
            ],
          ],
          [
            [
              [117.61864830577474, 3.438541292146965],
              [117.59747652282329, 3.4434137461475416],
              [117.56349486679471, 3.43847232763585],
              [117.54857758089065, 3.4422965822215588],
              [117.52141850476028, 3.414232918514415],
              [117.51495792626736, 3.381084482562869],
              [117.51955831196653, 3.3651773848284847],
              [117.53392314613632, 3.3488573310010565],
              [117.54117523864636, 3.326880271707239],
              [117.5709958511776, 3.306151824846893],
              [117.58513310470903, 3.2903460024660944],
              [117.60409196906915, 3.2784578697349502],
              [117.62708585582766, 3.244539484609561],
              [117.64809554587282, 3.241068097015102],
              [117.65277149710698, 3.260631930818988],
              [117.65739516265671, 3.305165219099649],
              [117.65709929379739, 3.3263787332919605],
              [117.66335998537511, 3.3576100448677266],
              [117.66805502921625, 3.362322074204428],
              [117.66484185676632, 3.3841605668567354],
              [117.66865495166473, 3.4036577833800266],
              [117.66578098010825, 3.4152204901335494],
              [117.65327922105928, 3.4191066207739595],
              [117.61864830577474, 3.438541292146965],
            ],
          ],
          [
            [
              [117.4448693588339, 3.5503453731404875],
              [117.41552711098745, 3.556924992194695],
              [117.41649108439606, 3.5416575948221976],
              [117.43970412514534, 3.537263985744403],
              [117.45625424533716, 3.5154866541857928],
              [117.47196596715571, 3.51155148531285],
              [117.48605562406783, 3.498318548662155],
              [117.50138831491881, 3.5062614832808663],
              [117.51139068623127, 3.5039853540408785],
              [117.54718979567258, 3.4712424116080456],
              [117.56305271870804, 3.47455113882404],
              [117.60606753319018, 3.4743858632170372],
              [117.61509514282659, 3.4866187564505253],
              [117.60942869987468, 3.5006169882834115],
              [117.56891761415659, 3.520175166250965],
              [117.54305862405636, 3.527234405443494],
              [117.50817147663236, 3.5431954840843787],
              [117.47863399766095, 3.5489222013985113],
              [117.4448693588339, 3.5503453731404875],
            ],
          ],
          [
            [
              [117.2480673322417, 3.5881477377279793],
              [117.23486939360771, 3.5855771074975564],
              [117.21955353716612, 3.5720315997785406],
              [117.20141585186013, 3.539053423476787],
              [117.23076864155962, 3.4967593733464355],
              [117.2651163792176, 3.4873721994674725],
              [117.29965768335546, 3.4847977911851444],
              [117.32304983398274, 3.491269501486329],
              [117.34764891796328, 3.484100243731291],
              [117.38405703570368, 3.469403155031614],
              [117.40764213857392, 3.466511617017545],
              [117.44464048766838, 3.456700062058246],
              [117.46177772216424, 3.4492705180200574],
              [117.47668329439853, 3.4350775766689026],
              [117.49289285482143, 3.4594457183452505],
              [117.51790566111742, 3.481845526449945],
              [117.50528381199842, 3.4972583450966113],
              [117.48012155906281, 3.4935851307492385],
              [117.46900293034105, 3.505291661580941],
              [117.43543611628377, 3.508632950550293],
              [117.43765809284673, 3.5289191153249817],
              [117.40713695610566, 3.537931451775137],
              [117.40038312038473, 3.5481512737623007],
              [117.37109588226917, 3.562561659493042],
              [117.33620669878019, 3.565389339346609],
              [117.31105172675586, 3.5598601041605207],
              [117.28655386776688, 3.5683675495228613],
              [117.27267132292332, 3.5873615261135683],
              [117.2480673322417, 3.5881477377279793],
            ],
          ],
          [
            [
              [117.62244393134245, 3.5758667496507996],
              [117.59636105322011, 3.5824601661038287],
              [117.55995623239426, 3.5879008873161524],
              [117.55143739891685, 3.577455849008288],
              [117.53718593868018, 3.575720870621751],
              [117.5232617678164, 3.5827577274872056],
              [117.50985307126234, 3.5792660062212462],
              [117.53469829339156, 3.562140127464943],
              [117.5625263626016, 3.5606227265576536],
              [117.58201891434146, 3.5552753387859184],
              [117.60454638570718, 3.5421396134524343],
              [117.62813720556767, 3.5369073362046493],
              [117.65696083687317, 3.544285688134039],
              [117.65566210162478, 3.5597805941990828],
              [117.62244393134245, 3.5758667496507996],
            ],
          ],
          [
            [
              [117.81739809124895, 3.576855824037125],
              [117.80089261340345, 3.589364152633607],
              [117.79513709979733, 3.583337394505179],
              [117.75623589137331, 3.5639538298054276],
              [117.75141900288475, 3.5353222694072315],
              [117.7591270031088, 3.5170494708564206],
              [117.77218156732556, 3.5058216140762966],
              [117.79694501589984, 3.492660606102163],
              [117.81581313416882, 3.4778177435940165],
              [117.82633892709987, 3.475256594016628],
              [117.84234363275255, 3.4554651525947406],
              [117.86709082338291, 3.450069987440031],
              [117.87325342140832, 3.4761518924958636],
              [117.85726215792226, 3.505000216486849],
              [117.85010440339556, 3.5372416447861497],
              [117.81739809124895, 3.576855824037125],
            ],
          ],
          [
            [
              [117.49285474155317, 3.569078499573152],
              [117.48875981891058, 3.578044293409448],
              [117.46255759625615, 3.591194674094993],
              [117.47050784506109, 3.5730994952455717],
              [117.49285474155317, 3.569078499573152],
            ],
          ],
          [
            [
              [117.42231988555432, 3.600744274528722],
              [117.38953610041074, 3.6136810347520054],
              [117.36902627694121, 3.6070613850749282],
              [117.32684593978831, 3.6006652861740918],
              [117.2988405143783, 3.6006281702539127],
              [117.29193686239637, 3.5965759510193607],
              [117.30287671129122, 3.5784944940257333],
              [117.31203462190035, 3.584243083751346],
              [117.34427461128439, 3.5880575015524983],
              [117.39116285618093, 3.5903035862415322],
              [117.40940923984465, 3.588308349450813],
              [117.42424172327696, 3.5777883922205547],
              [117.43562090987712, 3.5622010601308602],
              [117.46037787074215, 3.5687366312905624],
              [117.45556234832372, 3.582935666447895],
              [117.42231988555432, 3.600744274528722],
            ],
          ],
          [
            [
              [117.54544192370588, 3.5805492407549195],
              [117.54937498456798, 3.598695276266028],
              [117.54353975595507, 3.60709380653401],
              [117.5227499627407, 3.615303372152937],
              [117.51287988619788, 3.610413246474195],
              [117.50697295823545, 3.5950098644137825],
              [117.54544192370588, 3.5805492407549195],
            ],
          ],
          [
            [
              [117.37001504555792, 3.622581929012881],
              [117.32763385471867, 3.6191863299852116],
              [117.33907133627326, 3.6114672761938778],
              [117.3575424317379, 3.613499580341397],
              [117.37001504555792, 3.622581929012881],
            ],
          ],
          [
            [
              [117.48215589208087, 3.637394906150064],
              [117.45568535129803, 3.6334589341824994],
              [117.43654606674761, 3.6408400227488755],
              [117.43022668631261, 3.6237290250745104],
              [117.49060686181724, 3.598143073643996],
              [117.50337065363283, 3.605644382682158],
              [117.52047805851566, 3.6338439582321485],
              [117.48215589208087, 3.637394906150064],
            ],
          ],
          [
            [
              [117.49342175510992, 3.6675944768036857],
              [117.48908511988247, 3.654024107326734],
              [117.49783000964726, 3.639656048188101],
              [117.5078353532191, 3.635723975681003],
              [117.51817577159466, 3.6511925459992653],
              [117.50326524229718, 3.667841124868062],
              [117.49342175510992, 3.6675944768036857],
            ],
          ],
          [
            [
              [117.49139363910524, 3.684123613114309],
              [117.49283488811966, 3.699016525592242],
              [117.48434469715016, 3.708220640670106],
              [117.44935894625837, 3.698815076554581],
              [117.42953740272901, 3.6749087403461544],
              [117.45850271682696, 3.678805115163982],
              [117.46659419876335, 3.671395835549447],
              [117.49139363910524, 3.684123613114309],
            ],
          ],
          [
            [
              [117.74452200588587, 3.6994613842355193],
              [117.7331086207663, 3.7178980230753496],
              [117.72824878875065, 3.6854804598553983],
              [117.7088396401374, 3.6685525551563387],
              [117.71096036293045, 3.6488029061292764],
              [117.72129160372458, 3.6351762238037395],
              [117.73431488772201, 3.631474938018016],
              [117.76136739868662, 3.6574329722539005],
              [117.76852405064449, 3.6820994217509337],
              [117.74452200588587, 3.6994613842355193],
            ],
          ],
          [
            [
              [117.77493861892219, 3.752648336851337],
              [117.75442220881791, 3.7604659887144294],
              [117.74901749286312, 3.7543833655998924],
              [117.78896336456773, 3.7422650432971523],
              [117.80990587525605, 3.7302898723797284],
              [117.80948663919708, 3.7530597137348423],
              [117.77493861892219, 3.752648336851337],
            ],
          ],
          [
            [
              [117.68013138375636, 3.7755859934988507],
              [117.67910768097477, 3.7628471740578675],
              [117.71228811840206, 3.742882729154701],
              [117.72496053909765, 3.744211144728581],
              [117.7303772933775, 3.7410223699864105],
              [117.72853864204478, 3.7315615812020724],
              [117.73503140367643, 3.720239380143312],
              [117.7491073252429, 3.7011310484591604],
              [117.77704999326261, 3.686331563850615],
              [117.7919528099743, 3.6890092035120006],
              [117.81109031476626, 3.7025172445752332],
              [117.82595065919566, 3.7041994957102133],
              [117.81010175389292, 3.726024769227763],
              [117.74999999770182, 3.7471616737722684],
              [117.72556051280753, 3.762372080207399],
              [117.68013138375636, 3.7755859934988507],
            ],
          ],
          [
            [
              [117.79872759899956, 3.7819444314903876],
              [117.78149536461945, 3.7850234700571264],
              [117.77903646975733, 3.774389208994819],
              [117.79505068394201, 3.7596017591139343],
              [117.81481125433595, 3.7575297427035252],
              [117.8135933663408, 3.779350139197618],
              [117.79872759899956, 3.7819444314903876],
            ],
          ],
          [
            [
              [117.74175281883754, 3.7821457078582057],
              [117.73876171777908, 3.789267602817688],
              [117.72874446088827, 3.782907604502384],
              [117.7230757822216, 3.781908237375319],
              [117.71749104357684, 3.785097150613069],
              [117.7076923138493, 3.7722561097317],
              [117.7538176035996, 3.765092427773368],
              [117.78372328570117, 3.7549273574116455],
              [117.76582335416458, 3.7854446468530227],
              [117.74175281883754, 3.7821457078582057],
            ],
          ],
          [
            [
              [117.59534466552395, 3.7798254210064215],
              [117.55942547410643, 3.782843780516089],
              [117.53165418089577, 3.765980475018978],
              [117.51908869318868, 3.774012371473816],
              [117.50490559491732, 3.765046552456454],
              [117.49728398266973, 3.7740251580346467],
              [117.48381814409208, 3.7650585548084905],
              [117.47833244958292, 3.750460366352854],
              [117.48636643786085, 3.74207877031904],
              [117.48606834587747, 3.717122159060466],
              [117.50236786728328, 3.7020129335500656],
              [117.5204235667942, 3.6740515056724803],
              [117.5516840146056, 3.649191866511501],
              [117.57660404594003, 3.6338957196118145],
              [117.60094685371439, 3.625737173431844],
              [117.66853000165827, 3.6156727470996657],
              [117.71754357207828, 3.6343690256101127],
              [117.71021245254269, 3.650232807498128],
              [117.70805340514062, 3.6684989016030727],
              [117.7277448806218, 3.6859060460272417],
              [117.73136114809859, 3.7206676121206783],
              [117.72586822304015, 3.728967538021493],
              [117.72931643690413, 3.7404081150424986],
              [117.71261612273588, 3.742041163369038],
              [117.66893778934752, 3.768047640486259],
              [117.66494759365662, 3.7840536330687655],
              [117.64958975236527, 3.7676462092052247],
              [117.63101970719379, 3.776091125605035],
              [117.61314365175815, 3.7722773364299655],
              [117.59879280384484, 3.7733638937287424],
              [117.59534466552395, 3.7798254210064215],
            ],
          ],
          [
            [
              [117.70397972088745, 3.781417405388993],
              [117.68015314375259, 3.79355848863338],
              [117.67823857794804, 3.781460702349591],
              [117.69313892871867, 3.775076759584863],
              [117.70397972088745, 3.781417405388993],
            ],
          ],
          [
            [
              [117.74175281883754, 3.7821457078582057],
              [117.76480088525273, 3.786332072667051],
              [117.75620039872945, 3.799536681572647],
              [117.74368853220005, 3.8015643946811224],
              [117.73163626600092, 3.8108849953463277],
              [117.7293397788078, 3.7961416716009353],
              [117.71561418724502, 3.7963431521148436],
              [117.71566011562186, 3.7919647487742054],
              [117.71265395361718, 3.784431739533204],
              [117.7183225765258, 3.785349756685946],
              [117.72640978218578, 3.7829095695210526],
              [117.73200248522653, 3.7906121971951734],
              [117.73917407582303, 3.789683373788705],
              [117.74175281883754, 3.7821457078582057],
            ],
          ],
          [
            [
              [117.70397972088745, 3.781417405388993],
              [117.71006761492856, 3.786442127202463],
              [117.71508044590587, 3.792046646435608],
              [117.71391268162506, 3.794978588291258],
              [117.71624749522573, 3.797826203416605],
              [117.71908541116284, 3.7978238381996334],
              [117.72917176206727, 3.7971459418317863],
              [117.72592899382812, 3.8056973280693],
              [117.73116281790976, 3.8114553058177307],
              [117.72567726009754, 3.8169509980901353],
              [117.69483939921895, 3.812788149653443],
              [117.69376375429385, 3.8071622526359192],
              [117.6862488133321, 3.802020963396785],
              [117.69914991993289, 3.7912274503866],
              [117.70397972088745, 3.781417405388993],
            ],
          ],
          [
            [
              [117.7354172892766, 3.87298092622882],
              [117.71877387881148, 3.8724317588186636],
              [117.71012508880187, 3.8605618895196585],
              [117.72286786705843, 3.844752572373352],
              [117.73499654685327, 3.8408271242724368],
              [117.73480224292928, 3.819821674530715],
              [117.74385468374669, 3.805990188696285],
              [117.76103864521735, 3.802542364238775],
              [117.78613768930302, 3.789293706539354],
              [117.80708943782736, 3.789015901462733],
              [117.82650384117937, 3.7827752881490824],
              [117.8356639740133, 3.7939676612786],
              [117.82917815964436, 3.807536623316537],
              [117.77283590325442, 3.8458961925553012],
              [117.75852035373146, 3.865521452752887],
              [117.7354172892766, 3.87298092622882],
            ],
          ],
          [
            [
              [117.5228192393165, 4.027963962367039],
              [117.49966043937786, 4.05276963770865],
              [117.4644354022721, 4.030095521990802],
              [117.44327887721852, 4.005761437640274],
              [117.45659638521511, 3.9851464861214936],
              [117.4631909150288, 3.9677816242226527],
              [117.46530730039171, 3.953053070043211],
              [117.4616601537931, 3.930030074028764],
              [117.4965689182211, 3.9162591908090576],
              [117.52637384207924, 3.9296384444587034],
              [117.54225079094135, 3.9278390755129635],
              [117.56527800657477, 3.918393216373886],
              [117.59334442220734, 3.8869616635499824],
              [117.60620947318864, 3.8821056059889543],
              [117.65407847554127, 3.8896542157694967],
              [117.6816598139493, 3.887949378161295],
              [117.68272444307865, 3.913798233801515],
              [117.66557121503956, 3.9102120612377007],
              [117.64405274164073, 3.9114077071999986],
              [117.62213732008126, 3.923882546842094],
              [117.59745398401424, 3.933491176076984],
              [117.5775234552433, 3.969789705130836],
              [117.55465393033467, 3.9850926634956636],
              [117.54750610252472, 4.015362631280482],
              [117.53133992090068, 4.0308481861948735],
              [117.5228192393165, 4.027963962367039],
            ],
          ],
          [
            [
              [117.55434421461382, 4.0991712225738866],
              [117.57547526457188, 4.11834558787865],
              [117.5887401010749, 4.1407322802705835],
              [117.57498455309474, 4.1445304931877445],
              [117.55378034958358, 4.139266491455487],
              [117.53571589517253, 4.116229529868974],
              [117.53930119539393, 4.103953384641045],
              [117.55434421461382, 4.0991712225738866],
            ],
          ],
          [
            [
              [117.66086041237816, 4.146907465207391],
              [117.62941113481611, 4.135183756425249],
              [117.58927553763601, 4.080048629440739],
              [117.603026533248, 4.010830168677387],
              [117.62412210764921, 3.99038929083315],
              [117.66906204597672, 3.966490659116687],
              [117.7017591832398, 3.979436113985514],
              [117.72403513401423, 3.984371262423849],
              [117.72728396458331, 4.01177095666435],
              [117.74319999978024, 4.0340270000460805],
              [117.75350656767398, 4.0617375620389],
              [117.73112628385093, 4.077652763564231],
              [117.71043248607043, 4.097544401153243],
              [117.6916148656336, 4.120968537068393],
              [117.66877544373176, 4.144088210202767],
              [117.66086041237816, 4.146907465207391],
            ],
          ],
          [
            [
              [117.90110403482345, 4.166424557647247],
              [117.68345039651865, 4.166558506270121],
              [117.70490269690197, 4.150010749496516],
              [117.73112657253341, 4.120837646140501],
              [117.76633350178975, 4.094221154382808],
              [117.81499267727031, 4.06656931182431],
              [117.86121902563764, 4.028976658346153],
              [117.89294739340755, 4.042314167265033],
              [117.90598565921084, 4.0407318793727995],
              [117.92627782426223, 4.063103457758416],
              [117.91755975767671, 4.083067039312482],
              [117.92567868782123, 4.124770595486666],
              [117.92380203113885, 4.134401846268247],
              [117.90110403482345, 4.166424557647247],
            ],
          ],
          [
            [
              [117.98593392680937, 2.435721765746962],
              [117.95899707315641, 2.4522190732516833],
              [117.93816395690112, 2.4728579053680733],
              [117.88618696385731, 2.5399855520915935],
              [117.86406716686633, 2.5594527042789537],
              [117.83809083862138, 2.5900974690230214],
              [117.81189906698842, 2.60968878610862],
              [117.79857597779915, 2.6289659250374484],
              [117.78962544635738, 2.6527063237379025],
              [117.79018437860623, 2.7185172631612318],
              [117.78520058224115, 2.748287808635496],
              [117.7677057907897, 2.747366868686072],
              [117.7512112756799, 2.7541079754028033],
              [117.73234739321765, 2.7715308111980335],
              [117.69706830685698, 2.7837322821191606],
              [117.68495964866895, 2.782161914740641],
              [117.64605697704792, 2.791574261542962],
              [117.61522680177552, 2.7951914184492352],
              [117.59953574817632, 2.8140638129946183],
              [117.63559810626771, 2.817016242305783],
              [117.64532682197535, 2.823082337679807],
              [117.66469384184734, 2.845320448579912],
              [117.64985212777356, 2.8560966478420937],
              [117.6320061699488, 2.847471100810708],
              [117.59508529205266, 2.857505809045904],
              [117.5918580119378, 2.86183176482416],
              [117.5858536586195, 2.8866768592496896],
              [117.56864156455663, 2.882895098530014],
              [117.54555537677925, 2.8900804595456293],
              [117.5390398470114, 2.906593779479408],
              [117.5303648966227, 2.903205397511158],
              [117.48448204880754, 2.92312894934156],
              [117.47308324439632, 2.9353198316449607],
              [117.45959632660924, 2.931599300367452],
              [117.44841785609924, 2.940351015986664],
              [117.44741828392671, 2.9468832092927073],
              [117.45535265340231, 2.9499649314365115],
              [117.44921860435522, 2.9597289257493458],
              [117.43084721132982, 2.9654809787642193],
              [117.4090345355155, 2.962947130805503],
              [117.40665445903608, 2.9568232962581646],
              [117.38655828323829, 2.953962657150953],
              [117.381126375382, 2.9549144654303063],
              [117.37646509838567, 2.9596566274514657],
              [117.36922452079511, 2.955732722587129],
              [117.35431648621693, 2.9562351837068377],
              [117.37053690505422, 2.9836238162861264],
              [117.37075813737817, 2.9926072907938988],
              [117.38526144466005, 3.0138895284790124],
              [117.38649012182168, 3.0370490397814365],
              [117.37148277960671, 3.0463002244758286],
              [117.35376744201392, 3.0467585585606685],
              [117.34501653038865, 3.073956362196384],
              [117.36612718731988, 3.0795764540901587],
              [117.35951227796932, 3.1054256253923427],
              [117.36711862512448, 3.1165687273649496],
              [117.3782961117762, 3.111172834658646],
              [117.38589480120413, 3.095971374679266],
              [117.4060290246199, 3.114889765050691],
              [117.42633053992256, 3.11309044556765],
              [117.44887537899558, 3.127872512106819],
              [117.46220421934936, 3.1269620222795425],
              [117.46045647958294, 3.141867119671872],
              [117.4442725102682, 3.1616515140062234],
              [117.42655361136019, 3.1726771825059927],
              [117.40400948275146, 3.1642806118608178],
              [117.36844475192379, 3.181437211458217],
              [117.3176514906238, 3.177713195307774],
              [117.30918596715867, 3.2022113357845683],
              [117.29352574861446, 3.203849449092843],
              [117.2662507696574, 3.1808053477886347],
              [117.23845700418747, 3.1887373070811122],
              [117.23216232722973, 3.1764348845622976],
              [117.21527855226338, 3.1839837326631937],
              [117.23796091296583, 3.193903231449042],
              [117.26792907456718, 3.1862873541780914],
              [117.27759570108083, 3.1951507691927077],
              [117.27442175975648, 3.2094638617678584],
              [117.26258821857152, 3.231794811481393],
              [117.22425062876357, 3.249644632793604],
              [117.26506820433417, 3.2521859352327738],
              [117.28648359564147, 3.236990297464331],
              [117.30923440104687, 3.236748367243422],
              [117.3159256223696, 3.2136857758778774],
              [117.33017074508393, 3.2114138087003425],
              [117.33973209066346, 3.221289277578137],
              [117.379139655171, 3.229498471777106],
              [117.39683304930588, 3.2568810204427905],
              [117.37981426091949, 3.267564899800334],
              [117.3688280483758, 3.252623643366519],
              [117.36074362791612, 3.252416991750806],
              [117.35923745414527, 3.2619725088242717],
              [117.35266117966978, 3.267176780284217],
              [117.3599088259316, 3.273678877778025],
              [117.36740100235443, 3.2942409356707003],
              [117.38371710276215, 3.320996298040768],
              [117.40912968198927, 3.326409205020866],
              [117.41523354709057, 3.336758867527749],
              [117.41234883762877, 3.3913389455359493],
              [117.39156912671388, 3.4071957001215196],
              [117.37024086802171, 3.4120718152620952],
              [117.36087866292007, 3.4431054513555637],
              [117.35504274092978, 3.4491453665654603],
              [117.32589127796018, 3.4512619641683386],
              [117.31142641129986, 3.466218059415951],
              [117.28952963718302, 3.4636222535642105],
              [117.25546408070284, 3.470618751554184],
              [117.2297545318462, 3.4862031212770717],
              [117.22114571939949, 3.4860688138247724],
              [117.20635326479135, 3.5178085912934876],
              [117.19625055881625, 3.527169390754807],
              [117.19436796301113, 3.541776245077699],
              [117.21226287633078, 3.576363515363994],
              [117.22431903839299, 3.587407714394544],
              [117.22609190611661, 3.603621242626275],
              [117.17637412876309, 3.619094075731084],
              [117.13456080133994, 3.6211529062816226],
              [117.10274883079398, 3.6098821365513913],
              [117.07486927359821, 3.6113047192374665],
              [117.02714051062617, 3.587460773495934],
              [117.01643494321809, 3.5956352943334196],
              [117.06594675334009, 3.631378211435333],
              [117.088257089693, 3.6380524760409685],
              [117.12499740980263, 3.636564914639166],
              [117.15005158761073, 3.6432363994403545],
              [117.16868258972977, 3.6442683103343256],
              [117.201519604846, 3.6333857185761076],
              [117.22236822181605, 3.6294988657800786],
              [117.25772799625008, 3.617608708674993],
              [117.3233373832137, 3.6242234174767987],
              [117.37039393713053, 3.638041405386616],
              [117.39946354470692, 3.6378891645533713],
              [117.42841260805551, 3.6247408963753287],
              [117.43240910259806, 3.6384834113803777],
              [117.4371970374134, 3.6417424456573713],
              [117.45706302363965, 3.63451099978289],
              [117.47524376039132, 3.647969495395955],
              [117.48883859502519, 3.6525066578560086],
              [117.48542709418507, 3.673463969586578],
              [117.46873423989337, 3.667512205024309],
              [117.45715550991895, 3.6751510671680876],
              [117.42872284898033, 3.671145711605334],
              [117.42344342027764, 3.691626183001972],
              [117.43459327705818, 3.690802047876275],
              [117.44315683472018, 3.701165084201307],
              [117.47309371430356, 3.712591573342479],
              [117.48101037073206, 3.726485379496694],
              [117.48351272533205, 3.744984314374676],
              [117.47729481720216, 3.7488596846267797],
              [117.47611227616073, 3.755111538566723],
              [117.48083473133784, 3.7645807414081105],
              [117.49313374182873, 3.774579371540824],
              [117.5001982861221, 3.773670664169856],
              [117.50706477451979, 3.768066850005198],
              [117.52050755268147, 3.7766621582320568],
              [117.53210466030191, 3.768151367391738],
              [117.55609878383189, 3.7852341982996336],
              [117.57964333357302, 3.782622391710049],
              [117.59119430597252, 3.795813320128673],
              [117.6006547323301, 3.7938075190018594],
              [117.59944169258006, 3.775706471176761],
              [117.63333902727436, 3.780485968552682],
              [117.6489409553609, 3.771654219566642],
              [117.65728735192977, 3.785054588398566],
              [117.67971827657732, 3.7938392668687015],
              [117.69506094630572, 3.7907241672865553],
              [117.68541700698938, 3.802148274123624],
              [117.69304650269396, 3.8083659808054335],
              [117.69340502462228, 3.8125541055875374],
              [117.69710545155544, 3.814821716449103],
              [117.70871707873027, 3.8198871235919114],
              [117.73150932759347, 3.816288726543519],
              [117.73438227662086, 3.835576351567397],
              [117.7259012812192, 3.8388159182201207],
              [117.70507841993322, 3.859039713212269],
              [117.66892531125416, 3.8787979453689445],
              [117.62428727803547, 3.873724477602593],
              [117.59807156465104, 3.8763186835604415],
              [117.57931666383308, 3.88823014560478],
              [117.55701842785822, 3.9156551332778236],
              [117.53325733216025, 3.924778914615274],
              [117.49948246174631, 3.9127494678137396],
              [117.4873763819146, 3.9146223923095818],
              [117.45756846691131, 3.929949820327977],
              [117.45539162732177, 3.9365840704984976],
              [117.46412132483931, 3.9583310517367067],
              [117.45669220707975, 3.9710419939928556],
              [117.45486178724173, 3.985074062817773],
              [117.43973067313209, 4.004402550337488],
              [117.42346375934505, 4.008211549723285],
              [117.41266121534284, 4.018774214858183],
              [117.415990699811, 4.0287077961279465],
              [117.43822656600332, 4.013427105876303],
              [117.45119362168839, 4.036945049174733],
              [117.46494398327832, 4.048155528354982],
              [117.47382426058118, 4.070673117070726],
              [117.468357083113, 4.0828682225904345],
              [117.43036689871349, 4.093806772864298],
              [117.41840123003283, 4.094335871003693],
              [117.41868054057454, 4.114013560777266],
              [117.40723966767655, 4.128033357453546],
              [117.39120974683237, 4.13568032698555],
              [117.40746384617967, 4.1458819332046914],
              [117.44368800040093, 4.1274670004017935],
              [117.48479881161597, 4.109652366863315],
              [117.52396113729674, 4.103228856626458],
              [117.53418463031653, 4.124513275167544],
              [117.54813194613246, 4.142347875454163],
              [117.58779380474039, 4.161929412412519],
              [117.58373012184768, 4.178782320847461],
              [117.56663407630163, 4.1794223800388295],
              [117.54959739230583, 4.168447917137712],
              [117.53802276323995, 4.171837495204272],
              [117.52874137931121, 4.161246658806306],
              [117.50902971315077, 4.1705766582862225],
              [117.48923684090755, 4.174841177778205],
              [117.46782351983961, 4.1735539844289065],
              [117.44157662156385, 4.194236728642181],
              [117.438923856251, 4.207869266453542],
              [117.42648154958385, 4.2156920930157185],
              [117.42974816424646, 4.2291752417440875],
              [117.40970490455777, 4.242161799227688],
              [117.40616304788637, 4.255078881385316],
              [117.38915804790565, 4.257492215693276],
              [117.3776266582438, 4.268478882394561],
              [117.37365943663372, 4.285156937442764],
              [117.34361082617875, 4.289718882520447],
              [117.3241186035907, 4.302985271253306],
              [117.29079999252565, 4.315319160151148],
              [117.28146610437727, 4.335731104107197],
              [117.24883721576498, 4.373868883442242],
              [117.2311097157409, 4.370117772517233],
              [117.21374777124277, 4.353846383703683],
              [117.20100249287077, 4.335061383476102],
              [117.17924666140436, 4.335559161825927],
              [117.15210277122594, 4.353490828638883],
              [117.1088258277498, 4.332325828374337],
              [117.08535888320591, 4.33378055103168],
              [117.04417805077651, 4.347096384490612],
              [117.03238749358798, 4.34088082898711],
              [117.02217082822119, 4.318810273475378],
              [117.00754916206427, 4.320896662850771],
              [117.01061082752778, 4.3440155517763515],
              [116.97553193924762, 4.343415829876676],
              [116.95653249477891, 4.355955273217319],
              [116.94063610617138, 4.356146940329097],
              [116.91245943995125, 4.368634996407991],
              [116.90224527380042, 4.3662302740079895],
              [116.8889688837071, 4.3491274961371005],
              [116.87407805136104, 4.339488330422171],
              [116.85114027330508, 4.3388524962457495],
              [116.84393916156307, 4.3258122195583155],
              [116.82215305172201, 4.33655305208174],
              [116.8170208285485, 4.35034055207484],
              [116.80761527252685, 4.350633608754094],
              [116.79812527376248, 4.338616386437138],
              [116.78597749596474, 4.342299163275811],
              [116.78445944035093, 4.358922774266659],
              [116.76992444006089, 4.376955275765397],
              [116.75241027451466, 4.389533053607579],
              [116.7419188851485, 4.382286941589143],
              [116.74231249592503, 4.3670758300855255],
              [116.73502971854668, 4.35316860874434],
              [116.70241721828006, 4.333903609763809],
              [116.67438638522356, 4.351276109604839],
              [116.65887166259722, 4.340376386660978],
              [116.64246916252193, 4.336342775298874],
              [116.61823138567001, 4.345589443597987],
              [116.62058471900968, 4.35946722023607],
              [116.61117527431952, 4.379656943513169],
              [116.59926999691288, 4.373411665416086],
              [116.58770610755084, 4.382271665704877],
              [116.56727166255723, 4.408174443795588],
              [116.56564083016019, 4.3911758325986625],
              [116.55012944132068, 4.387098887403795],
              [116.53780110753519, 4.374338331348952],
              [116.53086944167148, 4.3474752760631645],
              [116.54181305153122, 4.3342113874456345],
              [116.53047666366717, 4.325887220319032],
              [116.50902221930005, 4.324129721109784],
              [116.49854916441245, 4.333473332728147],
              [116.48767555238487, 4.329472220166849],
              [116.48009721911149, 4.302441109469669],
              [116.45427860767165, 4.295759443443615],
              [116.44160138459631, 4.288278054388741],
              [116.43837694184242, 4.323007221506089],
              [116.42022277556066, 4.33096111076668],
              [116.40819972024099, 4.345279999472439],
              [116.40544166410155, 4.358884443362399],
              [116.38693110818417, 4.359013609390615],
              [116.38062666368967, 4.375752776069817],
              [116.36790305358306, 4.377924721548709],
              [116.34922860791403, 4.3901499984237375],
              [116.32521610853667, 4.38395305439002],
              [116.30792055289123, 4.368587499613454],
              [116.29360055352277, 4.3724294447631],
              [116.28051583054116, 4.360032221110146],
              [116.26260499652346, 4.361909999247132],
              [116.25309360918277, 4.377096665378872],
              [116.22129833095323, 4.3819299997697385],
              [116.20833610912416, 4.378150833594134],
              [116.17813610859457, 4.380682499797558],
              [116.16793277567717, 4.390766943239896],
              [116.16172222040416, 4.374623611590437],
              [116.15637499810771, 4.338375555187781],
              [116.1413508313758, 4.338024167581295],
              [116.13460055337293, 4.329386666761309],
              [116.11723555387778, 4.33540805503776],
              [116.08876833109366, 4.304100000714527],
              [116.08413888739983, 4.281181110220132],
              [116.0757249994092, 4.276627499159815],
              [116.03571360939566, 4.295041944530169],
              [116.03269110940721, 4.328705000435491],
              [116.00604805437422, 4.33863527759604],
              [116.00424194321113, 4.3474691660692315],
              [115.98518194289238, 4.350595277849834],
              [115.97786999870152, 4.357803611147801],
              [115.94278888729741, 4.3570063891335735],
              [115.92913472079078, 4.353913055842895],
              [115.91728888767204, 4.3692125005565],
              [115.90953860852005, 4.390997222743692],
              [115.90159083105209, 4.3954936117408465],
              [115.87852638708318, 4.391395000079228],
              [115.87191277635361, 4.377130555430824],
              [115.87356055467592, 4.354423889650832],
              [115.8660794426122, 4.341526111440942],
              [115.86566499814126, 4.318427500428868],
              [115.87127166495543, 4.285576388439608],
              [115.83807777636832, 4.264693055839928],
              [115.82555666570761, 4.261682500646941],
              [115.8300074986472, 4.2344024998755],
              [115.8181374989465, 4.224518334553522],
              [115.8038411085804, 4.2214777788764195],
              [115.79728499870384, 4.237691388047153],
              [115.76771472055793, 4.24488333299945],
              [115.75300360962899, 4.211545556482292],
              [115.73333055406192, 4.208524722038362],
              [115.72917444154734, 4.189664722848704],
              [115.70368138738615, 4.192086667265926],
              [115.69028166606608, 4.174886389395397],
              [115.68197777595367, 4.153115833329082],
              [115.68526249936497, 4.132679722791011],
              [115.67636416457458, 4.123233334850568],
              [115.67840743505519, 4.089664226622801],
              [115.65478883513674, 4.035064800600139],
              [115.6473001462848, 3.978251003581022],
              [115.64088941451496, 3.9615351931946634],
              [115.61673484024072, 3.936570812692878],
              [115.59040099011361, 3.943082147123448],
              [115.58113354567661, 3.9400355587940794],
              [115.56774449571196, 3.920089267605647],
              [115.57720618471774, 3.893489456473276],
              [115.6052868348196, 3.874901375398224],
              [115.61748613657596, 3.8752571175219828],
              [115.61795725182583, 3.841943250380723],
              [115.6139560844058, 3.8240244942350614],
              [115.60229948483345, 3.7985495038565773],
              [115.58875070717943, 3.7771652830514313],
              [115.59325143540548, 3.7689970457770983],
              [115.5787407491863, 3.74780506315318],
              [115.58183233959085, 3.728204766336944],
              [115.57504304360168, 3.721463697391755],
              [115.5840084165552, 3.6976628282769752],
              [115.57236785998884, 3.680853250077462],
              [115.57265260693237, 3.646302542628291],
              [115.58071593469265, 3.596088642576831],
              [115.58935486273674, 3.5787639363066432],
              [115.60420719060937, 3.5748941625355997],
              [115.61278887680533, 3.5535792389907215],
              [115.61422077916563, 3.537400875150297],
              [115.61030221267708, 3.5106178954995357],
              [115.62360660406159, 3.494678875429827],
              [115.63839979611373, 3.4565028362369503],
              [115.65833853479558, 3.43884030683472],
              [115.64244537025775, 3.418661555286576],
              [115.62528602682869, 3.4079978522191254],
              [115.61738281796272, 3.4211948366724414],
              [115.61140452429947, 3.4457500643140406],
              [115.58787340053709, 3.449885986149411],
              [115.57938350454515, 3.432494420680996],
              [115.58024032103401, 3.4188249890820543],
              [115.564875393085, 3.4048021194222997],
              [115.55721878821657, 3.3804847813476044],
              [115.54436934586863, 3.357704839706628],
              [115.54961377115865, 3.3287691672633173],
              [115.53394208266104, 3.3190156347637867],
              [115.53174351814891, 3.2810127420444815],
              [115.52577122925891, 3.259802050824021],
              [115.52968375679995, 3.225047183780532],
              [115.51681001297175, 3.2217239316141786],
              [115.5193058852584, 3.1968951751722443],
              [115.53195069856599, 3.1820935582711627],
              [115.54377099363649, 3.1817187864928655],
              [115.56292409087416, 3.1701157451455515],
              [115.5630309267359, 3.151700622585736],
              [115.55356888429662, 3.142237863386754],
              [115.53626609090747, 3.142172136435022],
              [115.52790041826972, 3.1169184889971007],
              [115.51515606260227, 3.1086687065956085],
              [115.51180367420022, 3.093302242604466],
              [115.51673433771953, 3.0557795563814807],
              [115.49916516873759, 3.0304004391289823],
              [115.48680679219979, 3.0180646193867346],
              [115.46897454993564, 3.0251790832481333],
              [115.44100055762965, 3.0227447758507537],
              [115.4250075611501, 3.0122440677096165],
              [115.41905822835463, 2.999121064185829],
              [115.4041163891601, 2.9841710177782943],
              [115.39088922525764, 2.9796750478652143],
              [115.35139160264043, 2.9812710252376746],
              [115.33184404206895, 2.97589048935464],
              [115.3235999002153, 3.0105765034029446],
              [115.3089577106299, 3.0188700288288146],
              [115.3062695776789, 3.0327091562026567],
              [115.28582957757305, 3.04734976747784],
              [115.27930708885117, 3.027247319807998],
              [115.25303241681263, 2.994650458751721],
              [115.24982612777364, 2.9646043645997793],
              [115.2106502358198, 2.957121061787632],
              [115.19206371416908, 2.934089525811032],
              [115.1650963330294, 2.9274538646042174],
              [115.14938240337528, 2.908015014081684],
              [115.15329064204957, 2.8703106890870913],
              [115.1430354110554, 2.86842563363615],
              [115.13735146729641, 2.852833903861608],
              [115.11910749201718, 2.835822397285881],
              [115.10419881082657, 2.8315706032008165],
              [115.10557797154627, 2.816151331019171],
              [115.12122518589365, 2.804529422794701],
              [115.14334582465017, 2.8049320007112897],
              [115.15526305584547, 2.792696056078171],
              [115.14242062461221, 2.777483517350504],
              [115.14175033920685, 2.74721159713755],
              [115.13106259366384, 2.7361506473345685],
              [115.10762663616038, 2.7217792175368913],
              [115.09606343567907, 2.6975804757584],
              [115.10821858252166, 2.6899443835265515],
              [115.12056947490146, 2.654864742513894],
              [115.11435743574714, 2.6364818813337934],
              [115.08934280832398, 2.6161578946711757],
              [115.09371319420859, 2.605244350046746],
              [115.10833270289197, 2.600369133328911],
              [115.1149553500095, 2.5831871890375737],
              [115.13707468924554, 2.5855877062077184],
              [115.1406954191583, 2.5970517087694134],
              [115.1547115331108, 2.6084248474288074],
              [115.17103997491495, 2.6134110287960084],
              [115.19448415312127, 2.583984994711784],
              [115.22044161898555, 2.5604592830695196],
              [115.22477594655277, 2.542141622737745],
              [115.23898126707184, 2.535067722797237],
              [115.25251939675286, 2.541072694849049],
              [115.25056591098803, 2.509306200320964],
              [115.2271829442376, 2.485523892313836],
              [115.20570209397647, 2.471044097806896],
              [115.19044425639743, 2.482658497591615],
              [115.15061313908518, 2.477096978542761],
              [115.13796293074472, 2.469379364566066],
              [115.13240167159995, 2.457034881654522],
              [115.11940819383312, 2.456140592214581],
              [115.09979195563426, 2.4253846586751138],
              [115.09794540285816, 2.40244770799535],
              [115.06456486357763, 2.407549132986162],
              [115.05199858266849, 2.404098656427891],
              [115.03932029389648, 2.391304797578414],
              [115.02755401930256, 2.364700153489366],
              [115.01367677776113, 2.364281381480623],
              [114.99450828323074, 2.3476112170840224],
              [114.97027015564322, 2.367794103714971],
              [114.9629217169637, 2.35932068330294],
              [114.94918355287871, 2.3241147972601084],
              [114.95219226715949, 2.308314703560768],
              [114.96902265297308, 2.2942760507991693],
              [114.95473721095357, 2.2791782610679547],
              [114.94033725286204, 2.2792813944208774],
              [114.91838325578271, 2.254812456729553],
              [114.87197375598407, 2.263658075060164],
              [114.85753300554381, 2.2538975728147648],
              [114.8312202355255, 2.2456306394424814],
              [114.81388720782843, 2.2620263082675365],
              [114.80038657807813, 2.2487269315027447],
              [114.8027847471185, 2.2343820110210117],
              [114.79093064383426, 2.2230357755806267],
              [114.78929093063198, 2.200928489058583],
              [114.74239445334138, 2.1910987830823956],
              [114.74546749702745, 2.180680150668479],
              [114.73593983555497, 2.1500621947145646],
              [114.74166835331187, 2.132228658325971],
              [114.76009078286336, 2.141820819175507],
              [114.7924041002691, 2.1457686361137007],
              [114.81393636387213, 2.1346047148357457],
              [114.82358215309398, 2.118225197834647],
              [114.81608344219717, 2.0999153696985786],
              [114.80132349987969, 2.0851244610250887],
              [114.81122511654598, 2.0746166752194313],
              [114.81311720559461, 2.0622368831257063],
              [114.79898830316768, 2.0597449534668613],
              [114.80005655026969, 2.042530522065988],
              [114.80991747171402, 2.025700789160112],
              [114.82934082781696, 2.0270991863783934],
              [114.86029706735587, 2.0438854064863676],
              [114.87160255271567, 2.0321157927097033],
              [114.88228603906953, 2.03059725326068],
              [114.87134108652197, 1.9889208062502348],
              [114.85182551123842, 1.9721687540848052],
              [114.85218701621784, 1.945629012269137],
              [114.881289231413, 1.9204002068041746],
              [114.86951849258446, 1.9029931595067637],
              [114.85595606412107, 1.8952473760655835],
              [114.83248716894775, 1.8960857456606846],
              [114.81722998697433, 1.8897976553375884],
              [114.79058829261567, 1.8474886251999578],
              [114.76930248282338, 1.8521027894122426],
              [114.7473284569428, 1.8693627435957865],
              [114.7318504329512, 1.858272190809032],
              [114.7279308718123, 1.8336393633659895],
              [114.70619420040214, 1.8206806506915427],
              [114.69767774832951, 1.8032980955307494],
              [114.70948172879878, 1.7959088258317024],
              [114.71435933411601, 1.7713111510888098],
              [114.70526881240585, 1.7605019673919173],
              [114.71921791427542, 1.715850724031725],
              [114.71115032462785, 1.7079460384789513],
              [114.71365324040482, 1.6854042867784642],
              [114.7112395256836, 1.6585366854197332],
              [114.70507691596697, 1.6373121662235235],
              [114.6934817463856, 1.632992213419982],
              [114.68085246526914, 1.6103644757400275],
              [114.65784765804301, 1.6008653047900339],
              [114.65168340616435, 1.5893350230928718],
              [114.63939651223927, 1.5895603194532555],
              [114.6104767479037, 1.5752155725406851],
              [114.59957829660118, 1.5314965969903938],
              [114.615963311158, 1.5155246617338207],
              [114.60534964457054, 1.499773950707322],
              [114.60625753355839, 1.487662332850448],
              [114.59481679016278, 1.4823705690465658],
              [114.58958656426864, 1.463283702381034],
              [114.60400587487129, 1.4442305207219874],
              [114.6337998485842, 1.4323276094316952],
              [114.6464117817784, 1.4087940646944048],
              [114.63882155766885, 1.3862095853041865],
              [114.67243306893101, 1.3528382775645014],
              [114.68854371562009, 1.3590564204175166],
              [114.69984943210568, 1.35396884858352],
              [114.70408907455123, 1.3370102734054399],
              [114.7145468643464, 1.3324879866313495],
              [114.72558478341114, 1.310535346628967],
              [114.71592885882978, 1.278230747251257],
              [114.72731287029274, 1.264000733147384],
              [114.75776510097865, 1.2548935254159232],
              [114.75679669211377, 1.2477069584094238],
              [114.80956235102178, 1.1931352656800414],
              [114.84826798837707, 1.1680904408212314],
              [114.85852113384328, 1.1923601930685095],
              [114.88042781938839, 1.2107804813342113],
              [114.89608083436303, 1.2093574804633818],
              [114.91914849430759, 1.194321873455749],
              [114.93587968759755, 1.1781109433653683],
              [114.97432216353707, 1.1932353206535709],
              [114.99028364057756, 1.182889632250749],
              [114.98914503501544, 1.1572735346459808],
              [114.9993980923482, 1.153279244341718],
              [115.00679722131474, 1.1273422236647548],
              [115.01564552951766, 1.12078534666648],
              [115.0454526513189, 1.115960779766226],
              [115.06035755985351, 1.122818140045183],
              [115.09327377466502, 1.1174408597079832],
              [115.12290523458898, 1.1294028924295958],
              [115.14195932366397, 1.116026792702371],
              [115.17354149494406, 1.1160348596210952],
              [115.18979126405566, 1.087810033806079],
              [115.18553021324885, 1.0676532293131231],
              [115.20031823779652, 1.061927278221333],
              [115.2300077948239, 1.0581766358430968],
              [115.25240368638106, 1.046624899019946],
              [115.28796700257521, 1.0469506478541462],
              [115.29964545207599, 1.0648039990053917],
              [115.31663535333894, 1.0593745615868215],
              [115.34225068472142, 1.0607393016774722],
              [115.36452103811553, 1.065893949446263],
              [115.37147528595574, 1.0719765150042235],
              [115.37844163687214, 1.099530700181731],
              [115.39389609772684, 1.1050018463581637],
              [115.41088366794702, 1.1277153523816423],
              [115.41815044638611, 1.1439773855479416],
              [115.41542188261383, 1.160293143313936],
              [115.40633790796812, 1.1727658650526678],
              [115.40816449669421, 1.190328357467763],
              [115.41753466529258, 1.1924667645296267],
              [115.42632603112145, 1.2185179847172662],
              [115.4363746437748, 1.2285489625646164],
              [115.46389479590812, 1.2394197606134867],
              [115.4951683513384, 1.255495005490161],
              [115.53853974431377, 1.2624641883716663],
              [115.57184375938209, 1.2719278387987742],
              [115.59377598927051, 1.2832141820888978],
              [115.59955083020668, 1.2804067747529189],
              [115.6317890838992, 1.2851984804256062],
              [115.65271350922114, 1.2813765955534677],
              [115.66699654735282, 1.2736943950803266],
              [115.69142878281218, 1.2709070327334189],
              [115.70848347393326, 1.2626162295554195],
              [115.71810690870689, 1.2658182836868832],
              [115.76050752400306, 1.2599315320146616],
              [115.7721991269882, 1.2709043914245512],
              [115.77103914004431, 1.3067450234640887],
              [115.75845179071757, 1.3312668565802142],
              [115.7690106499216, 1.355388642471894],
              [115.79298371852383, 1.3615157541487974],
              [115.8124952990189, 1.3792724812400934],
              [115.82567156489085, 1.3665721087159],
              [115.83194739295789, 1.378023264462115],
              [115.85505790829586, 1.402264023465932],
              [115.84836567433649, 1.4227273888579361],
              [115.85249998818392, 1.43599288366471],
              [115.88268939919647, 1.4530952595386566],
              [115.91198651276738, 1.4785554920423465],
              [115.95020660525074, 1.4884599982303826],
              [115.96274087299298, 1.521757966257212],
              [115.97274677054713, 1.5312360247228298],
              [116.00947217899795, 1.5272086771387876],
              [116.04082283420655, 1.5598521815330173],
              [116.04577145988503, 1.579477487596023],
              [116.03623285456263, 1.605871908861559],
              [116.05488830565082, 1.6253433436204432],
              [116.0765520960025, 1.6540164589924302],
              [116.10293203998174, 1.6656819726449612],
              [116.1150839789425, 1.654798444692517],
              [116.12935223132047, 1.6626557953287602],
              [116.13078922923955, 1.6774952350171475],
              [116.14131207868957, 1.6867347923767966],
              [116.14238126018711, 1.7018892474908398],
              [116.16186433936787, 1.7443182516860247],
              [116.15738521735398, 1.7524250122045828],
              [116.16817346303492, 1.7786364744937373],
              [116.17519365816088, 1.8156614983794839],
              [116.18412555385135, 1.8467056817218577],
              [116.17563888732491, 1.8709498284718507],
              [116.17953744479735, 1.9106968852531168],
              [116.19527406884322, 1.9553962594299037],
              [116.19395935623936, 1.9810038944142434],
              [116.22937818735352, 2.0106005406824465],
              [116.23289382698863, 2.028139744253849],
              [116.22558387389677, 2.0525471979948406],
              [116.22083725933214, 2.091965842149932],
              [116.23401655771806, 2.103215939164329],
              [116.26551400117023, 2.105003361515969],
              [116.28619088630046, 2.1026293024004303],
              [116.29567416533052, 2.1188089028086665],
              [116.31601225336283, 2.1167748647682174],
              [116.32169022562346, 2.1297040814782235],
              [116.32261307753151, 2.251490208206576],
              [116.31008545308123, 2.263509384750364],
              [116.32010440879151, 2.2796586825022587],
              [116.33037401994511, 2.283390644162921],
              [116.36006854212962, 2.3239994061477205],
              [116.37499767660154, 2.355547093823361],
              [116.40186939775458, 2.392706606824504],
              [116.41877404868342, 2.4278178671009414],
              [116.42753046505902, 2.4360032130778677],
              [116.44287323011554, 2.4667458492415903],
              [116.44138844582017, 2.484506146641422],
              [116.42402789886853, 2.4986686981019943],
              [116.43710399735164, 2.510082318844013],
              [116.4490393741678, 2.509853889245619],
              [116.49156800705794, 2.494402622782843],
              [116.50392498514998, 2.4799414640273767],
              [116.5170522667488, 2.4795814051577736],
              [116.52750195365229, 2.467172419271776],
              [116.55260943068072, 2.4546189348160397],
              [116.56450692715305, 2.4544998636778246],
              [116.56898031329092, 2.433382147068471],
              [116.58116315385655, 2.4280521539837423],
              [116.64613195732409, 2.4305458292266735],
              [116.69256000024393, 2.4397971731011125],
              [116.70797890820006, 2.4377413193064967],
              [116.72694190224706, 2.4436226624559367],
              [116.73658751969833, 2.4613722075613964],
              [116.7499873309506, 2.4646609788211435],
              [116.75409447178254, 2.475823604176014],
              [116.77653924688877, 2.482268965240621],
              [116.84862549457102, 2.519400410400692],
              [116.91652673002466, 2.533530129411986],
              [116.95298612980616, 2.5508460924732503],
              [117.00087549730642, 2.5827800443636306],
              [117.07413251660239, 2.6080991120844033],
              [117.14461192759848, 2.604940766802997],
              [117.2009314311407, 2.5962774194673557],
              [117.21796788760798, 2.596475878259241],
              [117.24202064513668, 2.6037179855966315],
              [117.28240369977095, 2.6217492136553346],
              [117.31459780664352, 2.626341573804609],
              [117.33544188563462, 2.621915776192168],
              [117.37446647384877, 2.6039748544559984],
              [117.40273447071934, 2.6096855602404503],
              [117.42794724037287, 2.5913456272988924],
              [117.45168446176854, 2.5576197015880098],
              [117.47031859532694, 2.544730598238573],
              [117.48673832371469, 2.539727329752509],
              [117.49855600895273, 2.52293414979124],
              [117.5407941239497, 2.5198995979881147],
              [117.57448505703985, 2.504078438569138],
              [117.59039793561635, 2.5202241804016694],
              [117.61178313308517, 2.521811781488566],
              [117.6223814959094, 2.5102690531743406],
              [117.63791506922507, 2.478019859229164],
              [117.66725055057248, 2.448952677124396],
              [117.68108063166585, 2.4157958280151206],
              [117.69970757424517, 2.403711459616204],
              [117.70835577789694, 2.3899416690728117],
              [117.72082870917768, 2.346972762283201],
              [117.74173198514302, 2.336141771825339],
              [117.79783086700093, 2.325750083999253],
              [117.80901027460459, 2.3267096480318514],
              [117.81899427186818, 2.3391406223419153],
              [117.82104078440477, 2.363132129744031],
              [117.83017831727557, 2.3663867762288078],
              [117.85696421072976, 2.39190943966895],
              [117.89868407341135, 2.404681319087672],
              [117.91935357240948, 2.395732575498016],
              [117.95187568091796, 2.414129836622692],
              [117.95837634399311, 2.4277689214985685],
              [117.97195792351943, 2.425344374443398],
              [117.98593392680937, 2.435721765746962],
            ],
          ],
        ],
      },
      properties: {
        cartodb_id: 35,
        country: 'Indonesia',
        id_1: 35,
        state: 'Kalimantan Utara',
        key: 'indonesia-kalimantanutara',
        name: 'North Kalimantan',
        slug: 'north-kalimantan',
      },
    },
  ],
};
