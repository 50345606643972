import { Avatar, Select } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { addDocumentPermission } from '../../services/action';
import { getTokenData } from '../../../../helpers/token';
import { getUsers } from '../../../../stores/user/action';
import { debounce } from 'lodash';

const AddAccess = ({ data, fetchUserPermissions }) => {
  const dispatch = useDispatch();
  const users = useSelector(state => state.user.list);
  const params = useSelector(state => state.user.params);
  const loadingGetUser = useSelector(state => state.user.loading);
  const [userSelected, setUserSelected] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChange = value => {
    const userToken = getTokenData();
    const formData = {
      documents_id: data?.id,
      user_id: value,
      permission_access: '[READ, UPDATE, DELETE]',
      created_by: userToken?.user_id,
    };
    setLoading(true);
    addDocumentPermission(formData)
      .then(() => {
        fetchUserPermissions();
        setUserSelected(null);
      })
      .finally(() => setLoading(false));
  };

  const handleSearch = debounce(value => {
    dispatch(getUsers({ ...params, search: value }));
  }, 500);

  return (
    <div className="p-5 border-b-4 border-gray-100">
      <Select
        style={{ width: '100%' }}
        placeholder="Tambahkan email atau nama"
        optionLabelProp="label"
        showSearch
        disabled={loading}
        loading={loading || loadingGetUser}
        showArrow={loading}
        value={userSelected}
        onChange={handleChange}
        onSearch={handleSearch}
        filterOption={false}
      >
        {users.map((item, idx) => (
          <Select.Option key={idx} value={item.user_id} label={`${item.first_name} ${item.last_name}`}>
            <div className="center-y">
              <div className="mr-3">
                <Avatar src="" icon={<UserOutlined />} size={40} className="border" />
              </div>
              <div>{`${item.first_name} ${item.last_name}`}</div>
            </div>
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default AddAccess;
