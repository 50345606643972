import { Avatar, Button, Col, Empty, Modal, Row, Select, Skeleton, Space } from 'antd';
import { useEffect, useState } from 'react';
import docIcon from '../../../../assets/icons/doc.svg';
import { UserOutlined, CloseOutlined, ExclamationCircleOutlined, GlobalOutlined } from '@ant-design/icons';
import { deleteDocumentPermission, getDocumentPermissions } from '../../services/action';
import { useDispatch } from 'react-redux';
import AddAccess from './AddAccess';
import { getUsers } from '../../../../stores/user/action';

const ModalAccess = ({ isOpen, onClose, data }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [userPermissions, setUserPermissions] = useState([]);

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  useEffect(() => {
    if (isOpen) {
      fetchUserPermissions();
    }
  }, [isOpen]);

  const fetchUserPermissions = () => {
    setLoading(true);
    getDocumentPermissions(data?.id)
      .then(res => setUserPermissions(res))
      .finally(() => setLoading(false));
  };

  const deleteUserPermissions = async id => {
    await deleteDocumentPermission(id).then(() => fetchUserPermissions());
  };

  const handleDelete = item => {
    Modal.confirm({
      title: 'Apakah anda yakin ingin menghapus akses untuk user ini?',
      icon: <ExclamationCircleOutlined className="mt-1" />,
      onOk: () => deleteUserPermissions(item?.id),
      okText: 'Iya',
      cancelText: 'Batal',
    });
  };

  return (
    <Modal
      width={400}
      title={
        <div className="flex items-center" style={{ fontSize: 14 }}>
          <div>
            <img src={docIcon} width={40} />
          </div>
          <div className="ml-3 w-full">
            <div>{data?.document_name}</div>
            {data?.sub_category && <div className="text-gray-400">{data?.sub_category}</div>}
          </div>
        </div>
      }
      maskClosable={false}
      centered
      open={isOpen}
      footer={null}
      onCancel={onClose}
      cancelText="Batal"
      okText="Simpan"
      confirmLoading={loading}
      destroyOnClose
      bodyStyle={{ padding: 0, paddingBottom: '1rem' }}
    >
      <AddAccess data={data} fetchUserPermissions={fetchUserPermissions} />
      {userPermissions.length > 0 && (
        <div className="p-5 py-3 border-b" style={{ fontSize: 16 }}>
          Anggota yang memiliki akses
        </div>
      )}
      <div style={{ maxHeight: '50vh', minHeight: '30vh', overflow: 'auto' }}>
        {loading && (
          <div className="p-5 pt-4">
            {[1, 2].map(item => (
              <Row key={item} className="py-3">
                <Col md={4}>
                  <Skeleton.Avatar active size="large" />
                </Col>
                <Col md={20}>
                  <Space direction="vertical" size={4} className="w-full">
                    <Skeleton.Input style={{ height: 16 }} active block />
                    <Skeleton.Input style={{ height: 16 }} active block />
                  </Space>
                </Col>
              </Row>
            ))}
          </div>
        )}
        {!loading &&
          userPermissions.map((item, idx) => (
            <div key={idx} className="center-y justify-between border-b p-3 px-5">
              <div className="center-y">
                <div className="mr-3">
                  <Avatar icon={<UserOutlined />} size={42} className="border" />
                </div>
                <div>
                  <div>{`${item.first_name || '-'} ${item.last_name || ''}`}</div>
                  <div className="text-gray-400">{`${item.structural?.position_name || '-'} ${
                    item.structural?.division_name || ''
                  }`}</div>
                </div>
              </div>
              <Button size="small" danger icon={<CloseOutlined />} onClick={() => handleDelete(item)} />
            </div>
          ))}
        {!loading && userPermissions.length === 0 && (
          <div className="p-5">
            <Empty
              image={<GlobalOutlined style={{ fontSize: 80, color: '#E0E0E0' }} />}
              imageStyle={{ display: 'flex', justifyContent: 'center', alignItems: 'end' }}
              description="Semua user dapat mengakses file ini"
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ModalAccess;
