import { Layout } from 'antd';
import React from 'react';
import AppContent from './content/AppContent';
import AppHeader from './header/AppHeader';
import AppSidebar from './sidebar/AppSidebar';

const DashboardLayout = () => {
  return (
    <Layout>
      <AppHeader />
      <Layout>
        <AppSidebar />
        <AppContent />
      </Layout>
    </Layout>
  );
};
export default DashboardLayout;
