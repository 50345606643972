import { Layout, Menu } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import menus from '../../routes/menus';

const { Sider } = Layout;

const AppSidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const collapsed = useSelector(state => state.app.collapsed);

  return (
    <Sider
      theme="light"
      collapsible
      collapsed={collapsed}
      onCollapse={value => dispatch({ type: 'collapse', collapsed: value })}
      collapsedWidth={55}
      width={240}
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        marginTop: '68px',
        zIndex: 1,
        left: 0,
        top: 0,
        bottom: 0,
      }}
    >
      <Menu
        mode="inline"
        selectedKeys={[location.pathname]}
        className="app-menu"
        items={menus.map(item => {
          if (item.children) {
            item.children.map(child => {
              child.onClick = () => navigate(child.key);
              return child;
            });
          } else {
            item.onClick = () => navigate(item.key);
          }
          return item;
        })}
      />
    </Sider>
  );
};

export default AppSidebar;
